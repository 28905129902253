<template>
  <div class="amap-wrapper">
    <div id="printoutPanel"></div>
    <a-select
      v-if="amap_is_search"
      class="search-box"
      show-search
      :value="searchName"
      placeholder="请输入地址查询"
      :default-active-first-option="false"
      :show-arrow="false"
      :filter-option="false"
      :not-found-content="null"
      @search="searchHandle"
      @select="handleSelect"
    >
      <a-select-option v-for="(d,index) in searchData" :key="index">
        {{ d.name }}
      </a-select-option>
    </a-select>
    <div
      :id="'byMap_'+amap_create_time"
      :style="{ width: amap_width, height: amap_height }"
      class="map_d"
      style="position: relative;"
    >

    </div>
  </div>
</template>

<script>
import { replaceStrAmis } from '@/utils/util'
var searchManager = null
export default {
  props: {
    width: {
      type: String || Number,
      default: '90%'
    },
    height: {
      type: String || Number,
      default: '500px'
    },
    isSearch: {
      type: Boolean,
      default: true
    },
    // province: {
    //   type: String,
    //   default: ''
    // },
    // city: {
    //   type: String,
    //   default: ''
    // },
    // area: {
    //   type: String,
    //   default: ''
    // },
    address: {
      type: String,
      default: ''
    },
    lat: {
      type: [String, Number],
      default: null
    },
    lng: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      searchName: null,
      searchData: [],
      zoom: 12,
      center: [116.396304, 39.909847],
      events: null,
      markers: [],
      latitude: '11111111',
      searchOption: {
        city: '',
        citylimit: false
      },
      plugin: [],
      defaultInfobox: null
    }
  },
  beforeMount() {
    // // console.log('byMapScript>>>>>', byMapScript)

    // byMapScript.src = 'https://www.bing.com/api/maps/mapcontrol?key=' + this.amap_key + '&mkt=zh-cn'
  },
  mounted() {
    // console.log('self>>>>>1>>>>>', this)
  },
  computed: {
    markersList() {
      // console.log('计算属性>>>>amap_list>>>>', this.amap_list)
      // console.log('计算属性>>>>data>>>>', this.$data.data)
      // console.log('计算属性>>>>', this.$data.data[this.amap_list])
      let markers = [
            // { lng: this.center[0], lat: this.center[1], name: label }
      ]
      if (this.amap_is_show_more_marker) {
        // // console.log('init>>>>>data>>>>>amap_list>>>>', this.$data.data[this.amap_list])
        const mapList = this.$data.data[this.amap_list]
        // // console.log('init>>>>>amap_list>>>>', mapList)
        if (mapList && mapList.length > 0) {
          // this.center = []

          mapList.forEach(item => {
            const lng = item[this.amap_longitude]
            // || 116.396304
            const lat = item[this.amap_latitude]
            // || 39.909847
            const label = item[this.amap_name]
            if (lng && lat) {
              // if (this.center.length === 0) {
              //   this.center = [lng, lat]
              // }
              markers.push(
                { lng: lng, lat: lat, name: label, info: item }
              )
            }
          })
          // if (this.center.length === 0) {
          //   this.center = [116.396304, 39.909847]
          // }
        }
      } else {
        const lng = this.$data.data[this.amap_longitude]
        // || 116.396304
        const lat = this.$data.data[this.amap_latitude]
        // || 39.909847
        const label = this.$data.data[this.amap_name]
        // // console.log('lng>>>>>>', lng)

        if (lng && lat) {
          // this.center = [lng, lat]
          markers = [
            { lng: lng, lat: lat, name: label, info: this.data }
          ]
        } else {
          // this.center = [116.396304, 39.909847]
        }
      }
      return markers
    }
  },
  watch: {
    markersList: {
      deep: true,
      handler() {
        this.updatedMap()
      }
    }
  },
  created() {
    // console.log('markersList>>>>', this.markersList)
    this.init()
    // setTimeout(() => {
    //   this.$nextTick(() => {
    //     this.init()
    //   })
    // }, 1000)
  },
  updated() {
    if (this.renderChild && this.body) {
      this.renderChild('body', this.body, 'ReactChildBody')
    }
    // // console.log('markersList>>>>>', this.markersList)
  },
  methods: {
    updatedMap() {
      this.markers = JSON.parse(JSON.stringify(this.markersList))
      // // console.log('updatedMap>>>>>>', this.markersList)
      this.getMap()
      // // 创建标注点
      // this.createMarkers()
      // // // 设置折线
      // this.setPolyline()
    },

    getMap () {
      // 初始化地图
      // eslint-disable-next-line no-undef
      this.map = new Microsoft.Maps.Map('#byMap_' + this.amap_create_time, {
        // eslint-disable-next-line no-undef
        navigationBarMode: Microsoft.Maps.NavigationBarMode.minified
        // eslint-disable-next-line no-undef
        // center: new Microsoft.Maps.Location(this.center[0], this.center[1])
      })
      // // console.log('初始化地图>>>>>>', this.map)

      // 地图点击事件
      this.clickHandle()
      // // 创建标注点
      this.createMarkers()
      // // 设置折线
      this.setPolyline()
    },
    /**
     * 地图点击事件
     */
    clickHandle() {
      if (!this.amap_is_click) {
        // 判断是否支持点击事件
        return false
      }
       // 给地图添加点击事件
      // eslint-disable-next-line no-undef
      Microsoft.Maps.Events.addHandler(this.map, 'click', (e) => {
        // console.log('>>>>>', e)
        this.search(e.location)
      })
    },
    search(location) {
      const that = this
      // Create an instance of the search manager and perform the search.
      // eslint-disable-next-line no-undef
      Microsoft.Maps.loadModule('Microsoft.Maps.Search', () => {
        // eslint-disable-next-line no-undef
        searchManager = new Microsoft.Maps.Search.SearchManager(this.map)
        var reverseGeocodeRequestOptions = {
            // eslint-disable-next-line no-undef
            location: new Microsoft.Maps.Location(location.latitude, location.longitude),
            callback: function (answer, userData) {
              that.map.setView({ bounds: answer.bestView })
              that.map.entities.clear()
              // // console.log('reverseGeocodeRequestOptions>>>>>>', answer)

              // eslint-disable-next-line no-undef
              that.map.entities.push(new Microsoft.Maps.Pushpin(reverseGeocodeRequestOptions.location))
                // document.getElementById('printoutPanel').innerHTML =
                //     answer.address.formattedAddress
              const detailObj = {
                addressComponent: {
                  detailedAddress: answer.address.formattedAddress,
                  province: answer.address.adminDistrict,
                  city: answer.address.district,
                  district: answer.address.locality
                },
                detailedAddress: answer.address.formattedAddress,
                lng: answer.location.longitude,
                lat: answer.location.latitude
              }
              const dataCopy = JSON.parse(JSON.stringify(that.$data.data))
              dataCopy[that.$data.amap_longitude] = detailObj.lng
              dataCopy[that.amap_latitude] = detailObj.lat
              dataCopy[that.amap_info] = detailObj
              // that.$data.data = dataCopy
              that.$set(that.$data, 'defaultData', dataCopy)
              // // console.log('设置标记点之后的数据>>>>>>', that.$data)
              that.$emit('update:amap_info', detailObj)
              that.$data.amap_info = detailObj
              this.setValidData(this.$schema.amap_info, detailObj.detailedAddress)
              this.setValidData(this.$schema.amap_longitude, detailObj.lng)
              this.setValidData(this.$schema.amap_latitude, detailObj.lat)
              this.setValidData(this.$schema.province, detailObj.addressComponent.province)
              this.setValidData(this.$schema.city, detailObj.addressComponent.city || detailObj.addressComponent.province)
              this.setValidData(this.$schema.area, detailObj.addressComponent.district)
              this.setValidData('amapDetailInfo', detailObj.addressComponent)
            }
        }
        searchManager.reverseGeocode(reverseGeocodeRequestOptions)
      })
    },
    searchHandle(value) {
      const that = this
      this.searchName = value
      // console.log('searchName>>>>>', this.searchName)

      if (!searchManager) {
          // Create an instance of the search manager and perform the search.
          // eslint-disable-next-line no-undef
          Microsoft.Maps.loadModule('Microsoft.Maps.Search', function () {
              // eslint-disable-next-line no-undef
              searchManager = new Microsoft.Maps.Search.SearchManager(that.map)
              that.searchHandle(this.searchName)
          })
      } else {
          // Remove any previous results from the map.
          that.map.entities.clear()

          // Get the users query and geocode it.
          that.geocodeQuery(that.searchName)
      }
    },
    geocodeQuery(query) {
      const that = this
      var searchRequest = {
          where: query,
          callback: function (r) {
            // console.log('搜索结果>>>>', r)

              if (r && r.results && r.results.length > 0) {
                that.searchData = r.results
                // console.log('searchData>>>>>', that.searchData)
              }
          },
          errorCallback: function (e) {
              // If there is an error, alert the user about it.
              // document.getElementById('printoutPanel').innerHTML = 'No results found.'
          }
      }
      // Make the geocode request.
      // eslint-disable-next-line no-undef
      searchManager.geocode(searchRequest)
  },
  /**
   * @desc 选择搜索结果
   * @param {  }
   * @version: 1.0.0
   */
  handleSelect(value) {
    // console.log('选择搜索结果>>>>>', value)

    this.search(this.searchData[value].location)
  },
    /**
     * @desc 创建标注点
     * @param {  }
     * @version: 1.0.0
     */
    createMarkers () {
      const clusteringList = []
      let isSetCenter = false
      // console.log('创建标注点>>>>>', this.markers)

      this.markers.forEach(item => {
        let color = this.amap_marker_color || '#aa298f'
        if (!isSetCenter) {
          if (item.lat && item.lng) {
            isSetCenter = true
            this.map.setView({
              // eslint-disable-next-line no-undef
              mapTypeId: Microsoft.Maps.MapTypeId.aerial,
              // eslint-disable-next-line no-undef
              center: new Microsoft.Maps.Location(item.lat, item.lng),
              zoom: 15
          })
          }
        }
        // 是否配置不同的状态颜色
        if (this.amap_is_set_status) {
          // // console.log('111>>>>>>>item>>>>', item)
          // // console.log('111>>>>>>>', this.amap_status_key)
          // // console.log('111>>>>>>>', item)
          // // console.log('111>>>>>>>', this.$schema.amap_status_key)
          if (this.amap_status_key) {
            for (const key in this.amap_status_key) {
              if (Object.hasOwnProperty.call(item.info, key)) {
                const itemKeyValue = item.info[key]
                const colorObj = this.amap_status_key[key]
                color = colorObj.color[itemKeyValue]
              }
            }
          }
        }
        // eslint-disable-next-line no-undef
        const marker = new Microsoft.Maps.Pushpin({ latitude: item.lat, longitude: item.lng }, {
          // icon: bar1
          color: color,
          title: item.name
        })
        // 记录聚合点
        clusteringList.push(marker)
        // icon: require('../assets/home/map-error.png'),
        // 将标注点加入地图实体
        // 如果不使用聚合功能
        if (!this.amap_is_set_Clustering) {
        // eslint-disable-next-line no-undef
          var layer = new Microsoft.Maps.Layer()
          layer.add(marker)
          this.map.layers.insert(layer)
        }

        // this.map.entities.push(marker)
        // 给标注点加点击事件
        // eslint-disable-next-line no-undef
        Microsoft.Maps.Events.addHandler(marker, 'click', () => { this.markerClick(item) })
      })

      // 如果使用聚合功能
      if (this.amap_is_set_Clustering) {
        this.setClustering(clusteringList)
      }

      // Microsoft.Maps.loadModule('Microsoft.Maps.Clustering', () => {
      //   var pushpins = Microsoft.Maps.TestDataGenerator.getPushpins(1000, map.getBounds())
      //   var clusterLayer = new Microsoft.Maps.ClusterLayer(pushpins, { gridSize: 100 })
      //   this.map.layers.insert(clusterLayer)
      // })
    },
    /**
     * @desc 标记点点击事件
     * @param {  }
     * @version: 1.0.0
     */
    markerClick(item) {
      if (!this.amap_is_show_window) {
        // 判断是否显示标记点详情
        return
      }
      if (this.defaultInfobox) {
        this.defaultInfobox.setOptions({ visible: false })
      }
      // 替换设置的值
      const descriptionTem = replaceStrAmis(this.amap_window_dom, item.info)

      // eslint-disable-next-line no-undef
      this.defaultInfobox = new Microsoft.Maps.Infobox(new Microsoft.Maps.Location(item.lat, item.lng), {
        // description: `<div style="padding:5px"><b>名称： ${item.name} </b></div>`,
        description: descriptionTem,
        visible: false
      })
      this.defaultInfobox.setMap(this.map)
      this.defaultInfobox.setOptions({ visible: true })
    },
    /**
     * @desc 设置折线
     * @param {  }
     * @version: 1.0.0
     */
    setPolyline() {
      if (!this.amap_is_show_polyline) {
        // 判断是否添加折线
        return
      }
      const polylineList = this.$data.data[this.amap_polyline_list] || []
      const PolylineArr = []
      polylineList.forEach(item => {
        const lng = item[this.amap_longitude]
        const lat = item[this.amap_latitude]
        // eslint-disable-next-line no-undef
        PolylineArr.push(new Microsoft.Maps.Location(lat, lng))
      })
      // Create the polyline.
      // eslint-disable-next-line no-undef
      var polyline = new Microsoft.Maps.Polyline(polylineList, {
          strokeColor: this.amap_stroke_color || 'red',
          strokeThickness: this.amap_stroke_thickness || 4
          // strokeDashArray: [3, 3]
      })

      // Add the polyline to the map using a layer.
      // eslint-disable-next-line no-undef
      var layer = new Microsoft.Maps.Layer()
      layer.add(polyline)
      this.map.layers.insert(layer)
    },
    /**
     * @desc 设置聚合点
     * @param {  }
     * @version: 1.0.0
     */
    setClustering(list) {
      const that = this
      // eslint-disable-next-line no-undef
      Microsoft.Maps.loadModule('Microsoft.Maps.Clustering', function () {
        // Generate 3000 random pushpins in the map view.
        // var pins = Microsoft.Maps.TestDataGenerator.getPushpins(10, that.map.getBounds())
        // var pins = []
        // list.forEach(item => {
        //   pins.push(new Microsoft.Maps.Location(item.lat, item.lng))
        // })
        // // console.log('设置聚合点>>>>>pins>>>>', pins)
        // console.log('设置聚合点>>>>>', list)
        // Create a ClusterLayer with options and add it to the map.
        // eslint-disable-next-line no-undef
        var clusterLayer = new Microsoft.Maps.ClusterLayer(list, {
            clusteredPinCallback: that.customizeClusteredPin,
            gridSize: 100
        })
        that.map.layers.insert(clusterLayer)
      })
    },
    customizeClusteredPin(cluster) {
            // Add click event to clustered pushpin
      // eslint-disable-next-line no-undef
      Microsoft.Maps.Events.addHandler(cluster, 'click', this.clusterClicked)
    },
    clusterClicked(e) {
      if (e.target.containedPushpins) {
          var locs = []
          for (var i = 0, len = e.target.containedPushpins.length; i < len; i++) {
                // Get the location of each pushpin.
              locs.push(e.target.containedPushpins[i].getLocation())
          }

          // Create a bounding box for the pushpins.
          // eslint-disable-next-line no-undef
          var bounds = Microsoft.Maps.LocationRect.fromLocations(locs)

          // Zoom into the bounding box of the cluster.
          // Add a padding to compensate for the pixel area of the pushpins.
          this.map.setView({ bounds: bounds, padding: 100 })
      }
    },
    setValidData(key, value) {
      if (this.store.storeType === 'ServiceStore') { // 上级作用域为顶层作用域或者Service
        this.store.reInitData({ [key]: value })
      } else if (this.store.storeType === 'FormStore') { // 上级作用域为表单
        this.store.setValueByName(key, value)
      }
    },
    init() {
      if (this.amap_is_show_more_marker) {
        // // console.log('init>>>>>data>>>>>amap_list>>>>', this.$data.data[this.amap_list])
        const mapList = this.$data.data[this.amap_list]
        // // console.log('init>>>>>amap_list>>>>', mapList)
        if (mapList && mapList.length > 0) {
          this.center = []
          this.markers = [
            // { lng: this.center[0], lat: this.center[1], name: label }
          ]
          mapList.forEach(item => {
            const lng = item[this.amap_longitude]
            // || 116.396304
            const lat = item[this.amap_latitude]
            // || 39.909847
            const label = item[this.amap_name]
            if (lng && lat) {
              if (this.center.length === 0) {
                this.center = [lng, lat]
              }
              this.markers.push(
                { lng: lng, lat: lat, name: label, info: item }
              )
            }
          })
          if (this.center.length === 0) {
            this.center = [116.396304, 39.909847]
          }
        }
      } else {
        const lng = this.$data.data[this.amap_longitude]
        // || 116.396304
        const lat = this.$data.data[this.amap_latitude]
        // || 39.909847
        const label = this.$data.data[this.amap_name]
        // // console.log('lng>>>>>>', lng)

        if (lng && lat) {
          this.center = [lng, lat]
          this.markers = [
            { lng: lng, lat: lat, name: label, info: this.data }
          ]
        } else {
          this.center = [116.396304, 39.909847]
        }
      }
      // this.getMap()
    },

    geoCoder(positionArr) {
      // 获取地址经纬度和省市区
      var that = this
      // 这里通过高德 SDK 完成。
      // eslint-disable-next-line no-undef
      var geocoder = new AMap.Geocoder({
        radius: 1000,
        extensions: 'all'
      })
      // console.log('positionArr>>>>>', positionArr)

      geocoder.getAddress(positionArr, (status, result) => {
        // // console.log('getAddress>>>>status>>>>>', status)
        // // console.log('getAddress>>>>result>>>>', result)

        if (status === 'complete' && result.info === 'OK') {
          if (result && result.regeocode) {
            const detailObj = {
              addressComponent: result.regeocode.addressComponent,
              detailedAddress: result.regeocode.formattedAddress,
              lng: positionArr[0],
              lat: positionArr[1]
            }

            that.amapInfo = detailObj
            that.markers.push({
              lng: positionArr[0],
              lat: positionArr[1],
              name: result.regeocode.formattedAddress
            })
            // // console.log('地址信息>>>>', detailObj)
            // // console.log('地址标记点信息>>>>', that.markers)
            // // console.log('地址标记点信息>>>>data', that.$data)
            // // console.log('地址标记点信息>>>>data', that.$data.amap_longitude)
            // // console.log('地址标记点信息>>>>data', that.$data.data)
            const dataCopy = JSON.parse(JSON.stringify(that.$data.data))
            dataCopy[that.$data.amap_longitude] = detailObj.lng
            dataCopy[that.amap_latitude] = detailObj.lat
            dataCopy[that.amap_info] = detailObj
            // that.$data.data = dataCopy
            that.$set(that.$data, 'defaultData', dataCopy)
            // // console.log('设置标记点之后的数据>>>>>>', that.$data)
            that.$emit('update:amap_info', detailObj)
            that.$data.amap_info = detailObj
            this.setValidData(this.$schema.amap_info, detailObj.detailedAddress)
            this.setValidData(this.$schema.amap_longitude, detailObj.lng)
            this.setValidData(this.$schema.amap_latitude, detailObj.lat)
            this.setValidData(this.$schema.province, detailObj.addressComponent.province)
            this.setValidData(this.$schema.city, detailObj.addressComponent.city || detailObj.addressComponent.province)
            this.setValidData(this.$schema.area, detailObj.addressComponent.district)
            this.setValidData('amapDetailInfo', detailObj.addressComponent)
          }
        } else {
          if (that.errorInfo[result]) {
            that.$message.error(that.errorInfo[result])
          }
        }
      })
    },
    /**
     * 添加amis事件动作:
     * 在这里设置自定义组件对外暴露的动作，其他组件可以通过组件动作触发自定义组件的对应动作
     */
     doAction(action, args) {
      // console.log('doAction>>>>>>', action, args)

      const actionType = action ? action.actionType : ''
      if (actionType === 'toast') {
        // 接收外部组件的事件动作'message'
        // console.log(args)
        alert('您触发了自定义组件的事件动作[message]')
      } else {
        // console.log('自定义组件中监听到的事件动作：', action, ', 事件参数：', args)
      }
    }
  }
}
</script>
<style lang="less" scoped>
::v-deep{
  .el-vue-search-box-container {
    width: 270px;
  }
}
.amap-box {
  ::v-deep{
    .amap-marker-label {
      transform: translate(-40%, -24px);
      border: none;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
    }
  }
}

.amap-wrapper {
  text-align: left;
  position: relative;
}
.amap-box {
  width: 90%;
  // margin-left: 5%;
}
.search-box {
  width: 30%;
  position: absolute;
  top: 15px;
  left: 3%;
  z-index: 999;
}
</style>
