/**
 * 向后端请求用户的菜单，动态生成路由
 */
import { constantRouterMap } from '@/config/router.config'
import { generatorDynamicRouter } from '@/router/generator-routers'

const permission = {
  state: {
    routers: constantRouterMap,
    menus: [],

    menusName: null, // 头部选中目录名称，用于切换目录
  },
  mutations: {
    SET_ROUTERS: (state, res) => {
      state.menus = constantRouterMap.concat(res.menus)
      state.routers = constantRouterMap.concat(res.routers)
    },
    SET_MENUS_NAME: (state, name) => {
      // console.log(name);
      state.menusName = name
    }
  },
  actions: {
    GenerateRoutes ({ commit }, data) {
      return new Promise(resolve => {
        generatorDynamicRouter(data).then((res) => {
          commit('SET_ROUTERS', res)
          resolve(res)
        })
      })
    }
  }
}

export default permission
