/* eslint-disable no-template-curly-in-string */
/**
 * @file 编辑器扩展, 增加自定义组件
 */
import { BasePlugin, registerEditorPlugin } from 'amis-editor'

export class InfoGridPlugin extends BasePlugin {
  // 关联渲染器名字
  rendererName = '评论';
  $schema = '/schemas/UnkownSchema.json';
  notRenderFormZone = true;
  // 组件名称（组件面板显示的Title）
  name = 'chamcomment';
  order = 0; // 排序
  description = '自定义评论';
  tags = ['展示', 'A自定义'];
  icon = 'fa fa-file-code-o';
  scaffold = {
    'type': 'crud',
    'syncLocation': false,
    'api': {
      'method': 'get',
      'url': '/article/article-comment/page',
      'messages': {},
      'requestAdaptor': '',
      'adaptor': '',
      'dataType': 'json',
      'data': {
        '&': '$$',
        'articleId': '${id}'
      },
      'silent': true
    },
    'bulkActions': [],
    'itemActions': [],
    'id': 'u:1694e2ab23ca',
    'perPageAvailable': [
      10,
      20,
      50,
      100
    ],
    'name': '',
    'messages': {},
    'mode': 'table',
    'bodyClassName': 'no-border',
    'autoFillHeight': true,
    'itemClassName': 'no-border',
    'footerToolbar': [
      {
        'type': 'statistics',
        'align': 'left'
      },
      {
        'type': 'pagination',
        'align': 'right'
      },
      {
        'type': 'switch-per-page',
        'align': 'right',
        'tpl': '内容',
        'wrapperComponent': '',
        'id': 'u:6fc10b224ca7'
      }
    ],
    'pageField': 'pageNum',
    'perPageField': 'pageSize',
    'alwaysShowPagination': true,
    'columns': [
      {
        'type': 'container',
        'body': [
          {
            'type': 'avatar',
            'showtype': 'image',
            'icon': '',
            'fit': 'cover',
            'style': {
              'width': 40,
              'height': 40,
              'flexBasis': '100%',
              'borderRadius': 20
            },
            'id': 'u:8885c42e815a',
            'body': [],
            'size': 'image',
            'wrapperBody': false,
            'label': '',
            'isFixedHeight': false,
            'isFixedWidth': false,
            'src': "${userFileInfosVo||'http://47.92.37.224:7076/pic/default.png'}"
          },
          {
            'type': 'container',
            'body': [
              {
                'type': 'container',
                'body': [
                  {
                    'type': 'tpl',
                    'tpl': '${userName}',
                    'inline': true,
                    'wrapperComponent': '',
                    'id': 'u:78dfb0357f72',
                    'themeCss': {
                      'baseControlClassName': {
                        'font:default': {
                          'color': '#3eacff',
                          'fontSize': '14px',
                          'font-family': '思源-Regular'
                        }
                      }
                    }
                  }
                ],
                'style': {
                  'position': 'static',
                  'display': 'block'
                },
                'size': 'none',
                'wrapperBody': false,
                'id': 'u:578d36837f88'
              },
              {
                'type': 'container',
                'body': [
                  {
                    'type': 'tpl',
                    'tpl': '${commentTime}',
                    'inline': true,
                    'wrapperComponent': '',
                    'id': 'u:c611087729e9',
                    'themeCss': {
                      'baseControlClassName': {
                        'font:default': {
                          'color': '#333333',
                          'fontSize': '12px',
                          'font-family': '思源-Regular'
                        }
                      }
                    }
                  }
                ],
                'style': {
                  'position': 'static',
                  'display': 'block'
                },
                'size': 'none',
                'wrapperBody': false,
                'id': 'u:9ee007f5a18d'
              },
              {
                'type': 'container',
                'body': [
                  {
                    'type': 'tpl',
                    'tpl': '${text}',
                    'inline': true,
                    'wrapperComponent': '',
                    'id': 'u:3fcb878f19ef',
                    'themeCss': {
                      'baseControlClassName': {
                        'font:default': {
                          'color': '#333333',
                          'fontSize': '14px',
                          'font-family': '思源-Regular'
                        }
                      }
                    }
                  }
                ],
                'style': {
                  'position': 'static',
                  'display': 'block'
                },
                'size': 'none',
                'wrapperBody': false,
                'id': 'u:1f7d117e9e05'
              },
              {
                'type': 'container',
                'body': [
                  {
                    'type': 'icon',
                    'icon': 'fa fas fa-thumbs-up',
                    'vendor': '',
                    'id': 'u:5e3926cd4af6',
                    'themeCss': {
                      'className': {
                        'font': {
                          'color': '#3eacff'
                        }
                      }
                    }
                  },
                  {
                    'type': 'tpl',
                    'tpl': '${dzNum||0}',
                    'inline': true,
                    'wrapperComponent': '',
                    'id': 'u:75685a9ddfd5',
                    'themeCss': {
                      'baseControlClassName': {
                        'padding-and-margin:default': {
                          'marginLeft': '5px'
                        }
                      }
                    }
                  },
                  {
                    'type': 'button',
                    'label': '回复',
                    'onEvent': {
                      'click': {
                        'actions': [
                          {
                            'ignoreError': false,
                            'actionType': 'dialog',
                            'dialog': {
                              'type': 'dialog',
                              'title': '回复',
                              'body': [
                                {
                                  'id': 'u:dfdb4f303a9c',
                                  'type': 'form',
                                  'title': '表单',
                                  'mode': 'horizontal',
                                  'dsType': 'api',
                                  'feat': 'Insert',
                                  'body': [
                                    {
                                      'type': 'hidden',
                                      'name': 'articleId',
                                      'id': 'u:52254281ca2e',
                                      'label': '文章标识'
                                    },
                                    {
                                      'type': 'hidden',
                                      'name': 'articleCommentPid',
                                      'id': 'u:e5af351dd989',
                                      'label': '回复人',
                                      'value': '${id}'
                                    },
                                    {
                                      'name': 'text',
                                      'label': '回复内容',
                                      'type': 'textarea',
                                      'id': 'u:f958c4fafed8',
                                      'minRows': 3,
                                      'maxRows': 20,
                                      'placeholder': '请输入',
                                      'value': "${''}",
                                      'required': true,
                                      'validations': {
                                        'maxLength': 2000
                                      },
                                      'validationErrors': {
                                        'maxLength': '最长输入2000个字符'
                                      }
                                    }
                                  ],
                                  'actions': [
                                    {
                                      'type': 'button',
                                      'label': '提交',
                                      'onEvent': {
                                        'click': {
                                          'actions': [
                                            {
                                              'actionType': 'submit',
                                              'componentId': 'u:dfdb4f303a9c'
                                            }
                                          ]
                                        }
                                      },
                                      'level': 'primary'
                                    }
                                  ],
                                  'resetAfterSubmit': true,
                                  'debug': false,
                                  'api': {
                                    'url': '/article/article-comment/add',
                                    'method': 'post',
                                    'requestAdaptor': '',
                                    'adaptor': 'return response',
                                    'messages': {},
                                    'data': {
                                      '&': '$$'
                                    }
                                  },
                                  'onEvent': {
                                    'submitSucc': {
                                      'weight': 0,
                                      'actions': [
                                        {
                                          'componentId': 'u:1694e2ab23ca',
                                          'ignoreError': false,
                                          'actionType': 'reload',
                                          'args': {
                                            'resetPage': true
                                          }
                                        }
                                      ]
                                    }
                                  }
                                }
                              ],
                              'showCloseButton': true,
                              'showErrorMsg': true,
                              'showLoading': true,
                              'className': 'app-popover :AMISCSSWrapper',
                              'actions': [
                                {
                                  'type': 'button',
                                  'actionType': 'cancel',
                                  'label': '取消',
                                  'id': 'u:091b9def2923'
                                },
                                {
                                  'type': 'button',
                                  'actionType': 'confirm',
                                  'label': '确认',
                                  'primary': true,
                                  'id': 'u:91308c203e7a'
                                }
                              ],
                              'id': 'u:ab5187ef3838',
                              'closeOnOutside': false,
                              'closeOnEsc': false,
                              'draggable': false,
                              'size': 'lg'
                            }
                          }
                        ]
                      }
                    },
                    'id': 'u:50bd22bbacc6',
                    'level': 'link',
                    'visible': true,
                    'size': 'xs',
                    'themeCss': {
                      'className': {
                        'padding-and-margin:default': {
                          'marginLeft': '20px'
                        }
                      }
                    }
                  }
                ],
                'style': {
                  'position': 'static',
                  'display': 'block',
                  'flex': '0 0 auto'
                },
                'size': 'none',
                'wrapperBody': false,
                'id': 'u:e7a79e47a845',
                'isFixedWidth': false,
                'themeCss': {
                  'baseControlClassName': {
                    'padding-and-margin:default': {
                      'marginBottom': '10px'
                    }
                  }
                }
              },
              {
                'type': 'container',
                'body': [
                  {
                    'type': 'each',
                    'columnsCount': 1,
                    'placeholder': '',
                    'style': {
                      'gutterY': 10,
                      'display': 'flex',
                      'flexWrap': 'nowrap',
                      'flexDirection': 'column',
                      'width': '100%'
                    },
                    'id': 'u:81095266044e',
                    'editorSetting': {
                      'displayName': ''
                    },
                    'source': '${returnList}',
                    'name': 'returnList',
                    'items': {
                      'type': 'container',
                      'body': [
                        {
                          'type': 'container',
                          'body': [
                            {
                              'type': 'avatar',
                              'showtype': 'image',
                              'icon': '',
                              'fit': 'cover',
                              'style': {
                                'width': 40,
                                'height': 40,
                                'borderRadius': 20
                              },
                              'id': 'u:6577b8741383',
                              'body': [],
                              'size': 'image',
                              'wrapperBody': false,
                              'label': '',
                              'isFixedHeight': false,
                              'isFixedWidth': false,
                              'src': "${userFileInfosVo||'http://47.92.37.224:7076/pic/default.png'}"
                            },
                            {
                              'type': 'container',
                              'body': [
                                {
                                  'type': 'container',
                                  'body': [
                                    {
                                      'type': 'tpl',
                                      'tpl': '${userName}',
                                      'inline': true,
                                      'wrapperComponent': '',
                                      'id': 'u:156f643f5bc4',
                                      'themeCss': {
                                        'baseControlClassName': {
                                          'font:default': {
                                            'color': '#3eacff',
                                            'fontSize': '14px',
                                            'font-family': '思源-Regular'
                                          }
                                        }
                                      }
                                    }
                                  ],
                                  'style': {
                                    'position': 'static',
                                    'display': 'block'
                                  },
                                  'size': 'none',
                                  'wrapperBody': false,
                                  'id': 'u:8abdd2cc643f'
                                },
                                {
                                  'type': 'container',
                                  'body': [
                                    {
                                      'type': 'tpl',
                                      'tpl': "${commentTime/1000|date:YYYY-MM-DD HH':'mm':'ss}",
                                      'inline': true,
                                      'wrapperComponent': '',
                                      'id': 'u:2825c35bd643',
                                      'themeCss': {
                                        'baseControlClassName': {
                                          'font:default': {
                                            'color': '#333333',
                                            'fontSize': '12px',
                                            'font-family': '思源-Regular'
                                          }
                                        }
                                      }
                                    }
                                  ],
                                  'style': {
                                    'position': 'static',
                                    'display': 'block'
                                  },
                                  'size': 'none',
                                  'wrapperBody': false,
                                  'id': 'u:4a7aa92f2d9e'
                                },
                                {
                                  'type': 'container',
                                  'body': [
                                    {
                                      'type': 'tpl',
                                      'tpl': '${text}',
                                      'inline': true,
                                      'wrapperComponent': '',
                                      'id': 'u:adcc7aa57912',
                                      'themeCss': {
                                        'baseControlClassName': {
                                          'font:default': {
                                            'color': '#333333',
                                            'fontSize': '14px',
                                            'font-family': '思源-Regular'
                                          }
                                        }
                                      }
                                    }
                                  ],
                                  'style': {
                                    'position': 'static',
                                    'display': 'block'
                                  },
                                  'size': 'none',
                                  'wrapperBody': false,
                                  'id': 'u:709ecbdd3475'
                                }
                              ],
                              'style': {
                                'position': 'static',
                                'display': 'flex',
                                'flexWrap': 'nowrap',
                                'flex': '0 0 150px',
                                'flexBasis': '100%',
                                'overflowX': 'visible',
                                'flexDirection': 'column'
                              },
                              'size': 'none',
                              'wrapperBody': false,
                              'label': '容器',
                              'id': 'u:08598e8cd685',
                              'isFixedHeight': false,
                              'themeCss': {
                                'baseControlClassName': {
                                  'padding-and-margin:default': {
                                    'marginLeft': '10px'
                                  }
                                }
                              }
                            }
                          ],
                          'style': {
                            'position': 'static',
                            'display': 'flex',
                            'flexWrap': 'nowrap',
                            'flexBasis': '100%'
                          },
                          'size': 'none',
                          'wrapperBody': false,
                          'label': '',
                          'id': 'u:fc7481632ad8',
                          'isFixedHeight': false,
                          'isFixedWidth': false
                        }
                      ],
                      'wrapperBody': false,
                      'style': {
                        'position': 'relative',
                        'display': 'flex',
                        'width': '100%',
                        'inset': 'auto',
                        'flexWrap': 'nowrap',
                        'flex': '0 0 auto'
                      },
                      'themeCss': {
                        'baseControlClassName': {
                          'radius:default': {
                            'top-left-border-radius': '6px',
                            'top-right-border-radius': '6px',
                            'bottom-left-border-radius': '6px',
                            'bottom-right-border-radius': '6px'
                          },
                          'boxShadow:default': ' 0px 0px 10px 0px var(--colors-neutral-line-8)',
                          'border:default': {
                            'top-border-width': 'var(--borders-width-1)',
                            'left-border-width': 'var(--borders-width-1)',
                            'right-border-width': 'var(--borders-width-1)',
                            'bottom-border-width': 'var(--borders-width-1)',
                            'top-border-style': 'var(--borders-style-1)',
                            'left-border-style': 'var(--borders-style-1)',
                            'right-border-style': 'var(--borders-style-1)',
                            'bottom-border-style': 'var(--borders-style-1)',
                            'top-border-color': '#3be157',
                            'left-border-color': '#3be157',
                            'right-border-color': '#3be157',
                            'bottom-border-color': '#3be157'
                          },
                          'padding-and-margin:default': {
                            'paddingTop': '10px',
                            'paddingRight': '10px',
                            'paddingBottom': '10px',
                            'paddingLeft': '10px',
                            'marginTop': '0px',
                            'marginBottom': '10px'
                          }
                        }
                      },
                      'id': 'u:c114e1da6a18',
                      'isFixedHeight': false,
                      'isFixedWidth': false
                    },
                    'isFixedHeight': false
                  }
                ],
                'style': {
                  'position': 'static',
                  'display': 'flex',
                  'flex': '0 0 auto',
                  'overflowX': 'visible',
                  'flexWrap': 'nowrap',
                  'width': '100%'
                },
                'size': 'none',
                'wrapperBody': false,
                'id': 'u:fa59986d7da7',
                'isFixedWidth': true
              }
            ],
            'style': {
              'position': 'static',
              'display': 'flex',
              'flexWrap': 'nowrap',
              'flex': '0 0 150px',
              'flexBasis': '100%',
              'overflowX': 'visible',
              'flexDirection': 'column'
            },
            'size': 'none',
            'wrapperBody': false,
            'label': '容器',
            'id': 'u:08598e8cd685',
            'isFixedHeight': false,
            'themeCss': {
              'baseControlClassName': {
                'padding-and-margin:default': {
                  'marginLeft': '10px'
                }
              }
            }
          }
        ],
        'style': {
          'position': 'static',
          'display': 'flex',
          'flexWrap': 'nowrap',
          'flexBasis': '100%',
          'width': '100%',
          'overflowX': 'visible'
        },
        'size': 'none',
        'wrapperBody': false,
        'label': '',
        'id': 'u:fc7481632ad8',
        'isFixedHeight': false,
        'isFixedWidth': true,
        'themeCss': {
          'baseControlClassName': {
            'padding-and-margin:default': {
              'margin': '0'
            }
          }
        },
        'stickyStatus': false,
        'stickyPosition': 'top',
        'name': '',
        'placeholder': '-',
        'innerClassName': 'p-l-none'
      }
    ],
    'onEvent': {
      'fetchInited': {
        'weight': 0,
        'actions': [
          {
            'componentId': 'u:1694e2ab23ca',
            'groupType': 'component',
            'actionType': 'reload'
          }
        ]
      }
    }
  };
  previewSchema = {
    'type': 'crud',
    'syncLocation': false,
    'api': {
      'method': 'get',
      'url': '/article/article-comment/page',
      'messages': {},
      'requestAdaptor': '',
      'adaptor': '',
      'dataType': 'json',
      'data': {
        '&': '$$',
        'articleId': '${id}'
      },
      'silent': true
    },
    'bulkActions': [],
    'itemActions': [],
    'id': 'u:1694e2ab23ca',
    'perPageAvailable': [
      10,
      20,
      50,
      100
    ],
    'name': '',
    'messages': {},
    'mode': 'table',
    'bodyClassName': 'no-border',
    'autoFillHeight': true,
    'itemClassName': 'no-border',
    'footerToolbar': [
      {
        'type': 'statistics',
        'align': 'left'
      },
      {
        'type': 'pagination',
        'align': 'right'
      },
      {
        'type': 'switch-per-page',
        'align': 'right',
        'tpl': '内容',
        'wrapperComponent': '',
        'id': 'u:6fc10b224ca7'
      }
    ],
    'pageField': 'pageNum',
    'perPageField': 'pageSize',
    'alwaysShowPagination': true,
    'columns': [
      {
        'type': 'container',
        'body': [
          {
            'type': 'avatar',
            'showtype': 'image',
            'icon': '',
            'fit': 'cover',
            'style': {
              'width': 40,
              'height': 40,
              'flexBasis': '100%',
              'borderRadius': 20
            },
            'id': 'u:8885c42e815a',
            'body': [],
            'size': 'image',
            'wrapperBody': false,
            'label': '',
            'isFixedHeight': false,
            'isFixedWidth': false,
            'src': "${userFileInfosVo||'http://47.92.37.224:7076/pic/default.png'}"
          },
          {
            'type': 'container',
            'body': [
              {
                'type': 'container',
                'body': [
                  {
                    'type': 'tpl',
                    'tpl': '${userName}',
                    'inline': true,
                    'wrapperComponent': '',
                    'id': 'u:78dfb0357f72',
                    'themeCss': {
                      'baseControlClassName': {
                        'font:default': {
                          'color': '#3eacff',
                          'fontSize': '14px',
                          'font-family': '思源-Regular'
                        }
                      }
                    }
                  }
                ],
                'style': {
                  'position': 'static',
                  'display': 'block'
                },
                'size': 'none',
                'wrapperBody': false,
                'id': 'u:578d36837f88'
              },
              {
                'type': 'container',
                'body': [
                  {
                    'type': 'tpl',
                    'tpl': '${commentTime}',
                    'inline': true,
                    'wrapperComponent': '',
                    'id': 'u:c611087729e9',
                    'themeCss': {
                      'baseControlClassName': {
                        'font:default': {
                          'color': '#333333',
                          'fontSize': '12px',
                          'font-family': '思源-Regular'
                        }
                      }
                    }
                  }
                ],
                'style': {
                  'position': 'static',
                  'display': 'block'
                },
                'size': 'none',
                'wrapperBody': false,
                'id': 'u:9ee007f5a18d'
              },
              {
                'type': 'container',
                'body': [
                  {
                    'type': 'tpl',
                    'tpl': '${text}',
                    'inline': true,
                    'wrapperComponent': '',
                    'id': 'u:3fcb878f19ef',
                    'themeCss': {
                      'baseControlClassName': {
                        'font:default': {
                          'color': '#333333',
                          'fontSize': '14px',
                          'font-family': '思源-Regular'
                        }
                      }
                    }
                  }
                ],
                'style': {
                  'position': 'static',
                  'display': 'block'
                },
                'size': 'none',
                'wrapperBody': false,
                'id': 'u:1f7d117e9e05'
              },
              {
                'type': 'container',
                'body': [
                  {
                    'type': 'icon',
                    'icon': 'fa fas fa-thumbs-up',
                    'vendor': '',
                    'id': 'u:5e3926cd4af6',
                    'themeCss': {
                      'className': {
                        'font': {
                          'color': '#3eacff'
                        }
                      }
                    }
                  },
                  {
                    'type': 'tpl',
                    'tpl': '${dzNum||0}',
                    'inline': true,
                    'wrapperComponent': '',
                    'id': 'u:75685a9ddfd5',
                    'themeCss': {
                      'baseControlClassName': {
                        'padding-and-margin:default': {
                          'marginLeft': '5px'
                        }
                      }
                    }
                  },
                  {
                    'type': 'button',
                    'label': '回复',
                    'onEvent': {
                      'click': {
                        'actions': [
                          {
                            'ignoreError': false,
                            'actionType': 'dialog',
                            'dialog': {
                              'type': 'dialog',
                              'title': '回复',
                              'body': [
                                {
                                  'id': 'u:dfdb4f303a9c',
                                  'type': 'form',
                                  'title': '表单',
                                  'mode': 'horizontal',
                                  'dsType': 'api',
                                  'feat': 'Insert',
                                  'body': [
                                    {
                                      'type': 'hidden',
                                      'name': 'articleId',
                                      'id': 'u:52254281ca2e',
                                      'label': '文章标识'
                                    },
                                    {
                                      'type': 'hidden',
                                      'name': 'articleCommentPid',
                                      'id': 'u:e5af351dd989',
                                      'label': '回复人',
                                      'value': '${id}'
                                    },
                                    {
                                      'name': 'text',
                                      'label': '回复内容',
                                      'type': 'textarea',
                                      'id': 'u:f958c4fafed8',
                                      'minRows': 3,
                                      'maxRows': 20,
                                      'placeholder': '请输入',
                                      'value': "${''}",
                                      'required': true,
                                      'validations': {
                                        'maxLength': 2000
                                      },
                                      'validationErrors': {
                                        'maxLength': '最长输入2000个字符'
                                      }
                                    }
                                  ],
                                  'actions': [
                                    {
                                      'type': 'button',
                                      'label': '提交',
                                      'onEvent': {
                                        'click': {
                                          'actions': [
                                            {
                                              'actionType': 'submit',
                                              'componentId': 'u:dfdb4f303a9c'
                                            }
                                          ]
                                        }
                                      },
                                      'level': 'primary'
                                    }
                                  ],
                                  'resetAfterSubmit': true,
                                  'debug': false,
                                  'api': {
                                    'url': '/article/article-comment/add',
                                    'method': 'post',
                                    'requestAdaptor': '',
                                    'adaptor': 'return response',
                                    'messages': {},
                                    'data': {
                                      '&': '$$'
                                    }
                                  },
                                  'onEvent': {
                                    'submitSucc': {
                                      'weight': 0,
                                      'actions': [
                                        {
                                          'componentId': 'u:1694e2ab23ca',
                                          'ignoreError': false,
                                          'actionType': 'reload',
                                          'args': {
                                            'resetPage': true
                                          }
                                        }
                                      ]
                                    }
                                  }
                                }
                              ],
                              'showCloseButton': true,
                              'showErrorMsg': true,
                              'showLoading': true,
                              'className': 'app-popover :AMISCSSWrapper',
                              'actions': [
                                {
                                  'type': 'button',
                                  'actionType': 'cancel',
                                  'label': '取消',
                                  'id': 'u:091b9def2923'
                                },
                                {
                                  'type': 'button',
                                  'actionType': 'confirm',
                                  'label': '确认',
                                  'primary': true,
                                  'id': 'u:91308c203e7a'
                                }
                              ],
                              'id': 'u:ab5187ef3838',
                              'closeOnOutside': false,
                              'closeOnEsc': false,
                              'draggable': false,
                              'size': 'lg'
                            }
                          }
                        ]
                      }
                    },
                    'id': 'u:50bd22bbacc6',
                    'level': 'link',
                    'visible': true,
                    'size': 'xs',
                    'themeCss': {
                      'className': {
                        'padding-and-margin:default': {
                          'marginLeft': '20px'
                        }
                      }
                    }
                  }
                ],
                'style': {
                  'position': 'static',
                  'display': 'block',
                  'flex': '0 0 auto'
                },
                'size': 'none',
                'wrapperBody': false,
                'id': 'u:e7a79e47a845',
                'isFixedWidth': false,
                'themeCss': {
                  'baseControlClassName': {
                    'padding-and-margin:default': {
                      'marginBottom': '10px'
                    }
                  }
                }
              },
              {
                'type': 'container',
                'body': [
                  {
                    'type': 'each',
                    'columnsCount': 1,
                    'placeholder': '',
                    'style': {
                      'gutterY': 10,
                      'display': 'flex',
                      'flexWrap': 'nowrap',
                      'flexDirection': 'column',
                      'width': '100%'
                    },
                    'id': 'u:81095266044e',
                    'editorSetting': {
                      'displayName': ''
                    },
                    'source': '${returnList}',
                    'name': 'returnList',
                    'items': {
                      'type': 'container',
                      'body': [
                        {
                          'type': 'container',
                          'body': [
                            {
                              'type': 'avatar',
                              'showtype': 'image',
                              'icon': '',
                              'fit': 'cover',
                              'style': {
                                'width': 40,
                                'height': 40,
                                'borderRadius': 20
                              },
                              'id': 'u:6577b8741383',
                              'body': [],
                              'size': 'image',
                              'wrapperBody': false,
                              'label': '',
                              'isFixedHeight': false,
                              'isFixedWidth': false,
                              'src': "${userFileInfosVo||'http://47.92.37.224:7076/pic/default.png'}"
                            },
                            {
                              'type': 'container',
                              'body': [
                                {
                                  'type': 'container',
                                  'body': [
                                    {
                                      'type': 'tpl',
                                      'tpl': '${userName}',
                                      'inline': true,
                                      'wrapperComponent': '',
                                      'id': 'u:156f643f5bc4',
                                      'themeCss': {
                                        'baseControlClassName': {
                                          'font:default': {
                                            'color': '#3eacff',
                                            'fontSize': '14px',
                                            'font-family': '思源-Regular'
                                          }
                                        }
                                      }
                                    }
                                  ],
                                  'style': {
                                    'position': 'static',
                                    'display': 'block'
                                  },
                                  'size': 'none',
                                  'wrapperBody': false,
                                  'id': 'u:8abdd2cc643f'
                                },
                                {
                                  'type': 'container',
                                  'body': [
                                    {
                                      'type': 'tpl',
                                      'tpl': "${commentTime/1000|date:YYYY-MM-DD HH':'mm':'ss}",
                                      'inline': true,
                                      'wrapperComponent': '',
                                      'id': 'u:2825c35bd643',
                                      'themeCss': {
                                        'baseControlClassName': {
                                          'font:default': {
                                            'color': '#333333',
                                            'fontSize': '12px',
                                            'font-family': '思源-Regular'
                                          }
                                        }
                                      }
                                    }
                                  ],
                                  'style': {
                                    'position': 'static',
                                    'display': 'block'
                                  },
                                  'size': 'none',
                                  'wrapperBody': false,
                                  'id': 'u:4a7aa92f2d9e'
                                },
                                {
                                  'type': 'container',
                                  'body': [
                                    {
                                      'type': 'tpl',
                                      'tpl': '${text}',
                                      'inline': true,
                                      'wrapperComponent': '',
                                      'id': 'u:adcc7aa57912',
                                      'themeCss': {
                                        'baseControlClassName': {
                                          'font:default': {
                                            'color': '#333333',
                                            'fontSize': '14px',
                                            'font-family': '思源-Regular'
                                          }
                                        }
                                      }
                                    }
                                  ],
                                  'style': {
                                    'position': 'static',
                                    'display': 'block'
                                  },
                                  'size': 'none',
                                  'wrapperBody': false,
                                  'id': 'u:709ecbdd3475'
                                }
                              ],
                              'style': {
                                'position': 'static',
                                'display': 'flex',
                                'flexWrap': 'nowrap',
                                'flex': '0 0 150px',
                                'flexBasis': '100%',
                                'overflowX': 'visible',
                                'flexDirection': 'column'
                              },
                              'size': 'none',
                              'wrapperBody': false,
                              'label': '容器',
                              'id': 'u:08598e8cd685',
                              'isFixedHeight': false,
                              'themeCss': {
                                'baseControlClassName': {
                                  'padding-and-margin:default': {
                                    'marginLeft': '10px'
                                  }
                                }
                              }
                            }
                          ],
                          'style': {
                            'position': 'static',
                            'display': 'flex',
                            'flexWrap': 'nowrap',
                            'flexBasis': '100%'
                          },
                          'size': 'none',
                          'wrapperBody': false,
                          'label': '',
                          'id': 'u:fc7481632ad8',
                          'isFixedHeight': false,
                          'isFixedWidth': false
                        }
                      ],
                      'wrapperBody': false,
                      'style': {
                        'position': 'relative',
                        'display': 'flex',
                        'width': '100%',
                        'inset': 'auto',
                        'flexWrap': 'nowrap',
                        'flex': '0 0 auto'
                      },
                      'themeCss': {
                        'baseControlClassName': {
                          'radius:default': {
                            'top-left-border-radius': '6px',
                            'top-right-border-radius': '6px',
                            'bottom-left-border-radius': '6px',
                            'bottom-right-border-radius': '6px'
                          },
                          'boxShadow:default': ' 0px 0px 10px 0px var(--colors-neutral-line-8)',
                          'border:default': {
                            'top-border-width': 'var(--borders-width-1)',
                            'left-border-width': 'var(--borders-width-1)',
                            'right-border-width': 'var(--borders-width-1)',
                            'bottom-border-width': 'var(--borders-width-1)',
                            'top-border-style': 'var(--borders-style-1)',
                            'left-border-style': 'var(--borders-style-1)',
                            'right-border-style': 'var(--borders-style-1)',
                            'bottom-border-style': 'var(--borders-style-1)',
                            'top-border-color': '#3be157',
                            'left-border-color': '#3be157',
                            'right-border-color': '#3be157',
                            'bottom-border-color': '#3be157'
                          },
                          'padding-and-margin:default': {
                            'paddingTop': '10px',
                            'paddingRight': '10px',
                            'paddingBottom': '10px',
                            'paddingLeft': '10px',
                            'marginTop': '0px',
                            'marginBottom': '10px'
                          }
                        }
                      },
                      'id': 'u:c114e1da6a18',
                      'isFixedHeight': false,
                      'isFixedWidth': false
                    },
                    'isFixedHeight': false
                  }
                ],
                'style': {
                  'position': 'static',
                  'display': 'flex',
                  'flex': '0 0 auto',
                  'overflowX': 'visible',
                  'flexWrap': 'nowrap',
                  'width': '100%'
                },
                'size': 'none',
                'wrapperBody': false,
                'id': 'u:fa59986d7da7',
                'isFixedWidth': true
              }
            ],
            'style': {
              'position': 'static',
              'display': 'flex',
              'flexWrap': 'nowrap',
              'flex': '0 0 150px',
              'flexBasis': '100%',
              'overflowX': 'visible',
              'flexDirection': 'column'
            },
            'size': 'none',
            'wrapperBody': false,
            'label': '容器',
            'id': 'u:08598e8cd685',
            'isFixedHeight': false,
            'themeCss': {
              'baseControlClassName': {
                'padding-and-margin:default': {
                  'marginLeft': '10px'
                }
              }
            }
          }
        ],
        'style': {
          'position': 'static',
          'display': 'flex',
          'flexWrap': 'nowrap',
          'flexBasis': '100%',
          'width': '100%',
          'overflowX': 'visible'
        },
        'size': 'none',
        'wrapperBody': false,
        'label': '',
        'id': 'u:fc7481632ad8',
        'isFixedHeight': false,
        'isFixedWidth': true,
        'themeCss': {
          'baseControlClassName': {
            'padding-and-margin:default': {
              'margin': '0'
            }
          }
        },
        'stickyStatus': false,
        'stickyPosition': 'top',
        'name': '',
        'placeholder': '-',
        'innerClassName': 'p-l-none'
      }
    ],
    'onEvent': {
      'fetchInited': {
        'weight': 0,
        'actions': [
          {
            'componentId': 'u:1694e2ab23ca',
            'groupType': 'component',
            'actionType': 'reload'
          }
        ]
      }
    }
  };

  // 容器类组件必需字段
  // regions = [
  //   {
  //     key: 'body',
  //     label: '内容区'
  //   }
  // ];

  panelTitle = '配置'; // 右侧属性面板Title

  panelControls = [ // 右侧属性面板配置项

  ];
}

registerEditorPlugin(InfoGridPlugin)
