/* eslint-disable no-template-curly-in-string */
/**
 * @file 编辑器扩展, 增加自定义组件
 */
import { BasePlugin, registerEditorPlugin } from 'amis-editor'

export class DebugPlugin extends BasePlugin {
  // 关联渲染器名字
  rendererName = 'debug';
  $schema = '/schemas/UnkownSchema.json';
  notRenderFormZone = true;
  // 组件名称（组件面板显示的Title）
  name = '设备调试';
  order=0; // 排序
  description = '设备调试展示信息';
  tags = ['设备', 'A自定义'];
  icon = 'fa fa-file-code-o';
  scaffold = { // 插入到页面时需要
    type: 'debug',
    'debug_server': 'mqttServer',
    'debug_account': 'admin',
    'debug_password': '123456',
    'mqttSubscribe': '/dev/log/',
    'mqttSubscribeCs': 'deviceNo',
    'debug_mqttPublish': '/server/toDev/',
    'debug_mqttPublishCs': 'deviceNo'
  };
  previewSchema = { // 组件面板预览时需要
    type: 'debug',
    label: 'debug'
  };

  // 容器类组件必需字段
  // regions = [
  //   {
  //     key: 'body',
  //     label: '内容区'
  //   }
  // ];

  panelTitle = '配置'; // 右侧属性面板Title
  panelControls = [ // 右侧属性面板配置项
    {
      type: 'tabs',
      tabsMode: 'line',
      className: 'editor-prop-config-tabs',
      contentClassName: 'no-border editor-prop-config-tabs-cont hoverShowScrollBar',
      linksClassName: 'editor-prop-config-tabs-links aa',
      tabs: [
        {
          title: '常规',
          className: 'p-none',
          controls: [
            {
              'type': 'collapse-group',
              className: 'ae-formItemControl ae-styleControl',
              'activeKey': [
                'amap_11', 'amap_12', 'amap_13'
              ],
              body: [
                {
                  'type': 'collapse',
                  'key': 'amap_11',
                  'header': '基本',
                  headingClassName: 'ae-formItemControl-header',
                  bodyClassName: 'ae-formItemControl-body',
                  'body': [
                    {
                      type: 'input-text',
                      name: 'debug_server',
                      label: '访问地址',
                      value: 'mqttServer',
                      // 'mode': 'inline',
                      'required': true

                    },
                    {
                      type: 'input-text',
                      name: 'debug_account',
                      label: '账户',
                      value: 'admin',
                      // 'mode': 'inline',
                      'required': false
                    },
                    {
                      type: 'input-text',
                      name: 'debug_password',
                      label: '密码',
                      value: '123456',
                      // 'mode': 'inline',
                      'required': false
                    }
                  ]
                },
                {
                  'type': 'collapse',
                  'key': 'amap_12',
                  'header': '订阅信息',
                  headingClassName: 'ae-formItemControl-header',
                  bodyClassName: 'ae-formItemControl-body',
                  'body': [
                    {
                      type: 'input-text',
                      name: 'mqttSubscribe',
                      label: '订阅主题头',
                      value: '/dev/log/',
                      // 'mode': 'inline',
                      'required': true

                    },
                    {
                      type: 'input-text',
                      name: 'mqttSubscribeCs',
                      label: '订阅主题参数',
                      value: 'deviceNo',
                      // 'mode': 'inline',
                      'required': true

                    }
                  ]
                },
                {
                  'type': 'collapse',
                  'key': 'amap_13',
                  'header': '发送信息',
                  headingClassName: 'ae-formItemControl-header',
                  bodyClassName: 'ae-formItemControl-body',
                  'body': [
                    {
                      type: 'input-text',
                      name: 'debug_mqttPublish',
                      label: '发送主题头',
                      value: '/server/toDev/',
                      // 'mode': 'inline',
                      'required': true
                    },
                    {
                      type: 'input-text',
                      name: 'debug_mqttPublishCs',
                      label: '发送主题参数',
                      value: 'deviceNo',
                      // 'mode': 'inline',
                      'required': true

                    }
                  ]
                }
              ]
            }

          ]
        }
      ]

    }

  ];
}

registerEditorPlugin(DebugPlugin)
