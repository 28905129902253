/* eslint-disable no-template-curly-in-string */
/**
 * @file 编辑器扩展, 增加自定义组件
 */
import { BasePlugin, registerEditorPlugin } from 'amis-editor'

export class InfoGridPlugin extends BasePlugin {
  // 关联渲染器名字
  rendererName = 'cham-chart';
  $schema = '/schemas/UnkownSchema.json';
  notRenderFormZone = true;
  // 组件名称（组件面板显示的Title）
  name = 'champie';
  order = 0; // 排序
  description = '自定义pie';
  tags = ['展示', 'A自定义'];
  icon = 'fa fa-file-code-o';
  scaffold = { // 插入到页面时需要
    'type': 'chart',
    'config': {
      'xAxis': {
        'show': false,
        'type': 'category',
        'data': [
          'Mon',
          'Tue',
          'Wed',
          'Thu',
          'Fri',
          'Sat',
          'Sun'
        ]
      },
      'tooltip': {
        'trigger': 'item'
      },
      'grid': {
        'left': 0,
        'right': '5px',
        'bottom': 0,
        'top': '8px',
        'backgroundColor': '#FFFFFF'
      },
      'yAxis': {
        'type': 'value',
        'show': false
      },
      'series': [
        {
          'data': [
            820,
            932,
            901,
            934,
            1290,
            1330,
            1320
          ],
          'type': 'line'
        }
      ],
      'backgroundColor': 'transparent'
    },
    'replaceChartOption': true,
    'name': '设备接入类型',
    'style': {
      'width': '100%',
      'height': '300px'
    },
    'label': '',
    'height': '90px',
    'dataFilter': "let sData = [{\r\n  name:'报警异常',\r\n  data:[\r\n    { value: 1048, name: 'Search Engine' },\r\n    { value: 735, name: 'Direct' },\r\n    { value: 580, name: 'Email' },\r\n    { value: 484, name: 'Union Ads' },\r\n    { value: 300, name: 'Video Ads' }\r\n  ]\r\n}];\r\nconfig.legend={\r\n  data:[],\r\n  orient:\"horizontal\",\r\n  left:\"center\",\r\n  top:\"4%\",\r\n  icon: 'circle'\r\n};\r\nconfig.series = sData.map(item => {\r\n  item.data.forEach(itemD => {\r\n    config.legend.data.push(itemD.name);\r\n  })\r\n  return {\r\n    smooth: true,\r\n    type: 'pie',\r\n    radius: ['0', '70%'],\r\n    center:['50%', '65%'],\r\n    roseType: 'radius',\r\n    label: {\r\n      show: false,\r\n      position: 'center'\r\n    },\r\n    name: item.name,\r\n    data: item.data,\r\n    // areaStyle: {\r\n    //   color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{\r\n    //       offset: 0,\r\n    //       color: '#520052C7'\r\n    //   }, {\r\n    //       offset: 1,\r\n    //       color: '#000052C7'\r\n    //   }])\r\n    // },\r\n    animationDuration: 2800,\r\n    animationEasing: 'cubicInOut'\r\n  }\r\n})",
    'themeCss': {
      'baseControlClassName': {
        'boxShadow:default': ' 0px 0px 0px 0px transparent'
      }
    },
    'id': 'u:fce707935df3'
  };
  previewSchema = { // 组件面板预览时需要
    'type': 'chart',
    'config': {
      'xAxis': {
        'show': false,
        'type': 'category',
        'data': [
          'Mon',
          'Tue',
          'Wed',
          'Thu',
          'Fri',
          'Sat',
          'Sun'
        ]
      },
      'tooltip': {
        'trigger': 'item'
      },
      'grid': {
        'left': 0,
        'right': '5px',
        'bottom': 0,
        'top': '8px',
        'backgroundColor': '#FFFFFF'
      },
      'yAxis': {
        'type': 'value',
        'show': false
      },
      'series': [
        {
          'data': [
            820,
            932,
            901,
            934,
            1290,
            1330,
            1320
          ],
          'type': 'line'
        }
      ],
      'backgroundColor': 'transparent'
    },
    'replaceChartOption': true,
    'name': '设备接入类型',
    'style': {
      'width': '100%',
      'height': '300px'
    },
    'label': '',
    'height': '90px',
    'dataFilter': "let sData = [{\r\n  name:'报警异常',\r\n  data:[\r\n    { value: 1048, name: 'Search Engine' },\r\n    { value: 735, name: 'Direct' },\r\n    { value: 580, name: 'Email' },\r\n    { value: 484, name: 'Union Ads' },\r\n    { value: 300, name: 'Video Ads' }\r\n  ]\r\n}];\r\nconfig.legend={\r\n  data:[],\r\n  orient:\"horizontal\",\r\n  left:\"center\",\r\n  top:\"4%\",\r\n  icon: 'circle'\r\n};\r\nconfig.series = sData.map(item => {\r\n  item.data.forEach(itemD => {\r\n    config.legend.data.push(itemD.name);\r\n  })\r\n  return {\r\n    smooth: true,\r\n    type: 'pie',\r\n    radius: ['0', '70%'],\r\n    center:['50%', '65%'],\r\n    roseType: 'radius',\r\n    label: {\r\n      show: false,\r\n      position: 'center'\r\n    },\r\n    name: item.name,\r\n    data: item.data,\r\n    // areaStyle: {\r\n    //   color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{\r\n    //       offset: 0,\r\n    //       color: '#520052C7'\r\n    //   }, {\r\n    //       offset: 1,\r\n    //       color: '#000052C7'\r\n    //   }])\r\n    // },\r\n    animationDuration: 2800,\r\n    animationEasing: 'cubicInOut'\r\n  }\r\n})",
    'themeCss': {
      'baseControlClassName': {
        'boxShadow:default': ' 0px 0px 0px 0px transparent'
      }
    },
    'id': 'u:fce707935df3'
  };

  // 容器类组件必需字段
  // regions = [
  //   {
  //     key: 'body',
  //     label: '内容区'
  //   }
  // ];

  panelTitle = '配置'; // 右侧属性面板Title

  panelControls = [ // 右侧属性面板配置项

  ];
}

registerEditorPlugin(InfoGridPlugin)
