/**
 * 项目默认配置项
 * title - 项目名称
 *
 */

export default {
  sysTpeyName:'share', // 当前系统类型名称 共享平台：share
  share: { // 共享产品
    storageKey: 'zzcyi_share_', // 本地缓存前加的key
    title: '充电空间',
    gov:'豫ICP备14022279号', //域名备案信息
    loginType: 7, // 登录页面类型  1,3,4,5,6
    loginTip: '创新，创造，只为生活更美好',
    loginTip2: 'Innovation,creation,only for better life',
    isLoginLeftLogo: true, // 登录页面是否有右上角logo
    isCodeLogin: false, // 是否支持验证码登录
    isQQLogin: false, // 是否支持QQ登录
    isWxLogin: true, // 是否支持微信登录
    isAliLogin: false, // 是否支持支付宝登录
    isIphoneLogin: false, // 是否支持苹果登录
    isLogoFont: true, // 是否显示logo字体
    isShowStore: true, // 是否显示顶部商城按钮
    isShowIotCard: true, // 是否显示顶部物联网卡按钮
    isShowScreen: true, // 是否显示顶部数据大屏按钮
    isShowScreenfull: false, // 是否显示顶部全屏按钮
    isShowRefresh: true, // 是否显示顶部刷新按钮
    isShowNoticeIcon: true, // 是否显示顶部消息按钮
    isShowHelp: false, // 是否显示顶部帮助按钮
    isShowSelectLang: false, // 是否显示顶部多语言按钮
    isShowCompaniesList: false, // 是否显示顶部多租户按钮
    isShowLoginFont:true, // 系统显示logo是否是整个公司logo
    loginLogo:'logoFont.png', // 登录页面左上角logo
    menuTopLogo:'menuTopLogo.png' // 菜单顶部logo
  }
}
