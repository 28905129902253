<template>
  <div class="menue-left">
    <!-- style="background: #2878FF !important;" -->
    <MenuTop @searchChange="searchChange"></MenuTop>
    <div class="menue-left-box">
      <!-- <ul
                class="menue-left-box-ul"
                v-for="(item,key0) in menusList"
                :key="key0"
            >
                <div
                    class="menue-left-box-ul-title"
                    @click="shousuoHandle(item,key0)"
                >
                    <img
                        style="height: 21px;margin-right: 10px;"
                        v-if="menusArr.includes(item?.meta?.title)"
                        :src="require(`@/assets/menu/${menusArr.indexOf(item?.meta?.title)+1}.png`)"
                        alt=""
                        srcset=""
                    >
                    <a-icon
                        v-else-if="(typeof item.meta.icon == 'object')"
                        slot="prefix"
                        :component="item.meta.icon"
                        style="font-size: 18px;color: #fff;margin-right: 10px;"
                    />
                    <a-icon
                        v-else
                        slot="prefix"
                        :type="item.meta.icon"
                        style="font-size: 18px;color: #fff;margin-right: 10px;"
                    />
                    <span class="font16B">{{ item?.meta?.title }}</span>
                </div>
                <div
                    v-if="!item.shousuo"
                    class="menue-left-box-ul-children"
                >
                    <router-link
                        class="router-link"
                        active-class="active-router-link"
                        v-for="(itemChild,key1) in item.childrenMenu"
                        :key="key1"
                        :to="'/'+itemChild?.path"
                        :title="itemChild?.meta?.title"
                    >
                        <span class="router-link-title font14M">
                            {{ itemChild?.meta?.title }}
                        </span>
                    </router-link>

                </div>
            </ul> -->

      <!-- :inline-collapsed="collapsed" openKeys -->
      <!-- <button @click="menuClickFun()">tiaozhuan</button> -->

      <a-menu
        :default-selected-keys="defaultKey"
        :default-open-keys="defaultOpen"
        mode="inline"
        theme="dark"
        @click="menuClick"
      >
        <template v-for="item in menusList.children">
          <a-menu-item :key="item.path" v-if="!item.children || item.type == 'A'">
            <!-- <a-icon
                            slot="prefix"
                            :type="item.meta.icon"
                            style="font-size: 18px;color: #fff;margin-right: 10px;"
                        /> -->
            <a-icon :type="item.meta.icon" />
            <span>{{ item.meta.title }}</span>
          </a-menu-item>

          <a-sub-menu :key="item.path" v-else>
            <span slot="title"
              ><a-icon :type="item.meta.icon" /><span>{{ item.meta.title }}</span></span
            >

            <template v-for="item2 in item.children">
              <a-menu-item :key="item2.path" v-if="!item2.children || item2.type == 'A'">
                <a-icon :type="item2.meta.icon" /><span>{{ item2.meta.title }}</span>
              </a-menu-item>
              <a-sub-menu v-else :key="item2.path" :title="item2.meta.title">
                <a-menu-item v-for="item3 in item2.children" :key="item3.path">
                  <a-icon :type="item3.meta.icon" /><span>{{ item3.meta.title }}</span>
                </a-menu-item>
              </a-sub-menu>
            </template>
          </a-sub-menu>
        </template>
      </a-menu>
    </div>
  </div>
</template>

<script>
import MenuTop from '../MenuTop'
import Icon from 'ant-design-vue/es/icon'
import { mapState } from 'vuex'

export default {
  components: {
    MenuTop,
    Icon,
  },
  props: {
    menus: {
      type: Array,
      require: true,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      searchMenu: '',
      menusArr: ['驾驶舱', '用户管理', '场地管理', '设备管理', '财务管理', '运营中心', '运维工单', '系统设置'],
      menusList: { children: [] },
      defaultKey: [], // 默认选中
      defaultOpen: [], // 默认展开
    }
  },
  computed: {
    ...mapState({
      menusName: (state) => state.permission.menusName,
    }),
    // menusList() {
    //   const list = []

    //   // this.menus

    //   let menus2 = this.$store.state.permission.menusName;
    //   // let menus2 = this.$store.state;
    //   console.log('menus2', menus2)
    //   menus2.children.forEach(item => {
    //     if (!item.hidden) {
    //       let isSearchResult = false // 是否有搜索出结果
    //       if (item.children) {
    //         const children = item.children.filter((mapItem) => {
    //           if (!mapItem.hidden) {
    //             // 如果用户使用搜索菜单
    //             if (this.searchMenu) {
    //               // 如果这个一级菜单下有搜索结果
    //               if (mapItem?.meta?.title.indexOf(this.searchMenu) !== -1) {
    //                 isSearchResult = true
    //                 return mapItem
    //               }
    //             } else {
    //               return mapItem
    //             }
    //           }
    //         })
    //         item['childrenMenu'] = children
    //       } else {
    //         item['childrenMenu'] = []
    //       }

    //       // 如果用户使用搜索菜单
    //       if (this.searchMenu) {
    //         // 如果这个一级菜单下有搜索结果
    //         if (isSearchResult) {
    //           list.push(item)
    //         }
    //       } else {
    //         list.push(item)
    //       }
    //     }
    //   })
    //   // console.log('list>>>>', list)

    //   return list
    // }
  },
  watch: {
    menusName(newValue, oldVal) {
      // console.log('menus2', newValue)
      this.getMenusList()
    },
  },
  created() {
    // console.log('菜单>>>>>', this.menus)
    // console.log('菜单>>>>>menusList>>>>', this.menusList)
  },
  mounted() {
    // console.log('xxx', this.$store.state.permission.menusName);
    if (this.$store.state.permission.menusName) {
      this.getMenusList()
    }
  },
  methods: {
    // 搜索菜单
    searchChange(name) {
      this.searchMenu = name
    },
    // 点击收缩
    shousuoHandle(item, key0) {
      // item['shousuo'] = !item?.shousuo
      if (item.shousuo) {
        this.$set(item, 'shousuo', false)
      } else {
        this.$set(item, 'shousuo', true)
      }
    },
    // 获取对应目录
    getMenusList() {
      if (this.$store.state.permission.menusName) {
        this.menusList = this.$store.state.permission.menusName
      }

      console.log('this.menusList', this.menusList)

      // 通过路由地址，给菜单赋予默认值
      this.defaultKeyDG(this.menusList)

      // console.log('this.menusList', this.menusList);
      // console.log('this.defaultOpen', this.defaultOpen);

      return
      const list = []

      this.$store.state.permission.menusName.children.forEach((item) => {
        if (!item.hidden) {
          let isSearchResult = false // 是否有搜索出结果
          if (item.children) {
            const children = item.children.filter((mapItem) => {
              if (!mapItem.hidden) {
                // 如果用户使用搜索菜单
                if (this.searchMenu) {
                  // 如果这个一级菜单下有搜索结果
                  if (mapItem?.meta?.title.indexOf(this.searchMenu) !== -1) {
                    isSearchResult = true
                    return mapItem
                  }
                } else {
                  return mapItem
                }
              }
            })
            item['childrenMenu'] = children
          } else {
            item['childrenMenu'] = []
          }

          // 如果用户使用搜索菜单
          if (this.searchMenu) {
            // 如果这个一级菜单下有搜索结果
            if (isSearchResult) {
              list.push(item)
            }
          } else {
            list.push(item)
          }
        }
      })
      // console.log('list>>>>', list)

      // return list
      this.menusList = list
    },

    menuClick({ item, key, keyPath }) {
      console.log('menuClick', item, key, keyPath)
      this.$router.push({
        path: '/' + key,
      })
    },
    menuClickFun() {
      this.$router.push({
        path: '/system/menu',
      })
    },

    // 递归查找，路由对应的目录
    defaultKeyDG(value) {
      value.children.forEach((item) => {
        if ('/' + item.path == this.$route.path) {
          this.defaultKey.push(item.path)
          this.defaultOpen.push(value.path)

          return
        }

        if (item.children) {
          this.defaultKeyDG(item)
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.menue-left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 210px;
  padding: 11px 0 0;
  // height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: #30323f !important;
  z-index: 1100;
  .menue-left-box {
    flex: 1;
    padding: 0 16px;
    overflow-y: auto;

    .menue-left-box-ul {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0;
      margin-bottom: 8px;
      .menue-left-box-ul-title {
        display: flex;
        align-items: center;
        padding-left: 16px;
        margin-bottom: 10px;
        cursor: pointer;

        span {
          color: #ffffff;
        }
      }

      .menue-left-box-ul-children {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        transition: all 3s;

        .router-link {
          width: 50%;
          // height: 30px;
          // line-height: 30px;
          // padding-top: 1px;
          padding-left: 16px;
          padding-top: 8px;
          padding-bottom: 8px;
          text-align: left;
          border-radius: 4px 4px 4px 4px;
          opacity: 1;
          color: #ffffff;
          margin-bottom: 8px;

          .router-link-title {
            -webkit-line-clamp: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;

            line-height: 1;
          }
        }

        .active-router-link {
          background: #ffffff;

          .router-link-title {
            color: #2878ff;
          }
        }
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.ant-menu-dark {
  background-color: #30323f;
}

.ant-menu.ant-menu-dark {
  color: #929292;
  .ant-menu-item-selected {
    background-color: #eed484;
    border-radius: 10px;
    color: #554c38;
    .anticon {
      color: #231f20;
    }
    span {
      color: #231f20;
    }
  }
}

.ant-menu-item {
  i,
  span {
    display: inline-block; /* 或者使用 block，根据需求选择 */
    vertical-align: middle; /* 垂直居中对齐 */
  }
}

::v-deep .ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #30323f !important;
  box-shadow: none !important;
}
</style>
