/* eslint-disable no-template-curly-in-string */
/**
 * @file 编辑器扩展, 增加自定义组件
 */
import { BasePlugin, registerEditorPlugin } from 'amis-editor'

export class InfoGridPlugin extends BasePlugin {
  // 关联渲染器名字
  rendererName = 'cham-tabs';
  $schema = '/schemas/UnkownSchema.json';
  notRenderFormZone = true;
  // 组件名称（组件面板显示的Title）
  name = 'chamtabs';
  order = 0; // 排序
  description = '自定义tabs';
  tags = ['展示', 'A自定义'];
  icon = 'fa fa-file-code-o';
  scaffold = { // 插入到页面时需要
    'type': 'list-select',
    'label': '',
    'name': 'list',
    'options': [
      {
        'label': '选项A',
        'value': 'A'
      },
      {
        'label': '选项B',
        'value': 'B'
      }
    ],
    'id': 'u:4c1834beaa46',
    'multiple': false,
    'activeItemSchema': {
      'type': 'container',
      'body': [
        {
          'type': 'tpl',
          'tpl': '${label}',
          'wrapperComponent': '',
          'inline': true,
          'id': 'u:a5a0ca2dbe08',
          'wrapperCustomStyle': {
            'root': {
              'font-size': '14px',
              'color': 'white',
              'background-color': '#2878FF',
              'padding': '9px 30px',
              'border-radius': '4px',
              'font-family': '思源-Regular',
              'line-height': '1'
            }
          }
        }
      ],
      'id': 'u:fe32c96197a4',
      'style': {
        'position': 'static',
        'display': 'block'
      },
      'isFixedHeight': false,
      'isFixedWidth': false
    },
    'value': 'A',
    'itemSchema': {
      'type': 'container',
      'body': [
        {
          'type': 'tpl',
          'tpl': '${label}',
          'wrapperComponent': '',
          'inline': true,
          'id': 'u:48e687326047',
          'wrapperCustomStyle': {
            'root': {
              'font-size': '14px',
              'color': '#999999',
              'background-color': '#F7F8FA',
              'padding': '9px 30px',
              'border-radius': '4px',
              'font-family': '思源-Regular',
              'line-height': '1'
            }
          }
        }
      ],
      'id': 'u:e2fbf730f069'
    }
  };
  previewSchema = { // 组件面板预览时需要
    'type': 'list-select',
    'label': '',
    'name': 'list',
    'options': [
      {
        'label': '选项A',
        'value': 'A'
      },
      {
        'label': '选项B',
        'value': 'B'
      }
    ],
    'id': 'u:4c1834beaa46',
    'multiple': false,
    'activeItemSchema': {
      'type': 'container',
      'body': [
        {
          'type': 'tpl',
          'tpl': '${label}',
          'wrapperComponent': '',
          'inline': true,
          'id': 'u:a5a0ca2dbe08',
          'wrapperCustomStyle': {
            'root': {
              'font-size': '14px',
              'color': 'white',
              'background-color': '#2878FF',
              'padding': '9px 30px',
              'border-radius': '4px',
              'font-family': '思源-Regular',
              'line-height': '1'
            }
          }
        }
      ],
      'id': 'u:fe32c96197a4',
      'style': {
        'position': 'static',
        'display': 'block'
      },
      'isFixedHeight': false,
      'isFixedWidth': false
    },
    'value': 'A',
    'itemSchema': {
      'type': 'container',
      'body': [
        {
          'type': 'tpl',
          'tpl': '${label}',
          'wrapperComponent': '',
          'inline': true,
          'id': 'u:48e687326047',
          'wrapperCustomStyle': {
            'root': {
              'font-size': '14px',
              'color': '#999999',
              'background-color': '#F7F8FA',
              'padding': '9px 30px',
              'border-radius': '4px',
              'font-family': '思源-Regular',
              'line-height': '1'
            }
          }
        }
      ],
      'id': 'u:e2fbf730f069'
    }
  };

  // 容器类组件必需字段
  // regions = [
  //   {
  //     key: 'body',
  //     label: '内容区'
  //   }
  // ];

  panelTitle = '配置'; // 右侧属性面板Title

  panelControls = [ // 右侧属性面板配置项

  ];
}

registerEditorPlugin(InfoGridPlugin)
