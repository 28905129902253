import request from '@/utils/request'

// 新增参数配置
export function writeDataPoint (data) {
  return request({
    url: '/gather/device/info/writeDataPoint',
    method: 'post',
    data: data
  })
}
