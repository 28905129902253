<template>
    <div class="menue-left-top">
        <img
            v-if="defaultSettings.isShowLoginFont"
            class="menue-left-top-logo"
            :src="require(`@/assets/config/${defaultSettings.sysTpeyName}/${defaultSettings.menuTopLogo}`)"
            alt=""
            srcset=""
        >
        <div
            v-else
            class="menue-left-top-logo"
            style="width: 100%;"
        >
            <img
                class="logoBoxLogo"
                :src="require(`@/assets/config/${defaultSettings.sysTpeyName}/${defaultSettings.menuTopLogo}`)"
            >
            <span class="logoBoxFont">{{ defaultSettings.title }}</span>
        </div>
        <!-- <div class="menue-left-top-search">
      修改
      <a-input class="font12R" v-model="searchMenu" placeholder="请输入" allow-clear @change="onChange" >
        <a-icon slot="prefix" type="search" style="font-size: 12px;color: #527ACC;"/>

      </a-input>
    </div> -->
    </div>
</template>

<script>
import defaultSettings from '@/config/defaultSettings'
export default {
    components: {
    },
    data() {
        return {
            defaultSettings,
            searchMenu: ''
        }
    },
    methods: {
        onChange() {
            this.$emit('searchChange', this.searchMenu)
        }
    }

}
</script>

<style lang="less" scoped>
.menue-left-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 16px;
    padding-bottom: 22px;

    .menue-left-top-logo {
        display: flex;
        align-items: center;
        // width: 155px;
        // height: 42px;
        margin-bottom: 20px;
        margin-right: 17px;
        width: 130px;

        .logoBoxLogo {
            height: 100% !important;
            margin-right: 16px;
        }

        .logoBoxFont {
            font-size: 23px;
            color: #ffffff;
            font-weight: bold;
            font-family: '思源-Bold';
        }
    }

    .menue-left-top-search {
        display: flex;
        align-items: center;
        width: 100%;
        height: 30px;
        background: #ffffff;
        box-shadow: inset 0px 3px 6px 1px rgba(40, 120, 255, 0.06);
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        overflow: hidden;

        /deep/.ant-input {
            padding-top: 0;
            padding-bottom: 0;
            font-size: 12px;
            font-family: '思源-Regular';
            color: #2878ff;
        }
    }
}
</style>
