/* eslint-disable no-template-curly-in-string */
/**
 * @file 编辑器扩展, 增加自定义组件
 */
import { BasePlugin, registerEditorPlugin } from 'amis-editor'

export class InfoGridPlugin extends BasePlugin {
  // 关联渲染器名字
  rendererName = 'cham-dialog';
  $schema = '/schemas/UnkownSchema.json';
  notRenderFormZone = true;
  // 组件名称（组件面板显示的Title）
  name = '详情弹窗';
  order = 0; // 排序
  description = '自定义详情弹窗';
  tags = ['布局容器', 'A自定义'];
  icon = 'fa fa-file-code-o';
  scaffold = { // 插入到页面时需要
    'type': 'drawer',
    'title': '',
    'body': [
      {
        'type': 'service',
        'body': [
          {
            'type': 'card',
            'header': {
              'title': '',
              'subTitle': ''
            },
            'body': [
              {
                'type': 'flex',
                'items': [
                  {
                    'type': 'container',
                    'body': [
                      {
                        'type': 'container',
                        'body': [
                          {
                            'type': 'list-select',
                            'label': '',
                            'name': 'detailType',
                            'options': [
                              {
                                'label': '订单信息',
                                'value': '1'
                              },
                              {
                                'label': '支付信息',
                                'value': '2'
                              },
                              {
                                'label': '订单分润',
                                'value': '3'
                              },
                              {
                                'label': '订单轨迹',
                                'value': '4'
                              },
                              {
                                'label': '售后信息',
                                'value': '5'
                              }
                            ],
                            'id': 'u:7a713c58518c',
                            'multiple': false,
                            'activeItemSchema': {
                              'type': 'container',
                              'body': [
                                {
                                  'type': 'tpl',
                                  'tpl': '${label}',
                                  'wrapperComponent': '',
                                  'inline': true,
                                  'id': 'u:b713cf6985ac',
                                  'wrapperCustomStyle': {
                                    'root': {
                                      'font-size': '14px',
                                      'color': 'white',
                                      'background-color': '#2878FF',
                                      'padding': '9px 30px',
                                      'border-radius': '4px',
                                      'font-family': '思源-Regular'
                                    }
                                  }
                                }
                              ],
                              'id': 'u:0e60f5049cec',
                              'style': {
                                'position': 'static',
                                'display': 'block'
                              },
                              'isFixedHeight': false,
                              'isFixedWidth': false,
                              'wrapperCustomStyle': {
                                'root': {
                                  'padding': '7px 0'
                                }
                              }
                            },
                            'value': '1',
                            'itemSchema': {
                              'type': 'container',
                              'body': [
                                {
                                  'type': 'tpl',
                                  'tpl': '${label}',
                                  'wrapperComponent': '',
                                  'inline': true,
                                  'id': 'u:1c27a27ad7aa',
                                  'wrapperCustomStyle': {
                                    'root': {
                                      'font-size': '14px',
                                      'color': '#999999',
                                      'background-color': '#F7F8FA',
                                      'padding': '9px 30px',
                                      'border-radius': '4px',
                                      'font-family': '思源-Regular'
                                    }
                                  }
                                }
                              ],
                              'id': 'u:a02de70f94dd',
                              'style': {
                                'position': 'static',
                                'display': 'block'
                              },
                              'isFixedHeight': false,
                              'isFixedWidth': false,
                              'wrapperCustomStyle': {
                                'root': {
                                  'padding': '7px 0'
                                }
                              }
                            }
                          }
                        ],
                        'size': 'none',
                        'style': {
                          'position': 'static',
                          'display': 'block',
                          'flex': '1 1 auto',
                          'flexGrow': 1
                        },
                        'wrapperBody': false,
                        'isFixedHeight': false,
                        'isFixedWidth': false,
                        'id': 'u:d91306cbb4d8',
                        'themeCss': {
                          'baseControlClassName': {
                            'padding-and-margin:default': {
                              'marginTop': '0px',
                              'marginRight': '0px',
                              'marginBottom': '0px',
                              'marginLeft': '0px'
                            }
                          }
                        }
                      }
                    ],
                    'size': '',
                    'style': {
                      'position': 'static',
                      'display': 'block',
                      'flex': '1 1 auto',
                      'flexGrow': 1
                    },
                    'wrapperBody': false,
                    'isFixedHeight': false,
                    'isFixedWidth': false,
                    'id': 'u:9c6f374e905b'
                  }
                ],
                'style': {
                  'position': 'relative'
                },
                'label': '',
                'id': 'u:244aee22ea4c'
              }
            ],
            'actions': [],
            'id': 'u:f6cb99a45b03',
            'bodyClassName': '',
            'className': 'zzcy-card m-none'
          },
          {
            'type': 'service',
            'body': [
              {
                'type': 'card',
                'header': {
                  'title': '',
                  'subTitle': ''
                },
                'body': [
                  {
                    'type': 'flex',
                    'items': [
                      {
                        'type': 'container',
                        'body': [
                          {
                            'type': 'divider',
                            'id': 'u:4c29baa78c2c',
                            'lineStyle': 'solid',
                            'direction': 'vertical',
                            'rotate': 0,
                            'style': {
                              'borderWidth': '3px',
                              'height': '12px',
                              'borderRadius': '2px 2px 2px 2px',
                              'marginRight': '8px',
                              'marginTop': '0px',
                              'marginBottom': '0px'
                            },
                            'color': '#2878ff'
                          },
                          {
                            'type': 'tpl',
                            'tpl': '用户信息',
                            'inline': true,
                            'wrapperComponent': '',
                            'id': 'u:867b8870ee37',
                            'themeCss': {
                              'baseControlClassName': {
                                'font:default': {
                                  'fontSize': '16px',
                                  'color': '#333333',
                                  'lineHeight': '1',
                                  'fontWeight': '700',
                                  'font-family': '思源-Bold'
                                }
                              }
                            }
                          }
                        ],
                        'size': 'none',
                        'style': {
                          'position': 'static',
                          'display': 'flex',
                          'flex': '1 1 auto',
                          'flexGrow': 1,
                          'flexWrap': 'nowrap',
                          'alignItems': 'center'
                        },
                        'wrapperBody': false,
                        'isFixedHeight': false,
                        'isFixedWidth': false,
                        'id': 'u:82b7fc616286'
                      },
                      {
                        'type': 'container',
                        'body': [
                          {
                            'type': 'container',
                            'body': [
                              {
                                'type': 'tpl',
                                'tpl': '用户头像',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:51e5bbd578c2',
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular',
                                      'color': '#666666'
                                    },
                                    'padding-and-margin:default': {
                                      'marginRight': '18px'
                                    }
                                  }
                                },
                                'wrapperCustomStyle': {
                                  'root': {
                                    'width': '70px'
                                  }
                                }
                              },
                              {
                                'type': 'avatar',
                                'showtype': 'image',
                                'icon': '',
                                'fit': 'cover',
                                'style': {
                                  'width': 35,
                                  'height': 35,
                                  'borderRadius': 20
                                },
                                'name': 'img',
                                'id': 'u:b4551375699b',
                                'quickEdit': false,
                                'popOver': false,
                                'copyable': false,
                                'mode': 'horizontal',
                                'borderMode': 'none'
                              }
                            ],
                            'style': {
                              'position': 'static',
                              'display': 'flex',
                              'flex': '0 0 150px',
                              'flexBasis': '20%',
                              'overflowX': 'visible',
                              'flexWrap': 'nowrap',
                              'alignItems': 'center'
                            },
                            'size': 'none',
                            'wrapperBody': false,
                            'id': 'u:0e54ddccf2c5',
                            'isFixedHeight': false
                          },
                          {
                            'type': 'container',
                            'body': [
                              {
                                'type': 'tpl',
                                'tpl': '用户昵称',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:ce99303fc894',
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular',
                                      'color': '#666666'
                                    },
                                    'padding-and-margin:default': {
                                      'marginRight': '18px'
                                    }
                                  }
                                },
                                'wrapperCustomStyle': {
                                  'root': {
                                    'width': '70px'
                                  }
                                }
                              },
                              {
                                'type': 'tpl',
                                'tpl': '18812345678',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:fbf8645fee71',
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular',
                                      'color': '#333333'
                                    },
                                    'padding-and-margin:default': {
                                      'marginRight': '18px'
                                    }
                                  }
                                },
                                'wrapperCustomStyle': {}
                              }
                            ],
                            'style': {
                              'position': 'static',
                              'display': 'flex',
                              'flex': '0 0 150px',
                              'flexBasis': '20%',
                              'overflowX': 'visible',
                              'flexWrap': 'nowrap',
                              'alignItems': 'center'
                            },
                            'size': 'none',
                            'wrapperBody': false,
                            'id': 'u:e564f31cb4a6',
                            'isFixedHeight': false
                          },
                          {
                            'type': 'container',
                            'body': [
                              {
                                'type': 'tpl',
                                'tpl': '登录账号',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:8768e5d436a6',
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular',
                                      'color': '#666666'
                                    },
                                    'padding-and-margin:default': {
                                      'marginRight': '18px'
                                    }
                                  }
                                },
                                'wrapperCustomStyle': {
                                  'root': {
                                    'width': '70px'
                                  }
                                }
                              },
                              {
                                'type': 'tpl',
                                'tpl': '18812345678',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:c2b779bef194',
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular',
                                      'color': '#333333'
                                    },
                                    'padding-and-margin:default': {
                                      'marginRight': '18px'
                                    }
                                  }
                                },
                                'wrapperCustomStyle': {}
                              }
                            ],
                            'style': {
                              'position': 'static',
                              'display': 'flex',
                              'flex': '0 0 150px',
                              'flexBasis': '20%',
                              'overflowX': 'visible',
                              'flexWrap': 'nowrap',
                              'alignItems': 'center'
                            },
                            'size': 'none',
                            'wrapperBody': false,
                            'id': 'u:bff4a26a98ca',
                            'isFixedHeight': false
                          },
                          {
                            'type': 'container',
                            'body': [
                              {
                                'type': 'tpl',
                                'tpl': '手机型号',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:4872852013c1',
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular',
                                      'color': '#666666'
                                    },
                                    'padding-and-margin:default': {
                                      'marginRight': '18px'
                                    }
                                  }
                                },
                                'wrapperCustomStyle': {
                                  'root': {
                                    'width': '70px'
                                  }
                                }
                              },
                              {
                                'type': 'tpl',
                                'tpl': '魅族16PLUS',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:40114c549c1a',
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular',
                                      'color': '#333333'
                                    },
                                    'padding-and-margin:default': {
                                      'marginRight': '18px'
                                    }
                                  }
                                },
                                'wrapperCustomStyle': {}
                              }
                            ],
                            'style': {
                              'position': 'static',
                              'display': 'flex',
                              'flex': '1 1 auto',
                              'flexBasis': '20%',
                              'overflowX': 'visible',
                              'flexGrow': 1,
                              'flexWrap': 'nowrap',
                              'alignItems': 'center'
                            },
                            'size': 'none',
                            'wrapperBody': false,
                            'id': 'u:d2a1ec7383e4',
                            'isFixedHeight': false
                          }
                        ],
                        'size': 'xs',
                        'style': {
                          'position': 'static',
                          'display': 'flex',
                          'flex': '1 1 auto',
                          'flexGrow': 1,
                          'flexWrap': 'nowrap'
                        },
                        'wrapperBody': false,
                        'isFixedHeight': false,
                        'isFixedWidth': false,
                        'id': 'u:e7d9df3ff813',
                        'themeCss': {
                          'baseControlClassName': {
                            'padding-and-margin:default': {
                              'marginTop': '10px'
                            }
                          }
                        }
                      }
                    ],
                    'style': {
                      'position': 'relative',
                      'inset': 'auto',
                      'flexWrap': 'nowrap',
                      'flexDirection': 'column'
                    },
                    'label': '',
                    'id': 'u:96adfb26d312',
                    'isFixedHeight': false,
                    'isFixedWidth': false,
                    'themeCss': {
                      'baseControlClassName': {
                        'padding-and-margin:default': {}
                      }
                    }
                  }
                ],
                'actions': [],
                'id': 'u:7b0c78df7645',
                'bodyClassName': '',
                'className': 'zzcy-card m-b-none m-r-none m-l-none m-t-sm'
              },
              {
                'type': 'card',
                'header': {
                  'title': '',
                  'subTitle': ''
                },
                'body': [
                  {
                    'type': 'flex',
                    'items': [
                      {
                        'type': 'container',
                        'body': [
                          {
                            'type': 'divider',
                            'id': 'u:2a3317bff161',
                            'lineStyle': 'solid',
                            'direction': 'vertical',
                            'rotate': 0,
                            'style': {
                              'borderWidth': '3px',
                              'height': '12px',
                              'borderRadius': '2px 2px 2px 2px',
                              'marginRight': '8px',
                              'marginTop': '0px',
                              'marginBottom': '0px'
                            },
                            'color': '#2878ff'
                          },
                          {
                            'type': 'tpl',
                            'tpl': '订单信息',
                            'inline': true,
                            'wrapperComponent': '',
                            'id': 'u:bb6028367d15',
                            'themeCss': {
                              'baseControlClassName': {
                                'font:default': {
                                  'fontSize': '16px',
                                  'color': '#333333',
                                  'lineHeight': '1',
                                  'fontWeight': '700',
                                  'font-family': '思源-Bold'
                                }
                              }
                            }
                          }
                        ],
                        'size': 'none',
                        'style': {
                          'position': 'static',
                          'display': 'flex',
                          'flex': '1 1 auto',
                          'flexGrow': 1,
                          'flexWrap': 'nowrap',
                          'alignItems': 'center'
                        },
                        'wrapperBody': false,
                        'isFixedHeight': false,
                        'isFixedWidth': false,
                        'id': 'u:27842961e90c'
                      },
                      {
                        'type': 'container',
                        'body': [
                          {
                            'type': 'container',
                            'body': [
                              {
                                'type': 'tpl',
                                'tpl': '订单号',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:79809b5a4907',
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular',
                                      'color': '#666666'
                                    },
                                    'padding-and-margin:default': {
                                      'marginRight': '18px'
                                    }
                                  }
                                },
                                'wrapperCustomStyle': {
                                  'root': {
                                    'width': '70px'
                                  }
                                }
                              },
                              {
                                'type': 'tpl',
                                'tpl': '18812345678',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:47addf7b04d5',
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular',
                                      'color': '#333333'
                                    },
                                    'padding-and-margin:default': {
                                      'marginRight': '18px'
                                    }
                                  }
                                },
                                'wrapperCustomStyle': {}
                              }
                            ],
                            'style': {
                              'position': 'static',
                              'display': 'flex',
                              'flex': '0 0 150px',
                              'flexBasis': '20%',
                              'overflowX': 'visible',
                              'flexWrap': 'nowrap',
                              'alignItems': 'center'
                            },
                            'size': 'none',
                            'wrapperBody': false,
                            'id': 'u:2406ff9ead30',
                            'isFixedHeight': false
                          },
                          {
                            'type': 'container',
                            'body': [
                              {
                                'type': 'tpl',
                                'tpl': '外部订单号',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:83dc2dddd2b1',
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular',
                                      'color': '#666666'
                                    },
                                    'padding-and-margin:default': {
                                      'marginRight': '18px'
                                    }
                                  }
                                },
                                'wrapperCustomStyle': {
                                  'root': {
                                    'width': '70px'
                                  }
                                }
                              },
                              {
                                'type': 'tpl',
                                'tpl': '18812345678',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:c995d9e4cb4d',
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular',
                                      'color': '#333333'
                                    },
                                    'padding-and-margin:default': {
                                      'marginRight': '18px'
                                    }
                                  }
                                },
                                'wrapperCustomStyle': {}
                              }
                            ],
                            'style': {
                              'position': 'static',
                              'display': 'flex',
                              'flex': '0 0 150px',
                              'flexBasis': '20%',
                              'overflowX': 'visible',
                              'flexWrap': 'nowrap',
                              'alignItems': 'center'
                            },
                            'size': 'none',
                            'wrapperBody': false,
                            'id': 'u:41a4ba3ab0e8',
                            'isFixedHeight': false
                          },
                          {
                            'type': 'container',
                            'body': [
                              {
                                'type': 'tpl',
                                'tpl': '支付方式',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:d37626031ffd',
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular',
                                      'color': '#666666'
                                    },
                                    'padding-and-margin:default': {
                                      'marginRight': '18px'
                                    }
                                  }
                                },
                                'wrapperCustomStyle': {
                                  'root': {
                                    'width': '70px'
                                  }
                                }
                              },
                              {
                                'type': 'tpl',
                                'tpl': '18812345678',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:d51d0e494b34',
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular',
                                      'color': '#333333'
                                    },
                                    'padding-and-margin:default': {
                                      'marginRight': '18px'
                                    }
                                  }
                                },
                                'wrapperCustomStyle': {}
                              }
                            ],
                            'style': {
                              'position': 'static',
                              'display': 'flex',
                              'flex': '0 0 150px',
                              'flexBasis': '20%',
                              'overflowX': 'visible',
                              'flexWrap': 'nowrap',
                              'alignItems': 'center'
                            },
                            'size': 'none',
                            'wrapperBody': false,
                            'id': 'u:0977cb1ca6bc',
                            'isFixedHeight': false
                          },
                          {
                            'type': 'container',
                            'body': [
                              {
                                'type': 'tpl',
                                'tpl': '订单来源',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:510c9ecb163d',
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular',
                                      'color': '#666666'
                                    },
                                    'padding-and-margin:default': {
                                      'marginRight': '18px'
                                    }
                                  }
                                },
                                'wrapperCustomStyle': {
                                  'root': {
                                    'width': '70px'
                                  }
                                }
                              },
                              {
                                'type': 'tpl',
                                'tpl': '18812345678',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:ce07985139c6',
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular',
                                      'color': '#333333'
                                    },
                                    'padding-and-margin:default': {
                                      'marginRight': '18px'
                                    }
                                  }
                                },
                                'wrapperCustomStyle': {}
                              }
                            ],
                            'style': {
                              'position': 'static',
                              'display': 'flex',
                              'flex': '0 0 150px',
                              'flexBasis': '20%',
                              'overflowX': 'visible',
                              'flexWrap': 'nowrap',
                              'alignItems': 'center'
                            },
                            'size': 'none',
                            'wrapperBody': false,
                            'id': 'u:632f95ba2ffd',
                            'isFixedHeight': false
                          },
                          {
                            'type': 'container',
                            'body': [
                              {
                                'type': 'tpl',
                                'tpl': '订单状态',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:bcafa628b06d',
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular',
                                      'color': '#666666'
                                    },
                                    'padding-and-margin:default': {
                                      'marginRight': '18px'
                                    }
                                  }
                                },
                                'wrapperCustomStyle': {
                                  'root': {
                                    'width': '70px'
                                  }
                                }
                              },
                              {
                                'type': 'tpl',
                                'tpl': '进行中',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:6afd4e8950a6',
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular',
                                      'color': '#00d8a9'
                                    },
                                    'padding-and-margin:default': {
                                      'marginRight': '18px'
                                    }
                                  }
                                },
                                'wrapperCustomStyle': {},
                                'visibleOn': '${1==1}'
                              },
                              {
                                'type': 'tpl',
                                'tpl': '已结束',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:8204b9eb7938',
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular',
                                      'color': '#ff2121'
                                    },
                                    'padding-and-margin:default': {
                                      'marginRight': '18px'
                                    }
                                  }
                                },
                                'wrapperCustomStyle': {},
                                'visibleOn': '${1==0}'
                              }
                            ],
                            'style': {
                              'position': 'static',
                              'display': 'flex',
                              'flex': '0 0 150px',
                              'flexBasis': '20%',
                              'overflowX': 'visible',
                              'flexWrap': 'nowrap',
                              'alignItems': 'center'
                            },
                            'size': 'none',
                            'wrapperBody': false,
                            'id': 'u:52038f86e2e9',
                            'isFixedHeight': false
                          }
                        ],
                        'size': 'xs',
                        'style': {
                          'position': 'static',
                          'display': 'flex',
                          'flex': '1 1 auto',
                          'flexGrow': 1,
                          'flexWrap': 'nowrap'
                        },
                        'wrapperBody': false,
                        'isFixedHeight': false,
                        'isFixedWidth': false,
                        'id': 'u:dbbff9b6664f',
                        'themeCss': {
                          'baseControlClassName': {
                            'padding-and-margin:default': {
                              'marginTop': '10px'
                            }
                          }
                        }
                      },
                      {
                        'type': 'container',
                        'body': [
                          {
                            'type': 'container',
                            'body': [
                              {
                                'type': 'tpl',
                                'tpl': '支付金额',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:a1c912750717',
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular',
                                      'color': '#666666'
                                    },
                                    'padding-and-margin:default': {
                                      'marginRight': '18px'
                                    }
                                  }
                                },
                                'wrapperCustomStyle': {
                                  'root': {
                                    'width': '70px'
                                  }
                                }
                              },
                              {
                                'type': 'tpl',
                                'tpl': '20元',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:93860311aab8',
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular',
                                      'color': '#333333'
                                    },
                                    'padding-and-margin:default': {
                                      'marginRight': '18px'
                                    }
                                  }
                                },
                                'wrapperCustomStyle': {}
                              }
                            ],
                            'style': {
                              'position': 'static',
                              'display': 'flex',
                              'flex': '0 0 150px',
                              'flexBasis': '20%',
                              'overflowX': 'visible',
                              'flexWrap': 'nowrap',
                              'alignItems': 'center'
                            },
                            'size': 'none',
                            'wrapperBody': false,
                            'id': 'u:8bcecd670532',
                            'isFixedHeight': false
                          },
                          {
                            'type': 'container',
                            'body': [
                              {
                                'type': 'tpl',
                                'tpl': '订单金额',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:4a93a84ef858',
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular',
                                      'color': '#666666'
                                    },
                                    'padding-and-margin:default': {
                                      'marginRight': '18px'
                                    }
                                  }
                                },
                                'wrapperCustomStyle': {
                                  'root': {
                                    'width': '70px'
                                  }
                                }
                              },
                              {
                                'type': 'tpl',
                                'tpl': '待结算/18元',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:1b95160c2f2a',
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular',
                                      'color': '#333333'
                                    },
                                    'padding-and-margin:default': {
                                      'marginRight': '18px'
                                    }
                                  }
                                },
                                'wrapperCustomStyle': {}
                              }
                            ],
                            'style': {
                              'position': 'static',
                              'display': 'flex',
                              'flex': '0 0 150px',
                              'flexBasis': '20%',
                              'overflowX': 'visible',
                              'flexWrap': 'nowrap',
                              'alignItems': 'center'
                            },
                            'size': 'none',
                            'wrapperBody': false,
                            'id': 'u:0e8341a9269c',
                            'isFixedHeight': false
                          },
                          {
                            'type': 'container',
                            'body': [
                              {
                                'type': 'tpl',
                                'tpl': '优惠金额',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:6570f2451dc0',
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular',
                                      'color': '#666666'
                                    },
                                    'padding-and-margin:default': {
                                      'marginRight': '18px'
                                    }
                                  }
                                },
                                'wrapperCustomStyle': {
                                  'root': {
                                    'width': '70px'
                                  }
                                }
                              },
                              {
                                'type': 'tpl',
                                'tpl': '2元（满20元减2元）',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:8eda476e8611',
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular',
                                      'color': '#333333'
                                    },
                                    'padding-and-margin:default': {
                                      'marginRight': '18px'
                                    }
                                  }
                                },
                                'wrapperCustomStyle': {}
                              }
                            ],
                            'style': {
                              'position': 'static',
                              'display': 'flex',
                              'flex': '0 0 150px',
                              'flexBasis': '20%',
                              'overflowX': 'visible',
                              'flexWrap': 'nowrap',
                              'alignItems': 'center'
                            },
                            'size': 'none',
                            'wrapperBody': false,
                            'id': 'u:d412c25bbbec',
                            'isFixedHeight': false
                          },
                          {
                            'type': 'container',
                            'body': [
                              {
                                'type': 'tpl',
                                'tpl': '开始时间',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:676cb24445d5',
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular',
                                      'color': '#666666'
                                    },
                                    'padding-and-margin:default': {
                                      'marginRight': '18px'
                                    }
                                  }
                                },
                                'wrapperCustomStyle': {
                                  'root': {
                                    'width': '70px'
                                  }
                                }
                              },
                              {
                                'type': 'tpl',
                                'tpl': '2023-01-02 15:15:15',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:8e64286890f1',
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular',
                                      'color': '#333333'
                                    },
                                    'padding-and-margin:default': {
                                      'marginRight': '18px'
                                    }
                                  }
                                },
                                'wrapperCustomStyle': {}
                              }
                            ],
                            'style': {
                              'position': 'static',
                              'display': 'flex',
                              'flex': '0 0 150px',
                              'flexBasis': '20%',
                              'overflowX': 'visible',
                              'flexWrap': 'nowrap',
                              'alignItems': 'center'
                            },
                            'size': 'none',
                            'wrapperBody': false,
                            'id': 'u:650826401ff0',
                            'isFixedHeight': false
                          },
                          {
                            'type': 'container',
                            'body': [
                              {
                                'type': 'tpl',
                                'tpl': '结束时间',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:4ec36a0e6562',
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular',
                                      'color': '#666666'
                                    },
                                    'padding-and-margin:default': {
                                      'marginRight': '18px'
                                    }
                                  }
                                },
                                'wrapperCustomStyle': {
                                  'root': {
                                    'width': '70px'
                                  }
                                }
                              },
                              {
                                'type': 'tpl',
                                'tpl': '2023-01-02 15:15:15',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:93326abd6702',
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular',
                                      'color': '#333333'
                                    },
                                    'padding-and-margin:default': {
                                      'marginRight': '18px'
                                    }
                                  }
                                },
                                'wrapperCustomStyle': {}
                              }
                            ],
                            'style': {
                              'position': 'static',
                              'display': 'flex',
                              'flex': '0 0 150px',
                              'flexBasis': '20%',
                              'overflowX': 'visible',
                              'flexWrap': 'nowrap',
                              'alignItems': 'center'
                            },
                            'size': 'none',
                            'wrapperBody': false,
                            'id': 'u:fa61b96f8d0a',
                            'isFixedHeight': false
                          }
                        ],
                        'size': 'xs',
                        'style': {
                          'position': 'static',
                          'display': 'flex',
                          'flex': '1 1 auto',
                          'flexGrow': 1,
                          'flexWrap': 'nowrap'
                        },
                        'wrapperBody': false,
                        'isFixedHeight': false,
                        'isFixedWidth': false,
                        'id': 'u:014aa64adeb4',
                        'themeCss': {
                          'baseControlClassName': {
                            'padding-and-margin:default': {
                              'marginTop': '10px'
                            }
                          }
                        }
                      },
                      {
                        'type': 'container',
                        'body': [
                          {
                            'type': 'container',
                            'body': [
                              {
                                'type': 'tpl',
                                'tpl': '部分退款',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:46405067f37a',
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular',
                                      'color': '#666666'
                                    },
                                    'padding-and-margin:default': {
                                      'marginRight': '18px'
                                    }
                                  }
                                },
                                'wrapperCustomStyle': {
                                  'root': {
                                    'width': '70px'
                                  }
                                }
                              },
                              {
                                'type': 'input-text',
                                'label': '',
                                'name': 'text',
                                'id': 'u:952fd23bd6e4',
                                'mode': 'inline',
                                'placeholder': '请输入退款金额'
                              }
                            ],
                            'style': {
                              'position': 'static',
                              'display': 'flex',
                              'flex': '0 0 150px',
                              'flexBasis': '20%',
                              'overflowX': 'visible',
                              'flexWrap': 'nowrap',
                              'alignItems': 'center'
                            },
                            'size': 'none',
                            'wrapperBody': false,
                            'id': 'u:d29b672881fc',
                            'isFixedHeight': false
                          },
                          {
                            'type': 'container',
                            'body': [
                              {
                                'type': 'button',
                                'label': '确认退款',
                                'onEvent': {
                                  'click': {
                                    'actions': []
                                  }
                                },
                                'id': 'u:e7373378b1b3',
                                'level': 'primary'
                              },
                              {
                                'type': 'tpl',
                                'tpl': '（进行中的订单不可退款，退单金额不能超过订单金额）',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:ab26e5bf00f6',
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'fontSize': '14px',
                                      'color': '#999999',
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular'
                                    }
                                  }
                                }
                              }
                            ],
                            'style': {
                              'position': 'static',
                              'display': 'flex',
                              'flex': '0 0 150px',
                              'flexBasis': '80%',
                              'overflowX': 'visible',
                              'flexWrap': 'nowrap',
                              'alignItems': 'center'
                            },
                            'size': 'none',
                            'wrapperBody': false,
                            'id': 'u:734cd73c136a',
                            'isFixedHeight': false
                          }
                        ],
                        'size': 'xs',
                        'style': {
                          'position': 'static',
                          'display': 'flex',
                          'flex': '1 1 auto',
                          'flexGrow': 1,
                          'flexWrap': 'nowrap'
                        },
                        'wrapperBody': false,
                        'isFixedHeight': false,
                        'isFixedWidth': false,
                        'id': 'u:8ef6feac2eae',
                        'themeCss': {
                          'baseControlClassName': {
                            'padding-and-margin:default': {
                              'marginTop': '10px'
                            }
                          }
                        }
                      }
                    ],
                    'style': {
                      'position': 'relative',
                      'inset': 'auto',
                      'flexWrap': 'nowrap',
                      'flexDirection': 'column'
                    },
                    'label': '',
                    'id': 'u:3ef6b67b953b',
                    'isFixedHeight': false,
                    'isFixedWidth': false,
                    'themeCss': {
                      'baseControlClassName': {
                        'padding-and-margin:default': {}
                      }
                    }
                  }
                ],
                'actions': [],
                'id': 'u:56307ef3b7f3',
                'bodyClassName': '',
                'className': 'zzcy-card m-b-none m-r-none m-l-none m-t-sm'
              },
              {
                'type': 'card',
                'header': {
                  'title': '',
                  'subTitle': ''
                },
                'body': [
                  {
                    'type': 'flex',
                    'items': [
                      {
                        'type': 'container',
                        'body': [
                          {
                            'type': 'divider',
                            'id': 'u:f57eff9812c2',
                            'lineStyle': 'solid',
                            'direction': 'vertical',
                            'rotate': 0,
                            'style': {
                              'borderWidth': '3px',
                              'height': '12px',
                              'borderRadius': '2px 2px 2px 2px',
                              'marginRight': '8px',
                              'marginTop': '0px',
                              'marginBottom': '0px'
                            },
                            'color': '#2878ff'
                          },
                          {
                            'type': 'tpl',
                            'tpl': '收费规则',
                            'inline': true,
                            'wrapperComponent': '',
                            'id': 'u:24faec951270',
                            'themeCss': {
                              'baseControlClassName': {
                                'font:default': {
                                  'fontSize': '16px',
                                  'color': '#333333',
                                  'lineHeight': '1',
                                  'fontWeight': '700',
                                  'font-family': '思源-Bold'
                                }
                              }
                            }
                          }
                        ],
                        'size': 'none',
                        'style': {
                          'position': 'static',
                          'display': 'flex',
                          'flex': '1 1 auto',
                          'flexGrow': 1,
                          'flexWrap': 'nowrap',
                          'alignItems': 'center'
                        },
                        'wrapperBody': false,
                        'isFixedHeight': false,
                        'isFixedWidth': false,
                        'id': 'u:7164df3d1f1e'
                      },
                      {
                        'type': 'container',
                        'body': [
                          {
                            'type': 'container',
                            'body': [
                              {
                                'type': 'tpl',
                                'tpl': '模板名称',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:7470725b130f',
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular',
                                      'color': '#666666'
                                    },
                                    'padding-and-margin:default': {
                                      'marginRight': '18px'
                                    }
                                  }
                                },
                                'wrapperCustomStyle': {
                                  'root': {
                                    'width': '70px'
                                  }
                                }
                              },
                              {
                                'type': 'tpl',
                                'tpl': '18812345678',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:869e197798ef',
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular',
                                      'color': '#333333'
                                    },
                                    'padding-and-margin:default': {
                                      'marginRight': '18px'
                                    }
                                  }
                                },
                                'wrapperCustomStyle': {}
                              }
                            ],
                            'style': {
                              'position': 'static',
                              'display': 'flex',
                              'flex': '0 0 150px',
                              'flexBasis': '20%',
                              'overflowX': 'visible',
                              'flexWrap': 'nowrap',
                              'alignItems': 'flex-start'
                            },
                            'size': 'none',
                            'wrapperBody': false,
                            'id': 'u:bc6e17db7396',
                            'isFixedHeight': false
                          },
                          {
                            'type': 'container',
                            'body': [
                              {
                                'type': 'tpl',
                                'tpl': '规则明细',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:70768c0348a2',
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular',
                                      'color': '#666666'
                                    },
                                    'padding-and-margin:default': {
                                      'marginRight': '18px'
                                    }
                                  }
                                },
                                'wrapperCustomStyle': {
                                  'root': {
                                    'width': '70px'
                                  }
                                }
                              },
                              {
                                'type': 'tpl',
                                'tpl': '<span>00:00~07:00   5元/小时  00:00~07:00   5元/小时  00:00~07:00   5元/小时</span></br>\n<span>00:00~07:00   5元/小时  00:00~07:00   5元/小时  00:00~07:00   5元/小时</span></br>\n<span>00:00~07:00   5元/小时  00:00~07:00   5元/小时  00:00~07:00   5元/小时</span></br><span>00:00~07:00   5元/小时  00:00~07:00   5元/小时  00:00~07:00   5元/小时</span>',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:0e2e9c23e438',
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular',
                                      'color': '#333333'
                                    },
                                    'padding-and-margin:default': {
                                      'marginRight': '18px'
                                    }
                                  }
                                },
                                'wrapperCustomStyle': {}
                              }
                            ],
                            'style': {
                              'position': 'static',
                              'display': 'flex',
                              'flex': '0 0 150px',
                              'flexBasis': '80%',
                              'overflowX': 'visible',
                              'flexWrap': 'nowrap',
                              'alignItems': 'flex-start'
                            },
                            'size': 'none',
                            'wrapperBody': false,
                            'id': 'u:8a7ec546c09a',
                            'isFixedHeight': false
                          }
                        ],
                        'size': 'xs',
                        'style': {
                          'position': 'static',
                          'display': 'flex',
                          'flex': '1 1 auto',
                          'flexGrow': 1,
                          'flexWrap': 'nowrap'
                        },
                        'wrapperBody': false,
                        'isFixedHeight': false,
                        'isFixedWidth': false,
                        'id': 'u:a69b4be3c29d',
                        'themeCss': {
                          'baseControlClassName': {
                            'padding-and-margin:default': {
                              'marginTop': '10px'
                            }
                          }
                        }
                      }
                    ],
                    'style': {
                      'position': 'relative',
                      'inset': 'auto',
                      'flexWrap': 'nowrap',
                      'flexDirection': 'column'
                    },
                    'label': '',
                    'id': 'u:4993105eacb6',
                    'isFixedHeight': false,
                    'isFixedWidth': false,
                    'themeCss': {
                      'baseControlClassName': {
                        'padding-and-margin:default': {}
                      }
                    }
                  }
                ],
                'actions': [],
                'id': 'u:c4f85ad72246',
                'bodyClassName': '',
                'className': 'zzcy-card m-b-none m-r-none m-l-none m-t-sm'
              },
              {
                'type': 'card',
                'header': {
                  'title': '',
                  'subTitle': ''
                },
                'body': [
                  {
                    'type': 'flex',
                    'items': [
                      {
                        'type': 'container',
                        'body': [
                          {
                            'type': 'divider',
                            'id': 'u:25af5d47466a',
                            'lineStyle': 'solid',
                            'direction': 'vertical',
                            'rotate': 0,
                            'style': {
                              'borderWidth': '3px',
                              'height': '12px',
                              'borderRadius': '2px 2px 2px 2px',
                              'marginRight': '8px',
                              'marginTop': '0px',
                              'marginBottom': '0px'
                            },
                            'color': '#2878ff'
                          },
                          {
                            'type': 'tpl',
                            'tpl': '场地信息',
                            'inline': true,
                            'wrapperComponent': '',
                            'id': 'u:73e9ca3e8279',
                            'themeCss': {
                              'baseControlClassName': {
                                'font:default': {
                                  'fontSize': '16px',
                                  'color': '#333333',
                                  'lineHeight': '1',
                                  'fontWeight': '700',
                                  'font-family': '思源-Bold'
                                }
                              }
                            }
                          }
                        ],
                        'size': 'none',
                        'style': {
                          'position': 'static',
                          'display': 'flex',
                          'flex': '1 1 auto',
                          'flexGrow': 1,
                          'flexWrap': 'nowrap',
                          'alignItems': 'center'
                        },
                        'wrapperBody': false,
                        'isFixedHeight': false,
                        'isFixedWidth': false,
                        'id': 'u:c4075da14a39'
                      },
                      {
                        'type': 'container',
                        'body': [
                          {
                            'type': 'container',
                            'body': [
                              {
                                'type': 'tpl',
                                'tpl': '场地名称',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:6e247ff3f701',
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular',
                                      'color': '#666666'
                                    },
                                    'padding-and-margin:default': {
                                      'marginRight': '18px'
                                    }
                                  }
                                },
                                'wrapperCustomStyle': {
                                  'root': {
                                    'width': '70px'
                                  }
                                }
                              },
                              {
                                'type': 'tpl',
                                'tpl': '18812345678',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:f9afb9bdc41f',
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular',
                                      'color': '#333333'
                                    },
                                    'padding-and-margin:default': {
                                      'marginRight': '18px'
                                    }
                                  }
                                },
                                'wrapperCustomStyle': {}
                              }
                            ],
                            'style': {
                              'position': 'static',
                              'display': 'flex',
                              'flex': '0 0 150px',
                              'flexBasis': '20%',
                              'overflowX': 'visible',
                              'flexWrap': 'nowrap',
                              'alignItems': 'center'
                            },
                            'size': 'none',
                            'wrapperBody': false,
                            'id': 'u:a8bf607119dc',
                            'isFixedHeight': false
                          },
                          {
                            'type': 'container',
                            'body': [
                              {
                                'type': 'tpl',
                                'tpl': '场地负责人',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:e159714ded72',
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular',
                                      'color': '#666666'
                                    },
                                    'padding-and-margin:default': {
                                      'marginRight': '18px'
                                    }
                                  }
                                },
                                'wrapperCustomStyle': {
                                  'root': {
                                    'width': '70px'
                                  }
                                }
                              },
                              {
                                'type': 'tpl',
                                'tpl': '18812345678',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:643b5aaa04fe',
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular',
                                      'color': '#333333'
                                    },
                                    'padding-and-margin:default': {
                                      'marginRight': '18px'
                                    }
                                  }
                                },
                                'wrapperCustomStyle': {}
                              }
                            ],
                            'style': {
                              'position': 'static',
                              'display': 'flex',
                              'flex': '0 0 150px',
                              'flexBasis': '20%',
                              'overflowX': 'visible',
                              'flexWrap': 'nowrap',
                              'alignItems': 'center'
                            },
                            'size': 'none',
                            'wrapperBody': false,
                            'id': 'u:dd052458b272',
                            'isFixedHeight': false
                          },
                          {
                            'type': 'container',
                            'body': [
                              {
                                'type': 'tpl',
                                'tpl': '联系方式',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:ca80c6e456b7',
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular',
                                      'color': '#666666'
                                    },
                                    'padding-and-margin:default': {
                                      'marginRight': '18px'
                                    }
                                  }
                                },
                                'wrapperCustomStyle': {
                                  'root': {
                                    'width': '70px'
                                  }
                                }
                              },
                              {
                                'type': 'tpl',
                                'tpl': '18812345678',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:e7d926073bd1',
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular',
                                      'color': '#333333'
                                    },
                                    'padding-and-margin:default': {
                                      'marginRight': '18px'
                                    }
                                  }
                                },
                                'wrapperCustomStyle': {}
                              }
                            ],
                            'style': {
                              'position': 'static',
                              'display': 'flex',
                              'flex': '0 0 150px',
                              'flexBasis': '20%',
                              'overflowX': 'visible',
                              'flexWrap': 'nowrap',
                              'alignItems': 'center'
                            },
                            'size': 'none',
                            'wrapperBody': false,
                            'id': 'u:49c628efb309',
                            'isFixedHeight': false
                          },
                          {
                            'type': 'container',
                            'body': [
                              {
                                'type': 'tpl',
                                'tpl': '地址',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:cb79fe4f1540',
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular',
                                      'color': '#666666'
                                    },
                                    'padding-and-margin:default': {
                                      'marginRight': '18px'
                                    }
                                  }
                                },
                                'wrapperCustomStyle': {
                                  'root': {
                                    'width': '70px'
                                  }
                                }
                              },
                              {
                                'type': 'tpl',
                                'tpl': '河南省郑州市高新区漳河路',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:415a2ac959ce',
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular',
                                      'color': '#333333'
                                    },
                                    'padding-and-margin:default': {
                                      'marginRight': '18px'
                                    }
                                  }
                                },
                                'wrapperCustomStyle': {}
                              }
                            ],
                            'style': {
                              'position': 'static',
                              'display': 'flex',
                              'flex': '0 0 150px',
                              'flexBasis': '40%',
                              'overflowX': 'visible',
                              'flexWrap': 'nowrap',
                              'alignItems': 'center'
                            },
                            'size': 'none',
                            'wrapperBody': false,
                            'id': 'u:dc6c3c445c48',
                            'isFixedHeight': false
                          }
                        ],
                        'size': 'xs',
                        'style': {
                          'position': 'static',
                          'display': 'flex',
                          'flex': '1 1 auto',
                          'flexGrow': 1,
                          'flexWrap': 'nowrap'
                        },
                        'wrapperBody': false,
                        'isFixedHeight': false,
                        'isFixedWidth': false,
                        'id': 'u:58987b317558',
                        'themeCss': {
                          'baseControlClassName': {
                            'padding-and-margin:default': {
                              'marginTop': '10px'
                            }
                          }
                        }
                      }
                    ],
                    'style': {
                      'position': 'relative',
                      'inset': 'auto',
                      'flexWrap': 'nowrap',
                      'flexDirection': 'column'
                    },
                    'label': '',
                    'id': 'u:c178432a576a',
                    'isFixedHeight': false,
                    'isFixedWidth': false,
                    'themeCss': {
                      'baseControlClassName': {
                        'padding-and-margin:default': {}
                      }
                    }
                  }
                ],
                'actions': [],
                'id': 'u:18a258dbed01',
                'bodyClassName': '',
                'className': 'zzcy-card m-b-none m-r-none m-l-none m-t-sm'
              },
              {
                'type': 'card',
                'header': {
                  'title': '',
                  'subTitle': ''
                },
                'body': [
                  {
                    'type': 'flex',
                    'items': [
                      {
                        'type': 'container',
                        'body': [
                          {
                            'type': 'divider',
                            'id': 'u:33c3c2e87b14',
                            'lineStyle': 'solid',
                            'direction': 'vertical',
                            'rotate': 0,
                            'style': {
                              'borderWidth': '3px',
                              'height': '12px',
                              'borderRadius': '2px 2px 2px 2px',
                              'marginRight': '8px',
                              'marginTop': '0px',
                              'marginBottom': '0px'
                            },
                            'color': '#2878ff'
                          },
                          {
                            'type': 'tpl',
                            'tpl': '设备信息',
                            'inline': true,
                            'wrapperComponent': '',
                            'id': 'u:e43ed883ae20',
                            'themeCss': {
                              'baseControlClassName': {
                                'font:default': {
                                  'fontSize': '18px',
                                  'color': '#333333',
                                  'lineHeight': '1',
                                  'fontWeight': '700',
                                  'font-family': '思源-Bold'
                                }
                              }
                            }
                          }
                        ],
                        'size': 'none',
                        'style': {
                          'position': 'static',
                          'display': 'flex',
                          'flex': '1 1 auto',
                          'flexGrow': 1,
                          'flexWrap': 'nowrap',
                          'alignItems': 'center'
                        },
                        'wrapperBody': false,
                        'isFixedHeight': false,
                        'isFixedWidth': false,
                        'id': 'u:f731fbc0c0ad'
                      },
                      {
                        'type': 'container',
                        'body': [
                          {
                            'type': 'container',
                            'body': [
                              {
                                'type': 'tpl',
                                'tpl': '设备编号',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:1a9fa6d695e7',
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular',
                                      'color': '#666666'
                                    },
                                    'padding-and-margin:default': {
                                      'marginRight': '18px'
                                    }
                                  }
                                },
                                'wrapperCustomStyle': {
                                  'root': {
                                    'width': '70px'
                                  }
                                }
                              },
                              {
                                'type': 'tpl',
                                'tpl': '18812345678',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:5945c0249c13',
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular',
                                      'color': '#333333'
                                    },
                                    'padding-and-margin:default': {
                                      'marginRight': '18px'
                                    }
                                  }
                                },
                                'wrapperCustomStyle': {}
                              }
                            ],
                            'style': {
                              'position': 'static',
                              'display': 'flex',
                              'flex': '0 0 150px',
                              'flexBasis': '20%',
                              'overflowX': 'visible',
                              'flexWrap': 'nowrap',
                              'alignItems': 'center'
                            },
                            'size': 'none',
                            'wrapperBody': false,
                            'id': 'u:ed9e858b1029',
                            'isFixedHeight': false
                          },
                          {
                            'type': 'container',
                            'body': [
                              {
                                'type': 'tpl',
                                'tpl': '版本号',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:ee20cce3b804',
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular',
                                      'color': '#666666'
                                    },
                                    'padding-and-margin:default': {
                                      'marginRight': '18px'
                                    }
                                  }
                                },
                                'wrapperCustomStyle': {
                                  'root': {
                                    'width': '70px'
                                  }
                                }
                              },
                              {
                                'type': 'tpl',
                                'tpl': '18812345678',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:620bf80f399b',
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular',
                                      'color': '#333333'
                                    },
                                    'padding-and-margin:default': {
                                      'marginRight': '18px'
                                    }
                                  }
                                },
                                'wrapperCustomStyle': {}
                              }
                            ],
                            'style': {
                              'position': 'static',
                              'display': 'flex',
                              'flex': '0 0 150px',
                              'flexBasis': '20%',
                              'overflowX': 'visible',
                              'flexWrap': 'nowrap',
                              'alignItems': 'center'
                            },
                            'size': 'none',
                            'wrapperBody': false,
                            'id': 'u:91e9f4248d13',
                            'isFixedHeight': false
                          },
                          {
                            'type': 'container',
                            'body': [
                              {
                                'type': 'tpl',
                                'tpl': '设备类型',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:79b5cfcfd8b6',
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular',
                                      'color': '#666666'
                                    },
                                    'padding-and-margin:default': {
                                      'marginRight': '18px'
                                    }
                                  }
                                },
                                'wrapperCustomStyle': {
                                  'root': {
                                    'width': '70px'
                                  }
                                }
                              },
                              {
                                'type': 'tpl',
                                'tpl': '18812345678',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:308fd0b91a3d',
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular',
                                      'color': '#333333'
                                    },
                                    'padding-and-margin:default': {
                                      'marginRight': '18px'
                                    }
                                  }
                                },
                                'wrapperCustomStyle': {}
                              }
                            ],
                            'style': {
                              'position': 'static',
                              'display': 'flex',
                              'flex': '0 0 150px',
                              'flexBasis': '20%',
                              'overflowX': 'visible',
                              'flexWrap': 'nowrap',
                              'alignItems': 'center'
                            },
                            'size': 'none',
                            'wrapperBody': false,
                            'id': 'u:4330a21f1ebd',
                            'isFixedHeight': false
                          }
                        ],
                        'size': 'xs',
                        'style': {
                          'position': 'static',
                          'display': 'flex',
                          'flex': '1 1 auto',
                          'flexGrow': 1,
                          'flexWrap': 'nowrap'
                        },
                        'wrapperBody': false,
                        'isFixedHeight': false,
                        'isFixedWidth': false,
                        'id': 'u:717a0f92406b',
                        'themeCss': {
                          'baseControlClassName': {
                            'padding-and-margin:default': {
                              'marginTop': '10px'
                            }
                          }
                        }
                      }
                    ],
                    'style': {
                      'position': 'relative',
                      'inset': 'auto',
                      'flexWrap': 'nowrap',
                      'flexDirection': 'column'
                    },
                    'label': '',
                    'id': 'u:cbf03dcc1f44',
                    'isFixedHeight': false,
                    'isFixedWidth': false,
                    'themeCss': {
                      'baseControlClassName': {
                        'padding-and-margin:default': {}
                      }
                    }
                  }
                ],
                'actions': [],
                'id': 'u:749a5ecfa011',
                'bodyClassName': '',
                'className': 'zzcy-card m-b-none m-r-none m-l-none m-t-sm'
              },
              {
                'type': 'card',
                'header': {
                  'title': '',
                  'subTitle': ''
                },
                'body': [
                  {
                    'type': 'flex',
                    'items': [
                      {
                        'type': 'container',
                        'body': [
                          {
                            'type': 'divider',
                            'id': 'u:b5a61979218f',
                            'lineStyle': 'solid',
                            'direction': 'vertical',
                            'rotate': 0,
                            'style': {
                              'borderWidth': '3px',
                              'height': '12px',
                              'borderRadius': '2px 2px 2px 2px',
                              'marginRight': '8px',
                              'marginTop': '0px',
                              'marginBottom': '0px'
                            },
                            'color': '#2878ff'
                          },
                          {
                            'type': 'tpl',
                            'tpl': '发票信息',
                            'inline': true,
                            'wrapperComponent': '',
                            'id': 'u:2f0e0c504046',
                            'themeCss': {
                              'baseControlClassName': {
                                'font:default': {
                                  'fontSize': '16px',
                                  'color': '#333333',
                                  'lineHeight': '1',
                                  'fontWeight': '700',
                                  'font-family': '思源-Bold'
                                }
                              }
                            }
                          },
                          {
                            'type': 'tpl',
                            'tpl': '（开票信息需要去开票记录中查看）',
                            'inline': true,
                            'wrapperComponent': '',
                            'id': 'u:ec987c19b3f1',
                            'themeCss': {
                              'baseControlClassName': {
                                'font:default': {
                                  'fontSize': '14px',
                                  'color': '#999999',
                                  'lineHeight': '1',
                                  'font-family': '思源-Regular'
                                }
                              }
                            }
                          }
                        ],
                        'size': 'none',
                        'style': {
                          'position': 'static',
                          'display': 'flex',
                          'flex': '1 1 auto',
                          'flexGrow': 1,
                          'flexWrap': 'nowrap',
                          'alignItems': 'center'
                        },
                        'wrapperBody': false,
                        'isFixedHeight': false,
                        'isFixedWidth': false,
                        'id': 'u:f7a160d6cd35'
                      },
                      {
                        'type': 'container',
                        'body': [
                          {
                            'type': 'container',
                            'body': [
                              {
                                'type': 'tpl',
                                'tpl': '是否已开票',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:1af8884c7661',
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular',
                                      'color': '#666666'
                                    },
                                    'padding-and-margin:default': {
                                      'marginRight': '18px'
                                    }
                                  }
                                },
                                'wrapperCustomStyle': {
                                  'root': {
                                    'width': '70px'
                                  }
                                }
                              },
                              {
                                'type': 'switch',
                                'label': '',
                                'option': '',
                                'name': 'switch',
                                'falseValue': false,
                                'trueValue': true,
                                'id': 'u:df4b3c616cb8',
                                'value': false,
                                'inputClassName': '',
                                'static': false,
                                'disabled': false,
                                'mode': 'inline'
                              }
                            ],
                            'style': {
                              'position': 'static',
                              'display': 'flex',
                              'flex': '0 0 150px',
                              'flexBasis': '20%',
                              'overflowX': 'visible',
                              'flexWrap': 'nowrap',
                              'alignItems': 'center'
                            },
                            'size': 'none',
                            'wrapperBody': false,
                            'id': 'u:112938790701',
                            'isFixedHeight': false
                          }
                        ],
                        'size': 'xs',
                        'style': {
                          'position': 'static',
                          'display': 'flex',
                          'flex': '1 1 auto',
                          'flexGrow': 1,
                          'flexWrap': 'nowrap'
                        },
                        'wrapperBody': false,
                        'isFixedHeight': false,
                        'isFixedWidth': false,
                        'id': 'u:ee9333574845',
                        'themeCss': {
                          'baseControlClassName': {
                            'padding-and-margin:default': {
                              'marginTop': '10px'
                            }
                          }
                        }
                      }
                    ],
                    'style': {
                      'position': 'relative',
                      'inset': 'auto',
                      'flexWrap': 'nowrap',
                      'flexDirection': 'column'
                    },
                    'label': '',
                    'id': 'u:b2aadfc6ebd4',
                    'isFixedHeight': false,
                    'isFixedWidth': false,
                    'themeCss': {
                      'baseControlClassName': {
                        'padding-and-margin:default': {}
                      }
                    }
                  }
                ],
                'actions': [],
                'id': 'u:397bc1e65da2',
                'bodyClassName': '',
                'className': 'zzcy-card m-b-none m-r-none m-l-none m-t-sm'
              }
            ],
            'id': 'u:17cb68486fdb',
            'dsType': 'api',
            'visibleOn': '${detailType==1}'
          },
          {
            'type': 'service',
            'body': [
              {
                'type': 'card',
                'header': {
                  'title': '',
                  'subTitle': ''
                },
                'body': [
                  {
                    'type': 'flex',
                    'items': [
                      {
                        'type': 'container',
                        'body': [
                          {
                            'type': 'divider',
                            'id': 'u:04060342e54e',
                            'lineStyle': 'solid',
                            'direction': 'vertical',
                            'rotate': 0,
                            'style': {
                              'height': '12px',
                              'borderWidth': '3px',
                              'marginRight': '8px'
                            },
                            'color': '#2878ff'
                          },
                          {
                            'type': 'tpl',
                            'tpl': '用户名称',
                            'inline': true,
                            'wrapperComponent': '',
                            'id': 'u:e0c624707961',
                            'themeCss': {
                              'baseControlClassName': {
                                'font:default': {
                                  'fontSize': '16px',
                                  'fontWeight': '700',
                                  'lineHeight': '1',
                                  'font-family': '思源-Regular'
                                }
                              }
                            }
                          }
                        ],
                        'style': {
                          'position': 'static',
                          'display': 'flex',
                          'flex': '0 0 auto',
                          'flexWrap': 'nowrap',
                          'alignItems': 'center'
                        },
                        'size': 'none',
                        'wrapperBody': false,
                        'id': 'u:f581bd69f3d8',
                        'isFixedWidth': false
                      },
                      {
                        'type': 'container',
                        'body': [
                          {
                            'type': 'container',
                            'body': [
                              {
                                'type': 'tpl',
                                'tpl': '场地名称',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:e8921dc7cc2b',
                                'wrapperCustomStyle': {
                                  'root': {
                                    'width': '70px'
                                  }
                                },
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'color': '#666666',
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular'
                                    },
                                    'padding-and-margin:default': {
                                      'marginRight': '18px'
                                    }
                                  }
                                }
                              },
                              {
                                'type': 'tpl',
                                'tpl': '河科大',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:7eeac940a12b',
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'color': '#333333',
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular'
                                    }
                                  }
                                }
                              }
                            ],
                            'style': {
                              'position': 'static',
                              'display': 'flex',
                              'flexWrap': 'nowrap',
                              'alignItems': 'center',
                              'flexBasis': '20%',
                              'flex': '0 0 150px',
                              'overflowX': 'visible'
                            },
                            'size': 'none',
                            'wrapperBody': false,
                            'id': 'u:9ee636ae306d',
                            'isFixedHeight': false
                          },
                          {
                            'type': 'container',
                            'body': [
                              {
                                'type': 'tpl',
                                'tpl': '场地名称',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:9f77a5eeed5e',
                                'wrapperCustomStyle': {
                                  'root': {
                                    'width': '70px'
                                  }
                                },
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'color': '#666666',
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular'
                                    },
                                    'padding-and-margin:default': {
                                      'marginRight': '18px'
                                    }
                                  }
                                }
                              },
                              {
                                'type': 'tpl',
                                'tpl': '河科大',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:c00ee659aa5b',
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'color': '#333333',
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular'
                                    }
                                  }
                                }
                              }
                            ],
                            'style': {
                              'position': 'static',
                              'display': 'flex',
                              'flexWrap': 'nowrap',
                              'alignItems': 'center',
                              'flexBasis': '20%',
                              'flex': '0 0 150px',
                              'overflowX': 'visible'
                            },
                            'size': 'none',
                            'wrapperBody': false,
                            'id': 'u:ac372758cfe3',
                            'isFixedHeight': false
                          },
                          {
                            'type': 'container',
                            'body': [
                              {
                                'type': 'tpl',
                                'tpl': '场地名称',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:3bd0bcb1f63b',
                                'wrapperCustomStyle': {
                                  'root': {
                                    'width': '70px'
                                  }
                                },
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'color': '#666666',
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular'
                                    },
                                    'padding-and-margin:default': {
                                      'marginRight': '18px'
                                    }
                                  }
                                }
                              },
                              {
                                'type': 'tpl',
                                'tpl': '河科大',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:b0b2ca700f9a',
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'color': '#333333',
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular'
                                    }
                                  }
                                }
                              }
                            ],
                            'style': {
                              'position': 'static',
                              'display': 'flex',
                              'flexWrap': 'nowrap',
                              'alignItems': 'center',
                              'flexBasis': '20%',
                              'flex': '0 0 150px',
                              'overflowX': 'visible'
                            },
                            'size': 'none',
                            'wrapperBody': false,
                            'id': 'u:6f314ab75722',
                            'isFixedHeight': false
                          },
                          {
                            'type': 'container',
                            'body': [
                              {
                                'type': 'tpl',
                                'tpl': '场地名称',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:76c5aec9bcc0',
                                'wrapperCustomStyle': {
                                  'root': {
                                    'width': '70px'
                                  }
                                },
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'color': '#666666',
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular'
                                    },
                                    'padding-and-margin:default': {
                                      'marginRight': '18px'
                                    }
                                  }
                                }
                              },
                              {
                                'type': 'tpl',
                                'tpl': '河科大',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:193c2848b8f5',
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'color': '#333333',
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular'
                                    }
                                  }
                                }
                              }
                            ],
                            'style': {
                              'position': 'static',
                              'display': 'flex',
                              'flexWrap': 'nowrap',
                              'alignItems': 'center',
                              'flexBasis': '20%',
                              'flex': '0 0 150px',
                              'overflowX': 'visible'
                            },
                            'size': 'none',
                            'wrapperBody': false,
                            'id': 'u:e959d24f5d18',
                            'isFixedHeight': false
                          },
                          {
                            'type': 'container',
                            'body': [
                              {
                                'type': 'tpl',
                                'tpl': '场地名称',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:e384ba558575',
                                'wrapperCustomStyle': {
                                  'root': {
                                    'width': '70px'
                                  }
                                },
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'color': '#666666',
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular'
                                    },
                                    'padding-and-margin:default': {
                                      'marginRight': '18px'
                                    }
                                  }
                                }
                              },
                              {
                                'type': 'tpl',
                                'tpl': '河科大',
                                'inline': true,
                                'wrapperComponent': '',
                                'id': 'u:9233b846fe4f',
                                'themeCss': {
                                  'baseControlClassName': {
                                    'font:default': {
                                      'color': '#333333',
                                      'lineHeight': '1',
                                      'font-family': '思源-Regular'
                                    }
                                  }
                                }
                              }
                            ],
                            'style': {
                              'position': 'static',
                              'display': 'flex',
                              'flexWrap': 'nowrap',
                              'alignItems': 'center',
                              'flexBasis': '20%',
                              'flex': '0 0 150px',
                              'overflowX': 'visible'
                            },
                            'size': 'none',
                            'wrapperBody': false,
                            'id': 'u:74237bc6fe9d',
                            'isFixedHeight': false
                          }
                        ],
                        'size': 'xs',
                        'style': {
                          'position': 'static',
                          'display': 'flex',
                          'flex': '1 1 auto',
                          'flexGrow': 1,
                          'flexWrap': 'nowrap'
                        },
                        'wrapperBody': false,
                        'isFixedHeight': false,
                        'isFixedWidth': false,
                        'id': 'u:63d8165812f9',
                        'themeCss': {
                          'baseControlClassName': {
                            'padding-and-margin:default': {
                              'marginTop': '10px'
                            }
                          }
                        }
                      }
                    ],
                    'style': {
                      'position': 'relative',
                      'inset': 'auto',
                      'flexWrap': 'nowrap',
                      'flexDirection': 'column'
                    },
                    'label': '',
                    'id': 'u:f6513f4cba5d',
                    'isFixedHeight': false,
                    'isFixedWidth': false
                  }
                ],
                'actions': [],
                'id': 'u:b2d6a9ad10ee',
                'bodyClassName': '',
                'className': 'zzcy-card m-r-none m-b-none m-l-none m-t-sm'
              }
            ],
            'id': 'u:0ebe238143c9',
            'dsType': 'api',
            'visibleOn': '${detailType==2}'
          }
        ],
        'id': 'u:86a8adac8649',
        'dsType': 'api'
      }
    ],
    'className': 'app-popover :AMISCSSWrapper zzcy-sbxq',
    'actions': [
      {
        'type': 'button',
        'actionType': 'cancel',
        'label': '关闭页面',
        'id': 'u:e26258a9f580'
      },
      {
        'type': 'button',
        'actionType': 'confirm',
        'label': '结束订单',
        'primary': true,
        'id': 'u:b5c8092dab33'
      }
    ],
    'id': 'u:f3eafea56aad',
    'resizable': false,
    'hideActions': false,
    'closeOnOutside': true,
    'closeOnEsc': true,
    'width': '80%',
    'size': 'xl',
    'footClassName': 'm-none p-none',
    'themeCss': {
      'drawerClassName': {
        'box-shadow': ' 0px 0px 0px 0px transparent'
      },
      'drawerBodyClassName': {
        'background': '#f6f9fd'
      },
      'drawerFooterClassName': {
        'background': '#f6f9fd'
      }
    },
    'showCloseButton': false,
    'headClassName': 'm-none p-none no-border w-xxs'
  };
  previewSchema = { // 组件面板预览时需要
    'type': 'card',
    'header': {
      'title': '',
      'subTitle': ''
    },
    'body': [
      {
        'type': 'flex',
        'items': [
          {
            'type': 'container',
            'body': [
              {
                'type': 'container',
                'body': [
                  {
                    'type': 'tpl',
                    'tpl': '500',
                    'inline': true,
                    'wrapperComponent': '',
                    'id': 'u:d6346f630d78',
                    'themeCss': {
                      'baseControlClassName': {
                        'font:default': {
                          'lineHeight': '1',
                          'fontSize': '24px',
                          'color': '#343c6a',
                          'font-family': '思源-Bold'
                        }
                      }
                    }
                  },
                  {
                    'type': 'tpl',
                    'tpl': '总场地数',
                    'inline': true,
                    'wrapperComponent': '',
                    'id': 'u:8790f060748c',
                    'themeCss': {
                      'baseControlClassName': {
                        'font:default': {
                          'lineHeight': '1',
                          'fontSize': '14px',
                          'color': '#999999',
                          'font-family': '思源-Regular'
                        },
                        'padding-and-margin:default': {
                          'marginTop': '14px'
                        }
                      }
                    }
                  }
                ],
                'size': 'none',
                'style': {
                  'position': 'static',
                  'display': 'flex',
                  'flex': '1 1 auto',
                  'flexGrow': 1,
                  'flexWrap': 'nowrap',
                  'flexDirection': 'column',
                  'justifyContent': 'center'
                },
                'wrapperBody': false,
                'isFixedHeight': false,
                'isFixedWidth': false,
                'id': 'u:01f47c0e73b4',
                'themeCss': {
                  'baseControlClassName': {
                    'padding-and-margin:default': {
                      'marginTop': '0px',
                      'marginRight': '0px',
                      'marginBottom': '0px',
                      'marginLeft': '0px'
                    }
                  }
                }
              }
            ],
            'size': 'xs',
            'style': {
              'position': 'static',
              'display': 'block',
              'flex': '1 1 auto',
              'flexGrow': 1,
              'flexBasis': 'auto'
            },
            'wrapperBody': false,
            'isFixedHeight': false,
            'isFixedWidth': false,
            'id': 'u:3904a4a3d827'
          },
          {
            'type': 'container',
            'body': [
              {
                'type': 'image',
                'id': 'u:9de6ae723902',
                'enlargeAble': false,
                'maxScale': '',
                'minScale': '',
                'style': {
                  'display': 'block'
                },
                'src': 'http://47.92.37.224:7076/pic/1.png',
                'imageMode': 'original',
                'themeCss': {
                  'imageControlClassName': {
                    'size:default': {
                      'width': '50px',
                      'height': '50px',
                      'minWidth': ''
                    },
                    'border:default': {
                      'top-border-width': '0',
                      'left-border-width': '0',
                      'right-border-width': '0',
                      'bottom-border-width': '0'
                    },
                    'radius:default': {
                      'top-left-border-radius': '1000px',
                      'top-right-border-radius': '1000px',
                      'bottom-left-border-radius': '1000px',
                      'bottom-right-border-radius': '1000px',
                      'overflow': 'hidden'
                    }
                  }
                }
              }
            ],
            'size': 'none',
            'style': {
              'position': 'static',
              'display': 'flex',
              'flex': '1 1 auto',
              'flexGrow': 1,
              'flexWrap': 'nowrap',
              'justifyContent': 'flex-end',
              'alignItems': 'center'
            },
            'wrapperBody': false,
            'isFixedHeight': false,
            'isFixedWidth': false,
            'id': 'u:34dde64310da'
          }
        ],
        'style': {
          'position': 'relative'
        },
        'label': '',
        'id': 'u:7af45bee7d9d'
      }
    ],
    'actions': [],
    'id': 'u:c8bd193911f2',
    'bodyClassName': '',
    'className': 'zzcy-card m-none'
  };

  // 容器类组件必需字段
  // regions = [
  //   {
  //     key: 'body',
  //     label: '内容区'
  //   }
  // ];

  panelTitle = '配置'; // 右侧属性面板Title

  panelControls = [ // 右侧属性面板配置项

  ];
}

registerEditorPlugin(InfoGridPlugin)
