import './index.less'

import debounce from 'lodash/debounce'
import PropTypes from 'ant-design-vue/es/_util/vue-types'
import { triggerEvent, inBrowser, isFun } from '../../utils/util'
import 'ant-design-vue/es/icon/style'
// import Icon from 'ant-design-vue/es/icon'
import { defaultRenderLogo } from '../SiderMenu/SiderMenu'
import defaultSettings from '@/config/defaultSettings'

// const right = require('@/assets/right.png')
// const left = require('@/assets/left.png')
const right = require('@/assets/homeImg/cdBtn.png')
const left = require('@/assets/homeImg/cdBtn.png')
export const GlobalHeaderProps = {
  collapsed: PropTypes.bool,
  handleCollapse: PropTypes.func,
  isMobile: PropTypes.bool.def(false),
  fixedHeader: PropTypes.bool.def(false),
  logo: PropTypes.any,
  menuRender: PropTypes.any,
  collapsedButtonRender: PropTypes.any,
  headerContentRender: PropTypes.any,
  rightContentRender: PropTypes.any
}

const defaultRenderCollapsedButton = (h, collapsed) => (
  <img src={collapsed ? right : left} style={{ width: '100%', height: '100%' }} />
  // <Icon type={collapsed ? 'menu-unfold' : 'menu-fold'}/>
)

const GlobalHeader = {
  name: 'GlobalHeader',
  props: GlobalHeaderProps,
  render (h) {
    const { isMobile, logo, rightContentRender, headerContentRender } = this.$props
    const toggle = () => {
      const { collapsed, handleCollapse } = this.$props
      if (handleCollapse) handleCollapse(!collapsed)
      this.triggerResizeEvent()
    }

    const mouseenter = (event) => {
      const { collapsed } = this.$props
      if (!collapsed) {
        document.querySelector('#moveBtn').style.display = 'block'
      }
    }
    const mouseleave = (event) => {
      const { collapsed } = this.$props
      if (!collapsed) {
        document.querySelector('#moveBtn').style.display = 'none'
      }
    }

    const openHome = (event) => {
      // console.log('打开驾驶舱>>>>>')
      this.$router.push({ path: '/' }).catch(() => { })
    }

    // const mouseMoveBtn = () => {
    //   // console.log('mouseMoveBtn>>>>>', 1)
    // }

    const renderCollapsedButton = () => {
      const {
        collapsed,
        collapsedButtonRender = defaultRenderCollapsedButton,
        menuRender
      } = this.$props
      if (collapsedButtonRender !== false && menuRender !== false && defaultSettings.isLogoFont) {
        // return (
        //   <span class="ant-pro-global-header-trigger" onClick={toggle}>
        //     {isFun(collapsedButtonRender) && collapsedButtonRender(h, collapsed) || collapsedButtonRender}
        //   </span>
        // )
        // const logo = require('@/assets/logo.png')
        const loginFont = require('@/assets/login_font.png')

        return (
          <div></div>
          // <div class="ant-pro-global-header-trigger" style={{ padding: '0 28px', display: 'flex', alignItems: 'center' }}>
          //   {/* <img src={logo} style={{ width: '20px', height: '20px', marginRight: '10px' }} onClick={openHome}/> */}
          //   <img src={loginFont} style={{ height: '42px' }} onClick={openHome}/>
          //   <div id='moveBtn' class='trigger-btn' style={{ left: collapsed ? '60px' : '210px', display: collapsed ? 'block' : 'none' }} onMouseenter={mouseenter} onMouseleave={mouseleave} onClick={toggle}>
          //     {isFun(collapsedButtonRender) && collapsedButtonRender(h, collapsed) || collapsedButtonRender}

          //   </div>
          //   {/* <span class="title-font" >
          //     诚毅物联网管理系统
          //   </span> */}
          // </div>
        )
      } else {
        // return (
        //   <span class="ant-pro-global-header-trigger" onClick={toggle}>
        //     {isFun(collapsedButtonRender) && collapsedButtonRender(h, collapsed) || collapsedButtonRender}
        //   </span>
        // )
        // const logo = require('@/assets/logo.png')
        // const loginFont = require('@/assets/login_font.png')

        return (
          <div></div>

          // <div class="ant-pro-global-header-trigger" style={{ padding: '0 0 0 17px', display: 'flex', alignItems: 'center' }}>
          //   <img src={logo} style={{ width: '20px', height: '20px', marginRight: '134px' }} onClick={openHome}/>
          //   {/* <img src={loginFont} style={{ height: '20px' }} onClick={openHome}/> */}
          //   <div id='moveBtn' class='trigger-btn' style={{ left: collapsed ? '60px' : '210px', display: collapsed ? 'block' : 'none' }} onMouseenter={mouseenter} onMouseleave={mouseleave} onClick={toggle}>
          //     {isFun(collapsedButtonRender) && collapsedButtonRender(h, collapsed) || collapsedButtonRender}

          //   </div>
          //   {/* <span class="title-font" >
          //     诚毅物联网管理系统
          //   </span> */}
          // </div>
        )
      }
    }

    const headerCls = 'ant-pro-global-header'

    return (
      <div class={headerCls} style={{ display: 'flex' }}>
        {isMobile && (
          <a class={`${headerCls}-logo`} key="logo" href={'/'}>
            {defaultRenderLogo(h, logo)}
          </a>
        )}
        {renderCollapsedButton()}
        {headerContentRender && (
          <div class={`${headerCls}-content`}>
            {isFun(headerContentRender) && headerContentRender(h, this.$props) || headerContentRender}
          </div>
        )}
        {isFun(rightContentRender) && rightContentRender(h, this.$props) || rightContentRender}
      </div>
    )
  },
  methods: {
    triggerResizeEvent: debounce(() => {
      inBrowser && triggerEvent(window, 'resize')
    })
  },
  beforeDestroy () {
    this.triggerResizeEvent.cancel && this.triggerResizeEvent.cancel()
  }
}

export default GlobalHeader
