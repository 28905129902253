export default {
  '请输入邮箱smtp': '请输入邮箱smtp',
  '修改': '修改',
  '退出登录': '退出登录',
  '状态': '状态',
  '修改成功': '修改成功',
  '清空数据': '清空数据',
  '强退': '强退',
  '收起': '收起',
  '登录地点': '登录地点',
  '保存': '保存',
  '可以省略，省略后默认为邮箱账号smtp.邮箱后缀,例如：邮箱账号为：zbtech@163.com，smtp为smtp.163.com': '可以省略，省略后默认为邮箱账号smtp.邮箱后缀,例如：邮箱账号为：zbtech@163.com，smtp为smtp.163.com',
  '用户昵称': '用户昵称',
  '清空': '清空',
  '请求参数': '请求参数',
  '通知': '通知',
  '操作信息': '操作信息',
  '此操作将会清空所有调度日志数据项': '此操作将会清空所有调度日志数据项',
  '邮箱账号不能为空': '邮箱账号不能为空',
  '添加字典类型': '添加字典类型',
  '邮件密匙ID': '邮件密匙ID',
  '失败': '失败',
  '邮件AccessKeyId': '邮件AccessKeyId',
  '操作': '操作',
  '系统模块': '系统模块',
  '登录信息': '登录信息',
  '请输入内容': '请输入内容',
  '否': '否',
  '发送邮箱账号': '发送邮箱账号',
  '其他': '其他',
  '是': '是',
  '审核失败': '审核失败',
  '列展开/排序': '列展开/排序',
  '富文本': '富文本',
  '登录时间': '登录时间',
  '男': '男',
  '腾讯云': '腾讯云',
  '访问编号': '访问编号',
  '请求地址': '请求地址',
  '审核状态': '审核状态',
  '展开': '展开',
  '刷新成功': '刷新成功',
  '操作日期': '操作日期',
  '参数类型': '参数类型',
  '腾讯邮件': '腾讯邮件',
  '邮箱密码不能为空': '邮箱密码不能为空',
  '切为全屏': '切为全屏',
  '当前选中编号为': '当前选中编号为',
  '发送邮箱smtp': '发送邮箱smtp',
  '的数据': '的数据',
  '操作人员': '操作人员',
  '删除': '删除',
  '刷新缓存': '刷新缓存',
  '重置': '重置',
  '公告': '公告',
  '主机': '主机',
  '昵称': '昵称',
  '请输入账号': '请输入账号',
  '参数主键': '参数主键',
  '此操作将会清空所有操作日志数据项': '此操作将会清空所有操作日志数据项',
  '密钥SECRET': '密钥SECRET',
  '此操作将导出当前条件下所有数据而非选中数据': '此操作将导出当前条件下所有数据而非选中数据',
  '条': '条',
  '此操作将会清空所有登录日志数据项': '此操作将会清空所有登录日志数据项',
  '审核失败原因': '审核失败原因',
  '注销时间': '注销时间',
  '参数名称': '参数名称',
  '用户名称': '用户名称',
  '解锁': '解锁',
  '新增成功': '新增成功',
  '操作状态': '操作状态',
  '共': '共',
  '输入框': '输入框',
  '女': '女',
  '文本域': '文本域',
  '导出': '导出',
  '请输入': '请输入',
  '取消': '取消',
  '不能为空': '不能为空',
  '日志编号': '日志编号',
  '系统内置': '系统内置',
  '当前选中字典编号为': '当前选中字典编号为',
  '个人设置': '个人设置',
  '阿里云': '阿里云',
  '注销原因': '注销原因',
  '邮件RegionId': '邮件RegionId',
  '成功': '成功',
  '浏览器': '浏览器',
  '字典名称': '字典名称',
  '登录地址': '登录地址',
  '审核人': '审核人',
  '发送邮箱密码': '发送邮箱密码',
  '登录状态': '登录状态',
  '添加参数': '添加参数',
  '待审核': '待审核',
  '审核时间': '审核时间',
  '查询': '查询',
  '联系电话': '联系电话',
  '修改字典类型': '修改字典类型',
  '是否确认清空?': '是否确认清空?',
  '接口信息': '接口信息',
  '参数键值': '参数键值',
  '请求方式': '请求方式',
  '操作方法': '操作方法',
  '字典编号': '字典编号',
  '生日': '生日',
  '参数键名': '参数键名',
  '详细': '详细',
  '本地邮件': '本地邮件',
  '阿里邮件': '阿里邮件',
  '授权': '授权',
  '生成代码': '生成代码',
  '操作系统': '操作系统',
  '审核成功': '审核成功',
  '使用状态': '使用状态',
  '个人中心': '个人中心',
  '禁用': '禁用',
  '备注': '备注',
  '操作模块': '操作模块',
  '邮箱': '邮箱',
  '启用': '启用',
  '操作类型': '操作类型',
  '区域ID': '区域ID',
  '新增': '新增',
  '导入': '导入',
  '邮件AccessKeySecret': '邮件AccessKeySecret',
  '修改参数': '修改参数',
  '是否确认导出?': '是否确认导出?',
  '查看更多': '查看更多',
  '列排序需拖动名称': '列排序需拖动名称',
  '性别': '性别',
  '字典类型': '字典类型',
  '请输入密码': '请输入密码',
  '创建时间': '创建时间',
  '确认删除所选中数据?': '确认删除所选中数据?',
  '操作地点': '操作地点',
  '清空成功': '清空成功',
  '删除成功': '删除成功',
  '提示': '提示',
  '确定注销并退出系统吗？': '确定注销并退出系统吗？',
  '确定': '确定',
  '密码登录': '密码登录',
  '普朗思后台管理系统': '普朗思后台管理系统',
  '退出全屏': '退出全屏',
  '全选/不选': '全选/不选',
  '点击选择显示数据项，调整数据项顺序': '点击选择显示数据项，调整数据项顺序',
  '数据写入': '数据写入',
  '设备标识': '设备标识',
  '参数': '参数',
  '写入值': '写入值',
  '请选择': '请选择',

  '手机号码': '手机号码',
  '添加用户': '添加用户',
  '取消批量授权': '取消批量授权',
  '返回': '返回',
  '取消授权': '取消授权',
  '手机': '手机',
  '确认要取消该用户的角色吗?': '确认要取消该用户的角色吗?',
  '当前选中用户': '当前选中用户',
  '取消授权成功': '取消授权成功',
  '是否取消选中用户授权数据项?': '是否取消选中用户授权数据项?',
  '角色名': '角色名',
  '权限字符': '权限字符',
  '确认': '确认',
  '停用': '停用',
  '的角色吗?': '的角色吗?',
  '更多': '更多',
  '脱敏权限': '脱敏权限',
  '数据权限': '数据权限',
  '分配用户': '分配用户',
  '角色编号': '角色编号',
  '权限标识': '权限标识',
  '排序': '排序',
  '确认要"': '确认要"',
  '"角色吗？': '"角色吗？',
  '关闭': '关闭',
  '异常': '异常',
  '角色名称': '角色名称',
  '角色标识': '角色标识',
  '权限范围': '权限范围',
  '展开/折叠': '展开/折叠',
  '全选/全不选': '全选/全不选',
  '父子联动': '父子联动',
  '当勾选项为空时方可切换模式，可以点击”全不选“后再次切换。': '当勾选项为空时方可切换模式，可以点击”全不选“后再次切换。',
  '全部数据权限': '全部数据权限',
  '自定义数据权限': '自定义数据权限',
  '本部门数据权限': '本部门数据权限',
  '本部门及以下数据权限': '本部门及以下数据权限',
  '仅本人数据权限': '仅本人数据权限',
  '分配数据权限': '分配数据权限',
  '超级管理员角色信息 不可更改': '超级管理员角色信息 不可更改',
  "控制器中定义的权限字符，如：@PreAuthorize(`@ss.hasRole('admin')`)": "控制器中定义的权限字符，如：@PreAuthorize(`@ss.hasRole('admin')`)",
  '菜单权限': '菜单权限',
  '顺序': '顺序',
  '添加角色': '添加角色',
  '修改角色': '修改角色',
  '列名': '列名',
  '列名描述': '列名描述',
  '是否脱敏': '是否脱敏',
  '脱敏方向': '脱敏方向',
  '从右向左': '从右向左',
  '从左向右': '从左向右',
  '起始位置': '起始位置',
  '脱敏字符数': '脱敏字符数',
  '设置脱敏权限': '设置脱敏权限',
  '操作成功！': '操作成功！',
  '选择用户': '选择用户',
  '手机号': '手机号',
  '请选择状态': '请选择状态',
  '项目': '项目',
  '用户编号': '用户编号',
  '用户名': '用户名',
  '重置密码': '重置密码',
  '分配角色': '分配角色',
  '的用户吗?': '的用户吗?',
  '密码': '密码',
  '岗位': '岗位',
  '角色': '角色',
  '用户密码长度必须介于 5 和 20 之间': '用户密码长度必须介于 5 和 20 之间',
  '请正确填写邮箱地址': '请正确填写邮箱地址',
  '请正确填写手机号': '请正确填写手机号',
  '修改用户': '修改用户',
  '新增用户': '新增用户',
  '请输入搜索内容': '请输入搜索内容',
  '新密码': '新密码',
  '确认密码': '确认密码',
  '请输入5-20位英文字母、数字或者符号（除空格），且字母、数字和标点符号至少包含两种': '请输入5-20位英文字母、数字或者符号（除空格），且字母、数字和标点符号至少包含两种',
  '请再次输入新密码确认': '请再次输入新密码确认',
  '两次输入的密码不一致': '两次输入的密码不一致',
  '重置成功': '重置成功',
  '菜单名称': '菜单名称',
  'DIY页面': 'DIY页面',
  '图标': '图标',
  '组件路径': '组件路径',
  '上级菜单': '上级菜单',
  '菜单类型': '菜单类型',
  '目录': '目录',
  '菜单': '菜单',
  'DIY菜单': 'DIY菜单',
  '按钮': '按钮',
  '选择图标': '选择图标',
  '是否外链': '是否外链',
  '选择是外链则路由地址需要以`http(s)://`开头': '选择是外链则路由地址需要以`http(s)://`开头',
  '路由地址': '路由地址',
  '访问的路由地址，如：`user`，如外网地址需内链访问则以`http(s)://`开头': '访问的路由地址，如：`user`，如外网地址需内链访问则以`http(s)://`开头',
  '访问的组件路径，如：`system/user/index`，默认在`views`目录下': '访问的组件路径，如：`system/user/index`，默认在`views`目录下',
  "控制器中定义的权限字符，如：@PreAuthorize(`@ss.hasPermi('system:user:list')`)": "控制器中定义的权限字符，如：@PreAuthorize(`@ss.hasPermi('system:user:list')`)",
  '是否显示': '是否显示',
  '选择隐藏则路由将不会出现在侧边栏，但仍然可以访问': '选择隐藏则路由将不会出现在侧边栏，但仍然可以访问',
  '选择停用则路由将不会出现在侧边栏，也不能被访问': '选择停用则路由将不会出现在侧边栏，也不能被访问',
  '是否缓存': '是否缓存',
  '选择是则会被`keep-alive`缓存，需要匹配组件的`name`和地址保持一致，仅在多页签下有效。': '选择是则会被`keep-alive`缓存，需要匹配组件的`name`和地址保持一致，仅在多页签下有效。',
  '缓存': '缓存',
  '不缓存': '不缓存',
  '添加菜单': '添加菜单',
  '修改菜单': '修改菜单'

}
