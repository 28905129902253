<template>
  <div class="debug_wrapper">
    <div class="debug_wrapper_hearder">
      <span class="debug_wrapper_hearder_text">数据日志</span>
      <!-- <a-radio-group v-model="readType" @change="readTypeChange">
        <a-radio :value="1">
          时间戳
        </a-radio>
        <a-radio :value="2">
          HEX
        </a-radio>
      </a-radio-group> -->
    </div>
    <div class="debug_wrapper_box">
      <div class="debug_wrapper_box_hex">
        <div class="debug_wrapper_box_hex_device" :class="hex&&hex.logType&&['sendToDev'].includes(hex.logType)?'debug_wrapper_box_hex_user':''" v-for="(hex,index) in hexList" :key="index">
          <div v-if="hex&&hex.logType&&['devReply','devLogin'].includes(hex.logType)" class="debug_wrapper_box_hex_icon">
            <img src="./img/2.png" alt="" srcset="">
          </div>
          <div v-if="hex" class="debug_wrapper_box_hex_content">
            <span class="debug_wrapper_box_hex_content_time">{{ hex.timeToken | dayjs('HH:mm:ss.SSS') }}</span>
            <div class="debug_wrapper_box_hex_content_text">
              <span v-if="hex&&hex.logType&&['devReply','devLogin'].includes(hex.logType)" class="debug_wrapper_box_hex_content_text_label">接收：</span>
              <div class="debug_wrapper_box_hex_content_text_info">
                {{ hex.data }}
              </div>
            </div>
          </div>

          <div v-if="hex&&hex.logType&&['sendToDev'].includes(hex.logType)" class="debug_wrapper_box_hex_icon">
            <img src="./img/1.png" alt="" srcset="">
          </div>
        </div>
      </div>
      <div class="debug_wrapper_box_input">
        <div class="debug_wrapper_box_input_btn">
          <div class="debug_wrapper_box_input_btn_tj">
            <span>RX:{{ RX }}</span>
            <span>TX:{{ TX }}</span>
          </div>

          <div class="debug_wrapper_box_input_btn_right">
            <div class="debug_wrapper_box_input_btn_right_btn" @click="btnHanlde('clear')">
              <img src="./img/3.png" alt="" srcset="">
              <span>清屏</span>
            </div>
            <div class="debug_wrapper_box_input_btn_right_btn" @click="btnHanlde('pause')">
              <img src="./img/4.png" alt="" srcset="">
              <span>{{ isPause?'继续':'暂停' }}</span>
            </div>
            <div class="debug_wrapper_box_input_btn_right_btn" @click="btnHanlde('resetCount')">
              <img src="./img/5.png" alt="" srcset="">
              <span>复位计数</span>
            </div>
          </div>
        </div>

        <div class="debug_wrapper_box_input_send">
          <a-input v-model="sendHex" class="debug_wrapper_box_input_send_value" placeholder="请输入…" />
          <div class="debug_wrapper_box_input_send_btn" @click="sendHandle()">
            <span>发送</span>
          </div>
        </div>
      </div>

      <div v-if="false" class="debug_wrapper_box_bottom">
        <a-checkbox v-model="isSendHex" @change="checkboxChange">
          HEX
        </a-checkbox>
        <a-checkbox v-model="isSendXHZQ" @change="checkboxChange">
          循环周期
        </a-checkbox>
        <a-input class="debug_wrapper_box_bottom_XHZQ" placeholder="请输入…" >
          <span slot="suffix">S</span>
        </a-input>
        <a-checkbox v-model="isSendHF" @change="checkboxChange">
          收到回复
        </a-checkbox>
      </div>
    </div>
    <!-- <span>{{ mqttServer }}</span>
    <span>{{ debug_account }}</span>
    <span>{{ debug_password }}</span>
    <span>{{ mqttSubscribe }}</span>
    <span>{{ mqttSubscribeCs }}</span>
    <span>{{ subscribe }}</span>

    <span>{{ debug_mqttPublish }}</span>
    <span>{{ debug_mqttPublishCs }}</span>
    <span>{{ publish }}</span> -->

  </div>
</template>

<script>
// import Vue from 'vue'
// var self = null
let client = null
export default {
  data() {
    // self = this
    return {
      readType: 1,
      isSendHex: true,
      isSendXHZQ: true,
      isSendHF: true,
      isPause: false, // 是否暂停
      resetCountRX: 0, // 复位计数
      resetCountTX: 0, // 复位计数
      hexList: [
        // {
        //   logType: 'devReply',
        //   devNo: 'dev02',
        //   timeToken: 1702956903300,
        //   data: '10312000b000c000d000e000f0010001100120013e3ea'
        // }
      ],
      sendHex: ''
    }
  },
  computed: {
    mqttServer() {
      if (this.debug_server.indexOf('.') === -1) {
        return this.$data.data[this.debug_server]
      } else {
        return this.debug_server
      }
    },
    subscribe() {
      // console.log('mqttSubscribe>>>>>', this.mqttSubscribe)
      // console.log('mqttSubscribeCs>>>>>', this.mqttSubscribeCs)
      return this.mqttSubscribe + this.$data.data[this.mqttSubscribeCs]
    },
    publish() {
      // console.log('debug_mqttPublish>>>>>', this.debug_mqttPublish)
      // console.log('debug_mqttPublishCs>>>>>', this.debug_mqttPublishCs)

      return this.debug_mqttPublish + this.$data.data[this.debug_mqttPublishCs]
    },
    RX() {
      let num = 0

      if (this.hexList.length > 0) {
        this.hexList.forEach(item => {
          if (item && ['devReply', 'devLogin'].includes(item.logType)) {
            num++
          }
        })
      }

      return num - this.resetCountRX
    },
    TX() {
      let num = 0
      if (this.hexList.length > 0 && !this.isResetCount) {
        this.hexList.forEach(item => {
          if (item && ['sendToDev'].includes(item.logType)) {
            num++
          }
        })
      }

      return num - this.resetCountTX
    }
  },
  mounted() {
    // console.log('self>>>>>1>>>>>', this)
  },
  created() {
    // console.log('debug_wrapper>>>>>>>>>',document.querySelector('.debug_wrapper'));
    
    // setTimeout(() => {
    //   document.querySelector('.debug_wrapper_box_hex').scrollTop = 0
    // }, 5000)
    this.connectMqtt()
    this.setData()
  },
  beforeDestroy() {
    client.end()
  },
  methods: {
    init(lng, lat, label) {

    },
    /**
     * @desc mqtt连接
     * @author 刘
     * @time 2023年06月02日 18:31:19 星期五
     */
     connectMqtt() {
      const that = this
      var mqtt = require('mqtt/dist/mqtt.js')
      // client = mqtt.connect('ws://emqx.zzcyi.cn:8083/mqtt')
      const mqttUrl = 'ws://' + this.mqttServer + ':8083/mqtt'
      // client = mqtt.connect('ws://47.104.102.88:8083/mqtt')
      client = mqtt.connect(mqttUrl, {
        username: this.debug_account,
        password: this.debug_password
      })
      client.on('connect', function() {
        // console.log('on connect', new Date().getTime())

        // console.log('主题>>>>', that.subscribe)

        client.subscribe(that.subscribe, function(err, granted) {
          // console.log('订阅主题>>>>>err>>>>', err, that.subscribe)
          // console.log('订阅主题>>>>>granted>>>>', granted)
          if (!err) {
            // console.log('订阅成功>>>>')
          }
        })
      }).on('reconnect', function() {
      // uni.showLoading({
      // 	title:'加载中...',
      // 	mask:true
      // })
      // setTimeout(function() {
      // 	uni.hideLoading()
      // }, 3000);
        // console.log('重新连接', new Date().getTime())
      }).on('close', function(err) {
      // that.setIsMqttConnect(false)
        // console.log('链接断开>>>>', err)
      }).on('error', function(err) {
        // console.log('on error', err)
      }).on('end', function() {
      // that.setIsMqttConnect(false)
        // console.log('on end')
      }).on('message', function(topic, message) {
        // console.log('mqtt消息', message.toString())
        // alert(message.toString())

        try {
          const messageObj = JSON.parse(message)
          // console.log('接受到的消息>>>', messageObj)
          // logType为"sendToDev"--向设备发送信息
          // logType为"devReply"--设备回复
          // logType为"devLogin"--设备上线
          that.setData(messageObj)
        } catch (e) {
        // TODO handle the exception
        }
      })
    },
    /**
     * @desc 发送数据
     * @param {  }
     * @version: 1.0.0
     */
    sendHandle() {
      // console.log('publish>>>>>', this.publish)
      // // console.log('sendHex>>>>>', JSON.stringify(this.sendHex))
      if (!this.sendHex) {
        return
      }
      let buffer = this.sendHex;
      if(this.isHex(this.sendHex)) {
        let sendHex = this.sendHex
        if(this.sendHex.length%2 != 0) {
          sendHex = '0'+this.sendHex
        }
        // console.log('sendHex>>>>',sendHex);
        
        buffer = Buffer.from(sendHex, 'hex')

      }
      client.publish(this.publish, buffer)
    },
    /**
     * @desc 判断是否是16进制
     * @param {  } 
     * @version: 1.0.0
     */
    isHex(str) {
      return /^[0-9a-f]+$/i.test(str);
    },
    checkboxChange() {

    },
    /**
     * @desc 监听读取类型修改
     * @param {  }
     * @version: 1.0.0
     */
    readTypeChange() {

    },
    /**
     * @desc 设置数据
     * @param {  }
     * @version: 1.0.0
     */
    setData(messageObj) {
      if (this.isPause) {
        // 暂停
        return
      }
      this.hexList.push(messageObj)
      // console.log('scrollHeight>>>>>>', document.querySelector('.debug_wrapper_box_hex').scrollHeight)

      document.querySelector('.debug_wrapper_box_hex').scrollTop = document.querySelector('.debug_wrapper_box_hex').scrollHeight
    },
    /**
     * @desc 按钮事件
     * @param {  }
     * @version: 1.0.0
     */
    btnHanlde(type) {
      switch (type) {
        case 'clear':
          this.hexList = []
          break
        case 'pause':
          this.isPause = !this.isPause
          break

        case 'resetCount':
          this.resetCountRX += this.RX
          this.resetCountTX += this.TX
          break

        default:
          break
      }
    },
    /**
     * 添加amis事件动作:
     * 在这里设置自定义组件对外暴露的动作，其他组件可以通过组件动作触发自定义组件的对应动作
     */
     doAction(action, args) {
      const actionType = action ? action.actionType : ''
      if (actionType === 'message') {
        // 接收外部组件的事件动作'message'
        alert('您触发了自定义组件的事件动作[message]')
      } else {
        // console.log('自定义组件中监听到的事件动作：', action, ', 事件参数：', args)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.debug_wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #fff;

  .debug_wrapper_hearder {
    margin-bottom: 17px;

    .debug_wrapper_hearder_text {
      font-size: 20px;
      font-family: Source Han Sans CN, Source Han Sans CN;
      font-weight: 500;
      color: #1C1F27;
      margin-right: 28px;
    }
  }

  .debug_wrapper_box {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    // height: 861px;
    background: #F8F9FB;
    border-radius: 16px 16px 16px 16px;
    opacity: 1;
    overflow: hidden;

    .debug_wrapper_box_hex {
      flex: 1;
      display: flex;
      flex-direction: column;
      // height: 689px;
      padding: 18px 13px;
      overflow-y: auto;

      .debug_wrapper_box_hex_device {
        display: flex;
        align-items: flex-start;
        margin-bottom: 25px;
      }

      .debug_wrapper_box_hex_user {
        display: flex;
        align-items: flex-start;
        margin-bottom: 25px;

        .debug_wrapper_box_hex_icon {
          margin-right: 0;
          margin-left: 11px;
        }

        .debug_wrapper_box_hex_content {
          align-items: flex-end;
          .debug_wrapper_box_hex_content_time {
            text-align: right;
          }

          .debug_wrapper_box_hex_content_text {
            justify-content: flex-end;
            background: #0052C7;
            .debug_wrapper_box_hex_content_text_info {
              justify-content: flex-end;
              color: #FFFFFF;
            }

          }
        }

      }

      .debug_wrapper_box_hex_icon {
          width: 45px;
          height: 45px;
          border-radius: 50%;
          margin-right: 11px;

          img {
            width: 45px;
            height: 45px;
          }

        }

        .debug_wrapper_box_hex_content {
          flex: 1;
          display: flex;
          flex-direction: column;

          .debug_wrapper_box_hex_content_time {
            font-size: 16px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #999999;
            margin-bottom: 8px;
          }

          .debug_wrapper_box_hex_content_text {
            display: flex;
            align-items: flex-start;
            width: fit-content;
            padding: 12px 49px 13px 14px;
            background: #F2F2F2;
            border-radius: 0px 18px 18px 18px;
            opacity: 1;

            .debug_wrapper_box_hex_content_text_label {
              flex-shrink: 0;
              font-size: 16px;
              font-family: Alibaba PuHuiTi, Alibaba PuHuiTi;
              font-weight: 500;
              color: #09080D;
            }

            .debug_wrapper_box_hex_content_text_info {
              display: flex;
              flex-wrap: wrap;
              word-spacing: normal;
              word-wrap: break-word;
              word-break: break-all;
              font-size: 16px;
              font-family: Alibaba PuHuiTi, Alibaba PuHuiTi;
              font-weight: 500;
              color: #09080D;

            }

          }

        }
    }

    .debug_wrapper_box_input {
      display: flex;
      flex-direction: column;
      border-top: 1px solid rgba(225, 225, 225, 0.4);
      border-bottom: 1px solid rgba(225, 225, 225, 0.4);
      padding: 11px 14px 14px 13px;

      .debug_wrapper_box_input_btn {
        display: flex;
        justify-content: space-between;

        .debug_wrapper_box_input_btn_tj {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 119px;
          height: 38px;
          padding: 0 7px;
          border-radius: 6px 6px 6px 6px;
          border: 1px solid #DFE1E6;

          span {
            font-size: 16px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            color: #575D6C;
          }
        }

        .debug_wrapper_box_input_btn_right {
          display: flex;
          align-items: center;

          .debug_wrapper_box_input_btn_right_btn {
            display: flex;
            padding: 8px 15px 8px 10px;
            background-color: #FFFFFF;
            margin-right: 12px;
            border-radius: 6px 6px 6px 6px;
            cursor: pointer;
            img {
              width: 22px;
              height: 22px;
              margin-right: 5px;
            }

            span {
              font-size: 16px;
              font-family: PingFang SC, PingFang SC;
              font-weight: 500;
              color: #1C1F27;
            }
          }

        }
      }

      .debug_wrapper_box_input_send {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;

        .debug_wrapper_box_input_send_value {
          // width: 624px;
          height: 38px;
          background: #FFFFFF;
          border-radius: 6px 6px 6px 6px;
          border: none;
          opacity: 1;
        }

        .debug_wrapper_box_input_send_btn {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 84px;
          height: 38px;
          margin-left: 12px;
          background: #2173FF;
          border-radius: 6px 6px 6px 6px;
          opacity: 1;
          cursor: pointer;

          span {
            font-size: 14px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 17px;
          }
        }

      }
    }

    .debug_wrapper_box_bottom {
      display: flex;
      align-items: center;

      padding: 11px 12px 14px 11px;

      .debug_wrapper_box_bottom_XHZQ {
        display: flex;
        align-items: center;
        width: 184px;
        height: 38px;
        margin-right: 28px;
        background: #FFFFFF;
        border-radius: 6px 6px 6px 6px;
        border: none;
        opacity: 1;

        .ant-input {
          background: #FFFFFF;
        }
      }
    }
  }

}
</style>
