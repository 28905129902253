// 注册自定义组件渲染器
import { registerRendererByType } from '@/utils/amis-widget/index.esm.js'
import ChamChuancan from './cham-chuancan.vue'

registerRendererByType(ChamChuancan, {
  type: 'cham-chuancan',
  usage: 'renderer',
  weight: 99,
  framework: 'vue'
})
