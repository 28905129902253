<template>
    <div class="topBtn">
        <!-- <div v-if="defaultSettings.isShowScreen" class="topBtnItem rightBorder" style="padding-left: 16px;" @click="toNext('/ScreenList')">
      <img style="height: 23px;" src="@/assets/topBtn/6.png" alt="" srcset="">
      <span class="font20B">数据大屏</span>
    </div>
    <div v-if="defaultSettings.isShowIotCardF" class="topBtnItem rightBorder" @click="toNext('not')">
      <a-icon :component="allIcon['wulianwangkaIcon']" :style="{ fontSize: '26px', marginRight: '10px' }"/>
      <span class="font20B">物联网卡</span>
    </div>
    <div v-if="defaultSettings.isShowStore" class="topBtnItem" @click="toNext('not')">
      <img style="height: 24px;" src="@/assets/topBtn/8.png" alt="" srcset="">
      <span class="font20B">商城</span>
    </div> -->
        <!-- 修改 -->
        <div class="tabListBox">
            <div
                :class="['tabListItem', tabPath.path == item.path ? 'tabListItemYes' : '']"
                v-for="(item, index) in tabListData"
                :key="index"
                @click="tabListClick(item)"
            >
                {{item.meta.title}}
            </div>
        </div>
    </div>
</template>
<script>
// import defaultSettings from '@/config/defaultSettings'
import { generatorDynamicRouter } from '@/router/generator-routers'

export default {
    props: {
    },
    data() {
        return {
            // defaultSettings
            tabPath: {},
            tabListData: [
            ]
        }
    },
    mounted() {
        generatorDynamicRouter('').then((res) => {
            console.log("头部路由", res.data);

            res.data.forEach((item, index) => {
                if (item.children) {
                    this.menusListDG(item);
                }
            })



            console.log("tabListData", res.data);


            this.tabPath = res.data[0];
            this.$store.commit('SET_MENUS_NAME', res.data[0])
            this.tabListData = res.data;


            // 解析路由地址，选中导航栏
            if (this.$route.path) {
                this.tabListData.forEach((item, index) => {
                    if (this.$route.path.indexOf(item.path) != -1) {
                        this.tabPath = item;
                        this.$store.commit('SET_MENUS_NAME', item)
                    }
                })
            }

        })
    },
    methods: {
        toNext(path) {
            if (path == 'not') {
                this.$message.error('目前暂未开放')
            } else {
                this.$router.push({ path: path }).catch(() => { })
            }
        },
        tabListClick(value) {
            this.tabPath = value;
            this.$store.commit('SET_MENUS_NAME', value)
        },
        // 递归处理目录结构
        menusListDG(valArr) {
            valArr.children.forEach((item, index) => {
                item.path = valArr.path + '/' + item.path;

                // item.indexKey = valArr.indexKey ? valArr.indexKey + '-' + index : index;

                if (item.children) {
                    this.menusListDG(item)
                }
            });

            return valArr;
        },
    }
}
</script>
<style lang="less" scoped>
.tabListBox {
    display: flex;
    height: 100%;
    width: auto;
    font-size: 16px;
    padding-left: 20px;

    .tabListItem {
        cursor: pointer;
        margin-right: 25px;
        color: #524A5A;
    }

    .tabListItemYes {
        font-size: 20px;
        font-weight: 700;
        color: #8B6900;
    }
}
</style>
