<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <keep-alive>
        <router-view/>
      </keep-alive>
    </div>
  </a-config-provider>
</template>

<script>
import { domTitle, setDocumentTitle } from '@/utils/domUtil'
import { i18nRender } from '@/locales'
import defaultSettings from '@/config/defaultSettings'

// import './qiankun'
// import reload from '@/mixins/reload'

export default {
  // provide () {
  //  return {
  //    reload: this.reload
  //  }
  // },
  // mixins: [reload],
  data () {
    return {
      // isRouterAlive: true
    }
  },
  computed: {
    locale () {
      // 只是为了切换语言时，更新标题
      const { title } = this.$route.meta
      title && (setDocumentTitle(`${i18nRender(title)} - ${domTitle}`))

      return this.$i18n.getLocaleMessage(this.$store.getters.lang).antLocale
    }
  },
  updated() {
    document.title = this.$t(defaultSettings.title)
  },
  methods: {
    // reload() {
    //   this.isRouterAlive = false
    //   this.$nextTick(function() {
    //     this.isRouterAlive = true
    //   })
    // }

  }
}
</script>
<style lang="less">
:root {
  --Tabs--line-color:#1C1F27 !important;
  --Tabs--line-fontSize:1.8vh !important;
  --Tabs--line-fontWeight: bold !important;
  --Tabs--line-lineHeight: 2.2vh !important;

  // --Tabs--line-onHove-color:#1C1F27 !important;
  --Tabs--line-hover-fontSize:1.8vh !important;
  --Tabs--line-hover-fontWeight: bold !important;
  --Tabs--line-hover-lineHeight: 2.2vh !important;

  // --Tabs--line-active-color:#1C1F27 !important;
  --Tabs--line-active-fontSize:1.8vh !important;
  --Tabs--line-active-fontWeight: bold !important;
  --Tabs--line-active-lineHeight: 2.2vh !important;

  // --Table-thead-fontSize: 18px;
  // --Table-thead-color: #000000;

  --Table-searchableForm-backgroundColor: transparent !important;

  // --Form--horizontal-label-widthBase: auto !important;

  --Pagination-height:1.9rem;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-thumb {
  background: #DFE1E6;
  // -webkit-box-shadow: inset 0 0 5px rgb(0 21 8.2%);
  // box-shadow: inset 0 0 5px rgb(0 21 8.2%);
  border-radius: 6px 6px 6px 6px;
}

.is-modalOpened {
  overflow: auto !important;
}

// .ant-popover {
//   z-index: 999999 !important;
// }

.logoBox {
  height: 4.81vh;
  display: flex;
  align-items: center;

  .logoBoxLogo {
    height: 100% !important;
    margin-right: 16px;
  }

  .logoBoxFont {
    font-size: 23px;
    color: #2878FF;
    font-weight: bold;
  }
}
</style>
