/**
 * 项目默认配置项
 * primaryColor - 默认主题色, 如果修改颜色不生效，请清理 localStorage
 * navTheme - sidebar theme ['dark', 'light'] 两种主题
 * colorWeak - 色盲模式
 * layout - 整体布局方式 ['sidemenu', 'topmenu'] 两种布局
 * fixedHeader - 固定 Header : boolean
 * fixSiderbar - 固定左侧菜单栏 ： boolean
 * contentWidth - 内容区布局： 流式 |  固定
 *
 * storageOptions: {} - Vue-ls 插件配置项 (localStorage/sessionStorage)
 *
 */
import initConfig from './initConfig.js'
// console.log('initConfig>>>>>',initConfig);
let initConfigInfo = initConfig[initConfig.sysTpeyName]
// console.log('initConfigInfo>>>>>',initConfigInfo);



export default {
  sysTpeyName:initConfig.sysTpeyName, // 当前系统类型名称
  storageKey: initConfigInfo.storageKey, // 本地缓存前加的key
  navTheme: 'light', // theme for nav menu  dark  light
  primaryColor: '#2878FF', // primary color of ant design
  layout: 'sidemenu', // nav menu position: `sidemenu` or `topmenu`
  contentWidth: 'Fluid', // layout of content: `Fluid` or `Fixed`, only works when layout is topmenu
  fixedHeader: true, // sticky header
  fixSiderbar: true, // sticky siderbar
  colorWeak: false,
  multiTab: false,
  tableSize: 'middle',
  tableBordered: false,
  hideFooter: false,
  title: initConfigInfo.title,
  gov:initConfigInfo.gov, //域名备案信息
  production: process.env.NODE_ENV === 'production' && process.env.VUE_APP_PREVIEW !== 'true',
  loginType: initConfigInfo.loginType, // 登录页面类型  1,3,4,5,6
  loginTip: initConfigInfo.loginTip,
  loginTip2: initConfigInfo.loginTip2,
  isLoginLeftLogo: initConfigInfo.isLoginLeftLogo, // 登录页面是否有右上角logo
  isCodeLogin: initConfigInfo.isCodeLogin, // 是否支持验证码登录
  isQQLogin: initConfigInfo.isQQLogin, // 是否支持QQ登录
  isWxLogin: initConfigInfo.isWxLogin, // 是否支持微信登录
  isAliLogin: initConfigInfo.isAliLogin, // 是否支持支付宝登录
  isIphoneLogin: initConfigInfo.isIphoneLogin, // 是否支持苹果登录
  isLogoFont: initConfigInfo.isLogoFont, // 是否显示logo字体
  isShowStore: initConfigInfo.isShowStore, // 是否显示顶部商城按钮
  isShowIotCard: initConfigInfo.isShowIotCard, // 是否显示顶部物联网卡按钮
  isShowScreen: initConfigInfo.isShowScreen, // 是否显示顶部数据大屏按钮
  isShowScreenfull: initConfigInfo.isShowScreenfull, // 是否显示顶部全屏按钮
  isShowRefresh: initConfigInfo.isShowRefresh, // 是否显示顶部刷新按钮
  isShowNoticeIcon: initConfigInfo.isShowNoticeIcon, // 是否显示顶部消息按钮
  isShowHelp: initConfigInfo.isShowHelp, // 是否显示顶部帮助按钮
  isShowSelectLang: initConfigInfo.isShowSelectLang, // 是否显示顶部多语言按钮
  isShowCompaniesList: initConfigInfo.isShowCompaniesList, // 是否显示顶部多租户按钮
  isShowLoginFont: initConfigInfo.isShowLoginFont, // 系统显示logo是否是整个公司logo+名称
  
  loginLogo:initConfigInfo.loginLogo,// 登录页面左上角logo
  menuTopLogo:initConfigInfo.menuTopLogo // 菜单顶部logo
}
