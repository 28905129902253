import request from '@/utils/request'

// 获取路由
export const getRouters = () => {
  return request({
    url: '/amis/getRouters',
    method: 'get'
  })
}

// 获取amis路由菜单内容
export function getMenuContent(query) {
  return request({
    url: '/amis/menu/content',
    method: 'get',
    params: query

  })
}

// 修改保存amis路由菜单内容
export function updateMenuContent (data) {
  return request({
    url: '/amis/menu/content',
    method: 'post',
    data: data
  })
}

// 保存amis路由菜单内容log
export function addMenuLog (data) {
  return request({
    url: '/system/menu-log/add',
    method: 'post',
    data: data
  })
}
