/* eslint-disable no-template-curly-in-string */
/**
 * @file 编辑器扩展, 增加自定义组件
 */
import { BasePlugin, registerEditorPlugin } from 'amis-editor'

export class InfoGridPlugin extends BasePlugin {
  // 关联渲染器名字
  rendererName = 'com-pie-chart';
  $schema = '/schemas/UnkownSchema.json';
  notRenderFormZone = true;
  // 组件名称（组件面板显示的Title）
  name = '详情按钮';
  order=0; // 排序
  description = '详情按钮信息';
  tags = ['布局容器', '表格', 'A自定义'];
  icon = 'fa fa-file-code-o';
  scaffold = { // 插入到页面时需要
    'type': 'button',
    'label': '详情',
    'onEvent': {
      'click': {
        'actions': [
          {
            'ignoreError': false,
            'actionType': 'setValue',
            'componentId': 'DeviceList:table',
            'args': {
              'value': {
                'deviceId': '\\${id}'
              }
            }
          },
          {
            'ignoreError': false,
            'actionType': 'drawer',
            'drawer': {
              'closeOnEsc': true,
              'size': 'xl',
              'type': 'drawer',
              'closeOnOutside': true,
              'title': '',
              'body': [
                {
                  'type': 'service',
                  'id': 'u:048c2e359e49',
                  'body': [
                    {
                      'type': 'tabs',
                      'id': 'u:42f7a01172c8',
                      'tabs': [
                        {
                          'title': '看板',
                          'body': [
                            {
                              'type': 'grid',
                              'id': 'u:4fcfa73ec993',
                              'columns': [
                                {
                                  'body': [
                                    {
                                      'type': 'info-grid',
                                      'label': 'info-grid',
                                      'source_data': 'deviceData',
                                      'grid_name': 'deviceName',
                                      'grid_child_data': 'data',
                                      'grid_child_name': 'attrName',
                                      'grid_child_value': 'value',
                                      'grid_child_unit': 'attrUnit',
                                      'grid_child_icon': 'icon',
                                      'grid_num': 5,
                                      'grid_margin_bottom': 5,
                                      'grid_padding_top': 10,
                                      'grid_padding_left': 0,
                                      'grid_padding_bottom': 10,
                                      'grid_padding_right': 0,
                                      'grid_background': 'rgba(255, 255, 255, 0)',
                                      'grid_name_color': 'rgb(87, 93, 108, 1)',
                                      'grid_value_color': 'rgb(28, 31, 39, 1)',
                                      'grid_alarm_data': 'threshold',
                                      'grid_alarm_max': 'max',
                                      'grid_alarm_min': 'min',
                                      'grid_alarm_color': 'color',
                                      'id': 'u:8915a8b6f2e9',
                                      'grid_edit': true,
                                      'grid_check_key': 'attrId'
                                    }
                                  ],
                                  'id': 'u:e53af4cd4848',
                                  'style': {
                                    'boxShadow': ' 0px 0px 0px 0px transparent'
                                  }
                                },
                                {
                                  'body': [
                                    {
                                      'type': 'panel',
                                      'title': '设备详情 ID:${id}',
                                      'body': [
                                        {
                                          'type': 'grid',
                                          'columns': [
                                            {
                                              'body': [
                                                {
                                                  'type': 'flex',
                                                  'className': 'p-1',
                                                  'items': [
                                                    {
                                                      'type': 'container',
                                                      'body': [
                                                        {
                                                          'type': 'tpl',
                                                          'tpl': '${deviceName}',
                                                          'inline': true,
                                                          'wrapperComponent': '',
                                                          'id': 'u:472bd957e043',
                                                          'style': {
                                                            'boxShadow': ' 0px 0px 0px 0px transparent'
                                                          }
                                                        }
                                                      ],
                                                      'size': 'xs',
                                                      'style': {
                                                        'position': 'static',
                                                        'display': 'block',
                                                        'flex': '1 1 auto',
                                                        'flexGrow': 1,
                                                        'flexBasis': 'auto',
                                                        'boxShadow': ' 0px 0px 0px 0px transparent'
                                                      },
                                                      'wrapperBody': false,
                                                      'isFixedHeight': false,
                                                      'isFixedWidth': false,
                                                      'id': 'u:f04976d9a0d8',
                                                      'wrapperComponent': 'div'
                                                    },
                                                    {
                                                      'type': 'container',
                                                      'body': [
                                                        {
                                                          'type': 'tpl',
                                                          'tpl': '设备名称',
                                                          'inline': true,
                                                          'wrapperComponent': '',
                                                          'id': 'u:a6d44c2b03e1',
                                                          'style': {
                                                            'boxShadow': ' 0px 0px 0px 0px transparent'
                                                          },
                                                          'className': 'font-bold'
                                                        }
                                                      ],
                                                      'size': 'xs',
                                                      'style': {
                                                        'position': 'static',
                                                        'display': 'block',
                                                        'flex': '1 1 auto',
                                                        'flexGrow': 1,
                                                        'flexBasis': 'auto',
                                                        'boxShadow': ' 0px 0px 0px 0px transparent'
                                                      },
                                                      'wrapperBody': false,
                                                      'isFixedHeight': false,
                                                      'isFixedWidth': false,
                                                      'id': 'u:6fb5ca5e8101'
                                                    }
                                                  ],
                                                  'style': {
                                                    'position': 'relative',
                                                    'inset': 'auto',
                                                    'flexWrap': 'nowrap',
                                                    'boxShadow': ' 0px 0px 0px 0px transparent',
                                                    'flexDirection': 'column'
                                                  },
                                                  'id': 'u:27731f73bdf5',
                                                  'isFixedHeight': false,
                                                  'isFixedWidth': false
                                                }
                                              ],
                                              'id': 'u:942614c4521e',
                                              'style': {
                                                'boxShadow': ' 0px 0px 0px 0px transparent'
                                              }
                                            },
                                            {
                                              'body': [
                                                {
                                                  'type': 'flex',
                                                  'className': 'p-1',
                                                  'items': [
                                                    {
                                                      'type': 'container',
                                                      'body': [
                                                        {
                                                          'type': 'tpl',
                                                          'tpl': '${deviceNo}',
                                                          'inline': true,
                                                          'wrapperComponent': '',
                                                          'id': 'u:472bd957e043',
                                                          'style': {
                                                            'boxShadow': ' 0px 0px 0px 0px transparent'
                                                          }
                                                        }
                                                      ],
                                                      'size': 'xs',
                                                      'style': {
                                                        'position': 'static',
                                                        'display': 'block',
                                                        'flex': '1 1 auto',
                                                        'flexGrow': 1,
                                                        'flexBasis': 'auto',
                                                        'boxShadow': ' 0px 0px 0px 0px transparent'
                                                      },
                                                      'wrapperBody': false,
                                                      'isFixedHeight': false,
                                                      'isFixedWidth': false,
                                                      'id': 'u:f04976d9a0d8',
                                                      'wrapperComponent': 'div'
                                                    },
                                                    {
                                                      'type': 'container',
                                                      'body': [
                                                        {
                                                          'type': 'tpl',
                                                          'tpl': '设备编号',
                                                          'inline': true,
                                                          'wrapperComponent': '',
                                                          'id': 'u:a6d44c2b03e1',
                                                          'style': {
                                                            'boxShadow': ' 0px 0px 0px 0px transparent'
                                                          },
                                                          'className': 'font-bold'
                                                        }
                                                      ],
                                                      'size': 'xs',
                                                      'style': {
                                                        'position': 'static',
                                                        'display': 'block',
                                                        'flex': '1 1 auto',
                                                        'flexGrow': 1,
                                                        'flexBasis': 'auto',
                                                        'boxShadow': ' 0px 0px 0px 0px transparent'
                                                      },
                                                      'wrapperBody': false,
                                                      'isFixedHeight': false,
                                                      'isFixedWidth': false,
                                                      'id': 'u:6fb5ca5e8101'
                                                    }
                                                  ],
                                                  'style': {
                                                    'position': 'relative',
                                                    'inset': 'auto',
                                                    'flexWrap': 'nowrap',
                                                    'boxShadow': ' 0px 0px 0px 0px transparent',
                                                    'flexDirection': 'column'
                                                  },
                                                  'id': 'u:27731f73bdf5',
                                                  'isFixedHeight': false,
                                                  'isFixedWidth': false
                                                }
                                              ],
                                              'id': 'u:2a63bf18b5a0',
                                              'style': {
                                                'boxShadow': ' 0px 0px 0px 0px transparent'
                                              }
                                            },
                                            {
                                              'body': [
                                                {
                                                  'type': 'flex',
                                                  'className': 'p-1',
                                                  'items': [
                                                    {
                                                      'type': 'container',
                                                      'body': [
                                                        {
                                                          'type': 'tag',
                                                          'id': 'u:9585762e20ee',
                                                          'label': "${IF(deviceType==0,'网关子设备',IF(deviceType==1,'直连设备',IF(deviceType==2,'网关设备','--')))}",
                                                          'color': 'processing',
                                                          'displayMode': 'normal'
                                                        }
                                                      ],
                                                      'size': 'xs',
                                                      'style': {
                                                        'position': 'static',
                                                        'display': 'block',
                                                        'flex': '1 1 auto',
                                                        'flexGrow': 1,
                                                        'flexBasis': 'auto',
                                                        'boxShadow': ' 0px 0px 0px 0px transparent'
                                                      },
                                                      'wrapperBody': false,
                                                      'isFixedHeight': false,
                                                      'isFixedWidth': false,
                                                      'id': 'u:f04976d9a0d8',
                                                      'wrapperComponent': 'div'
                                                    },
                                                    {
                                                      'type': 'container',
                                                      'body': [
                                                        {
                                                          'type': 'tpl',
                                                          'tpl': '模板类型',
                                                          'inline': true,
                                                          'wrapperComponent': '',
                                                          'id': 'u:a6d44c2b03e1',
                                                          'style': {
                                                            'boxShadow': ' 0px 0px 0px 0px transparent'
                                                          },
                                                          'className': 'font-bold'
                                                        }
                                                      ],
                                                      'size': 'xs',
                                                      'style': {
                                                        'position': 'static',
                                                        'display': 'block',
                                                        'flex': '1 1 auto',
                                                        'flexGrow': 1,
                                                        'flexBasis': 'auto',
                                                        'boxShadow': ' 0px 0px 0px 0px transparent'
                                                      },
                                                      'wrapperBody': false,
                                                      'isFixedHeight': false,
                                                      'isFixedWidth': false,
                                                      'id': 'u:6fb5ca5e8101'
                                                    }
                                                  ],
                                                  'style': {
                                                    'position': 'relative',
                                                    'inset': 'auto',
                                                    'flexWrap': 'nowrap',
                                                    'boxShadow': ' 0px 0px 0px 0px transparent',
                                                    'flexDirection': 'column'
                                                  },
                                                  'id': 'u:27731f73bdf5',
                                                  'isFixedHeight': false,
                                                  'isFixedWidth': false
                                                }
                                              ],
                                              'id': 'u:123bfc2df053',
                                              'style': {
                                                'boxShadow': ' 0px 0px 0px 0px transparent'
                                              }
                                            }
                                          ],
                                          'id': 'u:55ff95b8cb22',
                                          'style': {
                                            'boxShadow': ' 0px 0px 0px 0px transparent'
                                          }
                                        },
                                        {
                                          'type': 'grid',
                                          'columns': [
                                            {
                                              'body': [
                                                {
                                                  'type': 'flex',
                                                  'className': 'p-1',
                                                  'items': [
                                                    {
                                                      'type': 'container',
                                                      'body': [
                                                        {
                                                          'type': 'tpl',
                                                          'tpl': '${sampleInfo.sampleName}',
                                                          'inline': true,
                                                          'wrapperComponent': '',
                                                          'id': 'u:472bd957e043',
                                                          'style': {
                                                            'boxShadow': ' 0px 0px 0px 0px transparent'
                                                          }
                                                        }
                                                      ],
                                                      'size': 'xs',
                                                      'style': {
                                                        'position': 'static',
                                                        'display': 'block',
                                                        'flex': '1 1 auto',
                                                        'flexGrow': 1,
                                                        'flexBasis': 'auto',
                                                        'boxShadow': ' 0px 0px 0px 0px transparent'
                                                      },
                                                      'wrapperBody': false,
                                                      'isFixedHeight': false,
                                                      'isFixedWidth': false,
                                                      'id': 'u:f04976d9a0d8',
                                                      'wrapperComponent': 'div'
                                                    },
                                                    {
                                                      'type': 'container',
                                                      'body': [
                                                        {
                                                          'type': 'tpl',
                                                          'tpl': '所属模板',
                                                          'inline': true,
                                                          'wrapperComponent': '',
                                                          'id': 'u:a6d44c2b03e1',
                                                          'style': {
                                                            'boxShadow': ' 0px 0px 0px 0px transparent'
                                                          },
                                                          'className': 'font-bold'
                                                        }
                                                      ],
                                                      'size': 'xs',
                                                      'style': {
                                                        'position': 'static',
                                                        'display': 'block',
                                                        'flex': '1 1 auto',
                                                        'flexGrow': 1,
                                                        'flexBasis': 'auto',
                                                        'boxShadow': ' 0px 0px 0px 0px transparent'
                                                      },
                                                      'wrapperBody': false,
                                                      'isFixedHeight': false,
                                                      'isFixedWidth': false,
                                                      'id': 'u:6fb5ca5e8101'
                                                    }
                                                  ],
                                                  'style': {
                                                    'position': 'relative',
                                                    'inset': 'auto',
                                                    'flexWrap': 'nowrap',
                                                    'boxShadow': ' 0px 0px 0px 0px transparent',
                                                    'flexDirection': 'column'
                                                  },
                                                  'id': 'u:27731f73bdf5',
                                                  'isFixedHeight': false,
                                                  'isFixedWidth': false
                                                }
                                              ],
                                              'id': 'u:4473fcbc386f',
                                              'style': {
                                                'boxShadow': ' 0px 0px 0px 0px transparent'
                                              }
                                            },
                                            {
                                              'body': [
                                                {
                                                  'type': 'flex',
                                                  'className': 'p-1',
                                                  'items': [
                                                    {
                                                      'type': 'container',
                                                      'body': [
                                                        {
                                                          'type': 'tpl',
                                                          'tpl': "${IF(!!online,online,'--')}",
                                                          'inline': true,
                                                          'wrapperComponent': '',
                                                          'id': 'u:472bd957e043',
                                                          'style': {
                                                            'boxShadow': ' 0px 0px 0px 0px transparent'
                                                          }
                                                        }
                                                      ],
                                                      'size': 'xs',
                                                      'style': {
                                                        'position': 'static',
                                                        'display': 'block',
                                                        'flex': '1 1 auto',
                                                        'flexGrow': 1,
                                                        'flexBasis': 'auto',
                                                        'boxShadow': ' 0px 0px 0px 0px transparent'
                                                      },
                                                      'wrapperBody': false,
                                                      'isFixedHeight': false,
                                                      'isFixedWidth': false,
                                                      'id': 'u:f04976d9a0d8',
                                                      'wrapperComponent': 'div'
                                                    },
                                                    {
                                                      'type': 'container',
                                                      'body': [
                                                        {
                                                          'type': 'tpl',
                                                          'tpl': '状态',
                                                          'inline': true,
                                                          'wrapperComponent': '',
                                                          'id': 'u:a6d44c2b03e1',
                                                          'style': {
                                                            'boxShadow': ' 0px 0px 0px 0px transparent'
                                                          },
                                                          'className': 'font-bold'
                                                        }
                                                      ],
                                                      'size': 'xs',
                                                      'style': {
                                                        'position': 'static',
                                                        'display': 'block',
                                                        'flex': '1 1 auto',
                                                        'flexGrow': 1,
                                                        'flexBasis': 'auto',
                                                        'boxShadow': ' 0px 0px 0px 0px transparent'
                                                      },
                                                      'wrapperBody': false,
                                                      'isFixedHeight': false,
                                                      'isFixedWidth': false,
                                                      'id': 'u:6fb5ca5e8101'
                                                    }
                                                  ],
                                                  'style': {
                                                    'position': 'relative',
                                                    'inset': 'auto',
                                                    'flexWrap': 'nowrap',
                                                    'boxShadow': ' 0px 0px 0px 0px transparent',
                                                    'flexDirection': 'column'
                                                  },
                                                  'id': 'u:27731f73bdf5',
                                                  'isFixedHeight': false,
                                                  'isFixedWidth': false
                                                }
                                              ],
                                              'id': 'u:0377b58fdc78',
                                              'style': {
                                                'boxShadow': ' 0px 0px 0px 0px transparent'
                                              }
                                            }
                                          ],
                                          'id': 'u:4f621fdcc267',
                                          'style': {
                                            'boxShadow': ' 0px 0px 0px 0px transparent'
                                          }
                                        },
                                        {
                                          'type': 'grid',
                                          'columns': [
                                            {
                                              'body': [
                                                {
                                                  'type': 'flex',
                                                  'className': 'p-1',
                                                  'items': [
                                                    {
                                                      'type': 'container',
                                                      'body': [
                                                        {
                                                          'type': 'tpl',
                                                          'tpl': '${IF(!!alarmCount,alarmCount,0)}',
                                                          'inline': true,
                                                          'wrapperComponent': '',
                                                          'id': 'u:f8249b17b04e',
                                                          'style': {
                                                            'boxShadow': ' 0px 0px 0px 0px transparent'
                                                          }
                                                        }
                                                      ],
                                                      'size': 'xs',
                                                      'style': {
                                                        'position': 'static',
                                                        'display': 'block',
                                                        'flex': '1 1 auto',
                                                        'flexGrow': 1,
                                                        'flexBasis': 'auto',
                                                        'boxShadow': ' 0px 0px 0px 0px transparent'
                                                      },
                                                      'wrapperBody': false,
                                                      'isFixedHeight': false,
                                                      'isFixedWidth': false,
                                                      'id': 'u:88e409fd38ec',
                                                      'wrapperComponent': 'div'
                                                    },
                                                    {
                                                      'type': 'container',
                                                      'body': [
                                                        {
                                                          'type': 'tpl',
                                                          'tpl': '报警总数',
                                                          'inline': true,
                                                          'wrapperComponent': '',
                                                          'id': 'u:d4c23321346c',
                                                          'style': {
                                                            'boxShadow': ' 0px 0px 0px 0px transparent'
                                                          },
                                                          'className': 'font-bold'
                                                        }
                                                      ],
                                                      'size': 'xs',
                                                      'style': {
                                                        'position': 'static',
                                                        'display': 'block',
                                                        'flex': '1 1 auto',
                                                        'flexGrow': 1,
                                                        'flexBasis': 'auto',
                                                        'boxShadow': ' 0px 0px 0px 0px transparent'
                                                      },
                                                      'wrapperBody': false,
                                                      'isFixedHeight': false,
                                                      'isFixedWidth': false,
                                                      'id': 'u:b2eb336e6d63'
                                                    }
                                                  ],
                                                  'style': {
                                                    'position': 'relative',
                                                    'inset': 'auto',
                                                    'flexWrap': 'nowrap',
                                                    'boxShadow': ' 0px 0px 0px 0px transparent',
                                                    'flexDirection': 'column'
                                                  },
                                                  'id': 'u:8dc77d2354cb',
                                                  'isFixedHeight': false,
                                                  'isFixedWidth': false
                                                }
                                              ],
                                              'id': 'u:36bc54b8e76d',
                                              'style': {
                                                'boxShadow': ' 0px 0px 0px 0px transparent'
                                              }
                                            },
                                            {
                                              'body': [
                                                {
                                                  'type': 'flex',
                                                  'className': 'p-1',
                                                  'items': [
                                                    {
                                                      'type': 'container',
                                                      'body': [
                                                        {
                                                          'type': 'tpl',
                                                          'tpl': '${IF(!!dealAlarmCount,dealAlarmCount,0)}',
                                                          'inline': true,
                                                          'wrapperComponent': '',
                                                          'id': 'u:f868159f64c7',
                                                          'style': {
                                                            'boxShadow': ' 0px 0px 0px 0px transparent'
                                                          }
                                                        }
                                                      ],
                                                      'size': 'xs',
                                                      'style': {
                                                        'position': 'static',
                                                        'display': 'block',
                                                        'flex': '1 1 auto',
                                                        'flexGrow': 1,
                                                        'flexBasis': 'auto',
                                                        'boxShadow': ' 0px 0px 0px 0px transparent'
                                                      },
                                                      'wrapperBody': false,
                                                      'isFixedHeight': false,
                                                      'isFixedWidth': false,
                                                      'id': 'u:293ab0c2346d',
                                                      'wrapperComponent': 'div'
                                                    },
                                                    {
                                                      'type': 'container',
                                                      'body': [
                                                        {
                                                          'type': 'tpl',
                                                          'tpl': '已处理报警总数',
                                                          'inline': true,
                                                          'wrapperComponent': '',
                                                          'id': 'u:ad365e314eb2',
                                                          'style': {
                                                            'boxShadow': ' 0px 0px 0px 0px transparent'
                                                          },
                                                          'className': 'font-bold'
                                                        }
                                                      ],
                                                      'size': 'xs',
                                                      'style': {
                                                        'position': 'static',
                                                        'display': 'block',
                                                        'flex': '1 1 auto',
                                                        'flexGrow': 1,
                                                        'flexBasis': 'auto',
                                                        'boxShadow': ' 0px 0px 0px 0px transparent'
                                                      },
                                                      'wrapperBody': false,
                                                      'isFixedHeight': false,
                                                      'isFixedWidth': false,
                                                      'id': 'u:1ca4c136f4ce'
                                                    }
                                                  ],
                                                  'style': {
                                                    'position': 'relative',
                                                    'inset': 'auto',
                                                    'flexWrap': 'nowrap',
                                                    'boxShadow': ' 0px 0px 0px 0px transparent',
                                                    'flexDirection': 'column'
                                                  },
                                                  'id': 'u:39644b42dcc2',
                                                  'isFixedHeight': false,
                                                  'isFixedWidth': false
                                                }
                                              ],
                                              'id': 'u:c330926a4ca1'
                                            }
                                          ],
                                          'id': 'u:be4fdb6233e5',
                                          'style': {
                                            'boxShadow': ' 0px 0px 0px 0px transparent'
                                          },
                                          'align': 'left'
                                        },
                                        {
                                          'type': 'grid',
                                          'columns': [
                                            {
                                              'body': [
                                                {
                                                  'type': 'flex',
                                                  'className': 'p-1',
                                                  'items': [
                                                    {
                                                      'type': 'container',
                                                      'body': [
                                                        {
                                                          'type': 'tpl',
                                                          'tpl': '${recentlyUpperOnline}',
                                                          'inline': true,
                                                          'wrapperComponent': '',
                                                          'id': 'u:d2614e2451e7',
                                                          'style': {
                                                            'boxShadow': ' 0px 0px 0px 0px transparent'
                                                          }
                                                        }
                                                      ],
                                                      'size': 'xs',
                                                      'style': {
                                                        'position': 'static',
                                                        'display': 'block',
                                                        'flex': '1 1 auto',
                                                        'flexGrow': 1,
                                                        'flexBasis': 'auto',
                                                        'boxShadow': ' 0px 0px 0px 0px transparent'
                                                      },
                                                      'wrapperBody': false,
                                                      'isFixedHeight': false,
                                                      'isFixedWidth': false,
                                                      'id': 'u:4d571d9d6d83',
                                                      'wrapperComponent': 'div'
                                                    },
                                                    {
                                                      'type': 'container',
                                                      'body': [
                                                        {
                                                          'type': 'tpl',
                                                          'tpl': '最近上线时间',
                                                          'inline': true,
                                                          'wrapperComponent': '',
                                                          'id': 'u:9ca5ff19982f',
                                                          'style': {
                                                            'boxShadow': ' 0px 0px 0px 0px transparent'
                                                          },
                                                          'className': 'font-bold'
                                                        }
                                                      ],
                                                      'size': 'xs',
                                                      'style': {
                                                        'position': 'static',
                                                        'display': 'block',
                                                        'flex': '1 1 auto',
                                                        'flexGrow': 1,
                                                        'flexBasis': 'auto',
                                                        'boxShadow': ' 0px 0px 0px 0px transparent'
                                                      },
                                                      'wrapperBody': false,
                                                      'isFixedHeight': false,
                                                      'isFixedWidth': false,
                                                      'id': 'u:5995fad18598'
                                                    }
                                                  ],
                                                  'style': {
                                                    'position': 'relative',
                                                    'inset': 'auto',
                                                    'flexWrap': 'nowrap',
                                                    'boxShadow': ' 0px 0px 0px 0px transparent',
                                                    'flexDirection': 'column'
                                                  },
                                                  'id': 'u:4e47f23c5d6f',
                                                  'isFixedHeight': false,
                                                  'isFixedWidth': false
                                                }
                                              ],
                                              'id': 'u:8240cf0f90c2',
                                              'style': {
                                                'boxShadow': ' 0px 0px 0px 0px transparent'
                                              }
                                            },
                                            {
                                              'body': [
                                                {
                                                  'type': 'flex',
                                                  'className': 'p-1',
                                                  'items': [
                                                    {
                                                      'type': 'container',
                                                      'body': [
                                                        {
                                                          'type': 'tpl',
                                                          'tpl': '${recentlyBelowOnline}',
                                                          'inline': true,
                                                          'wrapperComponent': '',
                                                          'id': 'u:f34117289866',
                                                          'style': {
                                                            'boxShadow': ' 0px 0px 0px 0px transparent'
                                                          }
                                                        }
                                                      ],
                                                      'size': 'xs',
                                                      'style': {
                                                        'position': 'static',
                                                        'display': 'block',
                                                        'flex': '1 1 auto',
                                                        'flexGrow': 1,
                                                        'flexBasis': 'auto',
                                                        'boxShadow': ' 0px 0px 0px 0px transparent'
                                                      },
                                                      'wrapperBody': false,
                                                      'isFixedHeight': false,
                                                      'isFixedWidth': false,
                                                      'id': 'u:4f90ddc64aaa',
                                                      'wrapperComponent': 'div'
                                                    },
                                                    {
                                                      'type': 'container',
                                                      'body': [
                                                        {
                                                          'type': 'tpl',
                                                          'tpl': '最近离线时间',
                                                          'inline': true,
                                                          'wrapperComponent': '',
                                                          'id': 'u:ddf4e7a13362',
                                                          'style': {
                                                            'boxShadow': ' 0px 0px 0px 0px transparent'
                                                          },
                                                          'className': 'font-bold'
                                                        }
                                                      ],
                                                      'size': 'xs',
                                                      'style': {
                                                        'position': 'static',
                                                        'display': 'block',
                                                        'flex': '1 1 auto',
                                                        'flexGrow': 1,
                                                        'flexBasis': 'auto',
                                                        'boxShadow': ' 0px 0px 0px 0px transparent'
                                                      },
                                                      'wrapperBody': false,
                                                      'isFixedHeight': false,
                                                      'isFixedWidth': false,
                                                      'id': 'u:98019b41e2e0'
                                                    }
                                                  ],
                                                  'style': {
                                                    'position': 'relative',
                                                    'inset': 'auto',
                                                    'flexWrap': 'nowrap',
                                                    'boxShadow': ' 0px 0px 0px 0px transparent',
                                                    'flexDirection': 'column'
                                                  },
                                                  'id': 'u:6b4a5a7e68fa',
                                                  'isFixedHeight': false,
                                                  'isFixedWidth': false
                                                }
                                              ],
                                              'id': 'u:e5a2e262bd28',
                                              'style': {
                                                'boxShadow': ' 0px 0px 0px 0px transparent'
                                              }
                                            }
                                          ],
                                          'id': 'u:0e91f98c745c',
                                          'style': {
                                            'boxShadow': ' 0px 0px 0px 0px transparent'
                                          }
                                        },
                                        {
                                          'type': 'grid',
                                          'columns': [
                                            {
                                              'body': [
                                                {
                                                  'type': 'flex',
                                                  'className': 'p-1',
                                                  'items': [
                                                    {
                                                      'type': 'container',
                                                      'body': [
                                                        {
                                                          'type': 'tpl',
                                                          'tpl': "${IF(!!address,address,'--')}",
                                                          'inline': true,
                                                          'wrapperComponent': '',
                                                          'id': 'u:472bd957e043',
                                                          'style': {
                                                            'boxShadow': ' 0px 0px 0px 0px transparent'
                                                          }
                                                        }
                                                      ],
                                                      'size': 'xs',
                                                      'style': {
                                                        'position': 'static',
                                                        'display': 'block',
                                                        'flex': '1 1 auto',
                                                        'flexGrow': 1,
                                                        'flexBasis': 'auto',
                                                        'boxShadow': ' 0px 0px 0px 0px transparent'
                                                      },
                                                      'wrapperBody': false,
                                                      'isFixedHeight': false,
                                                      'isFixedWidth': false,
                                                      'id': 'u:f04976d9a0d8',
                                                      'wrapperComponent': 'div'
                                                    },
                                                    {
                                                      'type': 'container',
                                                      'body': [
                                                        {
                                                          'type': 'tpl',
                                                          'tpl': '设备地址',
                                                          'inline': true,
                                                          'wrapperComponent': '',
                                                          'id': 'u:a6d44c2b03e1',
                                                          'style': {
                                                            'boxShadow': '0px 0px 0px 0px transparent'
                                                          },
                                                          'className': 'font-bold'
                                                        }
                                                      ],
                                                      'size': 'xs',
                                                      'style': {
                                                        'position': 'static',
                                                        'display': 'block',
                                                        'flex': '1 1 auto',
                                                        'flexGrow': 1,
                                                        'flexBasis': 'auto',
                                                        'boxShadow': ' 0px 0px 0px 0px transparent'
                                                      },
                                                      'wrapperBody': false,
                                                      'isFixedHeight': false,
                                                      'isFixedWidth': false,
                                                      'id': 'u:6fb5ca5e8101'
                                                    }
                                                  ],
                                                  'style': {
                                                    'position': 'relative',
                                                    'inset': 'auto',
                                                    'flexWrap': 'nowrap',
                                                    'boxShadow': ' 0px 0px 0px 0px transparent',
                                                    'flexDirection': 'column'
                                                  },
                                                  'id': 'u:27731f73bdf5',
                                                  'isFixedHeight': false,
                                                  'isFixedWidth': false
                                                }
                                              ],
                                              'id': 'u:70ac64565481',
                                              'style': {
                                                'boxShadow': ' 0px 0px 0px 0px transparent'
                                              }
                                            }
                                          ],
                                          'id': 'u:f7deb696fb2c',
                                          'style': {
                                            'boxShadow': ' 0px 0px 0px 0px transparent'
                                          }
                                        }
                                      ],
                                      'id': 'u:7fc43957d415',
                                      'affixFooter': false,
                                      'actions': [],
                                      'hidden': false,
                                      'headerClassName': 'bg-primary p-t-sm p-b-sm p-l-xs no-border r-t text-white',
                                      'className': 'no-border Panel--default',
                                      'bodyClassName': 'no-border',
                                      'subFormMode': 'normal'
                                    }
                                  ],
                                  'id': 'u:0be14e7dff94',
                                  'style': {
                                    'boxShadow': ' 0px 0px 0px 0px transparent'
                                  },
                                  'md': 3
                                }
                              ],
                              'style': {
                                'boxShadow': ' 0px 0px 0px 0px transparent'
                              }
                            },
                            {
                              'type': 'grid',
                              'columns': [
                                {
                                  'body': [
                                    {
                                      'type': 'container',
                                      'style': {
                                        'position': 'static',
                                        'boxShadow': ' 0px 0px 0px 0px transparent',
                                        'display': 'flex',
                                        'flexWrap': 'nowrap',
                                        'justifyContent': 'space-between'
                                      },
                                      'id': 'u:1648ddc8f3af',
                                      'isFixedHeight': false,
                                      'isFixedWidth': false,
                                      'body': [
                                        {
                                          'type': 'container',
                                          'body': [
                                            {
                                              'type': 'button',
                                              'label': '下载数据',
                                              'onEvent': {
                                                'click': {
                                                  'actions': []
                                                }
                                              },
                                              'id': 'u:935406b3bea2',
                                              'themeCss': {
                                                'className': {
                                                  'padding-and-margin:default': {
                                                    'marginTop': '0',
                                                    'marginRight': '16px',
                                                    'marginBottom': '0',
                                                    'marginLeft': '0'
                                                  }
                                                }
                                              },
                                              'className': 'className-935406b3bea2'
                                            },
                                            {
                                              'type': 'button',
                                              'label': '列表显示',
                                              'onEvent': {
                                                'click': {
                                                  'actions': []
                                                }
                                              },
                                              'id': 'u:662feb81f8bf'
                                            }
                                          ],
                                          'style': {
                                            'position': 'static',
                                            'display': 'block',
                                            'boxShadow': ' 0px 0px 0px 0px transparent',
                                            'flex': '0 0 auto',
                                            'overflowY': 'visible',
                                            'height': '32px'
                                          },
                                          'wrapperBody': false,
                                          'id': 'u:a6932fa5abd2',
                                          'isFixedHeight': true,
                                          'isFixedWidth': false
                                        },
                                        {
                                          'type': 'container',
                                          'body': [
                                            {
                                              'type': 'form',
                                              'id': 'u:859b150461f1',
                                              'mode': 'inline',
                                              'title': '过滤条件',
                                              'target': 'historyChart',
                                              'submitOnInit': false,
                                              'wrapWithPanel': false,
                                              'body': [
                                                {
                                                  'type': 'select',
                                                  'label': '',
                                                  'name': 'selectId',
                                                  'id': 'u:e112d3d82a8e',
                                                  'placeholder': '选择设备',
                                                  'onEvent': {
                                                    'change': {
                                                      'weight': 0,
                                                      'actions': []
                                                    }
                                                  },
                                                  'multiple': false,
                                                  'value': '',
                                                  'selectFirst': true,
                                                  'source': '${ARRAYFILTER(deviceData,item => !item.isSelf||item.data.length>0)}',
                                                  'editable': false,
                                                  'labelField': 'deviceName',
                                                  'valueField': 'id',
                                                  'size': 'sm',
                                                  'mode': 'inline',
                                                  'searchable': false,
                                                  'showInvalidMatch': false,
                                                  'className': 'm-b-none',
                                                  'inputClassName': '',
                                                  'labelClassName': ''
                                                },
                                                {
                                                  'type': 'input-datetime-range',
                                                  'label': '',
                                                  'onEvent': {},
                                                  'id': 'u:2c9e14923689',
                                                  'name': 'startTime',
                                                  'extraName': 'endTime',
                                                  'inputFormat': 'YYYY-MM-DD HH:mm:ss',
                                                  'format': 'YYYY-MM-DD HH:mm:ss',
                                                  'timeFormat': 'HH:mm:ss',
                                                  'placeholder': '请选择日期时间范围',
                                                  'minDate': '',
                                                  'maxDate': '',
                                                  'shortcuts': [
                                                    'yesterday',
                                                    '7daysago',
                                                    'prevweek',
                                                    'thismonth',
                                                    'prevmonth',
                                                    'prevquarter'
                                                  ],
                                                  'className': 'm-b-none'
                                                },
                                                {
                                                  'type': 'button',
                                                  'label': '查询',
                                                  'onEvent': {
                                                    'click': {
                                                      'actions': [
                                                        {
                                                          'componentId': 'u:104133c688dd',
                                                          'ignoreError': false,
                                                          'actionType': 'reload',
                                                          'args': {
                                                            'resetPage': true
                                                          }
                                                        }
                                                      ],
                                                      'weight': 0
                                                    }
                                                  },
                                                  'id': 'u:717284fb8d03',
                                                  'themeCss': {
                                                    'className': {
                                                      'padding-and-margin:default': {
                                                        'marginTop': '0',
                                                        'marginRight': '0',
                                                        'marginBottom': '0',
                                                        'marginLeft': '0'
                                                      }
                                                    }
                                                  },
                                                  'className': 'className-717284fb8d03'
                                                }
                                              ],
                                              'actions': [],
                                              'name': '设备列表',
                                              'className': 'm-none p-none',
                                              'reload': '',
                                              'preventEnterSubmit': true,
                                              'submitOnChange': true,
                                              'staticClassName': 'm-none p-none'
                                            }
                                          ],
                                          'style': {
                                            'position': 'static',
                                            'display': 'flex',
                                            'boxShadow': ' 0px 0px 0px 0px transparent',
                                            'flex': '1 1 auto',
                                            'flexWrap': 'nowrap',
                                            'overflowX': 'auto',
                                            'flexGrow': 1,
                                            'flexBasis': 'auto',
                                            'justifyContent': 'flex-end',
                                            'height': '32px',
                                            'overflowY': 'hidden'
                                          },
                                          'wrapperBody': false,
                                          'id': 'u:0938fb2b3144',
                                          'isFixedHeight': true,
                                          'isFixedWidth': false
                                        },
                                        {
                                          'type': 'divider',
                                          'id': 'u:7b27f17d38e4'
                                        }
                                      ],
                                      'wrapperBody': false,
                                      'size': 'sm'
                                    },
                                    {
                                      'type': 'flex',
                                      'style': {
                                        'position': 'relative',
                                        'boxShadow': ' 0px 0px 0px 0px transparent',
                                        'inset': 'auto',
                                        'flexWrap': 'nowrap'
                                      },
                                      'id': 'u:cd2997a1468a',
                                      'isFixedHeight': false,
                                      'isFixedWidth': false,
                                      'className': 'm-none p-none',
                                      'items': [
                                        {
                                          'type': 'container',
                                          'body': [
                                            {
                                              'type': 'divider',
                                              'id': 'u:02987818fac2',
                                              'className': 'm-none p-none b-a'
                                            }
                                          ],
                                          'size': 'none',
                                          'style': {
                                            'position': 'static',
                                            'display': 'block',
                                            'flex': '1 1 auto',
                                            'flexGrow': '1',
                                            'flexBasis': 'auto',
                                            'boxShadow': ' 0px 0px 0px 0px transparent',
                                            'marginTop': '0',
                                            'marginRight': '0',
                                            'marginBottom': '0',
                                            'marginLeft': '0'
                                          },
                                          'wrapperBody': false,
                                          'isFixedHeight': false,
                                          'isFixedWidth': false,
                                          'id': 'u:f04976d9a0d8',
                                          'className': 'm-none p-none no-border'
                                        }
                                      ]
                                    },
                                    {
                                      'type': 'container',
                                      'body': [
                                        {
                                          'type': 'chart',
                                          'config': {
                                            'xAxis': {
                                              'type': 'time',
                                              'id': 'u:f4c5d3195bad',
                                              'axisLabel': {
                                                'formatter': '{yyyy}-{MM}-{dd} {HH}:{mm}:{ss}'
                                              }
                                            },
                                            'yAxis': {
                                              'type': 'value',
                                              'id': 'u:8887928a6efc'
                                            },
                                            'series': [],
                                            'backgroundColor': 'transparent',
                                            'dataZoom': [
                                              {
                                                'start': 0,
                                                'end': 10
                                              }
                                            ],
                                            'tooltip': {
                                              'trigger': 'axis'
                                            }
                                          },
                                          'replaceChartOption': false,
                                          'id': 'u:104133c688dd',
                                          'name': 'historyChart',
                                          'style': {
                                            'boxShadow': ' 0px 0px 0px 0px transparent',
                                            'width': '100%',
                                            'height': '300px'
                                          },
                                          'api': {
                                            'url': '/gather/device/info/history?id=${selectId}&startTime=${startTime}&endTime=${endTime}',
                                            'method': 'get',
                                            'requestAdaptor': '',
                                            'adaptor': '',
                                            'messages': {}
                                          },
                                          'initFetch': false,
                                          'dataFilter': "config.legend={\r\n  data:[]\r\n};\r\nconfig.series = [];\r\nconfig.series = data.items.map(function(item) { \r\n  config.legend.data.push(item.name);\r\n  let obj = { name: item.name, data:[],type:'line'}; \r\n  item.dps.forEach(function(itemDps) { \r\n    obj.data.push([formatDate(Number(itemDps.ts)), itemDps.val]) \r\n  }); \r\n  return obj;\r\n}); \r\nfunction formatDate(value) {var date = new Date(value);var y = date.getFullYear(),m = date.getMonth() + 1,d = date.getDate(),h = date.getHours(),i = date.getMinutes(),s = date.getSeconds();if (m < 10) { m = '0' + m; }if (d < 10) { d = '0' + d; }if (h < 10) { h = '0' + h; }if (i < 10) { i = '0' + i; }if (s < 10) { s = '0' + s; }var t = y + '-' + m + '-' + d + ' ' + h + ':' + i + ':' + s;return t;\r\n}\r\n"
                                        }
                                      ],
                                      'style': {
                                        'position': 'static',
                                        'display': 'block',
                                        'boxShadow': ' 0px 0px 0px 0px transparent'
                                      },
                                      'wrapperBody': false,
                                      'id': 'u:25b8c9da56ca',
                                      'isFixedHeight': false,
                                      'isFixedWidth': false
                                    }
                                  ],
                                  'id': 'u:a072016e6ac3',
                                  'style': {
                                    'boxShadow': ' 0px 0px 0px 0px transparent'
                                  }
                                }
                              ],
                              'id': 'u:c7dfcea692d1',
                              'style': {
                                'boxShadow': ' 0px 0px 0px 0px transparent'
                              }
                            }
                          ],
                          'id': 'u:193602a6a15f'
                        },
                        {
                          'title': '连接',
                          'body': [
                            {
                              'type': 'markdown',
                              'id': 'u:691227e932ba',
                              'value': '## TCP 接入点\n  TCP 接入方式为设备提供了最原始的 TCP/IP 传输方式，支持 ASCII、HEX、JSON 等有效负载，适合一些自定义协议的通信场景，也适用于 TCP 透传方式。  \n  **TCP 地址:** ${mqttServer}  \n  **TCP 端口:** ${mqttPort}  \n  **注册包:** ${mqttLogin}  \n  **心跳包:** ${mqttHeart}  \n\n  ## MQTT 接入点  \n  MQTT 接入方式为设备和云平台提供双向连接，设备既可上报属性数据，也可接收云端的消息下发。  \n  **MQTT 主机:** ${mqttServer}  \n  **MQTT 端口:** 1883  \n  **Username:** admin  \n  **Password:** 123456\n\n\n\n  '
                            }
                          ],
                          'id': 'u:14e6d17573b4'
                        },
                        {
                          'title': '属性',
                          'body': [
                            {
                              'type': 'crud',
                              'id': 'devAttrPage1:table',
                              'draggable': false,
                              'perPage': 10,
                              'keepItemSelectionOnPageChange': true,
                              'maxKeepItemSelectionLength': 11,
                              'labelTpl': '${id}',
                              'autoGenerateFilter': {
                                'columnsNum': 4,
                                'showBtnToolbar': false
                              },
                              'bulkActions': [],
                              'quickSaveApi': '',
                              'quickSaveItemApi': '',
                              'filterTogglable': true,
                              'headerToolbar': [
                                {
                                  'type': 'bulk-actions'
                                }
                              ],
                              'footerToolbar': [],
                              'columns': [
                                {
                                  'name': 'attrName',
                                  'label': '属性名称',
                                  'id': 'LIST:属性名称',
                                  'type': 'text',
                                  'placeholder': '-'
                                },
                                {
                                  'name': 'attrCode',
                                  'label': '属性标识符',
                                  'id': 'LIST:属性标识符',
                                  'type': 'text',
                                  'placeholder': '-'
                                },
                                {
                                  'name': 'currentValue',
                                  'label': '当前值',
                                  'id': 'LIST:单位',
                                  'type': 'text',
                                  'placeholder': '-',
                                  'value': '${value+attrUnit}'
                                },
                                {
                                  'name': 'attributeType',
                                  'label': '属性类型',
                                  'id': 'u:25ac6392a134',
                                  'type': 'mapping',
                                  'value': '0',
                                  'map': {
                                    '0': '设备上报',
                                    '1': '云端下发',
                                    '2': '设备云端共享',
                                    '3': '云端私有'
                                  },
                                  'itemSchema': {
                                    'type': 'tag',
                                    'label': '${item}'
                                  }
                                },
                                {
                                  'type': 'mapping',
                                  'value': 'Number',
                                  'map': {
                                    'Number': '数值',
                                    'Switch': '开关量',
                                    'Text': '文本',
                                    'Enum': '枚举',
                                    'Object': '键值对',
                                    'List': '列表'
                                  },
                                  'itemSchema': {
                                    'type': 'tag',
                                    'label': '${item}'
                                  },
                                  'name': 'dataType',
                                  'label': '属性类型',
                                  'id': 'u:9f9dcc8950f7'
                                },
                                {
                                  'type': 'operation',
                                  'label': '操作',
                                  'id': 'u:3ba1b5bedd76',
                                  'buttons': [
                                    {
                                      'type': 'button',
                                      'label': '读取',
                                      'onEvent': {
                                        'click': {
                                          'actions': [
                                            {
                                              'ignoreError': false,
                                              'outputVar': 'responseResult',
                                              'actionType': 'ajax',
                                              'options': {},
                                              'api': {
                                                'url': '/gather/device/info/readDataPoint',
                                                'method': 'post',
                                                'requestAdaptor': '',
                                                'adaptor': '',
                                                'messages': {},
                                                'data': {
                                                  'devNo': '${deviceNo}',
                                                  'attrIdentifier': '${attrCode}'
                                                },
                                                'dataType': 'json'
                                              }
                                            },
                                            {
                                              'componentId': 'u:048c2e359e49',
                                              'ignoreError': false,
                                              'actionType': 'reload'
                                            }
                                          ]
                                        }
                                      },
                                      'id': 'u:9ca927e16ca7',
                                      'level': 'link'
                                    },
                                    {
                                      'type': 'button',
                                      'label': '写入',
                                      'onEvent': {
                                        'click': {
                                          'actions': [
                                            {
                                              'ignoreError': false,
                                              'actionType': 'dialog',
                                              'dialog': {
                                                'type': 'dialog',
                                                'title': '数据写入',
                                                'body': [
                                                  {
                                                    'type': 'form',
                                                    'title': '表单',
                                                    'body': [
                                                      {
                                                        'label': '设备标识',
                                                        'type': 'input-text',
                                                        'name': 'devNo',
                                                        'id': 'u:c39e1d4845d8',
                                                        'value': '${deviceNo}',
                                                        'static': true
                                                      },
                                                      {
                                                        'name': 'dataIdentifier',
                                                        'type': 'input-text',
                                                        'label': '参数',
                                                        'id': 'u:fc36fb0b9e6b',
                                                        'value': '${attrCode}',
                                                        'static': true
                                                      },
                                                      {
                                                        'name': 'val',
                                                        'type': 'input-text',
                                                        'label': '写入值',
                                                        'id': 'u:ac2d8921765a'
                                                      }
                                                    ],
                                                    'api': {
                                                      'url': '/gather/device/info/writeDataPoint',
                                                      'method': 'post',
                                                      'requestAdaptor': "api.data.dataIdentifier = api.data.dataIdentifier.split(',');",
                                                      'adaptor': '',
                                                      'messages': {},
                                                      'dataType': 'json'
                                                    },
                                                    'id': 'u:a9ab1784c1b8',
                                                    'name': '设备详情demo',
                                                    'onEvent': {
                                                      'submitSucc': {
                                                        'weight': 0,
                                                        'actions': [
                                                          {
                                                            'componentId': 'u:048c2e359e49',
                                                            'ignoreError': false,
                                                            'actionType': 'reload'
                                                          }
                                                        ]
                                                      }
                                                    }
                                                  }
                                                ],
                                                'showCloseButton': true,
                                                'showErrorMsg': true,
                                                'showLoading': true,
                                                'className': 'app-popover',
                                                'id': 'u:23b77ffd5ba7',
                                                'closeOnEsc': false
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      'id': 'u:41ec7433abcc',
                                      'level': 'link'
                                    },
                                    {
                                      'type': 'button',
                                      'label': '历史记录',
                                      'onEvent': {
                                        'click': {
                                          'actions': [
                                            {
                                              'ignoreError': false,
                                              'actionType': 'dialog',
                                              'dialog': {
                                                'type': 'dialog',
                                                'title': '弹框标题',
                                                'body': [
                                                  {
                                                    'type': 'crud',
                                                    'syncLocation': false,
                                                    'api': {
                                                      'method': 'get',
                                                      'url': '/gather/device/info/historyByAttrCode?id=${deviceId}&attrs=${attrCode}'
                                                    },
                                                    'columns': [
                                                      {
                                                        'name': 'ts',
                                                        'label': '时间',
                                                        'type': 'datetime',
                                                        'id': 'u:394c5a1704ec',
                                                        'value': 1699428318,
                                                        'valueFormat': 'x'
                                                      },
                                                      {
                                                        'name': 'val',
                                                        'label': '数值',
                                                        'type': 'text',
                                                        'id': 'u:7bd207efd742',
                                                        'placeholder': '-'
                                                      }
                                                    ],
                                                    'bulkActions': [],
                                                    'itemActions': [],
                                                    'id': 'u:ae06abca4d95',
                                                    'perPageAvailable': [
                                                      20
                                                    ],
                                                    'name': '设备详情demo',
                                                    'messages': {},
                                                    'perPage': 20,
                                                    'loadDataOnce': true
                                                  }
                                                ],
                                                'showCloseButton': true,
                                                'showErrorMsg': true,
                                                'showLoading': true,
                                                'className': 'app-popover',
                                                'id': 'u:6b967b78a884',
                                                'closeOnEsc': false,
                                                'size': 'lg'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      'id': 'u:e9469bd1a691',
                                      'level': 'link'
                                    }
                                  ]
                                }
                              ],
                              'perPageAvailable': [
                                10,
                                20
                              ],
                              'messages': {},
                              'filterSettingSource': [
                                'objectCode',
                                'objectName',
                                'tableName',
                                'id',
                                'createTime'
                              ],
                              'perPageField': 'pageSize',
                              'pageField': 'pageNum',
                              'features': [
                                'filter'
                              ],
                              'itemActions': [],
                              'onEvent': {},
                              'footable': false,
                              'alwaysShowPagination': true,
                              'initFetch': true,
                              'name': '设备模板',
                              'source': '${deviceData[0].data}'
                            }
                          ],
                          'id': 'u:c2c939d20e4b',
                          'hiddenOn': "${permissions!='*:*:*'&&!ARRAYINCLUDES(permissions,'gather:sample:list')}"
                        },
                        {
                          'title': '报警',
                          'body': [
                            {
                              'type': 'tabs',
                              'id': 'u:4ce47ff3cc70',
                              'tabs': [
                                {
                                  'title': '告警历史',
                                  'body': [
                                    {
                                      'type': 'crud',
                                      'draggable': false,
                                      'api': {
                                        'method': 'get',
                                        'url': '/gather/device/alarm/page',
                                        'messages': {},
                                        'requestAdaptor': '',
                                        'adaptor': '',
                                        'dataType': 'json',
                                        'data': {
                                          '&': '$$',
                                          'deviceId': '${id}'
                                        }
                                      },
                                      'perPage': 10,
                                      'keepItemSelectionOnPageChange': true,
                                      'maxKeepItemSelectionLength': 11,
                                      'labelTpl': '${id}',
                                      'autoGenerateFilter': {
                                        'columnsNum': 4,
                                        'showBtnToolbar': false
                                      },
                                      'bulkActions': [],
                                      'quickSaveApi': '',
                                      'quickSaveItemApi': '',
                                      'filterTogglable': true,
                                      'headerToolbar': [
                                        'bulkActions',
                                        'export-excel',
                                        {
                                          'type': 'columns-toggler',
                                          'align': 'right'
                                        },
                                        {
                                          'type': 'drag-toggler',
                                          'align': 'right'
                                        },
                                        {
                                          'type': 'pagination',
                                          'align': 'right'
                                        }
                                      ],
                                      'footerToolbar': [
                                        {
                                          'type': 'statistics'
                                        },
                                        {
                                          'type': 'pagination',
                                          'layout': 'perPage,pager,go'
                                        }
                                      ],
                                      'columns': [
                                        {
                                          'name': 'triggerName',
                                          'label': '触发器名称',
                                          'id': 'LIST:触发器名称',
                                          'type': 'text'
                                        },
                                        {
                                          'type': 'mapping',
                                          'value': 1,
                                          'map': {
                                            '1': '独立触发器',
                                            '2': '模板触发器'
                                          },
                                          'itemSchema': {
                                            'type': 'tag',
                                            'label': '${item}'
                                          },
                                          'name': 'triggerType',
                                          'label': '触发器类型',
                                          'id': 'u:af11d37dd127'
                                        },
                                        {
                                          'name': 'triggerAttrName',
                                          'label': '触发属性名称',
                                          'id': 'LIST:触发属性名称',
                                          'type': 'text'
                                        },
                                        {
                                          'name': 'triggerCondition',
                                          'label': '触发条件',
                                          'id': 'LIST:触发条件',
                                          'type': 'text',
                                          'inline': true,
                                          'className': '',
                                          'placeholder': '-',
                                          'tpl': '${triggerCondition+deviceAttrUnit}'
                                        },
                                        {
                                          'name': 'value',
                                          'label': '报警触发值',
                                          'id': 'LIST:报警值',
                                          'type': 'text',
                                          'placeholder': '-',
                                          'inline': true,
                                          'tpl': '${value+deviceAttrUnit}'
                                        },
                                        {
                                          'name': 'alarmContent',
                                          'label': '报警内容',
                                          'id': 'LIST:报警内容',
                                          'type': 'text',
                                          'placeholder': '-',
                                          'toggled': false
                                        },
                                        {
                                          'name': 'alarmTime',
                                          'label': '报警时间',
                                          'id': 'LIST:报警时间',
                                          'type': 'text',
                                          'placeholder': '-',
                                          'sortable': false
                                        },
                                        {
                                          'type': 'mapping',
                                          'value': 1,
                                          'map': {
                                            '0': '报警中',
                                            '1': '已处理',
                                            '2': '已恢复'
                                          },
                                          'itemSchema': {
                                            'type': 'tag',
                                            'label': '${item}',
                                            'id': 'u:079b7a76d847',
                                            'displayMode': 'normal',
                                            'style': {
                                              'color': '#eb0d0d'
                                            }
                                          },
                                          'name': 'dealStatus',
                                          'label': '状态',
                                          'id': 'u:a71e84245805',
                                          'placeholder': '-',
                                          'searchable': {
                                            'type': 'select',
                                            'label': '处理状态',
                                            'name': 'dealStatus',
                                            'options': [
                                              {
                                                'label': '报警中',
                                                'value': 0
                                              },
                                              {
                                                'label': '已处理',
                                                'value': 1
                                              },
                                              {
                                                'label': '已恢复',
                                                'value': 2
                                              }
                                            ],
                                            'id': 'u:b095661ebc45',
                                            'multiple': false,
                                            'value': 0
                                          }
                                        },
                                        {
                                          'name': 'dealContent',
                                          'label': '处理内容',
                                          'id': 'LIST:处理内容',
                                          'type': 'text',
                                          'inline': true,
                                          'placeholder': '-',
                                          'popOver': false,
                                          'toggled': false
                                        },
                                        {
                                          'type': 'tpl',
                                          'tpl': '',
                                          'inline': true,
                                          'wrapperComponent': '',
                                          'name': 'dealTime',
                                          'label': '解决时间',
                                          'style': {
                                            'boxShadow': ' 0px 0px 0px 0px transparent'
                                          },
                                          'placeholder': '-',
                                          'id': 'u:d6677c29728f',
                                          'toggled': false
                                        },
                                        {
                                          'type': 'operation',
                                          'label': '操作',
                                          'buttons': [
                                            {
                                              'type': 'button',
                                              'label': '处理',
                                              'onEvent': {
                                                'click': {
                                                  'actions': [
                                                    {
                                                      'ignoreError': false,
                                                      'actionType': 'drawer',
                                                      'drawer': {
                                                        'closeOnEsc': true,
                                                        'size': 'md',
                                                        'type': 'drawer',
                                                        'closeOnOutside': true,
                                                        'title': '编辑',
                                                        'body': [
                                                          {
                                                            'name': '报警记录',
                                                            'id': 'u:203cbc4fd268',
                                                            'type': 'form',
                                                            'title': '表单',
                                                            'body': [
                                                              {
                                                                'name': 'id',
                                                                'label': '数据id',
                                                                'id': 'UPDATE:数据id',
                                                                'type': 'input-text',
                                                                'hidden': true
                                                              },
                                                              {
                                                                'name': 'dealContent',
                                                                'label': '处理内容',
                                                                'id': 'u:c73273ccf2ef',
                                                                'type': 'textarea'
                                                              }
                                                            ],
                                                            'api': {
                                                              'url': '/gather/device/alarm/deal',
                                                              'method': 'post',
                                                              'requestAdaptor': '',
                                                              'adaptor': '',
                                                              'messages': {
                                                                'success': '更新成功',
                                                                'failed': '更新失败'
                                                              },
                                                              'dataType': 'json',
                                                              'data': {
                                                                '&': '$$',
                                                                'objectId': '${objectId}'
                                                              }
                                                            },
                                                            'onEvent': {
                                                              'submitSucc': {
                                                                'weight': 0,
                                                                'actions': [
                                                                  {
                                                                    'componentId': 'DeviceAlarm:table',
                                                                    'ignoreError': false,
                                                                    'actionType': 'reload',
                                                                    'args': {
                                                                      'resetPage': true
                                                                    }
                                                                  }
                                                                ]
                                                              }
                                                            }
                                                          }
                                                        ],
                                                        'showCloseButton': true,
                                                        'showErrorMsg': true,
                                                        'showLoading': true,
                                                        'className': 'app-popover',
                                                        'id': 'u:9ba0f77d4e62'
                                                      }
                                                    }
                                                  ]
                                                }
                                              },
                                              'id': 'DeviceAlarm:update',
                                              'level': 'link'
                                            },
                                            {
                                              'type': 'button',
                                              'label': '删除',
                                              'onEvent': {
                                                'click': {
                                                  'actions': [
                                                    {
                                                      'ignoreError': false,
                                                      'actionType': 'ajax',
                                                      'outputVar': 'responseResult',
                                                      'options': {
                                                        'silent': false
                                                      },
                                                      'api': {
                                                        'url': '/gather/device/alarm/delete?id=${id}&objectId=${objectId}',
                                                        'method': 'post',
                                                        'requestAdaptor': 'var id = api.data.id;\r\napi.data=[];\r\napi.data[0]=id;\r\nreturn api;',
                                                        'adaptor': '',
                                                        'messages': {},
                                                        'dataType': 'json',
                                                        'replaceData': false,
                                                        'data': {
                                                          '&': '$$'
                                                        }
                                                      }
                                                    },
                                                    {
                                                      'componentId': 'DeviceAlarm:table',
                                                      'ignoreError': false,
                                                      'actionType': 'reload',
                                                      'args': {
                                                        'resetPage': true
                                                      }
                                                    }
                                                  ]
                                                }
                                              },
                                              'id': 'DeviceAlarm:delete',
                                              'level': 'link',
                                              'confirmText': '数据删除后无法恢复，请确认!'
                                            }
                                          ],
                                          'id': 'u:3ba1b5bedd76'
                                        }
                                      ],
                                      'id': 'DeviceAlarm:table',
                                      'perPageAvailable': [
                                        10,
                                        20
                                      ],
                                      'messages': {},
                                      'filterSettingSource': [
                                        'objectCode',
                                        'objectName',
                                        'tableName',
                                        'id',
                                        'createTime'
                                      ],
                                      'perPageField': 'pageSize',
                                      'pageField': 'pageNum',
                                      'features': [
                                        'filter'
                                      ],
                                      'itemActions': [],
                                      'onEvent': {},
                                      'footable': false,
                                      'alwaysShowPagination': true,
                                      'name': '表格2'
                                    }
                                  ],
                                  'id': 'u:2ad6ab927917'
                                },
                                {
                                  'title': '告警规则',
                                  'body': [
                                    {
                                      'type': 'crud',
                                      'draggable': false,
                                      'api': {
                                        'method': 'get',
                                        'url': '/gather/trigger/info/page',
                                        'messages': {},
                                        'requestAdaptor': '',
                                        'adaptor': '',
                                        'dataType': 'json',
                                        'data': {
                                          '&': '$$',
                                          'triggerObjectId': '${id}'
                                        }
                                      },
                                      'perPage': 10,
                                      'keepItemSelectionOnPageChange': true,
                                      'maxKeepItemSelectionLength': 11,
                                      'labelTpl': '${id}',
                                      'autoGenerateFilter': {
                                        'columnsNum': 4,
                                        'showBtnToolbar': false
                                      },
                                      'bulkActions': [],
                                      'quickSaveApi': '',
                                      'quickSaveItemApi': '',
                                      'filterTogglable': true,
                                      'headerToolbar': [
                                        'bulkActions',
                                        'export-excel',
                                        {
                                          'type': 'button',
                                          'label': '新增',
                                          'icon': 'fa fa-plus pull-left',
                                          'id': 'AlarmRule:add',
                                          'onEvent': {
                                            'click': {
                                              'actions': [
                                                {
                                                  'ignoreError': false,
                                                  'actionType': 'drawer',
                                                  'drawer': {
                                                    'closeOnEsc': true,
                                                    'size': 'md',
                                                    'type': 'drawer',
                                                    'closeOnOutside': true,
                                                    'title': '新增',
                                                    'body': [
                                                      {
                                                        'type': 'form',
                                                        'title': '表单',
                                                        'body': [
                                                          {
                                                            'name': 'triggerName',
                                                            'label': '规则名称',
                                                            'id': 'CREATE:规则名称',
                                                            'type': 'input-text'
                                                          },
                                                          {
                                                            'name': 'projectId',
                                                            'label': '所属项目',
                                                            'id': 'CREATE:所属项目',
                                                            'type': 'tree-select',
                                                            'multiple': false,
                                                            'onEvent': {},
                                                            'enableNodePath': false,
                                                            'showIcon': true,
                                                            'initiallyOpen': true,
                                                            'source': {
                                                              'url': '/system/project/info/projectAllTree',
                                                              'method': 'get'
                                                            },
                                                            'labelField': 'projectName',
                                                            'valueField': 'id',
                                                            'required': true
                                                          },
                                                          {
                                                            'name': 'status',
                                                            'label': '状态',
                                                            'id': 'u:67239c2b989d',
                                                            'type': 'switch',
                                                            'option': '',
                                                            'falseValue': 0,
                                                            'trueValue': 1,
                                                            'value': 0,
                                                            'hidden': true
                                                          },
                                                          {
                                                            'name': 'remark',
                                                            'label': '备注',
                                                            'id': 'CREATE:备注',
                                                            'type': 'input-text'
                                                          },
                                                          {
                                                            'name': 'sort',
                                                            'label': '排序',
                                                            'id': 'u:09c6a3e10435',
                                                            'type': 'input-number',
                                                            'keyboard': true,
                                                            'value': 1
                                                          }
                                                        ],
                                                        'api': {
                                                          'url': '/gather/trigger/info/add',
                                                          'method': 'post',
                                                          'requestAdaptor': '',
                                                          'adaptor': '',
                                                          'messages': {
                                                            'success': '创建成功',
                                                            'failed': '创建失败'
                                                          },
                                                          'dataType': 'json'
                                                        },
                                                        'id': 'u:f141fe10696a',
                                                        'onEvent': {
                                                          'submitSucc': {
                                                            'weight': 0,
                                                            'actions': [
                                                              {
                                                                'componentId': 'AlarmRule:table',
                                                                'ignoreError': false,
                                                                'actionType': 'reload',
                                                                'args': {
                                                                  'resetPage': true
                                                                }
                                                              }
                                                            ]
                                                          }
                                                        }
                                                      }
                                                    ],
                                                    'showCloseButton': true,
                                                    'showErrorMsg': true,
                                                    'showLoading': true,
                                                    'className': 'app-popover',
                                                    'id': 'u:9decfaedac64'
                                                  }
                                                }
                                              ]
                                            }
                                          },
                                          'primary': true
                                        },
                                        {
                                          'type': 'tpl',
                                          'className': 'v-middle',
                                          'id': 'u:1b06e197002e',
                                          'tpl': '<p>当前有 ${total} 条数据。</p>'
                                        },
                                        {
                                          'type': 'link',
                                          'href': 'https://www.baidu.com',
                                          'body': '占个位置吧',
                                          'htmlTarget': '_parent',
                                          'className': 'v-middle',
                                          'id': 'u:d0a3349c5766',
                                          'blank': true,
                                          'disabled': true
                                        },
                                        {
                                          'type': 'columns-toggler',
                                          'align': 'right'
                                        },
                                        {
                                          'type': 'drag-toggler',
                                          'align': 'right'
                                        },
                                        {
                                          'type': 'pagination',
                                          'align': 'right'
                                        }
                                      ],
                                      'footerToolbar': [
                                        {
                                          'type': 'statistics'
                                        },
                                        {
                                          'type': 'pagination',
                                          'layout': 'perPage,pager,go'
                                        }
                                      ],
                                      'columns': [
                                        {
                                          'name': 'triggerName',
                                          'label': '规则名称',
                                          'id': 'LIST:规则名称',
                                          'type': 'text',
                                          'searchable': {
                                            'clearable': 'true',
                                            'id': 'u:cc43ed3835ef'
                                          }
                                        },
                                        {
                                          'name': 'status',
                                          'label': '状态',
                                          'id': 'u:7e7ff8996c61',
                                          'type': 'switch',
                                          'option': '',
                                          'falseValue': 0,
                                          'trueValue': 1,
                                          'onEvent': {
                                            'change': {
                                              'weight': 0,
                                              'actions': [
                                                {
                                                  'ignoreError': false,
                                                  'outputVar': 'responseResult',
                                                  'actionType': 'ajax',
                                                  'options': {},
                                                  'api': {
                                                    'url': '/gather/trigger/info/updateStatus',
                                                    'method': 'post',
                                                    'requestAdaptor': '',
                                                    'adaptor': '',
                                                    'messages': {},
                                                    'data': {
                                                      '&': '$$',
                                                      'id': '${id}'
                                                    },
                                                    'dataType': 'json'
                                                  }
                                                }
                                              ]
                                            }
                                          }
                                        },
                                        {
                                          'name': 'createTime',
                                          'label': '创建时间',
                                          'id': 'LIST:创建时间',
                                          'type': 'text'
                                        },
                                        {
                                          'name': 'sort',
                                          'label': '排序',
                                          'id': 'LIST:排序',
                                          'type': 'text'
                                        },
                                        {
                                          'name': 'remark',
                                          'label': '备注',
                                          'id': 'LIST:备注',
                                          'type': 'text'
                                        },
                                        {
                                          'type': 'operation',
                                          'label': '操作',
                                          'buttons': [
                                            {
                                              'type': 'button',
                                              'label': '报警规则',
                                              'onEvent': {
                                                'click': {
                                                  'actions': [
                                                    {
                                                      'ignoreError': false,
                                                      'actionType': 'dialog',
                                                      'dialog': {
                                                        'size': 'lg',
                                                        'type': 'dialog',
                                                        'title': '报警规则配置',
                                                        'body': [
                                                          {
                                                            'type': 'form',
                                                            'title': '表单',
                                                            'body': [
                                                              {
                                                                'id': 'u:ac04c8b2a7d7',
                                                                'type': 'fieldset',
                                                                'title': '基础配置',
                                                                'body': [
                                                                  {
                                                                    'name': 'triggerName',
                                                                    'label': '规则名称',
                                                                    'id': 'UPDATE:规则名称',
                                                                    'type': 'input-text'
                                                                  },
                                                                  {
                                                                    'name': 'id',
                                                                    'label': '触发器Id',
                                                                    'id': 'UPDATE:所属项目',
                                                                    'type': 'input-text',
                                                                    'hidden': true,
                                                                    'required': true,
                                                                    'value': ''
                                                                  },
                                                                  {
                                                                    'name': 'projectId',
                                                                    'label': '所属项目',
                                                                    'id': 'UPDATE:所属项目',
                                                                    'type': 'input-text',
                                                                    'hidden': true,
                                                                    'required': true
                                                                  },
                                                                  {
                                                                    'name': 'status',
                                                                    'label': '状态',
                                                                    'id': 'u:f81a71a40fc2',
                                                                    'type': 'switch',
                                                                    'option': '',
                                                                    'falseValue': 0,
                                                                    'trueValue': 1,
                                                                    'value': 0,
                                                                    'hidden': true
                                                                  },
                                                                  {
                                                                    'name': 'sort',
                                                                    'label': '排序',
                                                                    'id': 'u:92c81c844665',
                                                                    'type': 'input-number',
                                                                    'keyboard': true,
                                                                    'required': true
                                                                  },
                                                                  {
                                                                    'name': 'remark',
                                                                    'label': '备注',
                                                                    'id': 'UPDATE:备注',
                                                                    'type': 'input-text'
                                                                  }
                                                                ],
                                                                'collapsable': true
                                                              },
                                                              {
                                                                'id': 'u:70c088b997f7',
                                                                'type': 'fieldset',
                                                                'title': '报警规则',
                                                                'collapsable': true,
                                                                'body': [
                                                                  {
                                                                    'type': 'radios',
                                                                    'label': '触发器类型',
                                                                    'name': 'triggerType',
                                                                    'options': [
                                                                      {
                                                                        'label': '设备',
                                                                        'value': 1
                                                                      },
                                                                      {
                                                                        'label': '模板',
                                                                        'value': 2
                                                                      }
                                                                    ],
                                                                    'id': 'u:10798a100867',
                                                                    'value': 1,
                                                                    'required': true
                                                                  },
                                                                  {
                                                                    'type': 'tree-select',
                                                                    'label': '设备源',
                                                                    'name': 'triggerObjectId',
                                                                    'id': 'u:385f6470cc83',
                                                                    'onEvent': {},
                                                                    'multiple': false,
                                                                    'enableNodePath': false,
                                                                    'showIcon': true,
                                                                    'initiallyOpen': true,
                                                                    'source': {
                                                                      'url': '/gather/trigger/info/getDeviceSource?triggerType=${triggerType}',
                                                                      'method': 'get'
                                                                    },
                                                                    'required': true
                                                                  },
                                                                  {
                                                                    'type': 'select',
                                                                    'label': '设备属性',
                                                                    'name': 'triggerAttrId',
                                                                    'id': 'u:51708f0109b8',
                                                                    'required': true,
                                                                    'multiple': false,
                                                                    'mode': 'inline',
                                                                    'size': 'sm',
                                                                    'staticClassName': '',
                                                                    'className': 'm-l-xl',
                                                                    'source': {
                                                                      'url': '/gather/trigger/info/getDeviceAttrSource?triggerObjectId=${triggerObjectId}&triggerType=${triggerType}',
                                                                      'method': 'get'
                                                                    }
                                                                  },
                                                                  {
                                                                    'type': 'select',
                                                                    'label': '',
                                                                    'name': 'triggerCondition',
                                                                    'options': [
                                                                      {
                                                                        'label': '开关OFF',
                                                                        'value': 0
                                                                      },
                                                                      {
                                                                        'label': '开关ON',
                                                                        'value': 1
                                                                      },
                                                                      {
                                                                        'label': '数值小于A',
                                                                        'value': 2
                                                                      },
                                                                      {
                                                                        'label': '数值大于B',
                                                                        'value': 3
                                                                      },
                                                                      {
                                                                        'label': '数值大于A且小于B',
                                                                        'value': 4
                                                                      },
                                                                      {
                                                                        'label': '数值小于A或大于B',
                                                                        'value': 5
                                                                      },
                                                                      {
                                                                        'label': '数值等于A',
                                                                        'value': 6
                                                                      }
                                                                    ],
                                                                    'id': 'u:190849f7ecf6',
                                                                    'value': 2,
                                                                    'multiple': false,
                                                                    'required': true,
                                                                    'mode': 'inline',
                                                                    'size': 'sm'
                                                                  },
                                                                  {
                                                                    'name': 'triggerValueA',
                                                                    'label': '',
                                                                    'id': 'UPDATE:A值',
                                                                    'type': 'input-text',
                                                                    'hiddenOn': '${triggerCondition!=2&&triggerCondition!=4&&triggerCondition!=5&&triggerCondition!=6}',
                                                                    'mode': 'inline',
                                                                    'placeholder': 'A值'
                                                                  },
                                                                  {
                                                                    'name': 'triggerValueB',
                                                                    'label': '',
                                                                    'id': 'UPDATE:B值',
                                                                    'type': 'input-text',
                                                                    'hiddenOn': '${triggerCondition!=3&&triggerCondition!=4&&triggerCondition!=5}',
                                                                    'mode': 'inline',
                                                                    'placeholder': 'B值'
                                                                  }
                                                                ]
                                                              },
                                                              {
                                                                'type': 'fieldset',
                                                                'title': '通知设置',
                                                                'collapsable': true,
                                                                'body': [
                                                                  {
                                                                    'type': 'switch',
                                                                    'label': '报警',
                                                                    'option': '',
                                                                    'name': 'alarmStatus',
                                                                    'falseValue': 0,
                                                                    'trueValue': 1,
                                                                    'id': 'u:841c935e0f94',
                                                                    'value': 1,
                                                                    'hidden': true
                                                                  },
                                                                  {
                                                                    'type': 'select',
                                                                    'label': '报警联系人',
                                                                    'name': 'alarmUserIds',
                                                                    'id': 'u:4fa9c5beb67f',
                                                                    'required': true,
                                                                    'multiple': true,
                                                                    'checkAll': false,
                                                                    'source': {
                                                                      'url': '/gather/alarm/user/dataSource',
                                                                      'method': 'get'
                                                                    }
                                                                  },
                                                                  {
                                                                    'type': 'select',
                                                                    'label': '报警类型',
                                                                    'name': 'alarmType',
                                                                    'options': [
                                                                      {
                                                                        'label': '微信公众号报警',
                                                                        'value': 1
                                                                      },
                                                                      {
                                                                        'label': '邮件报警',
                                                                        'value': 2
                                                                      },
                                                                      {
                                                                        'label': '短信报警',
                                                                        'value': 3
                                                                      },
                                                                      {
                                                                        'label': '电话报警',
                                                                        'value': 4
                                                                      }
                                                                    ],
                                                                    'id': 'u:09d234b0617f',
                                                                    'multiple': true,
                                                                    'required': true,
                                                                    'checkAll': false
                                                                  },
                                                                  {
                                                                    'type': 'textarea',
                                                                    'label': '报警推送内容',
                                                                    'name': 'alarmContent',
                                                                    'minRows': 3,
                                                                    'maxRows': 20,
                                                                    'required': true,
                                                                    'value': '开始报警',
                                                                    'id': 'u:da34c9cc8fb3'
                                                                  },
                                                                  {
                                                                    'type': 'textarea',
                                                                    'label': '回复推送内容',
                                                                    'name': 'alarmNormalContent',
                                                                    'minRows': 3,
                                                                    'maxRows': 20,
                                                                    'required': true,
                                                                    'value': '报警恢复',
                                                                    'id': 'u:35a3674529de'
                                                                  }
                                                                ],
                                                                'id': 'u:e69e4d607f8a'
                                                              }
                                                            ],
                                                            'id': 'u:203cbc4fd268',
                                                            'api': {
                                                              'url': '/gather/trigger/info/config',
                                                              'method': 'post',
                                                              'requestAdaptor': '',
                                                              'adaptor': 'response.data={};\r\nreturn response;',
                                                              'messages': {
                                                                'success': '更新成功',
                                                                'failed': '更新失败'
                                                              },
                                                              'dataType': 'json',
                                                              'data': {
                                                                '&': '$$',
                                                                'objectId': '${objectId}'
                                                              }
                                                            },
                                                            'onEvent': {
                                                              'submitSucc': {
                                                                'weight': 0,
                                                                'actions': [
                                                                  {
                                                                    'componentId': 'AlarmRule:table',
                                                                    'ignoreError': false,
                                                                    'actionType': 'reload',
                                                                    'args': {
                                                                      'resetPage': true
                                                                    }
                                                                  }
                                                                ]
                                                              }
                                                            },
                                                            'name': '报警规则',
                                                            'initApi': {
                                                              'url': '/gather/trigger/info/detail?id=${id}',
                                                              'method': 'get',
                                                              'requestAdaptor': '',
                                                              'adaptor': 'return {\r\n  status:0,\r\n  msg:"请求成功",\r\n  data:response.data\r\n}',
                                                              'messages': {},
                                                              'dataType': 'json',
                                                              'replaceData': false,
                                                              'responseData': {
                                                                '&': '$$'
                                                              }
                                                            }
                                                          }
                                                        ],
                                                        'showCloseButton': true,
                                                        'showErrorMsg': true,
                                                        'showLoading': true,
                                                        'className': 'app-popover',
                                                        'id': 'u:9ba0f77d4e62',
                                                        'resizable': false
                                                      }
                                                    }
                                                  ]
                                                }
                                              },
                                              'id': 'AlarmRule:update',
                                              'level': 'link'
                                            },
                                            {
                                              'type': 'button',
                                              'label': '删除',
                                              'onEvent': {
                                                'click': {
                                                  'actions': [
                                                    {
                                                      'ignoreError': false,
                                                      'actionType': 'ajax',
                                                      'outputVar': 'responseResult',
                                                      'options': {
                                                        'silent': false
                                                      },
                                                      'api': {
                                                        'url': '/gather/trigger/info/delete?id=${id}&objectId=${objectId}',
                                                        'method': 'post',
                                                        'requestAdaptor': 'var id = api.data.id;\r\napi.data=[];\r\napi.data[0]=id;\r\nreturn api;',
                                                        'adaptor': '',
                                                        'messages': {},
                                                        'dataType': 'json',
                                                        'replaceData': false,
                                                        'data': {
                                                          '&': '$$'
                                                        }
                                                      }
                                                    },
                                                    {
                                                      'componentId': 'AlarmRule:table',
                                                      'ignoreError': false,
                                                      'actionType': 'reload',
                                                      'args': {
                                                        'resetPage': true
                                                      }
                                                    }
                                                  ]
                                                }
                                              },
                                              'id': 'AlarmRule:delete',
                                              'level': 'link',
                                              'confirmText': '数据删除后无法恢复，请确认!'
                                            }
                                          ],
                                          'id': 'u:3ba1b5bedd76'
                                        }
                                      ],
                                      'id': 'AlarmRule:table',
                                      'perPageAvailable': [
                                        10,
                                        20
                                      ],
                                      'messages': {},
                                      'filterSettingSource': [
                                        'objectCode',
                                        'objectName',
                                        'tableName',
                                        'id',
                                        'createTime'
                                      ],
                                      'perPageField': 'pageSize',
                                      'pageField': 'pageNum',
                                      'features': [
                                        'filter'
                                      ],
                                      'itemActions': [],
                                      'onEvent': {},
                                      'footable': false,
                                      'alwaysShowPagination': true,
                                      'name': '表格2'
                                    }
                                  ],
                                  'id': 'u:7bd0978c6dd5'
                                }
                              ],
                              'tabsMode': 'radio'
                            }
                          ],
                          'id': 'u:76be2137fd77',
                          'hiddenOn': "${permissions!='*:*:*'&&!ARRAYINCLUDES(permissions,'gather:deviceAlarm:list\t')}"
                        },
                        {
                          'title': '组态',
                          'body': [
                            {
                              'type': 'tabs',
                              'tabs': [
                                {
                                  'title': '独立组态',
                                  'body': [
                                    {
                                      'type': 'crud',
                                      'syncLocation': false,
                                      'api': {
                                        'method': 'get',
                                        'url': '/gather/stage/info/page?stageType=1&sampleId=${sampleId}'
                                      },
                                      'columns': [
                                        {
                                          'name': 'projectId',
                                          'label': '所属项目',
                                          'type': 'mapping',
                                          'id': 'LIST:所属项目',
                                          'searchable': {
                                            'name': 'projectId',
                                            'type': 'tree-select',
                                            'label': '所属项目',
                                            'multiple': false,
                                            'onEvent': {},
                                            'enableNodePath': false,
                                            'showIcon': true,
                                            'initiallyOpen': true,
                                            'source': {
                                              'url': '/system/project/info/projectAllTree',
                                              'method': 'get'
                                            },
                                            'labelField': 'projectName',
                                            'valueField': 'id',
                                            'id': 'u:40bd28cde89c',
                                            'searchable': true
                                          },
                                          'source': {
                                            'url': '/system/project/info/dataSource',
                                            'method': 'get'
                                          }
                                        },
                                        {
                                          'type': 'text',
                                          'label': '组态名称',
                                          'name': 'stageName',
                                          'id': 'u:100228ca74eb',
                                          'searchable': true
                                        },
                                        {
                                          'name': 'resolutionWidth',
                                          'label': '分辨率宽度',
                                          'type': 'text',
                                          'id': 'u:4391eed23b25'
                                        },
                                        {
                                          'type': 'text',
                                          'label': '分辨率高度',
                                          'name': 'resolutionHeight',
                                          'id': 'u:676014e9bdaf'
                                        },
                                        {
                                          'type': 'image',
                                          'label': '图片',
                                          'name': 'stageBase64',
                                          'id': 'u:63f269eac847',
                                          'placeholder': '-',
                                          'enlargeAble': true
                                        },
                                        {
                                          'type': 'switch',
                                          'label': '状态',
                                          'name': 'status',
                                          'id': 'u:7e7ff8996c61',
                                          'option': '',
                                          'falseValue': 0,
                                          'trueValue': 1,
                                          'onEvent': {
                                            'change': {
                                              'weight': 0,
                                              'actions': [
                                                {
                                                  'ignoreError': false,
                                                  'outputVar': 'responseResult',
                                                  'actionType': 'ajax',
                                                  'options': {},
                                                  'api': {
                                                    'url': '/gather/stage/info/updateStatus',
                                                    'method': 'post',
                                                    'requestAdaptor': '',
                                                    'adaptor': '',
                                                    'messages': {},
                                                    'data': {
                                                      '&': '$$',
                                                      'id': '${id}'
                                                    },
                                                    'dataType': 'json'
                                                  }
                                                },
                                                {
                                                  'componentId': 'u:48f462f67630',
                                                  'ignoreError': false,
                                                  'actionType': 'reload',
                                                  'args': {
                                                    'resetPage': false
                                                  }
                                                }
                                              ]
                                            }
                                          },
                                          'searchable': {
                                            'type': 'select',
                                            'label': '状态',
                                            'clearable': true,
                                            'name': 'status',
                                            'options': [
                                              {
                                                'label': '启用',
                                                'value': 1
                                              },
                                              {
                                                'label': '禁用',
                                                'value': 0
                                              }
                                            ],
                                            'id': 'u:ace4c43c42fe',
                                            'multiple': false
                                          },
                                          'value': false
                                        },
                                        {
                                          'type': 'operation',
                                          'label': '操作',
                                          'id': 'u:5962c0829fa8',
                                          'buttons': [
                                            {
                                              'type': 'button',
                                              'label': '设计',
                                              'onEvent': {
                                                'click': {
                                                  'actions': [
                                                    {
                                                      'ignoreError': false,
                                                      'actionType': 'url',
                                                      'args': {
                                                        'url': '${window:location[origin]}/static/byzt/index_edit.html',
                                                        'params': {
                                                          'stageId': '${id}',
                                                          'projectId': '${projectId}',
                                                          'type': '1',
                                                          'height': '${resolutionHeight}',
                                                          'width': '${resolutionWidth}',
                                                          'token': '${token}',
                                                          'stageName': '${stageName}'
                                                        }
                                                      }
                                                    }
                                                  ]
                                                }
                                              },
                                              'id': 'u:6546ace0466c',
                                              'level': 'link'
                                            },
                                            {
                                              'type': 'button',
                                              'label': '分享',
                                              'onEvent': {
                                                'click': {
                                                  'actions': [
                                                    {
                                                      'ignoreError': true,
                                                      'actionType': 'dialog',
                                                      'dialog': {
                                                        'type': 'dialog',
                                                        'title': '分享链接',
                                                        'confirmBtnLevel': 'primary',
                                                        'body': [
                                                          {
                                                            'type': 'service',
                                                            'name': '独立组态',
                                                            'id': 'u:b57738ca5179',
                                                            'body': [
                                                              {
                                                                'type': 'tpl',
                                                                'id': 'u:44a34bb76286',
                                                                'tpl': "<span style='word-break:break-all;'>${window:location[origin]}/static/byzt/client_view.html?stageId=${id}&stageName=${stageName}&deviceNo=${dataKeyArray}&type=1&token=${token}</span>",
                                                                'inline': true,
                                                                'wrapperComponent': '',
                                                                'style': {
                                                                  'boxShadow': ' 0px 0px 0px 0px transparent'
                                                                }
                                                              },
                                                              {
                                                                'type': 'flex',
                                                                'className': 'p-1',
                                                                'items': [
                                                                  {
                                                                    'type': 'container',
                                                                    'body': [
                                                                      {
                                                                        'type': 'button',
                                                                        'label': '取消',
                                                                        'id': 'u:a58a4a57f915',
                                                                        'level': 'default',
                                                                        'onEvent': {
                                                                          'click': {
                                                                            'weight': 0,
                                                                            'actions': []
                                                                          }
                                                                        },
                                                                        'close': true,
                                                                        'className': 'mr-2 className-a58a4a57f915',
                                                                        'themeCss': {
                                                                          'className': {
                                                                            'padding-and-margin:default': {
                                                                              'marginTop': '0',
                                                                              'marginRight': '10px',
                                                                              'marginBottom': '0',
                                                                              'marginLeft': '0'
                                                                            }
                                                                          }
                                                                        }
                                                                      },
                                                                      {
                                                                        'type': 'button',
                                                                        'label': '复制',
                                                                        'onEvent': {
                                                                          'click': {
                                                                            'actions': [
                                                                              {
                                                                                'actionType': 'copy',
                                                                                'args': {
                                                                                  'content': '${locationUrl}/static/byzt/client_view.html?stageId=${id}&stageName=${stageName}&deviceNo=${dataKeyArray}&type=1&token=${token}',
                                                                                  'copyFormat': 'text/plain'
                                                                                },
                                                                                'ignoreError': false
                                                                              }
                                                                            ]
                                                                          }
                                                                        },
                                                                        'id': 'u:a06c47b1f3bf',
                                                                        'level': 'primary',
                                                                        'close': true
                                                                      }
                                                                    ],
                                                                    'size': 'xs',
                                                                    'style': {
                                                                      'position': 'static',
                                                                      'display': 'flex',
                                                                      'flex': '1 1 auto',
                                                                      'flexGrow': 1,
                                                                      'flexBasis': 'auto',
                                                                      'boxShadow': ' 0px 0px 0px 0px transparent',
                                                                      'flexWrap': 'nowrap',
                                                                      'justifyContent': 'flex-end',
                                                                      'alignItems': 'flex-end',
                                                                      'marginTop': '20px',
                                                                      'marginRight': '0',
                                                                      'marginBottom': '0',
                                                                      'marginLeft': '0'
                                                                    },
                                                                    'wrapperBody': false,
                                                                    'isFixedHeight': false,
                                                                    'isFixedWidth': false,
                                                                    'id': 'u:6bf826c6feba'
                                                                  }
                                                                ],
                                                                'style': {
                                                                  'position': 'relative',
                                                                  'inset': 'auto',
                                                                  'flexWrap': 'nowrap',
                                                                  'boxShadow': ' 0px 0px 0px 0px transparent'
                                                                },
                                                                'id': 'u:49affa0663ff',
                                                                'isFixedHeight': false,
                                                                'isFixedWidth': false
                                                              }
                                                            ],
                                                            'messages': {},
                                                            'api': {
                                                              'url': '/gather/stage/info/share',
                                                              'method': 'post',
                                                              'messages': {},
                                                              'requestAdaptor': '',
                                                              'adaptor': '',
                                                              'dataType': 'json',
                                                              'data': {
                                                                'type': 1,
                                                                'stageId': '${id}'
                                                              }
                                                            }
                                                          }
                                                        ],
                                                        'id': 'u:6f99a844c371',
                                                        'actions': []
                                                      }
                                                    }
                                                  ]
                                                }
                                              },
                                              'id': 'u:f2bd3b139631',
                                              'level': 'link'
                                            },
                                            {
                                              'type': 'button',
                                              'label': '修改',
                                              'onEvent': {
                                                'click': {
                                                  'actions': [
                                                    {
                                                      'ignoreError': false,
                                                      'actionType': 'drawer',
                                                      'drawer': {
                                                        'type': 'drawer',
                                                        'title': '编辑',
                                                        'body': [
                                                          {
                                                            'type': 'form',
                                                            'id': 'u:35c3af7117a9',
                                                            'title': '表单',
                                                            'body': [
                                                              {
                                                                'type': 'input-text',
                                                                'label': 'id',
                                                                'name': 'id',
                                                                'id': 'u:f7566dd0f2c2',
                                                                'hidden': true
                                                              },
                                                              {
                                                                'type': 'input-text',
                                                                'label': '组态类型',
                                                                'name': 'stageType',
                                                                'id': 'u:a4f9f9082323',
                                                                'value': '1',
                                                                'hidden': true,
                                                                'readOnly': true
                                                              },
                                                              {
                                                                'type': 'tree-select',
                                                                'label': '所属项目',
                                                                'name': 'projectId',
                                                                'id': 'CREATE:所属项目',
                                                                'multiple': false,
                                                                'onEvent': {},
                                                                'enableNodePath': false,
                                                                'showIcon': true,
                                                                'initiallyOpen': true,
                                                                'source': {
                                                                  'url': '/system/project/info/projectAllTree',
                                                                  'method': 'get'
                                                                },
                                                                'labelField': 'projectName',
                                                                'valueField': 'id',
                                                                'required': true,
                                                                'searchable': true
                                                              },
                                                              {
                                                                'type': 'input-text',
                                                                'label': '组态名称',
                                                                'name': 'stageName',
                                                                'id': 'u:eadf3148a116'
                                                              },
                                                              {
                                                                'type': 'input-text',
                                                                'label': '分辨率宽度',
                                                                'name': 'resolutionWidth',
                                                                'id': 'u:ffd2e8dcb42e',
                                                                'mode': 'inline',
                                                                'className': 'ml-7'
                                                              },
                                                              {
                                                                'type': 'input-text',
                                                                'name': 'resolutionHeight',
                                                                'label': 'x 高度',
                                                                'id': 'u:11d8348c9f29',
                                                                'mode': 'inline'
                                                              },
                                                              {
                                                                'type': 'switch',
                                                                'label': '状态',
                                                                'name': 'status',
                                                                'id': 'u:67239c2b989d',
                                                                'option': '',
                                                                'falseValue': 0,
                                                                'trueValue': 1,
                                                                'value': 1
                                                              },
                                                              {
                                                                'type': 'textarea',
                                                                'label': '备注',
                                                                'name': 'stageMemo',
                                                                'id': 'u:0d58c9d42906',
                                                                'minRows': 3,
                                                                'maxRows': 20
                                                              }
                                                            ],
                                                            'name': '独立组态',
                                                            'api': {
                                                              'url': '/gather/stage/info/edit',
                                                              'method': 'post',
                                                              'requestAdaptor': '',
                                                              'adaptor': 'response.data = {};\r\nreturn response;',
                                                              'messages': {},
                                                              'dataType': 'json'
                                                            },
                                                            'onEvent': {
                                                              'submitSucc': {
                                                                'weight': 0,
                                                                'actions': [
                                                                  {
                                                                    'componentId': 'u:48f462f67630',
                                                                    'ignoreError': false,
                                                                    'actionType': 'reload',
                                                                    'args': {
                                                                      'resetPage': true
                                                                    }
                                                                  }
                                                                ]
                                                              }
                                                            }
                                                          }
                                                        ],
                                                        'className': 'app-popover',
                                                        'id': 'u:74058ed82f89',
                                                        'resizable': false,
                                                        'size': 'lg',
                                                        'closeOnOutside': true
                                                      }
                                                    }
                                                  ]
                                                }
                                              },
                                              'id': 'u:192a4a1d220c',
                                              'level': 'link'
                                            },
                                            {
                                              'type': 'button',
                                              'label': '复制',
                                              'onEvent': {
                                                'click': {
                                                  'actions': [
                                                    {
                                                      'ignoreError': false,
                                                      'actionType': 'ajax',
                                                      'outputVar': 'responseResult',
                                                      'options': {},
                                                      'api': {
                                                        'url': '/gather/stage/info/copy',
                                                        'method': 'post',
                                                        'requestAdaptor': '',
                                                        'adaptor': '',
                                                        'messages': {},
                                                        'data': {
                                                          '&': '$$',
                                                          'id': '${id}'
                                                        }
                                                      }
                                                    },
                                                    {
                                                      'componentId': 'u:48f462f67630',
                                                      'ignoreError': false,
                                                      'actionType': 'reload',
                                                      'args': {
                                                        'resetPage': false
                                                      }
                                                    }
                                                  ]
                                                }
                                              },
                                              'id': 'u:2fe1b08abd9f',
                                              'level': 'link'
                                            },
                                            {
                                              'type': 'button',
                                              'label': '删除',
                                              'onEvent': {
                                                'click': {
                                                  'actions': [
                                                    {
                                                      'ignoreError': false,
                                                      'outputVar': 'responseResult',
                                                      'actionType': 'ajax',
                                                      'options': {},
                                                      'api': {
                                                        'url': '/gather/stage/info/delete?id=${id}&objectId=${objectId}',
                                                        'method': 'post',
                                                        'requestAdaptor': 'var id = api.data.id;\r\napi.data=[];\r\napi.data[0]=id;\r\nreturn api;',
                                                        'adaptor': '',
                                                        'messages': {},
                                                        'dataType': 'json',
                                                        'data': {
                                                          '&': '$$'
                                                        }
                                                      }
                                                    },
                                                    {
                                                      'ignoreError': false,
                                                      'actionType': 'reload',
                                                      'componentId': 'u:48f462f67630',
                                                      'args': {
                                                        'resetPage': true
                                                      }
                                                    }
                                                  ]
                                                }
                                              },
                                              'id': 'u:20d3b44b8d2e',
                                              'level': 'link'
                                            }
                                          ]
                                        }
                                      ],
                                      'bulkActions': [],
                                      'itemActions': [],
                                      'features': [],
                                      'filterColumnCount': 3,
                                      'id': 'u:48f462f67630',
                                      'perPageAvailable': [
                                        10,
                                        20
                                      ],
                                      'name': '独立组态',
                                      'messages': {},
                                      'headerToolbar': [
                                        {
                                          'type': 'bulk-actions',
                                          'align': 'left'
                                        },
                                        {
                                          'type': 'export-excel',
                                          'tpl': '内容',
                                          'wrapperComponent': '',
                                          'id': 'u:5377ddbfd09a'
                                        },
                                        {
                                          'type': 'columns-toggler',
                                          'tpl': '内容',
                                          'wrapperComponent': '',
                                          'align': 'right'
                                        },
                                        {
                                          'type': 'drag-toggler',
                                          'tpl': '内容',
                                          'wrapperComponent': '',
                                          'align': 'right'
                                        },
                                        {
                                          'type': 'pagination',
                                          'tpl': '内容',
                                          'wrapperComponent': '',
                                          'align': 'right'
                                        }
                                      ],
                                      'alwaysShowPagination': true,
                                      'perPage': 10,
                                      'maxKeepItemSelectionLength': 11,
                                      'keepItemSelectionOnPageChange': true,
                                      'pageField': 'pageNum',
                                      'perPageField': 'pageSize',
                                      'labelTpl': '${id}',
                                      'filter': null,
                                      'filterTogglable': true,
                                      'autoGenerateFilter': {
                                        'columnsNum': 4,
                                        'showBtnToolbar': false
                                      },
                                      'footerToolbar': [
                                        {
                                          'type': 'statistics',
                                          'tpl': '内容',
                                          'wrapperComponent': ''
                                        },
                                        {
                                          'type': 'pagination',
                                          'tpl': '内容',
                                          'wrapperComponent': '',
                                          'layout': 'perpage,pager,go'
                                        }
                                      ]
                                    }
                                  ],
                                  'id': 'u:23882ba7f486'
                                },
                                {
                                  'title': '模板组态',
                                  'body': [
                                    {
                                      'type': 'crud',
                                      'id': 'u:48f462f67630',
                                      'syncLocation': false,
                                      'api': {
                                        'method': 'get',
                                        'url': '/gather/stage/info/page?stageType=2&sampleId=${sampleId}',
                                        'messages': {},
                                        'requestAdaptor': '',
                                        'adaptor': ''
                                      },
                                      'columns': [
                                        {
                                          'name': 'projectId',
                                          'label': '所属项目',
                                          'type': 'mapping',
                                          'id': 'LIST:所属项目',
                                          'searchable': {
                                            'name': 'projectId',
                                            'type': 'tree-select',
                                            'label': '所属项目',
                                            'multiple': false,
                                            'onEvent': {},
                                            'enableNodePath': false,
                                            'showIcon': true,
                                            'initiallyOpen': true,
                                            'source': {
                                              'url': '/system/project/info/projectAllTree',
                                              'method': 'get'
                                            },
                                            'labelField': 'projectName',
                                            'valueField': 'id',
                                            'id': 'u:40bd28cde89c',
                                            'searchable': true
                                          },
                                          'source': {
                                            'url': '/system/project/info/dataSource',
                                            'method': 'get'
                                          }
                                        },
                                        {
                                          'name': 'stageName',
                                          'label': '组态名称',
                                          'type': 'text',
                                          'id': 'u:100228ca74eb',
                                          'searchable': true
                                        },
                                        {
                                          'type': 'text',
                                          'label': '分辨率宽度',
                                          'name': 'resolutionWidth',
                                          'id': 'u:4391eed23b25'
                                        },
                                        {
                                          'name': 'resolutionHeight',
                                          'label': '分辨率高度',
                                          'type': 'text',
                                          'id': 'u:676014e9bdaf'
                                        },
                                        {
                                          'type': 'image',
                                          'label': '图片',
                                          'name': 'stageBase64',
                                          'id': 'u:63f269eac847',
                                          'placeholder': '-',
                                          'enlargeAble': true
                                        },
                                        {
                                          'type': 'switch',
                                          'label': '状态',
                                          'name': 'status',
                                          'id': 'u:7e7ff8996c61',
                                          'option': '',
                                          'falseValue': 0,
                                          'trueValue': 1,
                                          'onEvent': {
                                            'change': {
                                              'weight': 0,
                                              'actions': [
                                                {
                                                  'ignoreError': false,
                                                  'outputVar': 'responseResult',
                                                  'actionType': 'ajax',
                                                  'options': {},
                                                  'api': {
                                                    'url': '/gather/stage/info/updateStatus',
                                                    'method': 'post',
                                                    'requestAdaptor': '',
                                                    'adaptor': '',
                                                    'messages': {},
                                                    'data': {
                                                      '&': '$$',
                                                      'id': '${id}'
                                                    },
                                                    'dataType': 'json'
                                                  }
                                                },
                                                {
                                                  'componentId': 'u:48f462f67630',
                                                  'ignoreError': false,
                                                  'actionType': 'reload',
                                                  'args': {
                                                    'resetPage': false
                                                  }
                                                }
                                              ]
                                            }
                                          },
                                          'searchable': {
                                            'type': 'select',
                                            'label': '状态',
                                            'clearable': true,
                                            'name': 'status',
                                            'options': [
                                              {
                                                'label': '启用',
                                                'value': 1
                                              },
                                              {
                                                'label': '禁用',
                                                'value': 0
                                              }
                                            ],
                                            'id': 'u:ace4c43c42fe',
                                            'multiple': false
                                          },
                                          'value': false
                                        },
                                        {
                                          'type': 'operation',
                                          'label': '操作',
                                          'id': 'u:5962c0829fa8',
                                          'buttons': [
                                            {
                                              'type': 'button',
                                              'label': '设计',
                                              'onEvent': {
                                                'click': {
                                                  'actions': [
                                                    {
                                                      'ignoreError': false,
                                                      'actionType': 'url',
                                                      'args': {
                                                        'url': '${window:location[origin]}/static/byzt/index_edit.html',
                                                        'params': {
                                                          'stageId': '${id}',
                                                          'projectId': '${projectId}',
                                                          'stageName': '${stageName}',
                                                          'type': '2',
                                                          'height': '${resolutionHeight}',
                                                          'width': '${resolutionWidth}',
                                                          'token': '${token}',
                                                          'sampleId': '${sampleId}'
                                                        }
                                                      }
                                                    }
                                                  ]
                                                }
                                              },
                                              'id': 'u:6546ace0466c',
                                              'level': 'link'
                                            },
                                            {
                                              'type': 'button',
                                              'label': '分享',
                                              'onEvent': {
                                                'click': {
                                                  'actions': [
                                                    {
                                                      'ignoreError': true,
                                                      'actionType': 'dialog',
                                                      'dialog': {
                                                        'type': 'dialog',
                                                        'title': '选择设备',
                                                        'body': [
                                                          {
                                                            'type': 'form',
                                                            'name': '独立组态',
                                                            'id': 'u:35c3af7117a9',
                                                            'body': [
                                                              {
                                                                'type': 'input-text',
                                                                'id': 'u:f7566dd0f2c2',
                                                                'label': 'id',
                                                                'name': 'id',
                                                                'hidden': true
                                                              },
                                                              {
                                                                'type': 'input-text',
                                                                'id': 'u:7a8484dafb4e',
                                                                'label': '项目id',
                                                                'name': 'projectId',
                                                                'hidden': true
                                                              },
                                                              {
                                                                'type': 'input-text',
                                                                'label': '模板id',
                                                                'name': 'sampleId',
                                                                'id': 'u:e937ba65ddea',
                                                                'hidden': true
                                                              },
                                                              {
                                                                'type': 'select',
                                                                'label': '模板下设备',
                                                                'name': 'devId',
                                                                'id': 'u:32f81607e6f6',
                                                                'multiple': false,
                                                                'source': {
                                                                  'url': '/gather/stage/info/deviceList?projectId=${projectId}&sampleId=${sampleId}',
                                                                  'method': 'get',
                                                                  'requestAdaptor': '',
                                                                  'adaptor': '',
                                                                  'messages': {}
                                                                },
                                                                'labelField': 'deviceName',
                                                                'valueField': 'id',
                                                                'clearable': true,
                                                                'onEvent': {
                                                                  'change': {
                                                                    'weight': 0,
                                                                    'actions': [
                                                                      {
                                                                        'componentId': 'u:be4729df26aa',
                                                                        'ignoreError': false,
                                                                        'actionType': 'setValue',
                                                                        'args': {
                                                                          'value': '${event.data.selectedItems.deviceNo}'
                                                                        }
                                                                      }
                                                                    ]
                                                                  }
                                                                },
                                                                'required': true
                                                              },
                                                              {
                                                                'type': 'input-text',
                                                                'label': '设备编号',
                                                                'name': 'deviceNo',
                                                                'id': 'u:be4729df26aa',
                                                                'onEvent': {},
                                                                'hidden': false,
                                                                'readOnly': true
                                                              }
                                                            ],
                                                            'title': '表单',
                                                            'api': {
                                                              'url': '/gather/stage/info/share',
                                                              'method': 'post',
                                                              'messages': {},
                                                              'requestAdaptor': '',
                                                              'adaptor': '',
                                                              'dataType': 'json',
                                                              'data': {
                                                                'type': 2,
                                                                'stageId': '${id}',
                                                                'devId': '${devId}'
                                                              }
                                                            },
                                                            'onEvent': {
                                                              'validateSucc': {
                                                                'weight': 0,
                                                                'actions': [
                                                                  {
                                                                    'ignoreError': false,
                                                                    'actionType': 'dialog',
                                                                    'dialog': {
                                                                      'type': 'dialog',
                                                                      'title': '分享链接',
                                                                      'confirmBtnLevel': 'primary',
                                                                      'body': [
                                                                        {
                                                                          'type': 'tpl',
                                                                          'id': 'u:44a34bb76286',
                                                                          'tpl': "<span style='word-break:break-all;'>${window:location[origin]}/static/byzt/client_view.html?stageId=${id}&stageName=${stageName}&sampleId=${sampleId}&devId=${devId}&deviceNo=${deviceNo}&type=2&token=${token}</span>",
                                                                          'inline': true,
                                                                          'wrapperComponent': '',
                                                                          'style': {
                                                                            'boxShadow': ' 0px 0px 0px 0px transparent'
                                                                          }
                                                                        },
                                                                        {
                                                                          'type': 'flex',
                                                                          'className': 'p-1',
                                                                          'items': [
                                                                            {
                                                                              'type': 'container',
                                                                              'body': [
                                                                                {
                                                                                  'type': 'button',
                                                                                  'label': '取消',
                                                                                  'id': 'u:a58a4a57f915',
                                                                                  'level': 'default',
                                                                                  'onEvent': {
                                                                                    'click': {
                                                                                      'weight': 0,
                                                                                      'actions': []
                                                                                    }
                                                                                  },
                                                                                  'close': true,
                                                                                  'className': 'mr-2 className-a58a4a57f915',
                                                                                  'themeCss': {
                                                                                    'className': {
                                                                                      'padding-and-margin:default': {
                                                                                        'marginTop': '0',
                                                                                        'marginRight': '10px',
                                                                                        'marginBottom': '0',
                                                                                        'marginLeft': '0'
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                },
                                                                                {
                                                                                  'type': 'button',
                                                                                  'label': '复制',
                                                                                  'onEvent': {
                                                                                    'click': {
                                                                                      'actions': [
                                                                                        {
                                                                                          'actionType': 'copy',
                                                                                          'args': {
                                                                                            'content': '${locationUrl}/static/byzt/client_view.html?stageId=${id}&stageName=${stageName}&sampleId=${sampleId}&devId=${devId}&deviceNo=${deviceNo}&type=2&token=${token}',
                                                                                            'copyFormat': 'text/plain'
                                                                                          },
                                                                                          'ignoreError': false
                                                                                        }
                                                                                      ]
                                                                                    }
                                                                                  },
                                                                                  'id': 'u:a06c47b1f3bf',
                                                                                  'level': 'primary',
                                                                                  'close': true
                                                                                }
                                                                              ],
                                                                              'size': 'xs',
                                                                              'style': {
                                                                                'position': 'static',
                                                                                'display': 'flex',
                                                                                'flex': '1 1 auto',
                                                                                'flexGrow': 1,
                                                                                'flexBasis': 'auto',
                                                                                'boxShadow': ' 0px 0px 0px 0px transparent',
                                                                                'flexWrap': 'nowrap',
                                                                                'justifyContent': 'flex-end',
                                                                                'alignItems': 'flex-end',
                                                                                'marginTop': '20px',
                                                                                'marginRight': '0',
                                                                                'marginBottom': '0',
                                                                                'marginLeft': '0'
                                                                              },
                                                                              'wrapperBody': false,
                                                                              'isFixedHeight': false,
                                                                              'isFixedWidth': false,
                                                                              'id': 'u:6bf826c6feba'
                                                                            }
                                                                          ],
                                                                          'style': {
                                                                            'position': 'relative',
                                                                            'inset': 'auto',
                                                                            'flexWrap': 'nowrap',
                                                                            'boxShadow': ' 0px 0px 0px 0px transparent'
                                                                          },
                                                                          'id': 'u:49affa0663ff',
                                                                          'isFixedHeight': false,
                                                                          'isFixedWidth': false
                                                                        }
                                                                      ],
                                                                      'id': 'u:6f99a844c371',
                                                                      'actions': []
                                                                    },
                                                                    'close': false
                                                                  }
                                                                ],
                                                                'close': false
                                                              }
                                                            },
                                                            'closeDialogOnSubmit': false
                                                          }
                                                        ],
                                                        'id': 'u:bf7469976e89',
                                                        'showCloseButton': true,
                                                        'showErrorMsg': true,
                                                        'showLoading': true,
                                                        'className': 'app-popover',
                                                        'closeOnEsc': false,
                                                        'close': false
                                                      }
                                                    }
                                                  ]
                                                }
                                              },
                                              'id': 'u:f2bd3b139631',
                                              'level': 'link'
                                            },
                                            {
                                              'type': 'button',
                                              'label': '修改',
                                              'onEvent': {
                                                'click': {
                                                  'actions': [
                                                    {
                                                      'ignoreError': false,
                                                      'actionType': 'drawer',
                                                      'drawer': {
                                                        'type': 'drawer',
                                                        'title': '编辑',
                                                        'body': [
                                                          {
                                                            'type': 'form',
                                                            'id': 'u:35c3af7117a9',
                                                            'title': '表单',
                                                            'body': [
                                                              {
                                                                'type': 'input-text',
                                                                'label': 'id',
                                                                'name': 'id',
                                                                'id': 'u:f7566dd0f2c2',
                                                                'hidden': true
                                                              },
                                                              {
                                                                'type': 'input-text',
                                                                'label': '组态类型',
                                                                'name': 'stageType',
                                                                'id': 'u:a4f9f9082323',
                                                                'value': '2',
                                                                'hidden': true,
                                                                'readOnly': true
                                                              },
                                                              {
                                                                'type': 'tree-select',
                                                                'label': '所属项目',
                                                                'name': 'projectId',
                                                                'id': 'CREATE:所属项目',
                                                                'multiple': false,
                                                                'onEvent': {},
                                                                'enableNodePath': false,
                                                                'showIcon': true,
                                                                'initiallyOpen': true,
                                                                'source': {
                                                                  'url': '/system/project/info/projectAllTree',
                                                                  'method': 'get'
                                                                },
                                                                'labelField': 'projectName',
                                                                'valueField': 'id',
                                                                'required': true,
                                                                'searchable': true
                                                              },
                                                              {
                                                                'type': 'select',
                                                                'label': '设备类型',
                                                                'name': 'deviceType',
                                                                'id': 'u:9356d704ffb9',
                                                                'multiple': false,
                                                                'required': true,
                                                                'options': [
                                                                  {
                                                                    'label': '直连设备',
                                                                    'value': 1
                                                                  },
                                                                  {
                                                                    'label': '网关设备',
                                                                    'value': 2
                                                                  },
                                                                  {
                                                                    'label': '网关子设备',
                                                                    'value': 0
                                                                  }
                                                                ],
                                                                'value': ''
                                                              },
                                                              {
                                                                'type': 'tree-select',
                                                                'label': '所属模板',
                                                                'name': 'sampleId',
                                                                'id': 'CREATE:所属项目',
                                                                'multiple': false,
                                                                'onEvent': {},
                                                                'enableNodePath': false,
                                                                'showIcon': true,
                                                                'initiallyOpen': true,
                                                                'source': {
                                                                  'url': '/gather/sample/info/getSampleDataSourceAmis?sampleType=${deviceType}',
                                                                  'method': 'get',
                                                                  'requestAdaptor': '',
                                                                  'adaptor': 'payload = payload.options;\r\nreturn payload;',
                                                                  'messages': {}
                                                                },
                                                                'labelField': 'label',
                                                                'valueField': 'value',
                                                                'required': true,
                                                                'searchable': true
                                                              },
                                                              {
                                                                'type': 'input-text',
                                                                'name': 'stageName',
                                                                'label': '组态名称',
                                                                'id': 'u:eadf3148a116'
                                                              },
                                                              {
                                                                'type': 'input-text',
                                                                'label': '分辨率宽度',
                                                                'name': 'resolutionWidth',
                                                                'id': 'u:ffd2e8dcb42e',
                                                                'mode': 'inline',
                                                                'className': 'ml-7'
                                                              },
                                                              {
                                                                'type': 'input-text',
                                                                'label': 'x 高度',
                                                                'name': 'resolutionHeight',
                                                                'id': 'u:11d8348c9f29',
                                                                'mode': 'inline'
                                                              },
                                                              {
                                                                'type': 'switch',
                                                                'label': '状态',
                                                                'name': 'status',
                                                                'id': 'u:67239c2b989d',
                                                                'option': '',
                                                                'falseValue': 0,
                                                                'trueValue': 1,
                                                                'value': 1
                                                              },
                                                              {
                                                                'type': 'textarea',
                                                                'label': '备注',
                                                                'name': 'stageMemo',
                                                                'id': 'u:0d58c9d42906',
                                                                'minRows': 3,
                                                                'maxRows': 20
                                                              }
                                                            ],
                                                            'name': '独立组态',
                                                            'api': {
                                                              'url': '/gather/stage/info/edit',
                                                              'method': 'post',
                                                              'requestAdaptor': '',
                                                              'adaptor': 'response.data = {};\r\nreturn response;',
                                                              'messages': {},
                                                              'dataType': 'json'
                                                            },
                                                            'onEvent': {
                                                              'submitSucc': {
                                                                'weight': 0,
                                                                'actions': [
                                                                  {
                                                                    'componentId': 'u:48f462f67630',
                                                                    'ignoreError': false,
                                                                    'actionType': 'reload',
                                                                    'args': {
                                                                      'resetPage': true
                                                                    }
                                                                  }
                                                                ]
                                                              }
                                                            }
                                                          }
                                                        ],
                                                        'className': 'app-popover',
                                                        'id': 'u:74058ed82f89',
                                                        'resizable': false,
                                                        'size': 'lg',
                                                        'closeOnOutside': true
                                                      }
                                                    }
                                                  ]
                                                }
                                              },
                                              'id': 'u:192a4a1d220c',
                                              'level': 'link'
                                            },
                                            {
                                              'type': 'button',
                                              'label': '复制',
                                              'onEvent': {
                                                'click': {
                                                  'actions': [
                                                    {
                                                      'ignoreError': false,
                                                      'actionType': 'ajax',
                                                      'outputVar': 'responseResult',
                                                      'options': {},
                                                      'api': {
                                                        'url': '/gather/stage/info/copy',
                                                        'method': 'post',
                                                        'requestAdaptor': '',
                                                        'adaptor': '',
                                                        'messages': {},
                                                        'data': {
                                                          '&': '$$',
                                                          'id': '${id}'
                                                        }
                                                      }
                                                    },
                                                    {
                                                      'componentId': 'u:48f462f67630',
                                                      'ignoreError': false,
                                                      'actionType': 'reload',
                                                      'args': {
                                                        'resetPage': false
                                                      }
                                                    }
                                                  ]
                                                }
                                              },
                                              'id': 'u:2fe1b08abd9f',
                                              'level': 'link'
                                            },
                                            {
                                              'type': 'button',
                                              'label': '删除',
                                              'onEvent': {
                                                'click': {
                                                  'actions': [
                                                    {
                                                      'ignoreError': false,
                                                      'outputVar': 'responseResult',
                                                      'actionType': 'ajax',
                                                      'options': {},
                                                      'api': {
                                                        'url': '/gather/stage/info/delete?id=${id}&objectId=${objectId}',
                                                        'method': 'post',
                                                        'requestAdaptor': 'var id = api.data.id;\r\napi.data=[];\r\napi.data[0]=id;\r\nreturn api;',
                                                        'adaptor': '',
                                                        'messages': {},
                                                        'dataType': 'json',
                                                        'data': {
                                                          '&': '$$'
                                                        }
                                                      }
                                                    },
                                                    {
                                                      'ignoreError': false,
                                                      'actionType': 'reload',
                                                      'componentId': 'u:48f462f67630',
                                                      'args': {
                                                        'resetPage': true
                                                      }
                                                    }
                                                  ]
                                                }
                                              },
                                              'id': 'u:20d3b44b8d2e',
                                              'level': 'link'
                                            }
                                          ]
                                        }
                                      ],
                                      'bulkActions': [],
                                      'itemActions': [],
                                      'features': [],
                                      'filterColumnCount': 3,
                                      'perPageAvailable': [
                                        10,
                                        20
                                      ],
                                      'name': '独立组态',
                                      'messages': {},
                                      'headerToolbar': [
                                        {
                                          'type': 'bulk-actions',
                                          'align': 'left'
                                        },
                                        {
                                          'type': 'export-excel',
                                          'tpl': '内容',
                                          'wrapperComponent': '',
                                          'id': 'u:5377ddbfd09a'
                                        },
                                        {
                                          'type': 'button',
                                          'tpl': '内容',
                                          'wrapperComponent': '',
                                          'label': '新增',
                                          'align': 'left',
                                          'id': 'u:76f6472136d6',
                                          'level': 'primary',
                                          'onEvent': {
                                            'click': {
                                              'weight': 0,
                                              'actions': [
                                                {
                                                  'ignoreError': false,
                                                  'actionType': 'drawer',
                                                  'drawer': {
                                                    'type': 'drawer',
                                                    'title': '新增',
                                                    'body': [
                                                      {
                                                        'type': 'form',
                                                        'title': '表单',
                                                        'body': [
                                                          {
                                                            'type': 'input-text',
                                                            'label': '组态类型',
                                                            'name': 'stageType',
                                                            'id': 'u:578d5471cc7a',
                                                            'hidden': true,
                                                            'readOnly': true,
                                                            'static': false,
                                                            'value': '2'
                                                          },
                                                          {
                                                            'type': 'tree-select',
                                                            'label': '所属项目',
                                                            'name': 'projectId',
                                                            'id': 'CREATE:所属项目',
                                                            'multiple': false,
                                                            'onEvent': {},
                                                            'enableNodePath': false,
                                                            'showIcon': true,
                                                            'initiallyOpen': true,
                                                            'source': {
                                                              'url': '/system/project/info/projectAllTree',
                                                              'method': 'get'
                                                            },
                                                            'labelField': 'projectName',
                                                            'valueField': 'id',
                                                            'required': true,
                                                            'searchable': true
                                                          },
                                                          {
                                                            'type': 'select',
                                                            'label': '设备类型',
                                                            'name': 'deviceType',
                                                            'id': 'u:9356d704ffb9',
                                                            'multiple': false,
                                                            'required': true,
                                                            'options': [
                                                              {
                                                                'label': '直连设备',
                                                                'value': 1
                                                              },
                                                              {
                                                                'label': '网关设备',
                                                                'value': 2
                                                              },
                                                              {
                                                                'label': '网关子设备',
                                                                'value': 0
                                                              }
                                                            ],
                                                            'value': ''
                                                          },
                                                          {
                                                            'type': 'tree-select',
                                                            'label': '所属模板',
                                                            'name': 'sampleId',
                                                            'id': 'CREATE:所属项目',
                                                            'multiple': false,
                                                            'onEvent': {},
                                                            'enableNodePath': false,
                                                            'showIcon': true,
                                                            'initiallyOpen': true,
                                                            'source': {
                                                              'url': '/gather/sample/info/getSampleDataSourceAmis?sampleType=${deviceType}',
                                                              'method': 'get',
                                                              'requestAdaptor': '',
                                                              'adaptor': 'response.data.value=null;\r\nreturn response;',
                                                              'messages': {}
                                                            },
                                                            'labelField': 'label',
                                                            'valueField': 'value',
                                                            'required': true,
                                                            'searchable': true
                                                          },
                                                          {
                                                            'type': 'input-text',
                                                            'name': 'stageName',
                                                            'label': '组态名称',
                                                            'id': 'u:eadf3148a116'
                                                          },
                                                          {
                                                            'type': 'input-text',
                                                            'label': '分辨率宽度',
                                                            'name': 'resolutionWidth',
                                                            'id': 'u:ffd2e8dcb42e',
                                                            'mode': 'inline',
                                                            'className': 'ml-7'
                                                          },
                                                          {
                                                            'type': 'input-text',
                                                            'label': 'x 高度',
                                                            'name': 'resolutionHeight',
                                                            'id': 'u:11d8348c9f29',
                                                            'mode': 'inline'
                                                          },
                                                          {
                                                            'type': 'switch',
                                                            'label': '状态',
                                                            'name': 'status',
                                                            'id': 'u:67239c2b989d',
                                                            'option': '',
                                                            'falseValue': 0,
                                                            'trueValue': 1,
                                                            'value': 1
                                                          },
                                                          {
                                                            'type': 'textarea',
                                                            'label': '备注',
                                                            'name': 'stageMemo',
                                                            'id': 'u:0d58c9d42906',
                                                            'minRows': 3,
                                                            'maxRows': 20
                                                          }
                                                        ],
                                                        'id': 'u:35c3af7117a9',
                                                        'name': '独立组态',
                                                        'api': {
                                                          'url': '/gather/stage/info/add',
                                                          'method': 'post',
                                                          'requestAdaptor': '',
                                                          'adaptor': 'response.data = {};\r\nreturn response;',
                                                          'messages': {},
                                                          'dataType': 'json'
                                                        },
                                                        'onEvent': {
                                                          'submitSucc': {
                                                            'weight': 0,
                                                            'actions': [
                                                              {
                                                                'componentId': 'u:48f462f67630',
                                                                'ignoreError': false,
                                                                'actionType': 'reload',
                                                                'args': {
                                                                  'resetPage': true
                                                                }
                                                              }
                                                            ]
                                                          }
                                                        }
                                                      }
                                                    ],
                                                    'className': 'app-popover',
                                                    'id': 'u:74058ed82f89',
                                                    'resizable': false,
                                                    'size': 'lg',
                                                    'closeOnOutside': true
                                                  }
                                                }
                                              ]
                                            }
                                          }
                                        },
                                        {
                                          'type': 'columns-toggler',
                                          'tpl': '内容',
                                          'wrapperComponent': '',
                                          'align': 'right'
                                        },
                                        {
                                          'type': 'drag-toggler',
                                          'tpl': '内容',
                                          'wrapperComponent': '',
                                          'align': 'right'
                                        },
                                        {
                                          'type': 'pagination',
                                          'tpl': '内容',
                                          'wrapperComponent': '',
                                          'align': 'right'
                                        }
                                      ],
                                      'alwaysShowPagination': true,
                                      'perPage': 10,
                                      'maxKeepItemSelectionLength': 11,
                                      'keepItemSelectionOnPageChange': true,
                                      'pageField': 'pageNum',
                                      'perPageField': 'pageSize',
                                      'labelTpl': '${id}',
                                      'filter': null,
                                      'filterTogglable': true,
                                      'autoGenerateFilter': {
                                        'columnsNum': 4,
                                        'showBtnToolbar': false
                                      },
                                      'footerToolbar': [
                                        {
                                          'type': 'statistics',
                                          'tpl': '内容',
                                          'wrapperComponent': ''
                                        },
                                        {
                                          'type': 'pagination',
                                          'tpl': '内容',
                                          'wrapperComponent': '',
                                          'layout': 'perpage,pager,go'
                                        }
                                      ]
                                    }
                                  ],
                                  'id': 'u:4d647baace14'
                                }
                              ],
                              'id': 'u:5c2c8a3d6d37',
                              'tabsMode': 'radio'
                            }
                          ],
                          'id': 'u:a62508156b47'
                        },
                        {
                          'title': '规则',
                          'body': [
                            {
                              'type': 'crud',
                              'id': 'AlarmRule:table',
                              'api': {
                                'method': 'get',
                                'url': '/gather/rule/info/page?ruleObjectId=${deviceId}',
                                'messages': {},
                                'requestAdaptor': '',
                                'adaptor': '',
                                'dataType': 'json',
                                'data': {
                                  '&': '$$',
                                  'objectId': '${objectId}'
                                }
                              },
                              'columns': [
                                {
                                  'name': 'ruleName',
                                  'label': '规则名称',
                                  'type': 'text',
                                  'id': 'LIST:规则名称',
                                  'searchable': {
                                    'clearable': 'true',
                                    'id': 'u:cc43ed3835ef',
                                    'name': '规则列表',
                                    'type': 'input-text'
                                  },
                                  'placeholder': '-'
                                },
                                {
                                  'name': 'projectId',
                                  'label': '所属项目',
                                  'type': 'mapping',
                                  'id': 'LIST:所属项目',
                                  'searchable': {
                                    'id': 'u:8c1eeb1ea671',
                                    'name': 'projectId',
                                    'type': 'tree-select',
                                    'label': '所属项目',
                                    'multiple': false,
                                    'onEvent': {},
                                    'enableNodePath': false,
                                    'showIcon': true,
                                    'initiallyOpen': true,
                                    'source': {
                                      'url': '/system/project/info/projectAllTree',
                                      'method': 'get'
                                    },
                                    'labelField': 'projectName',
                                    'valueField': 'id'
                                  },
                                  'source': {
                                    'url': '/system/project/info/dataSource',
                                    'method': 'get'
                                  }
                                },
                                {
                                  'type': 'switch',
                                  'label': '状态',
                                  'name': 'status',
                                  'id': 'u:7e7ff8996c61',
                                  'option': '',
                                  'falseValue': 0,
                                  'trueValue': 1,
                                  'onEvent': {
                                    'change': {
                                      'weight': 0,
                                      'actions': [
                                        {
                                          'ignoreError': false,
                                          'outputVar': 'responseResult',
                                          'actionType': 'ajax',
                                          'options': {},
                                          'api': {
                                            'url': '/gather/rule/info/updateStatus',
                                            'method': 'post',
                                            'requestAdaptor': '',
                                            'adaptor': '',
                                            'messages': {},
                                            'data': {
                                              '&': '$$',
                                              'id': '${id}'
                                            },
                                            'dataType': 'json'
                                          }
                                        }
                                      ]
                                    }
                                  },
                                  'searchable': {
                                    'type': 'select',
                                    'label': '状态',
                                    'name': 'status',
                                    'options': [
                                      {
                                        'label': '启用',
                                        'value': 1
                                      },
                                      {
                                        'label': '禁用',
                                        'value': 0
                                      }
                                    ],
                                    'id': 'u:ace4c43c42fe',
                                    'multiple': false,
                                    'clearable': true
                                  },
                                  'value': false
                                },
                                {
                                  'type': 'mapping',
                                  'map': {
                                    '1': '协议解析前',
                                    '2': '协议解析后',
                                    '3': '数据存储前',
                                    '4': '数据存储后'
                                  },
                                  'itemSchema': {
                                    'type': 'tag',
                                    'label': '${item}'
                                  },
                                  'name': 'ruleTriggerType',
                                  'label': '触发节点',
                                  'id': 'u:7193932bcd4a',
                                  'placeholder': '未配置'
                                },
                                {
                                  'type': 'text',
                                  'label': '创建时间',
                                  'name': 'createTime',
                                  'id': 'LIST:创建时间'
                                },
                                {
                                  'type': 'text',
                                  'label': '排序',
                                  'name': 'sort',
                                  'id': 'LIST:排序'
                                },
                                {
                                  'type': 'text',
                                  'label': '备注',
                                  'name': 'remark',
                                  'id': 'LIST:备注'
                                },
                                {
                                  'type': 'operation',
                                  'label': '操作',
                                  'id': 'u:3ba1b5bedd76',
                                  'buttons': [
                                    {
                                      'type': 'button',
                                      'label': '规则配置',
                                      'onEvent': {
                                        'click': {
                                          'actions': [
                                            {
                                              'ignoreError': false,
                                              'actionType': 'dialog',
                                              'dialog': {
                                                'size': 'lg',
                                                'type': 'dialog',
                                                'title': '报警规则配置',
                                                'body': [
                                                  {
                                                    'type': 'form',
                                                    'title': '表单',
                                                    'body': [
                                                      {
                                                        'id': 'u:ac04c8b2a7d7',
                                                        'type': 'fieldset',
                                                        'title': '基础配置',
                                                        'body': [
                                                          {
                                                            'name': 'ruleName',
                                                            'label': '规则名称',
                                                            'id': 'UPDATE:规则名称',
                                                            'type': 'input-text'
                                                          },
                                                          {
                                                            'name': 'id',
                                                            'label': '规则Id',
                                                            'id': 'UPDATE:所属项目',
                                                            'type': 'input-text',
                                                            'hidden': true,
                                                            'required': true,
                                                            'value': ''
                                                          },
                                                          {
                                                            'name': 'projectId',
                                                            'label': '所属项目',
                                                            'id': 'UPDATE:所属项目',
                                                            'type': 'input-text',
                                                            'hidden': true
                                                          },
                                                          {
                                                            'name': 'status',
                                                            'label': '状态',
                                                            'id': 'u:f81a71a40fc2',
                                                            'type': 'switch',
                                                            'option': '',
                                                            'falseValue': 0,
                                                            'trueValue': 1,
                                                            'value': 0,
                                                            'hidden': true
                                                          },
                                                          {
                                                            'name': 'sort',
                                                            'label': '排序',
                                                            'id': 'u:92c81c844665',
                                                            'type': 'input-number',
                                                            'keyboard': true,
                                                            'required': true
                                                          },
                                                          {
                                                            'name': 'remark',
                                                            'label': '备注',
                                                            'id': 'UPDATE:备注',
                                                            'type': 'input-text'
                                                          }
                                                        ],
                                                        'collapsable': true
                                                      },
                                                      {
                                                        'id': 'u:70c088b997f7',
                                                        'type': 'fieldset',
                                                        'title': '对象配置',
                                                        'collapsable': true,
                                                        'body': [
                                                          {
                                                            'type': 'select',
                                                            'label': '触发节点',
                                                            'name': 'ruleTriggerType',
                                                            'options': [
                                                              {
                                                                'label': '协议解析前',
                                                                'value': 1
                                                              },
                                                              {
                                                                'label': '协议解析后',
                                                                'value': 2
                                                              },
                                                              {
                                                                'label': '数据存储前',
                                                                'value': 3
                                                              },
                                                              {
                                                                'label': '数据存储后',
                                                                'value': 4
                                                              }
                                                            ],
                                                            'id': 'u:4fa9c5beb67f',
                                                            'value': 1,
                                                            'required': true,
                                                            'multiple': false,
                                                            'checkAll': false
                                                          },
                                                          {
                                                            'type': 'radios',
                                                            'label': '触发对象类型',
                                                            'name': 'ruleType',
                                                            'id': 'u:d57d8b8d6cc5',
                                                            'required': true,
                                                            'options': [
                                                              {
                                                                'label': '设备',
                                                                'value': 1
                                                              },
                                                              {
                                                                'label': '模板',
                                                                'value': 2
                                                              }
                                                            ],
                                                            'value': 1,
                                                            'selectFirst': false
                                                          },
                                                          {
                                                            'type': 'tree-select',
                                                            'label': '设备源',
                                                            'name': 'ruleObjectId',
                                                            'id': 'u:385f6470cc83',
                                                            'required': true,
                                                            'multiple': false,
                                                            'source': {
                                                              'url': '/gather/trigger/info/getDeviceSource?triggerType=${ruleType}',
                                                              'method': 'get'
                                                            },
                                                            'onEvent': {},
                                                            'enableNodePath': false,
                                                            'showIcon': true,
                                                            'initiallyOpen': true
                                                          },
                                                          {
                                                            'type': 'switch',
                                                            'label': '筛选条件',
                                                            'name': 'isFilter',
                                                            'id': 'u:e10cd1f0ea3b',
                                                            'value': false,
                                                            'option': '开启后执行规则需满足条件',
                                                            'falseValue': false,
                                                            'trueValue': true,
                                                            'hidden': true
                                                          },
                                                          {
                                                            'name': 'ruleAttrId',
                                                            'label': '设备属性',
                                                            'id': 'u:51708f0109b8',
                                                            'type': 'select',
                                                            'hiddenOn': '${!isFilter}',
                                                            'mode': 'inline',
                                                            'multiple': false,
                                                            'size': 'sm',
                                                            'staticClassName': '',
                                                            'className': 'm-l-xl',
                                                            'source': {
                                                              'url': '/gather/trigger/info/getDeviceAttrSource?triggerObjectId=${ruleObjectId}&triggerType=${ruleType}',
                                                              'method': 'get'
                                                            }
                                                          },
                                                          {
                                                            'name': 'ruleCondition',
                                                            'label': '',
                                                            'id': 'u:190849f7ecf6',
                                                            'type': 'select',
                                                            'hiddenOn': '${!isFilter}',
                                                            'mode': 'inline',
                                                            'options': [
                                                              {
                                                                'label': '开关OFF',
                                                                'value': 0
                                                              },
                                                              {
                                                                'label': '开关ON',
                                                                'value': 1
                                                              },
                                                              {
                                                                'label': '数值小于A',
                                                                'value': 2
                                                              },
                                                              {
                                                                'label': '数值大于B',
                                                                'value': 3
                                                              },
                                                              {
                                                                'label': '数值大于A且小于B',
                                                                'value': 4
                                                              },
                                                              {
                                                                'label': '数值小于A或大于B',
                                                                'value': 5
                                                              },
                                                              {
                                                                'label': '数值等于A',
                                                                'value': 6
                                                              }
                                                            ],
                                                            'value': 2,
                                                            'multiple': false,
                                                            'required': true,
                                                            'size': 'sm'
                                                          },
                                                          {
                                                            'name': 'ruleValueA',
                                                            'label': '',
                                                            'id': 'UPDATE:A值',
                                                            'type': 'input-text',
                                                            'hiddenOn': '${(ruleCondition!=2&&ruleCondition!=4&&ruleCondition!=5&&ruleCondition!=6)||!isFilter}',
                                                            'mode': 'inline',
                                                            'placeholder': 'A值'
                                                          },
                                                          {
                                                            'name': 'ruleValueB',
                                                            'label': '',
                                                            'id': 'UPDATE:B值',
                                                            'type': 'input-text',
                                                            'hiddenOn': '${(ruleCondition!=3&&ruleCondition!=4&&ruleCondition!=5)||!isFilter}',
                                                            'mode': 'inline',
                                                            'placeholder': 'B值'
                                                          }
                                                        ]
                                                      },
                                                      {
                                                        'type': 'fieldset',
                                                        'title': '规则动作',
                                                        'collapsable': true,
                                                        'body': [
                                                          {
                                                            'type': 'select',
                                                            'label': '动作类型',
                                                            'name': 'ruleComponentType',
                                                            'id': 'u:4fa9c5beb67f',
                                                            'value': 2,
                                                            'required': true,
                                                            'multiple': false,
                                                            'checkAll': false,
                                                            'options': [
                                                              {
                                                                'label': '标准动作',
                                                                'value': 1
                                                              },
                                                              {
                                                                'label': '自定义脚本',
                                                                'value': 2
                                                              }
                                                            ]
                                                          },
                                                          {
                                                            'type': 'select',
                                                            'label': '动作',
                                                            'name': 'ruleComponentId',
                                                            'id': 'u:09d234b0617f',
                                                            'multiple': false,
                                                            'checkAll': false,
                                                            'options': [
                                                              {
                                                                'label': '微信公众号报警',
                                                                'value': 1
                                                              },
                                                              {
                                                                'label': '邮件报警',
                                                                'value': 2
                                                              },
                                                              {
                                                                'label': '短信报警',
                                                                'value': 3
                                                              },
                                                              {
                                                                'label': '电话报警',
                                                                'value': 4
                                                              }
                                                            ],
                                                            'hidden': true
                                                          },
                                                          {
                                                            'type': 'editor',
                                                            'label': '自定义脚本',
                                                            'name': 'script',
                                                            'id': 'u:704c6feef2dc',
                                                            'language': 'java',
                                                            'value': 'import com.yomahub.liteflow.slot.DefaultContext;\nimport com.yomahub.liteflow.spi.holder.ContextAwareHolder;\nimport com.yomahub.liteflow.script.body.JaninoCommonScriptBody;\nimport com.yomahub.liteflow.script.ScriptExecuteWrap;\nimport com.zbtech.iot.core.engine.context.MessageContext;\nimport com.zbtech.gather.modular.device.engine.context.GatherMessageContext;\n\n\npublic class Demo implements JaninoCommonScriptBody{\n    public Void body(ScriptExecuteWrap wrap){\n        MessageContext context = (MessageContext)wrap.cmp.getContextBean(MessageContext.class);\n        GatherMessageContext dataContext = (GatherMessageContext)wrap.cmp.getContextBean(GatherMessageContext.class);\n\n        return null;\n    }\n}'
                                                          }
                                                        ],
                                                        'id': 'u:e69e4d607f8a'
                                                      }
                                                    ],
                                                    'id': 'u:203cbc4fd268',
                                                    'api': {
                                                      'url': '/gather/rule/info/config',
                                                      'method': 'post',
                                                      'requestAdaptor': '',
                                                      'adaptor': 'response.data={};\r\nreturn response;',
                                                      'messages': {
                                                        'success': '更新成功',
                                                        'failed': '更新失败'
                                                      },
                                                      'dataType': 'json',
                                                      'data': {
                                                        '&': '$$',
                                                        'objectId': '${objectId}'
                                                      }
                                                    },
                                                    'onEvent': {
                                                      'submitSucc': {
                                                        'weight': 0,
                                                        'actions': [
                                                          {
                                                            'componentId': 'AlarmRule:table',
                                                            'ignoreError': false,
                                                            'actionType': 'reload',
                                                            'args': {
                                                              'resetPage': true
                                                            }
                                                          }
                                                        ]
                                                      }
                                                    },
                                                    'name': '报警规则',
                                                    'initApi': {
                                                      'url': '/gather/rule/info/detail?id=${id}',
                                                      'method': 'get',
                                                      'requestAdaptor': '',
                                                      'adaptor': '',
                                                      'messages': {},
                                                      'dataType': 'json'
                                                    }
                                                  }
                                                ],
                                                'showCloseButton': true,
                                                'showErrorMsg': true,
                                                'showLoading': true,
                                                'className': 'app-popover',
                                                'id': 'u:9ba0f77d4e62',
                                                'resizable': false
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      'id': 'AlarmRule:update',
                                      'level': 'link',
                                      'hiddenOn': "${permissions!='*:*:*'&&!ARRAYINCLUDES(permissions,'gather:rule:edit')}"
                                    },
                                    {
                                      'type': 'button',
                                      'label': '删除',
                                      'onEvent': {
                                        'click': {
                                          'actions': [
                                            {
                                              'ignoreError': false,
                                              'outputVar': 'responseResult',
                                              'actionType': 'ajax',
                                              'options': {
                                                'silent': false
                                              },
                                              'api': {
                                                'url': '/gather/rule/info/delete?id=${id}',
                                                'method': 'post',
                                                'requestAdaptor': 'var id = api.data.id;\r\napi.data=[];\r\napi.data[0]=id;\r\nreturn api;',
                                                'adaptor': '',
                                                'messages': {},
                                                'dataType': 'json',
                                                'replaceData': false,
                                                'data': {
                                                  '&': '$$'
                                                }
                                              }
                                            },
                                            {
                                              'componentId': 'AlarmRule:table',
                                              'ignoreError': false,
                                              'actionType': 'reload',
                                              'args': {
                                                'resetPage': true
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      'id': 'AlarmRule:delete',
                                      'level': 'link',
                                      'confirmText': '数据删除后无法恢复，请确认!',
                                      'hiddenOn': "${permissions!='*:*:*'&&!ARRAYINCLUDES(permissions,'gather:rule:delete')}"
                                    }
                                  ]
                                }
                              ],
                              'bulkActions': [],
                              'itemActions': [],
                              'perPageAvailable': [
                                10,
                                20
                              ],
                              'messages': {},
                              'pageField': 'pageNum',
                              'perPageField': 'pageSize',
                              'features': [
                                'filter'
                              ],
                              'headerToolbar': [
                                'bulkActions',
                                {
                                  'type': 'export-excel',
                                  'id': 'u:0e71cdedac66',
                                  'hiddenOn': "${permissions!='*:*:*'&&!ARRAYINCLUDES(permissions,'gather:trigger:export')}"
                                },
                                {
                                  'type': 'columns-toggler',
                                  'align': 'right'
                                },
                                {
                                  'type': 'drag-toggler',
                                  'align': 'right'
                                },
                                {
                                  'type': 'pagination',
                                  'align': 'right'
                                }
                              ],
                              'footerToolbar': [
                                {
                                  'type': 'statistics'
                                },
                                {
                                  'type': 'pagination',
                                  'layout': 'perPage,pager,go'
                                }
                              ],
                              'alwaysShowPagination': true,
                              'draggable': false,
                              'perPage': 10,
                              'keepItemSelectionOnPageChange': true,
                              'maxKeepItemSelectionLength': 11,
                              'labelTpl': '${id}',
                              'autoGenerateFilter': {
                                'columnsNum': 4,
                                'showBtnToolbar': false
                              },
                              'quickSaveApi': '',
                              'quickSaveItemApi': '',
                              'filterTogglable': true,
                              'filterSettingSource': [
                                'objectCode',
                                'objectName',
                                'tableName',
                                'id',
                                'createTime'
                              ],
                              'onEvent': {},
                              'footable': false,
                              'name': '设备详情demo'
                            }
                          ],
                          'id': 'u:653e672b2965',
                          'hiddenOn': "${permissions!='*:*:*'&&!ARRAYINCLUDES(permissions,'gather:rule:list')}"
                        },
                        {
                          'title': '调试',
                          'body': [
                            {
                              'id': 'u:9875d91e8486',
                              'type': 'log',
                              'autoScroll': true,
                              'height': 500,
                              'encoding': 'utf-8',
                              'source': {
                                'url': 'http://192.168.1.52:8080/admin-api/gather/device/debug/logs',
                                'method': 'get',
                                'requestAdaptor': '',
                                'adaptor': '',
                                'messages': {}
                              },
                              'credentials': 'same-origin',
                              'operation': 'restart,clear,showLineNumber,stop'
                            }
                          ],
                          'id': 'u:ce7676cdf332',
                          'hidden': 'true'
                        }
                      ],
                      'mountOnEnter': true,
                      'unmountOnExit': true,
                      'onEvent': {
                        'change': {
                          'weight': 0,
                          'actions': [
                            {
                              'componentId': 'u:048c2e359e49',
                              'ignoreError': false,
                              'actionType': 'reload',
                              'expression': '${event.data.value==1}'
                            }
                          ]
                        }
                      }
                    }
                  ],
                  'name': '设备列表',
                  'messages': {},
                  'api': {
                    'url': '/gather/device/info/detail?id=${deviceId}',
                    'method': 'get',
                    'requestAdaptor': '',
                    'adaptor': 'response.data.deviceId= response.data.id;\r\nreturn response;',
                    'dataType': 'json'
                  },
                  'onEvent': {
                    'fetchInited': {
                      'weight': 0,
                      'actions': [
                        {
                          'componentId': 'u:104133c688dd',
                          'ignoreError': false,
                          'actionType': 'reload'
                        }
                      ]
                    }
                  }
                }
              ],
              'showCloseButton': true,
              'showErrorMsg': true,
              'showLoading': true,
              'className': 'app-popover',
              'id': 'u:3c55f7f2f583',
              'width': '80%',
              'resizable': false,
              'headClassName': 'm-none p-none no-border w-xxs',
              'actions': [],
              'footClassName': 'm-none p-none'
            }
          }
        ]
      }
    },
    'id': 'u:1a484815ca6f',
    'themeCss': {
      'className': {
        'background:default': '#2878FF',
        'font:default': {
          'color': 'white'
        },
        'background:hover': '#2878FF',
        'font:hover': {
          'color': 'white'
        },
        'border:hover': {
          'border': '0'
        },
        'border:default': {
          'border': '0'
        },
        'background:active': '#2878FF',
        'font:active': {
          'color': 'white'
        },
        'border:active': {
          'border': '0'
        },
        'padding:default': '10px',
        'margin': '0 !important',
        'font-family': '思源-Medium'
      }
    },
    'className': 'className-1a484815ca6f',
    'hiddenOn': "${permissions!='*:*:*'&&!ARRAYINCLUDES(permissions,'gather:device:detail')}",
    'icon': 'icon-a-zu57153'
  };
  previewSchema = { // 组件面板预览时需要
    'type': 'button',
    'label': '详情',
    'onEvent': {
      'click': {
        'actions': [
          {
            'ignoreError': false,
            'actionType': 'setValue',
            'componentId': 'DeviceList:table',
            'args': {
              'value': {
                'deviceId': '\\${id}'
              }
            }
          },
          {
            'ignoreError': false,
            'actionType': 'drawer',
            'drawer': {
              'closeOnEsc': true,
              'size': 'xl',
              'type': 'drawer',
              'closeOnOutside': true,
              'title': '',
              'body': [
                {
                  'type': 'service',
                  'id': 'u:048c2e359e49',
                  'body': [
                    {
                      'type': 'tabs',
                      'id': 'u:42f7a01172c8',
                      'tabs': [
                        {
                          'title': '看板',
                          'body': [
                            {
                              'type': 'grid',
                              'id': 'u:4fcfa73ec993',
                              'columns': [
                                {
                                  'body': [
                                    {
                                      'type': 'info-grid',
                                      'label': 'info-grid',
                                      'source_data': 'deviceData',
                                      'grid_name': 'deviceName',
                                      'grid_child_data': 'data',
                                      'grid_child_name': 'attrName',
                                      'grid_child_value': 'value',
                                      'grid_child_unit': 'attrUnit',
                                      'grid_child_icon': 'icon',
                                      'grid_num': 5,
                                      'grid_margin_bottom': 5,
                                      'grid_padding_top': 10,
                                      'grid_padding_left': 0,
                                      'grid_padding_bottom': 10,
                                      'grid_padding_right': 0,
                                      'grid_background': 'rgba(255, 255, 255, 0)',
                                      'grid_name_color': 'rgb(87, 93, 108, 1)',
                                      'grid_value_color': 'rgb(28, 31, 39, 1)',
                                      'grid_alarm_data': 'threshold',
                                      'grid_alarm_max': 'max',
                                      'grid_alarm_min': 'min',
                                      'grid_alarm_color': 'color',
                                      'id': 'u:8915a8b6f2e9',
                                      'grid_edit': true,
                                      'grid_check_key': 'attrId'
                                    }
                                  ],
                                  'id': 'u:e53af4cd4848',
                                  'style': {
                                    'boxShadow': ' 0px 0px 0px 0px transparent'
                                  }
                                },
                                {
                                  'body': [
                                    {
                                      'type': 'panel',
                                      'title': '设备详情 ID:${id}',
                                      'body': [
                                        {
                                          'type': 'grid',
                                          'columns': [
                                            {
                                              'body': [
                                                {
                                                  'type': 'flex',
                                                  'className': 'p-1',
                                                  'items': [
                                                    {
                                                      'type': 'container',
                                                      'body': [
                                                        {
                                                          'type': 'tpl',
                                                          'tpl': '${deviceName}',
                                                          'inline': true,
                                                          'wrapperComponent': '',
                                                          'id': 'u:472bd957e043',
                                                          'style': {
                                                            'boxShadow': ' 0px 0px 0px 0px transparent'
                                                          }
                                                        }
                                                      ],
                                                      'size': 'xs',
                                                      'style': {
                                                        'position': 'static',
                                                        'display': 'block',
                                                        'flex': '1 1 auto',
                                                        'flexGrow': 1,
                                                        'flexBasis': 'auto',
                                                        'boxShadow': ' 0px 0px 0px 0px transparent'
                                                      },
                                                      'wrapperBody': false,
                                                      'isFixedHeight': false,
                                                      'isFixedWidth': false,
                                                      'id': 'u:f04976d9a0d8',
                                                      'wrapperComponent': 'div'
                                                    },
                                                    {
                                                      'type': 'container',
                                                      'body': [
                                                        {
                                                          'type': 'tpl',
                                                          'tpl': '设备名称',
                                                          'inline': true,
                                                          'wrapperComponent': '',
                                                          'id': 'u:a6d44c2b03e1',
                                                          'style': {
                                                            'boxShadow': ' 0px 0px 0px 0px transparent'
                                                          },
                                                          'className': 'font-bold'
                                                        }
                                                      ],
                                                      'size': 'xs',
                                                      'style': {
                                                        'position': 'static',
                                                        'display': 'block',
                                                        'flex': '1 1 auto',
                                                        'flexGrow': 1,
                                                        'flexBasis': 'auto',
                                                        'boxShadow': ' 0px 0px 0px 0px transparent'
                                                      },
                                                      'wrapperBody': false,
                                                      'isFixedHeight': false,
                                                      'isFixedWidth': false,
                                                      'id': 'u:6fb5ca5e8101'
                                                    }
                                                  ],
                                                  'style': {
                                                    'position': 'relative',
                                                    'inset': 'auto',
                                                    'flexWrap': 'nowrap',
                                                    'boxShadow': ' 0px 0px 0px 0px transparent',
                                                    'flexDirection': 'column'
                                                  },
                                                  'id': 'u:27731f73bdf5',
                                                  'isFixedHeight': false,
                                                  'isFixedWidth': false
                                                }
                                              ],
                                              'id': 'u:942614c4521e',
                                              'style': {
                                                'boxShadow': ' 0px 0px 0px 0px transparent'
                                              }
                                            },
                                            {
                                              'body': [
                                                {
                                                  'type': 'flex',
                                                  'className': 'p-1',
                                                  'items': [
                                                    {
                                                      'type': 'container',
                                                      'body': [
                                                        {
                                                          'type': 'tpl',
                                                          'tpl': '${deviceNo}',
                                                          'inline': true,
                                                          'wrapperComponent': '',
                                                          'id': 'u:472bd957e043',
                                                          'style': {
                                                            'boxShadow': ' 0px 0px 0px 0px transparent'
                                                          }
                                                        }
                                                      ],
                                                      'size': 'xs',
                                                      'style': {
                                                        'position': 'static',
                                                        'display': 'block',
                                                        'flex': '1 1 auto',
                                                        'flexGrow': 1,
                                                        'flexBasis': 'auto',
                                                        'boxShadow': ' 0px 0px 0px 0px transparent'
                                                      },
                                                      'wrapperBody': false,
                                                      'isFixedHeight': false,
                                                      'isFixedWidth': false,
                                                      'id': 'u:f04976d9a0d8',
                                                      'wrapperComponent': 'div'
                                                    },
                                                    {
                                                      'type': 'container',
                                                      'body': [
                                                        {
                                                          'type': 'tpl',
                                                          'tpl': '设备编号',
                                                          'inline': true,
                                                          'wrapperComponent': '',
                                                          'id': 'u:a6d44c2b03e1',
                                                          'style': {
                                                            'boxShadow': ' 0px 0px 0px 0px transparent'
                                                          },
                                                          'className': 'font-bold'
                                                        }
                                                      ],
                                                      'size': 'xs',
                                                      'style': {
                                                        'position': 'static',
                                                        'display': 'block',
                                                        'flex': '1 1 auto',
                                                        'flexGrow': 1,
                                                        'flexBasis': 'auto',
                                                        'boxShadow': ' 0px 0px 0px 0px transparent'
                                                      },
                                                      'wrapperBody': false,
                                                      'isFixedHeight': false,
                                                      'isFixedWidth': false,
                                                      'id': 'u:6fb5ca5e8101'
                                                    }
                                                  ],
                                                  'style': {
                                                    'position': 'relative',
                                                    'inset': 'auto',
                                                    'flexWrap': 'nowrap',
                                                    'boxShadow': ' 0px 0px 0px 0px transparent',
                                                    'flexDirection': 'column'
                                                  },
                                                  'id': 'u:27731f73bdf5',
                                                  'isFixedHeight': false,
                                                  'isFixedWidth': false
                                                }
                                              ],
                                              'id': 'u:2a63bf18b5a0',
                                              'style': {
                                                'boxShadow': ' 0px 0px 0px 0px transparent'
                                              }
                                            },
                                            {
                                              'body': [
                                                {
                                                  'type': 'flex',
                                                  'className': 'p-1',
                                                  'items': [
                                                    {
                                                      'type': 'container',
                                                      'body': [
                                                        {
                                                          'type': 'tag',
                                                          'id': 'u:9585762e20ee',
                                                          'label': "${IF(deviceType==0,'网关子设备',IF(deviceType==1,'直连设备',IF(deviceType==2,'网关设备','--')))}",
                                                          'color': 'processing',
                                                          'displayMode': 'normal'
                                                        }
                                                      ],
                                                      'size': 'xs',
                                                      'style': {
                                                        'position': 'static',
                                                        'display': 'block',
                                                        'flex': '1 1 auto',
                                                        'flexGrow': 1,
                                                        'flexBasis': 'auto',
                                                        'boxShadow': ' 0px 0px 0px 0px transparent'
                                                      },
                                                      'wrapperBody': false,
                                                      'isFixedHeight': false,
                                                      'isFixedWidth': false,
                                                      'id': 'u:f04976d9a0d8',
                                                      'wrapperComponent': 'div'
                                                    },
                                                    {
                                                      'type': 'container',
                                                      'body': [
                                                        {
                                                          'type': 'tpl',
                                                          'tpl': '模板类型',
                                                          'inline': true,
                                                          'wrapperComponent': '',
                                                          'id': 'u:a6d44c2b03e1',
                                                          'style': {
                                                            'boxShadow': ' 0px 0px 0px 0px transparent'
                                                          },
                                                          'className': 'font-bold'
                                                        }
                                                      ],
                                                      'size': 'xs',
                                                      'style': {
                                                        'position': 'static',
                                                        'display': 'block',
                                                        'flex': '1 1 auto',
                                                        'flexGrow': 1,
                                                        'flexBasis': 'auto',
                                                        'boxShadow': ' 0px 0px 0px 0px transparent'
                                                      },
                                                      'wrapperBody': false,
                                                      'isFixedHeight': false,
                                                      'isFixedWidth': false,
                                                      'id': 'u:6fb5ca5e8101'
                                                    }
                                                  ],
                                                  'style': {
                                                    'position': 'relative',
                                                    'inset': 'auto',
                                                    'flexWrap': 'nowrap',
                                                    'boxShadow': ' 0px 0px 0px 0px transparent',
                                                    'flexDirection': 'column'
                                                  },
                                                  'id': 'u:27731f73bdf5',
                                                  'isFixedHeight': false,
                                                  'isFixedWidth': false
                                                }
                                              ],
                                              'id': 'u:123bfc2df053',
                                              'style': {
                                                'boxShadow': ' 0px 0px 0px 0px transparent'
                                              }
                                            }
                                          ],
                                          'id': 'u:55ff95b8cb22',
                                          'style': {
                                            'boxShadow': ' 0px 0px 0px 0px transparent'
                                          }
                                        },
                                        {
                                          'type': 'grid',
                                          'columns': [
                                            {
                                              'body': [
                                                {
                                                  'type': 'flex',
                                                  'className': 'p-1',
                                                  'items': [
                                                    {
                                                      'type': 'container',
                                                      'body': [
                                                        {
                                                          'type': 'tpl',
                                                          'tpl': '${sampleInfo.sampleName}',
                                                          'inline': true,
                                                          'wrapperComponent': '',
                                                          'id': 'u:472bd957e043',
                                                          'style': {
                                                            'boxShadow': ' 0px 0px 0px 0px transparent'
                                                          }
                                                        }
                                                      ],
                                                      'size': 'xs',
                                                      'style': {
                                                        'position': 'static',
                                                        'display': 'block',
                                                        'flex': '1 1 auto',
                                                        'flexGrow': 1,
                                                        'flexBasis': 'auto',
                                                        'boxShadow': ' 0px 0px 0px 0px transparent'
                                                      },
                                                      'wrapperBody': false,
                                                      'isFixedHeight': false,
                                                      'isFixedWidth': false,
                                                      'id': 'u:f04976d9a0d8',
                                                      'wrapperComponent': 'div'
                                                    },
                                                    {
                                                      'type': 'container',
                                                      'body': [
                                                        {
                                                          'type': 'tpl',
                                                          'tpl': '所属模板',
                                                          'inline': true,
                                                          'wrapperComponent': '',
                                                          'id': 'u:a6d44c2b03e1',
                                                          'style': {
                                                            'boxShadow': ' 0px 0px 0px 0px transparent'
                                                          },
                                                          'className': 'font-bold'
                                                        }
                                                      ],
                                                      'size': 'xs',
                                                      'style': {
                                                        'position': 'static',
                                                        'display': 'block',
                                                        'flex': '1 1 auto',
                                                        'flexGrow': 1,
                                                        'flexBasis': 'auto',
                                                        'boxShadow': ' 0px 0px 0px 0px transparent'
                                                      },
                                                      'wrapperBody': false,
                                                      'isFixedHeight': false,
                                                      'isFixedWidth': false,
                                                      'id': 'u:6fb5ca5e8101'
                                                    }
                                                  ],
                                                  'style': {
                                                    'position': 'relative',
                                                    'inset': 'auto',
                                                    'flexWrap': 'nowrap',
                                                    'boxShadow': ' 0px 0px 0px 0px transparent',
                                                    'flexDirection': 'column'
                                                  },
                                                  'id': 'u:27731f73bdf5',
                                                  'isFixedHeight': false,
                                                  'isFixedWidth': false
                                                }
                                              ],
                                              'id': 'u:4473fcbc386f',
                                              'style': {
                                                'boxShadow': ' 0px 0px 0px 0px transparent'
                                              }
                                            },
                                            {
                                              'body': [
                                                {
                                                  'type': 'flex',
                                                  'className': 'p-1',
                                                  'items': [
                                                    {
                                                      'type': 'container',
                                                      'body': [
                                                        {
                                                          'type': 'tpl',
                                                          'tpl': "${IF(!!online,online,'--')}",
                                                          'inline': true,
                                                          'wrapperComponent': '',
                                                          'id': 'u:472bd957e043',
                                                          'style': {
                                                            'boxShadow': ' 0px 0px 0px 0px transparent'
                                                          }
                                                        }
                                                      ],
                                                      'size': 'xs',
                                                      'style': {
                                                        'position': 'static',
                                                        'display': 'block',
                                                        'flex': '1 1 auto',
                                                        'flexGrow': 1,
                                                        'flexBasis': 'auto',
                                                        'boxShadow': ' 0px 0px 0px 0px transparent'
                                                      },
                                                      'wrapperBody': false,
                                                      'isFixedHeight': false,
                                                      'isFixedWidth': false,
                                                      'id': 'u:f04976d9a0d8',
                                                      'wrapperComponent': 'div'
                                                    },
                                                    {
                                                      'type': 'container',
                                                      'body': [
                                                        {
                                                          'type': 'tpl',
                                                          'tpl': '状态',
                                                          'inline': true,
                                                          'wrapperComponent': '',
                                                          'id': 'u:a6d44c2b03e1',
                                                          'style': {
                                                            'boxShadow': ' 0px 0px 0px 0px transparent'
                                                          },
                                                          'className': 'font-bold'
                                                        }
                                                      ],
                                                      'size': 'xs',
                                                      'style': {
                                                        'position': 'static',
                                                        'display': 'block',
                                                        'flex': '1 1 auto',
                                                        'flexGrow': 1,
                                                        'flexBasis': 'auto',
                                                        'boxShadow': ' 0px 0px 0px 0px transparent'
                                                      },
                                                      'wrapperBody': false,
                                                      'isFixedHeight': false,
                                                      'isFixedWidth': false,
                                                      'id': 'u:6fb5ca5e8101'
                                                    }
                                                  ],
                                                  'style': {
                                                    'position': 'relative',
                                                    'inset': 'auto',
                                                    'flexWrap': 'nowrap',
                                                    'boxShadow': ' 0px 0px 0px 0px transparent',
                                                    'flexDirection': 'column'
                                                  },
                                                  'id': 'u:27731f73bdf5',
                                                  'isFixedHeight': false,
                                                  'isFixedWidth': false
                                                }
                                              ],
                                              'id': 'u:0377b58fdc78',
                                              'style': {
                                                'boxShadow': ' 0px 0px 0px 0px transparent'
                                              }
                                            }
                                          ],
                                          'id': 'u:4f621fdcc267',
                                          'style': {
                                            'boxShadow': ' 0px 0px 0px 0px transparent'
                                          }
                                        },
                                        {
                                          'type': 'grid',
                                          'columns': [
                                            {
                                              'body': [
                                                {
                                                  'type': 'flex',
                                                  'className': 'p-1',
                                                  'items': [
                                                    {
                                                      'type': 'container',
                                                      'body': [
                                                        {
                                                          'type': 'tpl',
                                                          'tpl': '${IF(!!alarmCount,alarmCount,0)}',
                                                          'inline': true,
                                                          'wrapperComponent': '',
                                                          'id': 'u:f8249b17b04e',
                                                          'style': {
                                                            'boxShadow': ' 0px 0px 0px 0px transparent'
                                                          }
                                                        }
                                                      ],
                                                      'size': 'xs',
                                                      'style': {
                                                        'position': 'static',
                                                        'display': 'block',
                                                        'flex': '1 1 auto',
                                                        'flexGrow': 1,
                                                        'flexBasis': 'auto',
                                                        'boxShadow': ' 0px 0px 0px 0px transparent'
                                                      },
                                                      'wrapperBody': false,
                                                      'isFixedHeight': false,
                                                      'isFixedWidth': false,
                                                      'id': 'u:88e409fd38ec',
                                                      'wrapperComponent': 'div'
                                                    },
                                                    {
                                                      'type': 'container',
                                                      'body': [
                                                        {
                                                          'type': 'tpl',
                                                          'tpl': '报警总数',
                                                          'inline': true,
                                                          'wrapperComponent': '',
                                                          'id': 'u:d4c23321346c',
                                                          'style': {
                                                            'boxShadow': ' 0px 0px 0px 0px transparent'
                                                          },
                                                          'className': 'font-bold'
                                                        }
                                                      ],
                                                      'size': 'xs',
                                                      'style': {
                                                        'position': 'static',
                                                        'display': 'block',
                                                        'flex': '1 1 auto',
                                                        'flexGrow': 1,
                                                        'flexBasis': 'auto',
                                                        'boxShadow': ' 0px 0px 0px 0px transparent'
                                                      },
                                                      'wrapperBody': false,
                                                      'isFixedHeight': false,
                                                      'isFixedWidth': false,
                                                      'id': 'u:b2eb336e6d63'
                                                    }
                                                  ],
                                                  'style': {
                                                    'position': 'relative',
                                                    'inset': 'auto',
                                                    'flexWrap': 'nowrap',
                                                    'boxShadow': ' 0px 0px 0px 0px transparent',
                                                    'flexDirection': 'column'
                                                  },
                                                  'id': 'u:8dc77d2354cb',
                                                  'isFixedHeight': false,
                                                  'isFixedWidth': false
                                                }
                                              ],
                                              'id': 'u:36bc54b8e76d',
                                              'style': {
                                                'boxShadow': ' 0px 0px 0px 0px transparent'
                                              }
                                            },
                                            {
                                              'body': [
                                                {
                                                  'type': 'flex',
                                                  'className': 'p-1',
                                                  'items': [
                                                    {
                                                      'type': 'container',
                                                      'body': [
                                                        {
                                                          'type': 'tpl',
                                                          'tpl': '${IF(!!dealAlarmCount,dealAlarmCount,0)}',
                                                          'inline': true,
                                                          'wrapperComponent': '',
                                                          'id': 'u:f868159f64c7',
                                                          'style': {
                                                            'boxShadow': ' 0px 0px 0px 0px transparent'
                                                          }
                                                        }
                                                      ],
                                                      'size': 'xs',
                                                      'style': {
                                                        'position': 'static',
                                                        'display': 'block',
                                                        'flex': '1 1 auto',
                                                        'flexGrow': 1,
                                                        'flexBasis': 'auto',
                                                        'boxShadow': ' 0px 0px 0px 0px transparent'
                                                      },
                                                      'wrapperBody': false,
                                                      'isFixedHeight': false,
                                                      'isFixedWidth': false,
                                                      'id': 'u:293ab0c2346d',
                                                      'wrapperComponent': 'div'
                                                    },
                                                    {
                                                      'type': 'container',
                                                      'body': [
                                                        {
                                                          'type': 'tpl',
                                                          'tpl': '已处理报警总数',
                                                          'inline': true,
                                                          'wrapperComponent': '',
                                                          'id': 'u:ad365e314eb2',
                                                          'style': {
                                                            'boxShadow': ' 0px 0px 0px 0px transparent'
                                                          },
                                                          'className': 'font-bold'
                                                        }
                                                      ],
                                                      'size': 'xs',
                                                      'style': {
                                                        'position': 'static',
                                                        'display': 'block',
                                                        'flex': '1 1 auto',
                                                        'flexGrow': 1,
                                                        'flexBasis': 'auto',
                                                        'boxShadow': ' 0px 0px 0px 0px transparent'
                                                      },
                                                      'wrapperBody': false,
                                                      'isFixedHeight': false,
                                                      'isFixedWidth': false,
                                                      'id': 'u:1ca4c136f4ce'
                                                    }
                                                  ],
                                                  'style': {
                                                    'position': 'relative',
                                                    'inset': 'auto',
                                                    'flexWrap': 'nowrap',
                                                    'boxShadow': ' 0px 0px 0px 0px transparent',
                                                    'flexDirection': 'column'
                                                  },
                                                  'id': 'u:39644b42dcc2',
                                                  'isFixedHeight': false,
                                                  'isFixedWidth': false
                                                }
                                              ],
                                              'id': 'u:c330926a4ca1'
                                            }
                                          ],
                                          'id': 'u:be4fdb6233e5',
                                          'style': {
                                            'boxShadow': ' 0px 0px 0px 0px transparent'
                                          },
                                          'align': 'left'
                                        },
                                        {
                                          'type': 'grid',
                                          'columns': [
                                            {
                                              'body': [
                                                {
                                                  'type': 'flex',
                                                  'className': 'p-1',
                                                  'items': [
                                                    {
                                                      'type': 'container',
                                                      'body': [
                                                        {
                                                          'type': 'tpl',
                                                          'tpl': '${recentlyUpperOnline}',
                                                          'inline': true,
                                                          'wrapperComponent': '',
                                                          'id': 'u:d2614e2451e7',
                                                          'style': {
                                                            'boxShadow': ' 0px 0px 0px 0px transparent'
                                                          }
                                                        }
                                                      ],
                                                      'size': 'xs',
                                                      'style': {
                                                        'position': 'static',
                                                        'display': 'block',
                                                        'flex': '1 1 auto',
                                                        'flexGrow': 1,
                                                        'flexBasis': 'auto',
                                                        'boxShadow': ' 0px 0px 0px 0px transparent'
                                                      },
                                                      'wrapperBody': false,
                                                      'isFixedHeight': false,
                                                      'isFixedWidth': false,
                                                      'id': 'u:4d571d9d6d83',
                                                      'wrapperComponent': 'div'
                                                    },
                                                    {
                                                      'type': 'container',
                                                      'body': [
                                                        {
                                                          'type': 'tpl',
                                                          'tpl': '最近上线时间',
                                                          'inline': true,
                                                          'wrapperComponent': '',
                                                          'id': 'u:9ca5ff19982f',
                                                          'style': {
                                                            'boxShadow': ' 0px 0px 0px 0px transparent'
                                                          },
                                                          'className': 'font-bold'
                                                        }
                                                      ],
                                                      'size': 'xs',
                                                      'style': {
                                                        'position': 'static',
                                                        'display': 'block',
                                                        'flex': '1 1 auto',
                                                        'flexGrow': 1,
                                                        'flexBasis': 'auto',
                                                        'boxShadow': ' 0px 0px 0px 0px transparent'
                                                      },
                                                      'wrapperBody': false,
                                                      'isFixedHeight': false,
                                                      'isFixedWidth': false,
                                                      'id': 'u:5995fad18598'
                                                    }
                                                  ],
                                                  'style': {
                                                    'position': 'relative',
                                                    'inset': 'auto',
                                                    'flexWrap': 'nowrap',
                                                    'boxShadow': ' 0px 0px 0px 0px transparent',
                                                    'flexDirection': 'column'
                                                  },
                                                  'id': 'u:4e47f23c5d6f',
                                                  'isFixedHeight': false,
                                                  'isFixedWidth': false
                                                }
                                              ],
                                              'id': 'u:8240cf0f90c2',
                                              'style': {
                                                'boxShadow': ' 0px 0px 0px 0px transparent'
                                              }
                                            },
                                            {
                                              'body': [
                                                {
                                                  'type': 'flex',
                                                  'className': 'p-1',
                                                  'items': [
                                                    {
                                                      'type': 'container',
                                                      'body': [
                                                        {
                                                          'type': 'tpl',
                                                          'tpl': '${recentlyBelowOnline}',
                                                          'inline': true,
                                                          'wrapperComponent': '',
                                                          'id': 'u:f34117289866',
                                                          'style': {
                                                            'boxShadow': ' 0px 0px 0px 0px transparent'
                                                          }
                                                        }
                                                      ],
                                                      'size': 'xs',
                                                      'style': {
                                                        'position': 'static',
                                                        'display': 'block',
                                                        'flex': '1 1 auto',
                                                        'flexGrow': 1,
                                                        'flexBasis': 'auto',
                                                        'boxShadow': ' 0px 0px 0px 0px transparent'
                                                      },
                                                      'wrapperBody': false,
                                                      'isFixedHeight': false,
                                                      'isFixedWidth': false,
                                                      'id': 'u:4f90ddc64aaa',
                                                      'wrapperComponent': 'div'
                                                    },
                                                    {
                                                      'type': 'container',
                                                      'body': [
                                                        {
                                                          'type': 'tpl',
                                                          'tpl': '最近离线时间',
                                                          'inline': true,
                                                          'wrapperComponent': '',
                                                          'id': 'u:ddf4e7a13362',
                                                          'style': {
                                                            'boxShadow': ' 0px 0px 0px 0px transparent'
                                                          },
                                                          'className': 'font-bold'
                                                        }
                                                      ],
                                                      'size': 'xs',
                                                      'style': {
                                                        'position': 'static',
                                                        'display': 'block',
                                                        'flex': '1 1 auto',
                                                        'flexGrow': 1,
                                                        'flexBasis': 'auto',
                                                        'boxShadow': ' 0px 0px 0px 0px transparent'
                                                      },
                                                      'wrapperBody': false,
                                                      'isFixedHeight': false,
                                                      'isFixedWidth': false,
                                                      'id': 'u:98019b41e2e0'
                                                    }
                                                  ],
                                                  'style': {
                                                    'position': 'relative',
                                                    'inset': 'auto',
                                                    'flexWrap': 'nowrap',
                                                    'boxShadow': ' 0px 0px 0px 0px transparent',
                                                    'flexDirection': 'column'
                                                  },
                                                  'id': 'u:6b4a5a7e68fa',
                                                  'isFixedHeight': false,
                                                  'isFixedWidth': false
                                                }
                                              ],
                                              'id': 'u:e5a2e262bd28',
                                              'style': {
                                                'boxShadow': ' 0px 0px 0px 0px transparent'
                                              }
                                            }
                                          ],
                                          'id': 'u:0e91f98c745c',
                                          'style': {
                                            'boxShadow': ' 0px 0px 0px 0px transparent'
                                          }
                                        },
                                        {
                                          'type': 'grid',
                                          'columns': [
                                            {
                                              'body': [
                                                {
                                                  'type': 'flex',
                                                  'className': 'p-1',
                                                  'items': [
                                                    {
                                                      'type': 'container',
                                                      'body': [
                                                        {
                                                          'type': 'tpl',
                                                          'tpl': "${IF(!!address,address,'--')}",
                                                          'inline': true,
                                                          'wrapperComponent': '',
                                                          'id': 'u:472bd957e043',
                                                          'style': {
                                                            'boxShadow': ' 0px 0px 0px 0px transparent'
                                                          }
                                                        }
                                                      ],
                                                      'size': 'xs',
                                                      'style': {
                                                        'position': 'static',
                                                        'display': 'block',
                                                        'flex': '1 1 auto',
                                                        'flexGrow': 1,
                                                        'flexBasis': 'auto',
                                                        'boxShadow': ' 0px 0px 0px 0px transparent'
                                                      },
                                                      'wrapperBody': false,
                                                      'isFixedHeight': false,
                                                      'isFixedWidth': false,
                                                      'id': 'u:f04976d9a0d8',
                                                      'wrapperComponent': 'div'
                                                    },
                                                    {
                                                      'type': 'container',
                                                      'body': [
                                                        {
                                                          'type': 'tpl',
                                                          'tpl': '设备地址',
                                                          'inline': true,
                                                          'wrapperComponent': '',
                                                          'id': 'u:a6d44c2b03e1',
                                                          'style': {
                                                            'boxShadow': '0px 0px 0px 0px transparent'
                                                          },
                                                          'className': 'font-bold'
                                                        }
                                                      ],
                                                      'size': 'xs',
                                                      'style': {
                                                        'position': 'static',
                                                        'display': 'block',
                                                        'flex': '1 1 auto',
                                                        'flexGrow': 1,
                                                        'flexBasis': 'auto',
                                                        'boxShadow': ' 0px 0px 0px 0px transparent'
                                                      },
                                                      'wrapperBody': false,
                                                      'isFixedHeight': false,
                                                      'isFixedWidth': false,
                                                      'id': 'u:6fb5ca5e8101'
                                                    }
                                                  ],
                                                  'style': {
                                                    'position': 'relative',
                                                    'inset': 'auto',
                                                    'flexWrap': 'nowrap',
                                                    'boxShadow': ' 0px 0px 0px 0px transparent',
                                                    'flexDirection': 'column'
                                                  },
                                                  'id': 'u:27731f73bdf5',
                                                  'isFixedHeight': false,
                                                  'isFixedWidth': false
                                                }
                                              ],
                                              'id': 'u:70ac64565481',
                                              'style': {
                                                'boxShadow': ' 0px 0px 0px 0px transparent'
                                              }
                                            }
                                          ],
                                          'id': 'u:f7deb696fb2c',
                                          'style': {
                                            'boxShadow': ' 0px 0px 0px 0px transparent'
                                          }
                                        }
                                      ],
                                      'id': 'u:7fc43957d415',
                                      'affixFooter': false,
                                      'actions': [],
                                      'hidden': false,
                                      'headerClassName': 'bg-primary p-t-sm p-b-sm p-l-xs no-border r-t text-white',
                                      'className': 'no-border Panel--default',
                                      'bodyClassName': 'no-border',
                                      'subFormMode': 'normal'
                                    }
                                  ],
                                  'id': 'u:0be14e7dff94',
                                  'style': {
                                    'boxShadow': ' 0px 0px 0px 0px transparent'
                                  },
                                  'md': 3
                                }
                              ],
                              'style': {
                                'boxShadow': ' 0px 0px 0px 0px transparent'
                              }
                            },
                            {
                              'type': 'grid',
                              'columns': [
                                {
                                  'body': [
                                    {
                                      'type': 'container',
                                      'style': {
                                        'position': 'static',
                                        'boxShadow': ' 0px 0px 0px 0px transparent',
                                        'display': 'flex',
                                        'flexWrap': 'nowrap',
                                        'justifyContent': 'space-between'
                                      },
                                      'id': 'u:1648ddc8f3af',
                                      'isFixedHeight': false,
                                      'isFixedWidth': false,
                                      'body': [
                                        {
                                          'type': 'container',
                                          'body': [
                                            {
                                              'type': 'button',
                                              'label': '下载数据',
                                              'onEvent': {
                                                'click': {
                                                  'actions': []
                                                }
                                              },
                                              'id': 'u:935406b3bea2',
                                              'themeCss': {
                                                'className': {
                                                  'padding-and-margin:default': {
                                                    'marginTop': '0',
                                                    'marginRight': '16px',
                                                    'marginBottom': '0',
                                                    'marginLeft': '0'
                                                  }
                                                }
                                              },
                                              'className': 'className-935406b3bea2'
                                            },
                                            {
                                              'type': 'button',
                                              'label': '列表显示',
                                              'onEvent': {
                                                'click': {
                                                  'actions': []
                                                }
                                              },
                                              'id': 'u:662feb81f8bf'
                                            }
                                          ],
                                          'style': {
                                            'position': 'static',
                                            'display': 'block',
                                            'boxShadow': ' 0px 0px 0px 0px transparent',
                                            'flex': '0 0 auto',
                                            'overflowY': 'visible',
                                            'height': '32px'
                                          },
                                          'wrapperBody': false,
                                          'id': 'u:a6932fa5abd2',
                                          'isFixedHeight': true,
                                          'isFixedWidth': false
                                        },
                                        {
                                          'type': 'container',
                                          'body': [
                                            {
                                              'type': 'form',
                                              'id': 'u:859b150461f1',
                                              'mode': 'inline',
                                              'title': '过滤条件',
                                              'target': 'historyChart',
                                              'submitOnInit': false,
                                              'wrapWithPanel': false,
                                              'body': [
                                                {
                                                  'type': 'select',
                                                  'label': '',
                                                  'name': 'selectId',
                                                  'id': 'u:e112d3d82a8e',
                                                  'placeholder': '选择设备',
                                                  'onEvent': {
                                                    'change': {
                                                      'weight': 0,
                                                      'actions': []
                                                    }
                                                  },
                                                  'multiple': false,
                                                  'value': '',
                                                  'selectFirst': true,
                                                  'source': '${ARRAYFILTER(deviceData,item => !item.isSelf||item.data.length>0)}',
                                                  'editable': false,
                                                  'labelField': 'deviceName',
                                                  'valueField': 'id',
                                                  'size': 'sm',
                                                  'mode': 'inline',
                                                  'searchable': false,
                                                  'showInvalidMatch': false,
                                                  'className': 'm-b-none',
                                                  'inputClassName': '',
                                                  'labelClassName': ''
                                                },
                                                {
                                                  'type': 'input-datetime-range',
                                                  'label': '',
                                                  'onEvent': {},
                                                  'id': 'u:2c9e14923689',
                                                  'name': 'startTime',
                                                  'extraName': 'endTime',
                                                  'inputFormat': 'YYYY-MM-DD HH:mm:ss',
                                                  'format': 'YYYY-MM-DD HH:mm:ss',
                                                  'timeFormat': 'HH:mm:ss',
                                                  'placeholder': '请选择日期时间范围',
                                                  'minDate': '',
                                                  'maxDate': '',
                                                  'shortcuts': [
                                                    'yesterday',
                                                    '7daysago',
                                                    'prevweek',
                                                    'thismonth',
                                                    'prevmonth',
                                                    'prevquarter'
                                                  ],
                                                  'className': 'm-b-none'
                                                },
                                                {
                                                  'type': 'button',
                                                  'label': '查询',
                                                  'onEvent': {
                                                    'click': {
                                                      'actions': [
                                                        {
                                                          'componentId': 'u:104133c688dd',
                                                          'ignoreError': false,
                                                          'actionType': 'reload',
                                                          'args': {
                                                            'resetPage': true
                                                          }
                                                        }
                                                      ],
                                                      'weight': 0
                                                    }
                                                  },
                                                  'id': 'u:717284fb8d03',
                                                  'themeCss': {
                                                    'className': {
                                                      'padding-and-margin:default': {
                                                        'marginTop': '0',
                                                        'marginRight': '0',
                                                        'marginBottom': '0',
                                                        'marginLeft': '0'
                                                      }
                                                    }
                                                  },
                                                  'className': 'className-717284fb8d03'
                                                }
                                              ],
                                              'actions': [],
                                              'name': '设备列表',
                                              'className': 'm-none p-none',
                                              'reload': '',
                                              'preventEnterSubmit': true,
                                              'submitOnChange': true,
                                              'staticClassName': 'm-none p-none'
                                            }
                                          ],
                                          'style': {
                                            'position': 'static',
                                            'display': 'flex',
                                            'boxShadow': ' 0px 0px 0px 0px transparent',
                                            'flex': '1 1 auto',
                                            'flexWrap': 'nowrap',
                                            'overflowX': 'auto',
                                            'flexGrow': 1,
                                            'flexBasis': 'auto',
                                            'justifyContent': 'flex-end',
                                            'height': '32px',
                                            'overflowY': 'hidden'
                                          },
                                          'wrapperBody': false,
                                          'id': 'u:0938fb2b3144',
                                          'isFixedHeight': true,
                                          'isFixedWidth': false
                                        },
                                        {
                                          'type': 'divider',
                                          'id': 'u:7b27f17d38e4'
                                        }
                                      ],
                                      'wrapperBody': false,
                                      'size': 'sm'
                                    },
                                    {
                                      'type': 'flex',
                                      'style': {
                                        'position': 'relative',
                                        'boxShadow': ' 0px 0px 0px 0px transparent',
                                        'inset': 'auto',
                                        'flexWrap': 'nowrap'
                                      },
                                      'id': 'u:cd2997a1468a',
                                      'isFixedHeight': false,
                                      'isFixedWidth': false,
                                      'className': 'm-none p-none',
                                      'items': [
                                        {
                                          'type': 'container',
                                          'body': [
                                            {
                                              'type': 'divider',
                                              'id': 'u:02987818fac2',
                                              'className': 'm-none p-none b-a'
                                            }
                                          ],
                                          'size': 'none',
                                          'style': {
                                            'position': 'static',
                                            'display': 'block',
                                            'flex': '1 1 auto',
                                            'flexGrow': '1',
                                            'flexBasis': 'auto',
                                            'boxShadow': ' 0px 0px 0px 0px transparent',
                                            'marginTop': '0',
                                            'marginRight': '0',
                                            'marginBottom': '0',
                                            'marginLeft': '0'
                                          },
                                          'wrapperBody': false,
                                          'isFixedHeight': false,
                                          'isFixedWidth': false,
                                          'id': 'u:f04976d9a0d8',
                                          'className': 'm-none p-none no-border'
                                        }
                                      ]
                                    },
                                    {
                                      'type': 'container',
                                      'body': [
                                        {
                                          'type': 'chart',
                                          'config': {
                                            'xAxis': {
                                              'type': 'time',
                                              'id': 'u:f4c5d3195bad',
                                              'axisLabel': {
                                                'formatter': '{yyyy}-{MM}-{dd} {HH}:{mm}:{ss}'
                                              }
                                            },
                                            'yAxis': {
                                              'type': 'value',
                                              'id': 'u:8887928a6efc'
                                            },
                                            'series': [],
                                            'backgroundColor': 'transparent',
                                            'dataZoom': [
                                              {
                                                'start': 0,
                                                'end': 10
                                              }
                                            ],
                                            'tooltip': {
                                              'trigger': 'axis'
                                            }
                                          },
                                          'replaceChartOption': false,
                                          'id': 'u:104133c688dd',
                                          'name': 'historyChart',
                                          'style': {
                                            'boxShadow': ' 0px 0px 0px 0px transparent',
                                            'width': '100%',
                                            'height': '300px'
                                          },
                                          'api': {
                                            'url': '/gather/device/info/history?id=${selectId}&startTime=${startTime}&endTime=${endTime}',
                                            'method': 'get',
                                            'requestAdaptor': '',
                                            'adaptor': '',
                                            'messages': {}
                                          },
                                          'initFetch': false,
                                          'dataFilter': "config.legend={\r\n  data:[]\r\n};\r\nconfig.series = [];\r\nconfig.series = data.items.map(function(item) { \r\n  config.legend.data.push(item.name);\r\n  let obj = { name: item.name, data:[],type:'line'}; \r\n  item.dps.forEach(function(itemDps) { \r\n    obj.data.push([formatDate(Number(itemDps.ts)), itemDps.val]) \r\n  }); \r\n  return obj;\r\n}); \r\nfunction formatDate(value) {var date = new Date(value);var y = date.getFullYear(),m = date.getMonth() + 1,d = date.getDate(),h = date.getHours(),i = date.getMinutes(),s = date.getSeconds();if (m < 10) { m = '0' + m; }if (d < 10) { d = '0' + d; }if (h < 10) { h = '0' + h; }if (i < 10) { i = '0' + i; }if (s < 10) { s = '0' + s; }var t = y + '-' + m + '-' + d + ' ' + h + ':' + i + ':' + s;return t;\r\n}\r\n"
                                        }
                                      ],
                                      'style': {
                                        'position': 'static',
                                        'display': 'block',
                                        'boxShadow': ' 0px 0px 0px 0px transparent'
                                      },
                                      'wrapperBody': false,
                                      'id': 'u:25b8c9da56ca',
                                      'isFixedHeight': false,
                                      'isFixedWidth': false
                                    }
                                  ],
                                  'id': 'u:a072016e6ac3',
                                  'style': {
                                    'boxShadow': ' 0px 0px 0px 0px transparent'
                                  }
                                }
                              ],
                              'id': 'u:c7dfcea692d1',
                              'style': {
                                'boxShadow': ' 0px 0px 0px 0px transparent'
                              }
                            }
                          ],
                          'id': 'u:193602a6a15f'
                        },
                        {
                          'title': '连接',
                          'body': [
                            {
                              'type': 'markdown',
                              'id': 'u:691227e932ba',
                              'value': '## TCP 接入点\n  TCP 接入方式为设备提供了最原始的 TCP/IP 传输方式，支持 ASCII、HEX、JSON 等有效负载，适合一些自定义协议的通信场景，也适用于 TCP 透传方式。  \n  **TCP 地址:** ${mqttServer}  \n  **TCP 端口:** ${mqttPort}  \n  **注册包:** ${mqttLogin}  \n  **心跳包:** ${mqttHeart}  \n\n  ## MQTT 接入点  \n  MQTT 接入方式为设备和云平台提供双向连接，设备既可上报属性数据，也可接收云端的消息下发。  \n  **MQTT 主机:** ${mqttServer}  \n  **MQTT 端口:** 1883  \n  **Username:** admin  \n  **Password:** 123456\n\n\n\n  '
                            }
                          ],
                          'id': 'u:14e6d17573b4'
                        },
                        {
                          'title': '属性',
                          'body': [
                            {
                              'type': 'crud',
                              'id': 'devAttrPage1:table',
                              'draggable': false,
                              'perPage': 10,
                              'keepItemSelectionOnPageChange': true,
                              'maxKeepItemSelectionLength': 11,
                              'labelTpl': '${id}',
                              'autoGenerateFilter': {
                                'columnsNum': 4,
                                'showBtnToolbar': false
                              },
                              'bulkActions': [],
                              'quickSaveApi': '',
                              'quickSaveItemApi': '',
                              'filterTogglable': true,
                              'headerToolbar': [
                                {
                                  'type': 'bulk-actions'
                                }
                              ],
                              'footerToolbar': [],
                              'columns': [
                                {
                                  'name': 'attrName',
                                  'label': '属性名称',
                                  'id': 'LIST:属性名称',
                                  'type': 'text',
                                  'placeholder': '-'
                                },
                                {
                                  'name': 'attrCode',
                                  'label': '属性标识符',
                                  'id': 'LIST:属性标识符',
                                  'type': 'text',
                                  'placeholder': '-'
                                },
                                {
                                  'name': 'currentValue',
                                  'label': '当前值',
                                  'id': 'LIST:单位',
                                  'type': 'text',
                                  'placeholder': '-',
                                  'value': '${value+attrUnit}'
                                },
                                {
                                  'name': 'attributeType',
                                  'label': '属性类型',
                                  'id': 'u:25ac6392a134',
                                  'type': 'mapping',
                                  'value': '0',
                                  'map': {
                                    '0': '设备上报',
                                    '1': '云端下发',
                                    '2': '设备云端共享',
                                    '3': '云端私有'
                                  },
                                  'itemSchema': {
                                    'type': 'tag',
                                    'label': '${item}'
                                  }
                                },
                                {
                                  'type': 'mapping',
                                  'value': 'Number',
                                  'map': {
                                    'Number': '数值',
                                    'Switch': '开关量',
                                    'Text': '文本',
                                    'Enum': '枚举',
                                    'Object': '键值对',
                                    'List': '列表'
                                  },
                                  'itemSchema': {
                                    'type': 'tag',
                                    'label': '${item}'
                                  },
                                  'name': 'dataType',
                                  'label': '属性类型',
                                  'id': 'u:9f9dcc8950f7'
                                },
                                {
                                  'type': 'operation',
                                  'label': '操作',
                                  'id': 'u:3ba1b5bedd76',
                                  'buttons': [
                                    {
                                      'type': 'button',
                                      'label': '读取',
                                      'onEvent': {
                                        'click': {
                                          'actions': [
                                            {
                                              'ignoreError': false,
                                              'outputVar': 'responseResult',
                                              'actionType': 'ajax',
                                              'options': {},
                                              'api': {
                                                'url': '/gather/device/info/readDataPoint',
                                                'method': 'post',
                                                'requestAdaptor': '',
                                                'adaptor': '',
                                                'messages': {},
                                                'data': {
                                                  'devNo': '${deviceNo}',
                                                  'attrIdentifier': '${attrCode}'
                                                },
                                                'dataType': 'json'
                                              }
                                            },
                                            {
                                              'componentId': 'u:048c2e359e49',
                                              'ignoreError': false,
                                              'actionType': 'reload'
                                            }
                                          ]
                                        }
                                      },
                                      'id': 'u:9ca927e16ca7',
                                      'level': 'link'
                                    },
                                    {
                                      'type': 'button',
                                      'label': '写入',
                                      'onEvent': {
                                        'click': {
                                          'actions': [
                                            {
                                              'ignoreError': false,
                                              'actionType': 'dialog',
                                              'dialog': {
                                                'type': 'dialog',
                                                'title': '数据写入',
                                                'body': [
                                                  {
                                                    'type': 'form',
                                                    'title': '表单',
                                                    'body': [
                                                      {
                                                        'label': '设备标识',
                                                        'type': 'input-text',
                                                        'name': 'devNo',
                                                        'id': 'u:c39e1d4845d8',
                                                        'value': '${deviceNo}',
                                                        'static': true
                                                      },
                                                      {
                                                        'name': 'dataIdentifier',
                                                        'type': 'input-text',
                                                        'label': '参数',
                                                        'id': 'u:fc36fb0b9e6b',
                                                        'value': '${attrCode}',
                                                        'static': true
                                                      },
                                                      {
                                                        'name': 'val',
                                                        'type': 'input-text',
                                                        'label': '写入值',
                                                        'id': 'u:ac2d8921765a'
                                                      }
                                                    ],
                                                    'api': {
                                                      'url': '/gather/device/info/writeDataPoint',
                                                      'method': 'post',
                                                      'requestAdaptor': "api.data.dataIdentifier = api.data.dataIdentifier.split(',');",
                                                      'adaptor': '',
                                                      'messages': {},
                                                      'dataType': 'json'
                                                    },
                                                    'id': 'u:a9ab1784c1b8',
                                                    'name': '设备详情demo',
                                                    'onEvent': {
                                                      'submitSucc': {
                                                        'weight': 0,
                                                        'actions': [
                                                          {
                                                            'componentId': 'u:048c2e359e49',
                                                            'ignoreError': false,
                                                            'actionType': 'reload'
                                                          }
                                                        ]
                                                      }
                                                    }
                                                  }
                                                ],
                                                'showCloseButton': true,
                                                'showErrorMsg': true,
                                                'showLoading': true,
                                                'className': 'app-popover',
                                                'id': 'u:23b77ffd5ba7',
                                                'closeOnEsc': false
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      'id': 'u:41ec7433abcc',
                                      'level': 'link'
                                    },
                                    {
                                      'type': 'button',
                                      'label': '历史记录',
                                      'onEvent': {
                                        'click': {
                                          'actions': [
                                            {
                                              'ignoreError': false,
                                              'actionType': 'dialog',
                                              'dialog': {
                                                'type': 'dialog',
                                                'title': '弹框标题',
                                                'body': [
                                                  {
                                                    'type': 'crud',
                                                    'syncLocation': false,
                                                    'api': {
                                                      'method': 'get',
                                                      'url': '/gather/device/info/historyByAttrCode?id=${deviceId}&attrs=${attrCode}'
                                                    },
                                                    'columns': [
                                                      {
                                                        'name': 'ts',
                                                        'label': '时间',
                                                        'type': 'datetime',
                                                        'id': 'u:394c5a1704ec',
                                                        'value': 1699428318,
                                                        'valueFormat': 'x'
                                                      },
                                                      {
                                                        'name': 'val',
                                                        'label': '数值',
                                                        'type': 'text',
                                                        'id': 'u:7bd207efd742',
                                                        'placeholder': '-'
                                                      }
                                                    ],
                                                    'bulkActions': [],
                                                    'itemActions': [],
                                                    'id': 'u:ae06abca4d95',
                                                    'perPageAvailable': [
                                                      20
                                                    ],
                                                    'name': '设备详情demo',
                                                    'messages': {},
                                                    'perPage': 20,
                                                    'loadDataOnce': true
                                                  }
                                                ],
                                                'showCloseButton': true,
                                                'showErrorMsg': true,
                                                'showLoading': true,
                                                'className': 'app-popover',
                                                'id': 'u:6b967b78a884',
                                                'closeOnEsc': false,
                                                'size': 'lg'
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      'id': 'u:e9469bd1a691',
                                      'level': 'link'
                                    }
                                  ]
                                }
                              ],
                              'perPageAvailable': [
                                10,
                                20
                              ],
                              'messages': {},
                              'filterSettingSource': [
                                'objectCode',
                                'objectName',
                                'tableName',
                                'id',
                                'createTime'
                              ],
                              'perPageField': 'pageSize',
                              'pageField': 'pageNum',
                              'features': [
                                'filter'
                              ],
                              'itemActions': [],
                              'onEvent': {},
                              'footable': false,
                              'alwaysShowPagination': true,
                              'initFetch': true,
                              'name': '设备模板',
                              'source': '${deviceData[0].data}'
                            }
                          ],
                          'id': 'u:c2c939d20e4b',
                          'hiddenOn': "${permissions!='*:*:*'&&!ARRAYINCLUDES(permissions,'gather:sample:list')}"
                        },
                        {
                          'title': '报警',
                          'body': [
                            {
                              'type': 'tabs',
                              'id': 'u:4ce47ff3cc70',
                              'tabs': [
                                {
                                  'title': '告警历史',
                                  'body': [
                                    {
                                      'type': 'crud',
                                      'draggable': false,
                                      'api': {
                                        'method': 'get',
                                        'url': '/gather/device/alarm/page',
                                        'messages': {},
                                        'requestAdaptor': '',
                                        'adaptor': '',
                                        'dataType': 'json',
                                        'data': {
                                          '&': '$$',
                                          'deviceId': '${id}'
                                        }
                                      },
                                      'perPage': 10,
                                      'keepItemSelectionOnPageChange': true,
                                      'maxKeepItemSelectionLength': 11,
                                      'labelTpl': '${id}',
                                      'autoGenerateFilter': {
                                        'columnsNum': 4,
                                        'showBtnToolbar': false
                                      },
                                      'bulkActions': [],
                                      'quickSaveApi': '',
                                      'quickSaveItemApi': '',
                                      'filterTogglable': true,
                                      'headerToolbar': [
                                        'bulkActions',
                                        'export-excel',
                                        {
                                          'type': 'columns-toggler',
                                          'align': 'right'
                                        },
                                        {
                                          'type': 'drag-toggler',
                                          'align': 'right'
                                        },
                                        {
                                          'type': 'pagination',
                                          'align': 'right'
                                        }
                                      ],
                                      'footerToolbar': [
                                        {
                                          'type': 'statistics'
                                        },
                                        {
                                          'type': 'pagination',
                                          'layout': 'perPage,pager,go'
                                        }
                                      ],
                                      'columns': [
                                        {
                                          'name': 'triggerName',
                                          'label': '触发器名称',
                                          'id': 'LIST:触发器名称',
                                          'type': 'text'
                                        },
                                        {
                                          'type': 'mapping',
                                          'value': 1,
                                          'map': {
                                            '1': '独立触发器',
                                            '2': '模板触发器'
                                          },
                                          'itemSchema': {
                                            'type': 'tag',
                                            'label': '${item}'
                                          },
                                          'name': 'triggerType',
                                          'label': '触发器类型',
                                          'id': 'u:af11d37dd127'
                                        },
                                        {
                                          'name': 'triggerAttrName',
                                          'label': '触发属性名称',
                                          'id': 'LIST:触发属性名称',
                                          'type': 'text'
                                        },
                                        {
                                          'name': 'triggerCondition',
                                          'label': '触发条件',
                                          'id': 'LIST:触发条件',
                                          'type': 'text',
                                          'inline': true,
                                          'className': '',
                                          'placeholder': '-',
                                          'tpl': '${triggerCondition+deviceAttrUnit}'
                                        },
                                        {
                                          'name': 'value',
                                          'label': '报警触发值',
                                          'id': 'LIST:报警值',
                                          'type': 'text',
                                          'placeholder': '-',
                                          'inline': true,
                                          'tpl': '${value+deviceAttrUnit}'
                                        },
                                        {
                                          'name': 'alarmContent',
                                          'label': '报警内容',
                                          'id': 'LIST:报警内容',
                                          'type': 'text',
                                          'placeholder': '-',
                                          'toggled': false
                                        },
                                        {
                                          'name': 'alarmTime',
                                          'label': '报警时间',
                                          'id': 'LIST:报警时间',
                                          'type': 'text',
                                          'placeholder': '-',
                                          'sortable': false
                                        },
                                        {
                                          'type': 'mapping',
                                          'value': 1,
                                          'map': {
                                            '0': '报警中',
                                            '1': '已处理',
                                            '2': '已恢复'
                                          },
                                          'itemSchema': {
                                            'type': 'tag',
                                            'label': '${item}',
                                            'id': 'u:079b7a76d847',
                                            'displayMode': 'normal',
                                            'style': {
                                              'color': '#eb0d0d'
                                            }
                                          },
                                          'name': 'dealStatus',
                                          'label': '状态',
                                          'id': 'u:a71e84245805',
                                          'placeholder': '-',
                                          'searchable': {
                                            'type': 'select',
                                            'label': '处理状态',
                                            'name': 'dealStatus',
                                            'options': [
                                              {
                                                'label': '报警中',
                                                'value': 0
                                              },
                                              {
                                                'label': '已处理',
                                                'value': 1
                                              },
                                              {
                                                'label': '已恢复',
                                                'value': 2
                                              }
                                            ],
                                            'id': 'u:b095661ebc45',
                                            'multiple': false,
                                            'value': 0
                                          }
                                        },
                                        {
                                          'name': 'dealContent',
                                          'label': '处理内容',
                                          'id': 'LIST:处理内容',
                                          'type': 'text',
                                          'inline': true,
                                          'placeholder': '-',
                                          'popOver': false,
                                          'toggled': false
                                        },
                                        {
                                          'type': 'tpl',
                                          'tpl': '',
                                          'inline': true,
                                          'wrapperComponent': '',
                                          'name': 'dealTime',
                                          'label': '解决时间',
                                          'style': {
                                            'boxShadow': ' 0px 0px 0px 0px transparent'
                                          },
                                          'placeholder': '-',
                                          'id': 'u:d6677c29728f',
                                          'toggled': false
                                        },
                                        {
                                          'type': 'operation',
                                          'label': '操作',
                                          'buttons': [
                                            {
                                              'type': 'button',
                                              'label': '处理',
                                              'onEvent': {
                                                'click': {
                                                  'actions': [
                                                    {
                                                      'ignoreError': false,
                                                      'actionType': 'drawer',
                                                      'drawer': {
                                                        'closeOnEsc': true,
                                                        'size': 'md',
                                                        'type': 'drawer',
                                                        'closeOnOutside': true,
                                                        'title': '编辑',
                                                        'body': [
                                                          {
                                                            'name': '报警记录',
                                                            'id': 'u:203cbc4fd268',
                                                            'type': 'form',
                                                            'title': '表单',
                                                            'body': [
                                                              {
                                                                'name': 'id',
                                                                'label': '数据id',
                                                                'id': 'UPDATE:数据id',
                                                                'type': 'input-text',
                                                                'hidden': true
                                                              },
                                                              {
                                                                'name': 'dealContent',
                                                                'label': '处理内容',
                                                                'id': 'u:c73273ccf2ef',
                                                                'type': 'textarea'
                                                              }
                                                            ],
                                                            'api': {
                                                              'url': '/gather/device/alarm/deal',
                                                              'method': 'post',
                                                              'requestAdaptor': '',
                                                              'adaptor': '',
                                                              'messages': {
                                                                'success': '更新成功',
                                                                'failed': '更新失败'
                                                              },
                                                              'dataType': 'json',
                                                              'data': {
                                                                '&': '$$',
                                                                'objectId': '${objectId}'
                                                              }
                                                            },
                                                            'onEvent': {
                                                              'submitSucc': {
                                                                'weight': 0,
                                                                'actions': [
                                                                  {
                                                                    'componentId': 'DeviceAlarm:table',
                                                                    'ignoreError': false,
                                                                    'actionType': 'reload',
                                                                    'args': {
                                                                      'resetPage': true
                                                                    }
                                                                  }
                                                                ]
                                                              }
                                                            }
                                                          }
                                                        ],
                                                        'showCloseButton': true,
                                                        'showErrorMsg': true,
                                                        'showLoading': true,
                                                        'className': 'app-popover',
                                                        'id': 'u:9ba0f77d4e62'
                                                      }
                                                    }
                                                  ]
                                                }
                                              },
                                              'id': 'DeviceAlarm:update',
                                              'level': 'link'
                                            },
                                            {
                                              'type': 'button',
                                              'label': '删除',
                                              'onEvent': {
                                                'click': {
                                                  'actions': [
                                                    {
                                                      'ignoreError': false,
                                                      'actionType': 'ajax',
                                                      'outputVar': 'responseResult',
                                                      'options': {
                                                        'silent': false
                                                      },
                                                      'api': {
                                                        'url': '/gather/device/alarm/delete?id=${id}&objectId=${objectId}',
                                                        'method': 'post',
                                                        'requestAdaptor': 'var id = api.data.id;\r\napi.data=[];\r\napi.data[0]=id;\r\nreturn api;',
                                                        'adaptor': '',
                                                        'messages': {},
                                                        'dataType': 'json',
                                                        'replaceData': false,
                                                        'data': {
                                                          '&': '$$'
                                                        }
                                                      }
                                                    },
                                                    {
                                                      'componentId': 'DeviceAlarm:table',
                                                      'ignoreError': false,
                                                      'actionType': 'reload',
                                                      'args': {
                                                        'resetPage': true
                                                      }
                                                    }
                                                  ]
                                                }
                                              },
                                              'id': 'DeviceAlarm:delete',
                                              'level': 'link',
                                              'confirmText': '数据删除后无法恢复，请确认!'
                                            }
                                          ],
                                          'id': 'u:3ba1b5bedd76'
                                        }
                                      ],
                                      'id': 'DeviceAlarm:table',
                                      'perPageAvailable': [
                                        10,
                                        20
                                      ],
                                      'messages': {},
                                      'filterSettingSource': [
                                        'objectCode',
                                        'objectName',
                                        'tableName',
                                        'id',
                                        'createTime'
                                      ],
                                      'perPageField': 'pageSize',
                                      'pageField': 'pageNum',
                                      'features': [
                                        'filter'
                                      ],
                                      'itemActions': [],
                                      'onEvent': {},
                                      'footable': false,
                                      'alwaysShowPagination': true,
                                      'name': '表格2'
                                    }
                                  ],
                                  'id': 'u:2ad6ab927917'
                                },
                                {
                                  'title': '告警规则',
                                  'body': [
                                    {
                                      'type': 'crud',
                                      'draggable': false,
                                      'api': {
                                        'method': 'get',
                                        'url': '/gather/trigger/info/page',
                                        'messages': {},
                                        'requestAdaptor': '',
                                        'adaptor': '',
                                        'dataType': 'json',
                                        'data': {
                                          '&': '$$',
                                          'triggerObjectId': '${id}'
                                        }
                                      },
                                      'perPage': 10,
                                      'keepItemSelectionOnPageChange': true,
                                      'maxKeepItemSelectionLength': 11,
                                      'labelTpl': '${id}',
                                      'autoGenerateFilter': {
                                        'columnsNum': 4,
                                        'showBtnToolbar': false
                                      },
                                      'bulkActions': [],
                                      'quickSaveApi': '',
                                      'quickSaveItemApi': '',
                                      'filterTogglable': true,
                                      'headerToolbar': [
                                        'bulkActions',
                                        'export-excel',
                                        {
                                          'type': 'button',
                                          'label': '新增',
                                          'icon': 'fa fa-plus pull-left',
                                          'id': 'AlarmRule:add',
                                          'onEvent': {
                                            'click': {
                                              'actions': [
                                                {
                                                  'ignoreError': false,
                                                  'actionType': 'drawer',
                                                  'drawer': {
                                                    'closeOnEsc': true,
                                                    'size': 'md',
                                                    'type': 'drawer',
                                                    'closeOnOutside': true,
                                                    'title': '新增',
                                                    'body': [
                                                      {
                                                        'type': 'form',
                                                        'title': '表单',
                                                        'body': [
                                                          {
                                                            'name': 'triggerName',
                                                            'label': '规则名称',
                                                            'id': 'CREATE:规则名称',
                                                            'type': 'input-text'
                                                          },
                                                          {
                                                            'name': 'projectId',
                                                            'label': '所属项目',
                                                            'id': 'CREATE:所属项目',
                                                            'type': 'tree-select',
                                                            'multiple': false,
                                                            'onEvent': {},
                                                            'enableNodePath': false,
                                                            'showIcon': true,
                                                            'initiallyOpen': true,
                                                            'source': {
                                                              'url': '/system/project/info/projectAllTree',
                                                              'method': 'get'
                                                            },
                                                            'labelField': 'projectName',
                                                            'valueField': 'id',
                                                            'required': true
                                                          },
                                                          {
                                                            'name': 'status',
                                                            'label': '状态',
                                                            'id': 'u:67239c2b989d',
                                                            'type': 'switch',
                                                            'option': '',
                                                            'falseValue': 0,
                                                            'trueValue': 1,
                                                            'value': 0,
                                                            'hidden': true
                                                          },
                                                          {
                                                            'name': 'remark',
                                                            'label': '备注',
                                                            'id': 'CREATE:备注',
                                                            'type': 'input-text'
                                                          },
                                                          {
                                                            'name': 'sort',
                                                            'label': '排序',
                                                            'id': 'u:09c6a3e10435',
                                                            'type': 'input-number',
                                                            'keyboard': true,
                                                            'value': 1
                                                          }
                                                        ],
                                                        'api': {
                                                          'url': '/gather/trigger/info/add',
                                                          'method': 'post',
                                                          'requestAdaptor': '',
                                                          'adaptor': '',
                                                          'messages': {
                                                            'success': '创建成功',
                                                            'failed': '创建失败'
                                                          },
                                                          'dataType': 'json'
                                                        },
                                                        'id': 'u:f141fe10696a',
                                                        'onEvent': {
                                                          'submitSucc': {
                                                            'weight': 0,
                                                            'actions': [
                                                              {
                                                                'componentId': 'AlarmRule:table',
                                                                'ignoreError': false,
                                                                'actionType': 'reload',
                                                                'args': {
                                                                  'resetPage': true
                                                                }
                                                              }
                                                            ]
                                                          }
                                                        }
                                                      }
                                                    ],
                                                    'showCloseButton': true,
                                                    'showErrorMsg': true,
                                                    'showLoading': true,
                                                    'className': 'app-popover',
                                                    'id': 'u:9decfaedac64'
                                                  }
                                                }
                                              ]
                                            }
                                          },
                                          'primary': true
                                        },
                                        {
                                          'type': 'tpl',
                                          'className': 'v-middle',
                                          'id': 'u:1b06e197002e',
                                          'tpl': '<p>当前有 ${total} 条数据。</p>'
                                        },
                                        {
                                          'type': 'link',
                                          'href': 'https://www.baidu.com',
                                          'body': '占个位置吧',
                                          'htmlTarget': '_parent',
                                          'className': 'v-middle',
                                          'id': 'u:d0a3349c5766',
                                          'blank': true,
                                          'disabled': true
                                        },
                                        {
                                          'type': 'columns-toggler',
                                          'align': 'right'
                                        },
                                        {
                                          'type': 'drag-toggler',
                                          'align': 'right'
                                        },
                                        {
                                          'type': 'pagination',
                                          'align': 'right'
                                        }
                                      ],
                                      'footerToolbar': [
                                        {
                                          'type': 'statistics'
                                        },
                                        {
                                          'type': 'pagination',
                                          'layout': 'perPage,pager,go'
                                        }
                                      ],
                                      'columns': [
                                        {
                                          'name': 'triggerName',
                                          'label': '规则名称',
                                          'id': 'LIST:规则名称',
                                          'type': 'text',
                                          'searchable': {
                                            'clearable': 'true',
                                            'id': 'u:cc43ed3835ef'
                                          }
                                        },
                                        {
                                          'name': 'status',
                                          'label': '状态',
                                          'id': 'u:7e7ff8996c61',
                                          'type': 'switch',
                                          'option': '',
                                          'falseValue': 0,
                                          'trueValue': 1,
                                          'onEvent': {
                                            'change': {
                                              'weight': 0,
                                              'actions': [
                                                {
                                                  'ignoreError': false,
                                                  'outputVar': 'responseResult',
                                                  'actionType': 'ajax',
                                                  'options': {},
                                                  'api': {
                                                    'url': '/gather/trigger/info/updateStatus',
                                                    'method': 'post',
                                                    'requestAdaptor': '',
                                                    'adaptor': '',
                                                    'messages': {},
                                                    'data': {
                                                      '&': '$$',
                                                      'id': '${id}'
                                                    },
                                                    'dataType': 'json'
                                                  }
                                                }
                                              ]
                                            }
                                          }
                                        },
                                        {
                                          'name': 'createTime',
                                          'label': '创建时间',
                                          'id': 'LIST:创建时间',
                                          'type': 'text'
                                        },
                                        {
                                          'name': 'sort',
                                          'label': '排序',
                                          'id': 'LIST:排序',
                                          'type': 'text'
                                        },
                                        {
                                          'name': 'remark',
                                          'label': '备注',
                                          'id': 'LIST:备注',
                                          'type': 'text'
                                        },
                                        {
                                          'type': 'operation',
                                          'label': '操作',
                                          'buttons': [
                                            {
                                              'type': 'button',
                                              'label': '报警规则',
                                              'onEvent': {
                                                'click': {
                                                  'actions': [
                                                    {
                                                      'ignoreError': false,
                                                      'actionType': 'dialog',
                                                      'dialog': {
                                                        'size': 'lg',
                                                        'type': 'dialog',
                                                        'title': '报警规则配置',
                                                        'body': [
                                                          {
                                                            'type': 'form',
                                                            'title': '表单',
                                                            'body': [
                                                              {
                                                                'id': 'u:ac04c8b2a7d7',
                                                                'type': 'fieldset',
                                                                'title': '基础配置',
                                                                'body': [
                                                                  {
                                                                    'name': 'triggerName',
                                                                    'label': '规则名称',
                                                                    'id': 'UPDATE:规则名称',
                                                                    'type': 'input-text'
                                                                  },
                                                                  {
                                                                    'name': 'id',
                                                                    'label': '触发器Id',
                                                                    'id': 'UPDATE:所属项目',
                                                                    'type': 'input-text',
                                                                    'hidden': true,
                                                                    'required': true,
                                                                    'value': ''
                                                                  },
                                                                  {
                                                                    'name': 'projectId',
                                                                    'label': '所属项目',
                                                                    'id': 'UPDATE:所属项目',
                                                                    'type': 'input-text',
                                                                    'hidden': true,
                                                                    'required': true
                                                                  },
                                                                  {
                                                                    'name': 'status',
                                                                    'label': '状态',
                                                                    'id': 'u:f81a71a40fc2',
                                                                    'type': 'switch',
                                                                    'option': '',
                                                                    'falseValue': 0,
                                                                    'trueValue': 1,
                                                                    'value': 0,
                                                                    'hidden': true
                                                                  },
                                                                  {
                                                                    'name': 'sort',
                                                                    'label': '排序',
                                                                    'id': 'u:92c81c844665',
                                                                    'type': 'input-number',
                                                                    'keyboard': true,
                                                                    'required': true
                                                                  },
                                                                  {
                                                                    'name': 'remark',
                                                                    'label': '备注',
                                                                    'id': 'UPDATE:备注',
                                                                    'type': 'input-text'
                                                                  }
                                                                ],
                                                                'collapsable': true
                                                              },
                                                              {
                                                                'id': 'u:70c088b997f7',
                                                                'type': 'fieldset',
                                                                'title': '报警规则',
                                                                'collapsable': true,
                                                                'body': [
                                                                  {
                                                                    'type': 'radios',
                                                                    'label': '触发器类型',
                                                                    'name': 'triggerType',
                                                                    'options': [
                                                                      {
                                                                        'label': '设备',
                                                                        'value': 1
                                                                      },
                                                                      {
                                                                        'label': '模板',
                                                                        'value': 2
                                                                      }
                                                                    ],
                                                                    'id': 'u:10798a100867',
                                                                    'value': 1,
                                                                    'required': true
                                                                  },
                                                                  {
                                                                    'type': 'tree-select',
                                                                    'label': '设备源',
                                                                    'name': 'triggerObjectId',
                                                                    'id': 'u:385f6470cc83',
                                                                    'onEvent': {},
                                                                    'multiple': false,
                                                                    'enableNodePath': false,
                                                                    'showIcon': true,
                                                                    'initiallyOpen': true,
                                                                    'source': {
                                                                      'url': '/gather/trigger/info/getDeviceSource?triggerType=${triggerType}',
                                                                      'method': 'get'
                                                                    },
                                                                    'required': true
                                                                  },
                                                                  {
                                                                    'type': 'select',
                                                                    'label': '设备属性',
                                                                    'name': 'triggerAttrId',
                                                                    'id': 'u:51708f0109b8',
                                                                    'required': true,
                                                                    'multiple': false,
                                                                    'mode': 'inline',
                                                                    'size': 'sm',
                                                                    'staticClassName': '',
                                                                    'className': 'm-l-xl',
                                                                    'source': {
                                                                      'url': '/gather/trigger/info/getDeviceAttrSource?triggerObjectId=${triggerObjectId}&triggerType=${triggerType}',
                                                                      'method': 'get'
                                                                    }
                                                                  },
                                                                  {
                                                                    'type': 'select',
                                                                    'label': '',
                                                                    'name': 'triggerCondition',
                                                                    'options': [
                                                                      {
                                                                        'label': '开关OFF',
                                                                        'value': 0
                                                                      },
                                                                      {
                                                                        'label': '开关ON',
                                                                        'value': 1
                                                                      },
                                                                      {
                                                                        'label': '数值小于A',
                                                                        'value': 2
                                                                      },
                                                                      {
                                                                        'label': '数值大于B',
                                                                        'value': 3
                                                                      },
                                                                      {
                                                                        'label': '数值大于A且小于B',
                                                                        'value': 4
                                                                      },
                                                                      {
                                                                        'label': '数值小于A或大于B',
                                                                        'value': 5
                                                                      },
                                                                      {
                                                                        'label': '数值等于A',
                                                                        'value': 6
                                                                      }
                                                                    ],
                                                                    'id': 'u:190849f7ecf6',
                                                                    'value': 2,
                                                                    'multiple': false,
                                                                    'required': true,
                                                                    'mode': 'inline',
                                                                    'size': 'sm'
                                                                  },
                                                                  {
                                                                    'name': 'triggerValueA',
                                                                    'label': '',
                                                                    'id': 'UPDATE:A值',
                                                                    'type': 'input-text',
                                                                    'hiddenOn': '${triggerCondition!=2&&triggerCondition!=4&&triggerCondition!=5&&triggerCondition!=6}',
                                                                    'mode': 'inline',
                                                                    'placeholder': 'A值'
                                                                  },
                                                                  {
                                                                    'name': 'triggerValueB',
                                                                    'label': '',
                                                                    'id': 'UPDATE:B值',
                                                                    'type': 'input-text',
                                                                    'hiddenOn': '${triggerCondition!=3&&triggerCondition!=4&&triggerCondition!=5}',
                                                                    'mode': 'inline',
                                                                    'placeholder': 'B值'
                                                                  }
                                                                ]
                                                              },
                                                              {
                                                                'type': 'fieldset',
                                                                'title': '通知设置',
                                                                'collapsable': true,
                                                                'body': [
                                                                  {
                                                                    'type': 'switch',
                                                                    'label': '报警',
                                                                    'option': '',
                                                                    'name': 'alarmStatus',
                                                                    'falseValue': 0,
                                                                    'trueValue': 1,
                                                                    'id': 'u:841c935e0f94',
                                                                    'value': 1,
                                                                    'hidden': true
                                                                  },
                                                                  {
                                                                    'type': 'select',
                                                                    'label': '报警联系人',
                                                                    'name': 'alarmUserIds',
                                                                    'id': 'u:4fa9c5beb67f',
                                                                    'required': true,
                                                                    'multiple': true,
                                                                    'checkAll': false,
                                                                    'source': {
                                                                      'url': '/gather/alarm/user/dataSource',
                                                                      'method': 'get'
                                                                    }
                                                                  },
                                                                  {
                                                                    'type': 'select',
                                                                    'label': '报警类型',
                                                                    'name': 'alarmType',
                                                                    'options': [
                                                                      {
                                                                        'label': '微信公众号报警',
                                                                        'value': 1
                                                                      },
                                                                      {
                                                                        'label': '邮件报警',
                                                                        'value': 2
                                                                      },
                                                                      {
                                                                        'label': '短信报警',
                                                                        'value': 3
                                                                      },
                                                                      {
                                                                        'label': '电话报警',
                                                                        'value': 4
                                                                      }
                                                                    ],
                                                                    'id': 'u:09d234b0617f',
                                                                    'multiple': true,
                                                                    'required': true,
                                                                    'checkAll': false
                                                                  },
                                                                  {
                                                                    'type': 'textarea',
                                                                    'label': '报警推送内容',
                                                                    'name': 'alarmContent',
                                                                    'minRows': 3,
                                                                    'maxRows': 20,
                                                                    'required': true,
                                                                    'value': '开始报警',
                                                                    'id': 'u:da34c9cc8fb3'
                                                                  },
                                                                  {
                                                                    'type': 'textarea',
                                                                    'label': '回复推送内容',
                                                                    'name': 'alarmNormalContent',
                                                                    'minRows': 3,
                                                                    'maxRows': 20,
                                                                    'required': true,
                                                                    'value': '报警恢复',
                                                                    'id': 'u:35a3674529de'
                                                                  }
                                                                ],
                                                                'id': 'u:e69e4d607f8a'
                                                              }
                                                            ],
                                                            'id': 'u:203cbc4fd268',
                                                            'api': {
                                                              'url': '/gather/trigger/info/config',
                                                              'method': 'post',
                                                              'requestAdaptor': '',
                                                              'adaptor': 'response.data={};\r\nreturn response;',
                                                              'messages': {
                                                                'success': '更新成功',
                                                                'failed': '更新失败'
                                                              },
                                                              'dataType': 'json',
                                                              'data': {
                                                                '&': '$$',
                                                                'objectId': '${objectId}'
                                                              }
                                                            },
                                                            'onEvent': {
                                                              'submitSucc': {
                                                                'weight': 0,
                                                                'actions': [
                                                                  {
                                                                    'componentId': 'AlarmRule:table',
                                                                    'ignoreError': false,
                                                                    'actionType': 'reload',
                                                                    'args': {
                                                                      'resetPage': true
                                                                    }
                                                                  }
                                                                ]
                                                              }
                                                            },
                                                            'name': '报警规则',
                                                            'initApi': {
                                                              'url': '/gather/trigger/info/detail?id=${id}',
                                                              'method': 'get',
                                                              'requestAdaptor': '',
                                                              'adaptor': 'return {\r\n  status:0,\r\n  msg:"请求成功",\r\n  data:response.data\r\n}',
                                                              'messages': {},
                                                              'dataType': 'json',
                                                              'replaceData': false,
                                                              'responseData': {
                                                                '&': '$$'
                                                              }
                                                            }
                                                          }
                                                        ],
                                                        'showCloseButton': true,
                                                        'showErrorMsg': true,
                                                        'showLoading': true,
                                                        'className': 'app-popover',
                                                        'id': 'u:9ba0f77d4e62',
                                                        'resizable': false
                                                      }
                                                    }
                                                  ]
                                                }
                                              },
                                              'id': 'AlarmRule:update',
                                              'level': 'link'
                                            },
                                            {
                                              'type': 'button',
                                              'label': '删除',
                                              'onEvent': {
                                                'click': {
                                                  'actions': [
                                                    {
                                                      'ignoreError': false,
                                                      'actionType': 'ajax',
                                                      'outputVar': 'responseResult',
                                                      'options': {
                                                        'silent': false
                                                      },
                                                      'api': {
                                                        'url': '/gather/trigger/info/delete?id=${id}&objectId=${objectId}',
                                                        'method': 'post',
                                                        'requestAdaptor': 'var id = api.data.id;\r\napi.data=[];\r\napi.data[0]=id;\r\nreturn api;',
                                                        'adaptor': '',
                                                        'messages': {},
                                                        'dataType': 'json',
                                                        'replaceData': false,
                                                        'data': {
                                                          '&': '$$'
                                                        }
                                                      }
                                                    },
                                                    {
                                                      'componentId': 'AlarmRule:table',
                                                      'ignoreError': false,
                                                      'actionType': 'reload',
                                                      'args': {
                                                        'resetPage': true
                                                      }
                                                    }
                                                  ]
                                                }
                                              },
                                              'id': 'AlarmRule:delete',
                                              'level': 'link',
                                              'confirmText': '数据删除后无法恢复，请确认!'
                                            }
                                          ],
                                          'id': 'u:3ba1b5bedd76'
                                        }
                                      ],
                                      'id': 'AlarmRule:table',
                                      'perPageAvailable': [
                                        10,
                                        20
                                      ],
                                      'messages': {},
                                      'filterSettingSource': [
                                        'objectCode',
                                        'objectName',
                                        'tableName',
                                        'id',
                                        'createTime'
                                      ],
                                      'perPageField': 'pageSize',
                                      'pageField': 'pageNum',
                                      'features': [
                                        'filter'
                                      ],
                                      'itemActions': [],
                                      'onEvent': {},
                                      'footable': false,
                                      'alwaysShowPagination': true,
                                      'name': '表格2'
                                    }
                                  ],
                                  'id': 'u:7bd0978c6dd5'
                                }
                              ],
                              'tabsMode': 'radio'
                            }
                          ],
                          'id': 'u:76be2137fd77',
                          'hiddenOn': "${permissions!='*:*:*'&&!ARRAYINCLUDES(permissions,'gather:deviceAlarm:list\t')}"
                        },
                        {
                          'title': '组态',
                          'body': [
                            {
                              'type': 'tabs',
                              'tabs': [
                                {
                                  'title': '独立组态',
                                  'body': [
                                    {
                                      'type': 'crud',
                                      'syncLocation': false,
                                      'api': {
                                        'method': 'get',
                                        'url': '/gather/stage/info/page?stageType=1&sampleId=${sampleId}'
                                      },
                                      'columns': [
                                        {
                                          'name': 'projectId',
                                          'label': '所属项目',
                                          'type': 'mapping',
                                          'id': 'LIST:所属项目',
                                          'searchable': {
                                            'name': 'projectId',
                                            'type': 'tree-select',
                                            'label': '所属项目',
                                            'multiple': false,
                                            'onEvent': {},
                                            'enableNodePath': false,
                                            'showIcon': true,
                                            'initiallyOpen': true,
                                            'source': {
                                              'url': '/system/project/info/projectAllTree',
                                              'method': 'get'
                                            },
                                            'labelField': 'projectName',
                                            'valueField': 'id',
                                            'id': 'u:40bd28cde89c',
                                            'searchable': true
                                          },
                                          'source': {
                                            'url': '/system/project/info/dataSource',
                                            'method': 'get'
                                          }
                                        },
                                        {
                                          'type': 'text',
                                          'label': '组态名称',
                                          'name': 'stageName',
                                          'id': 'u:100228ca74eb',
                                          'searchable': true
                                        },
                                        {
                                          'name': 'resolutionWidth',
                                          'label': '分辨率宽度',
                                          'type': 'text',
                                          'id': 'u:4391eed23b25'
                                        },
                                        {
                                          'type': 'text',
                                          'label': '分辨率高度',
                                          'name': 'resolutionHeight',
                                          'id': 'u:676014e9bdaf'
                                        },
                                        {
                                          'type': 'image',
                                          'label': '图片',
                                          'name': 'stageBase64',
                                          'id': 'u:63f269eac847',
                                          'placeholder': '-',
                                          'enlargeAble': true
                                        },
                                        {
                                          'type': 'switch',
                                          'label': '状态',
                                          'name': 'status',
                                          'id': 'u:7e7ff8996c61',
                                          'option': '',
                                          'falseValue': 0,
                                          'trueValue': 1,
                                          'onEvent': {
                                            'change': {
                                              'weight': 0,
                                              'actions': [
                                                {
                                                  'ignoreError': false,
                                                  'outputVar': 'responseResult',
                                                  'actionType': 'ajax',
                                                  'options': {},
                                                  'api': {
                                                    'url': '/gather/stage/info/updateStatus',
                                                    'method': 'post',
                                                    'requestAdaptor': '',
                                                    'adaptor': '',
                                                    'messages': {},
                                                    'data': {
                                                      '&': '$$',
                                                      'id': '${id}'
                                                    },
                                                    'dataType': 'json'
                                                  }
                                                },
                                                {
                                                  'componentId': 'u:48f462f67630',
                                                  'ignoreError': false,
                                                  'actionType': 'reload',
                                                  'args': {
                                                    'resetPage': false
                                                  }
                                                }
                                              ]
                                            }
                                          },
                                          'searchable': {
                                            'type': 'select',
                                            'label': '状态',
                                            'clearable': true,
                                            'name': 'status',
                                            'options': [
                                              {
                                                'label': '启用',
                                                'value': 1
                                              },
                                              {
                                                'label': '禁用',
                                                'value': 0
                                              }
                                            ],
                                            'id': 'u:ace4c43c42fe',
                                            'multiple': false
                                          },
                                          'value': false
                                        },
                                        {
                                          'type': 'operation',
                                          'label': '操作',
                                          'id': 'u:5962c0829fa8',
                                          'buttons': [
                                            {
                                              'type': 'button',
                                              'label': '设计',
                                              'onEvent': {
                                                'click': {
                                                  'actions': [
                                                    {
                                                      'ignoreError': false,
                                                      'actionType': 'url',
                                                      'args': {
                                                        'url': '${window:location[origin]}/static/byzt/index_edit.html',
                                                        'params': {
                                                          'stageId': '${id}',
                                                          'projectId': '${projectId}',
                                                          'type': '1',
                                                          'height': '${resolutionHeight}',
                                                          'width': '${resolutionWidth}',
                                                          'token': '${token}',
                                                          'stageName': '${stageName}'
                                                        }
                                                      }
                                                    }
                                                  ]
                                                }
                                              },
                                              'id': 'u:6546ace0466c',
                                              'level': 'link'
                                            },
                                            {
                                              'type': 'button',
                                              'label': '分享',
                                              'onEvent': {
                                                'click': {
                                                  'actions': [
                                                    {
                                                      'ignoreError': true,
                                                      'actionType': 'dialog',
                                                      'dialog': {
                                                        'type': 'dialog',
                                                        'title': '分享链接',
                                                        'confirmBtnLevel': 'primary',
                                                        'body': [
                                                          {
                                                            'type': 'service',
                                                            'name': '独立组态',
                                                            'id': 'u:b57738ca5179',
                                                            'body': [
                                                              {
                                                                'type': 'tpl',
                                                                'id': 'u:44a34bb76286',
                                                                'tpl': "<span style='word-break:break-all;'>${window:location[origin]}/static/byzt/client_view.html?stageId=${id}&stageName=${stageName}&deviceNo=${dataKeyArray}&type=1&token=${token}</span>",
                                                                'inline': true,
                                                                'wrapperComponent': '',
                                                                'style': {
                                                                  'boxShadow': ' 0px 0px 0px 0px transparent'
                                                                }
                                                              },
                                                              {
                                                                'type': 'flex',
                                                                'className': 'p-1',
                                                                'items': [
                                                                  {
                                                                    'type': 'container',
                                                                    'body': [
                                                                      {
                                                                        'type': 'button',
                                                                        'label': '取消',
                                                                        'id': 'u:a58a4a57f915',
                                                                        'level': 'default',
                                                                        'onEvent': {
                                                                          'click': {
                                                                            'weight': 0,
                                                                            'actions': []
                                                                          }
                                                                        },
                                                                        'close': true,
                                                                        'className': 'mr-2 className-a58a4a57f915',
                                                                        'themeCss': {
                                                                          'className': {
                                                                            'padding-and-margin:default': {
                                                                              'marginTop': '0',
                                                                              'marginRight': '10px',
                                                                              'marginBottom': '0',
                                                                              'marginLeft': '0'
                                                                            }
                                                                          }
                                                                        }
                                                                      },
                                                                      {
                                                                        'type': 'button',
                                                                        'label': '复制',
                                                                        'onEvent': {
                                                                          'click': {
                                                                            'actions': [
                                                                              {
                                                                                'actionType': 'copy',
                                                                                'args': {
                                                                                  'content': '${locationUrl}/static/byzt/client_view.html?stageId=${id}&stageName=${stageName}&deviceNo=${dataKeyArray}&type=1&token=${token}',
                                                                                  'copyFormat': 'text/plain'
                                                                                },
                                                                                'ignoreError': false
                                                                              }
                                                                            ]
                                                                          }
                                                                        },
                                                                        'id': 'u:a06c47b1f3bf',
                                                                        'level': 'primary',
                                                                        'close': true
                                                                      }
                                                                    ],
                                                                    'size': 'xs',
                                                                    'style': {
                                                                      'position': 'static',
                                                                      'display': 'flex',
                                                                      'flex': '1 1 auto',
                                                                      'flexGrow': 1,
                                                                      'flexBasis': 'auto',
                                                                      'boxShadow': ' 0px 0px 0px 0px transparent',
                                                                      'flexWrap': 'nowrap',
                                                                      'justifyContent': 'flex-end',
                                                                      'alignItems': 'flex-end',
                                                                      'marginTop': '20px',
                                                                      'marginRight': '0',
                                                                      'marginBottom': '0',
                                                                      'marginLeft': '0'
                                                                    },
                                                                    'wrapperBody': false,
                                                                    'isFixedHeight': false,
                                                                    'isFixedWidth': false,
                                                                    'id': 'u:6bf826c6feba'
                                                                  }
                                                                ],
                                                                'style': {
                                                                  'position': 'relative',
                                                                  'inset': 'auto',
                                                                  'flexWrap': 'nowrap',
                                                                  'boxShadow': ' 0px 0px 0px 0px transparent'
                                                                },
                                                                'id': 'u:49affa0663ff',
                                                                'isFixedHeight': false,
                                                                'isFixedWidth': false
                                                              }
                                                            ],
                                                            'messages': {},
                                                            'api': {
                                                              'url': '/gather/stage/info/share',
                                                              'method': 'post',
                                                              'messages': {},
                                                              'requestAdaptor': '',
                                                              'adaptor': '',
                                                              'dataType': 'json',
                                                              'data': {
                                                                'type': 1,
                                                                'stageId': '${id}'
                                                              }
                                                            }
                                                          }
                                                        ],
                                                        'id': 'u:6f99a844c371',
                                                        'actions': []
                                                      }
                                                    }
                                                  ]
                                                }
                                              },
                                              'id': 'u:f2bd3b139631',
                                              'level': 'link'
                                            },
                                            {
                                              'type': 'button',
                                              'label': '修改',
                                              'onEvent': {
                                                'click': {
                                                  'actions': [
                                                    {
                                                      'ignoreError': false,
                                                      'actionType': 'drawer',
                                                      'drawer': {
                                                        'type': 'drawer',
                                                        'title': '编辑',
                                                        'body': [
                                                          {
                                                            'type': 'form',
                                                            'id': 'u:35c3af7117a9',
                                                            'title': '表单',
                                                            'body': [
                                                              {
                                                                'type': 'input-text',
                                                                'label': 'id',
                                                                'name': 'id',
                                                                'id': 'u:f7566dd0f2c2',
                                                                'hidden': true
                                                              },
                                                              {
                                                                'type': 'input-text',
                                                                'label': '组态类型',
                                                                'name': 'stageType',
                                                                'id': 'u:a4f9f9082323',
                                                                'value': '1',
                                                                'hidden': true,
                                                                'readOnly': true
                                                              },
                                                              {
                                                                'type': 'tree-select',
                                                                'label': '所属项目',
                                                                'name': 'projectId',
                                                                'id': 'CREATE:所属项目',
                                                                'multiple': false,
                                                                'onEvent': {},
                                                                'enableNodePath': false,
                                                                'showIcon': true,
                                                                'initiallyOpen': true,
                                                                'source': {
                                                                  'url': '/system/project/info/projectAllTree',
                                                                  'method': 'get'
                                                                },
                                                                'labelField': 'projectName',
                                                                'valueField': 'id',
                                                                'required': true,
                                                                'searchable': true
                                                              },
                                                              {
                                                                'type': 'input-text',
                                                                'label': '组态名称',
                                                                'name': 'stageName',
                                                                'id': 'u:eadf3148a116'
                                                              },
                                                              {
                                                                'type': 'input-text',
                                                                'label': '分辨率宽度',
                                                                'name': 'resolutionWidth',
                                                                'id': 'u:ffd2e8dcb42e',
                                                                'mode': 'inline',
                                                                'className': 'ml-7'
                                                              },
                                                              {
                                                                'type': 'input-text',
                                                                'name': 'resolutionHeight',
                                                                'label': 'x 高度',
                                                                'id': 'u:11d8348c9f29',
                                                                'mode': 'inline'
                                                              },
                                                              {
                                                                'type': 'switch',
                                                                'label': '状态',
                                                                'name': 'status',
                                                                'id': 'u:67239c2b989d',
                                                                'option': '',
                                                                'falseValue': 0,
                                                                'trueValue': 1,
                                                                'value': 1
                                                              },
                                                              {
                                                                'type': 'textarea',
                                                                'label': '备注',
                                                                'name': 'stageMemo',
                                                                'id': 'u:0d58c9d42906',
                                                                'minRows': 3,
                                                                'maxRows': 20
                                                              }
                                                            ],
                                                            'name': '独立组态',
                                                            'api': {
                                                              'url': '/gather/stage/info/edit',
                                                              'method': 'post',
                                                              'requestAdaptor': '',
                                                              'adaptor': 'response.data = {};\r\nreturn response;',
                                                              'messages': {},
                                                              'dataType': 'json'
                                                            },
                                                            'onEvent': {
                                                              'submitSucc': {
                                                                'weight': 0,
                                                                'actions': [
                                                                  {
                                                                    'componentId': 'u:48f462f67630',
                                                                    'ignoreError': false,
                                                                    'actionType': 'reload',
                                                                    'args': {
                                                                      'resetPage': true
                                                                    }
                                                                  }
                                                                ]
                                                              }
                                                            }
                                                          }
                                                        ],
                                                        'className': 'app-popover',
                                                        'id': 'u:74058ed82f89',
                                                        'resizable': false,
                                                        'size': 'lg',
                                                        'closeOnOutside': true
                                                      }
                                                    }
                                                  ]
                                                }
                                              },
                                              'id': 'u:192a4a1d220c',
                                              'level': 'link'
                                            },
                                            {
                                              'type': 'button',
                                              'label': '复制',
                                              'onEvent': {
                                                'click': {
                                                  'actions': [
                                                    {
                                                      'ignoreError': false,
                                                      'actionType': 'ajax',
                                                      'outputVar': 'responseResult',
                                                      'options': {},
                                                      'api': {
                                                        'url': '/gather/stage/info/copy',
                                                        'method': 'post',
                                                        'requestAdaptor': '',
                                                        'adaptor': '',
                                                        'messages': {},
                                                        'data': {
                                                          '&': '$$',
                                                          'id': '${id}'
                                                        }
                                                      }
                                                    },
                                                    {
                                                      'componentId': 'u:48f462f67630',
                                                      'ignoreError': false,
                                                      'actionType': 'reload',
                                                      'args': {
                                                        'resetPage': false
                                                      }
                                                    }
                                                  ]
                                                }
                                              },
                                              'id': 'u:2fe1b08abd9f',
                                              'level': 'link'
                                            },
                                            {
                                              'type': 'button',
                                              'label': '删除',
                                              'onEvent': {
                                                'click': {
                                                  'actions': [
                                                    {
                                                      'ignoreError': false,
                                                      'outputVar': 'responseResult',
                                                      'actionType': 'ajax',
                                                      'options': {},
                                                      'api': {
                                                        'url': '/gather/stage/info/delete?id=${id}&objectId=${objectId}',
                                                        'method': 'post',
                                                        'requestAdaptor': 'var id = api.data.id;\r\napi.data=[];\r\napi.data[0]=id;\r\nreturn api;',
                                                        'adaptor': '',
                                                        'messages': {},
                                                        'dataType': 'json',
                                                        'data': {
                                                          '&': '$$'
                                                        }
                                                      }
                                                    },
                                                    {
                                                      'ignoreError': false,
                                                      'actionType': 'reload',
                                                      'componentId': 'u:48f462f67630',
                                                      'args': {
                                                        'resetPage': true
                                                      }
                                                    }
                                                  ]
                                                }
                                              },
                                              'id': 'u:20d3b44b8d2e',
                                              'level': 'link'
                                            }
                                          ]
                                        }
                                      ],
                                      'bulkActions': [],
                                      'itemActions': [],
                                      'features': [],
                                      'filterColumnCount': 3,
                                      'id': 'u:48f462f67630',
                                      'perPageAvailable': [
                                        10,
                                        20
                                      ],
                                      'name': '独立组态',
                                      'messages': {},
                                      'headerToolbar': [
                                        {
                                          'type': 'bulk-actions',
                                          'align': 'left'
                                        },
                                        {
                                          'type': 'export-excel',
                                          'tpl': '内容',
                                          'wrapperComponent': '',
                                          'id': 'u:5377ddbfd09a'
                                        },
                                        {
                                          'type': 'columns-toggler',
                                          'tpl': '内容',
                                          'wrapperComponent': '',
                                          'align': 'right'
                                        },
                                        {
                                          'type': 'drag-toggler',
                                          'tpl': '内容',
                                          'wrapperComponent': '',
                                          'align': 'right'
                                        },
                                        {
                                          'type': 'pagination',
                                          'tpl': '内容',
                                          'wrapperComponent': '',
                                          'align': 'right'
                                        }
                                      ],
                                      'alwaysShowPagination': true,
                                      'perPage': 10,
                                      'maxKeepItemSelectionLength': 11,
                                      'keepItemSelectionOnPageChange': true,
                                      'pageField': 'pageNum',
                                      'perPageField': 'pageSize',
                                      'labelTpl': '${id}',
                                      'filter': null,
                                      'filterTogglable': true,
                                      'autoGenerateFilter': {
                                        'columnsNum': 4,
                                        'showBtnToolbar': false
                                      },
                                      'footerToolbar': [
                                        {
                                          'type': 'statistics',
                                          'tpl': '内容',
                                          'wrapperComponent': ''
                                        },
                                        {
                                          'type': 'pagination',
                                          'tpl': '内容',
                                          'wrapperComponent': '',
                                          'layout': 'perpage,pager,go'
                                        }
                                      ]
                                    }
                                  ],
                                  'id': 'u:23882ba7f486'
                                },
                                {
                                  'title': '模板组态',
                                  'body': [
                                    {
                                      'type': 'crud',
                                      'id': 'u:48f462f67630',
                                      'syncLocation': false,
                                      'api': {
                                        'method': 'get',
                                        'url': '/gather/stage/info/page?stageType=2&sampleId=${sampleId}',
                                        'messages': {},
                                        'requestAdaptor': '',
                                        'adaptor': ''
                                      },
                                      'columns': [
                                        {
                                          'name': 'projectId',
                                          'label': '所属项目',
                                          'type': 'mapping',
                                          'id': 'LIST:所属项目',
                                          'searchable': {
                                            'name': 'projectId',
                                            'type': 'tree-select',
                                            'label': '所属项目',
                                            'multiple': false,
                                            'onEvent': {},
                                            'enableNodePath': false,
                                            'showIcon': true,
                                            'initiallyOpen': true,
                                            'source': {
                                              'url': '/system/project/info/projectAllTree',
                                              'method': 'get'
                                            },
                                            'labelField': 'projectName',
                                            'valueField': 'id',
                                            'id': 'u:40bd28cde89c',
                                            'searchable': true
                                          },
                                          'source': {
                                            'url': '/system/project/info/dataSource',
                                            'method': 'get'
                                          }
                                        },
                                        {
                                          'name': 'stageName',
                                          'label': '组态名称',
                                          'type': 'text',
                                          'id': 'u:100228ca74eb',
                                          'searchable': true
                                        },
                                        {
                                          'type': 'text',
                                          'label': '分辨率宽度',
                                          'name': 'resolutionWidth',
                                          'id': 'u:4391eed23b25'
                                        },
                                        {
                                          'name': 'resolutionHeight',
                                          'label': '分辨率高度',
                                          'type': 'text',
                                          'id': 'u:676014e9bdaf'
                                        },
                                        {
                                          'type': 'image',
                                          'label': '图片',
                                          'name': 'stageBase64',
                                          'id': 'u:63f269eac847',
                                          'placeholder': '-',
                                          'enlargeAble': true
                                        },
                                        {
                                          'type': 'switch',
                                          'label': '状态',
                                          'name': 'status',
                                          'id': 'u:7e7ff8996c61',
                                          'option': '',
                                          'falseValue': 0,
                                          'trueValue': 1,
                                          'onEvent': {
                                            'change': {
                                              'weight': 0,
                                              'actions': [
                                                {
                                                  'ignoreError': false,
                                                  'outputVar': 'responseResult',
                                                  'actionType': 'ajax',
                                                  'options': {},
                                                  'api': {
                                                    'url': '/gather/stage/info/updateStatus',
                                                    'method': 'post',
                                                    'requestAdaptor': '',
                                                    'adaptor': '',
                                                    'messages': {},
                                                    'data': {
                                                      '&': '$$',
                                                      'id': '${id}'
                                                    },
                                                    'dataType': 'json'
                                                  }
                                                },
                                                {
                                                  'componentId': 'u:48f462f67630',
                                                  'ignoreError': false,
                                                  'actionType': 'reload',
                                                  'args': {
                                                    'resetPage': false
                                                  }
                                                }
                                              ]
                                            }
                                          },
                                          'searchable': {
                                            'type': 'select',
                                            'label': '状态',
                                            'clearable': true,
                                            'name': 'status',
                                            'options': [
                                              {
                                                'label': '启用',
                                                'value': 1
                                              },
                                              {
                                                'label': '禁用',
                                                'value': 0
                                              }
                                            ],
                                            'id': 'u:ace4c43c42fe',
                                            'multiple': false
                                          },
                                          'value': false
                                        },
                                        {
                                          'type': 'operation',
                                          'label': '操作',
                                          'id': 'u:5962c0829fa8',
                                          'buttons': [
                                            {
                                              'type': 'button',
                                              'label': '设计',
                                              'onEvent': {
                                                'click': {
                                                  'actions': [
                                                    {
                                                      'ignoreError': false,
                                                      'actionType': 'url',
                                                      'args': {
                                                        'url': '${window:location[origin]}/static/byzt/index_edit.html',
                                                        'params': {
                                                          'stageId': '${id}',
                                                          'projectId': '${projectId}',
                                                          'stageName': '${stageName}',
                                                          'type': '2',
                                                          'height': '${resolutionHeight}',
                                                          'width': '${resolutionWidth}',
                                                          'token': '${token}',
                                                          'sampleId': '${sampleId}'
                                                        }
                                                      }
                                                    }
                                                  ]
                                                }
                                              },
                                              'id': 'u:6546ace0466c',
                                              'level': 'link'
                                            },
                                            {
                                              'type': 'button',
                                              'label': '分享',
                                              'onEvent': {
                                                'click': {
                                                  'actions': [
                                                    {
                                                      'ignoreError': true,
                                                      'actionType': 'dialog',
                                                      'dialog': {
                                                        'type': 'dialog',
                                                        'title': '选择设备',
                                                        'body': [
                                                          {
                                                            'type': 'form',
                                                            'name': '独立组态',
                                                            'id': 'u:35c3af7117a9',
                                                            'body': [
                                                              {
                                                                'type': 'input-text',
                                                                'id': 'u:f7566dd0f2c2',
                                                                'label': 'id',
                                                                'name': 'id',
                                                                'hidden': true
                                                              },
                                                              {
                                                                'type': 'input-text',
                                                                'id': 'u:7a8484dafb4e',
                                                                'label': '项目id',
                                                                'name': 'projectId',
                                                                'hidden': true
                                                              },
                                                              {
                                                                'type': 'input-text',
                                                                'label': '模板id',
                                                                'name': 'sampleId',
                                                                'id': 'u:e937ba65ddea',
                                                                'hidden': true
                                                              },
                                                              {
                                                                'type': 'select',
                                                                'label': '模板下设备',
                                                                'name': 'devId',
                                                                'id': 'u:32f81607e6f6',
                                                                'multiple': false,
                                                                'source': {
                                                                  'url': '/gather/stage/info/deviceList?projectId=${projectId}&sampleId=${sampleId}',
                                                                  'method': 'get',
                                                                  'requestAdaptor': '',
                                                                  'adaptor': '',
                                                                  'messages': {}
                                                                },
                                                                'labelField': 'deviceName',
                                                                'valueField': 'id',
                                                                'clearable': true,
                                                                'onEvent': {
                                                                  'change': {
                                                                    'weight': 0,
                                                                    'actions': [
                                                                      {
                                                                        'componentId': 'u:be4729df26aa',
                                                                        'ignoreError': false,
                                                                        'actionType': 'setValue',
                                                                        'args': {
                                                                          'value': '${event.data.selectedItems.deviceNo}'
                                                                        }
                                                                      }
                                                                    ]
                                                                  }
                                                                },
                                                                'required': true
                                                              },
                                                              {
                                                                'type': 'input-text',
                                                                'label': '设备编号',
                                                                'name': 'deviceNo',
                                                                'id': 'u:be4729df26aa',
                                                                'onEvent': {},
                                                                'hidden': false,
                                                                'readOnly': true
                                                              }
                                                            ],
                                                            'title': '表单',
                                                            'api': {
                                                              'url': '/gather/stage/info/share',
                                                              'method': 'post',
                                                              'messages': {},
                                                              'requestAdaptor': '',
                                                              'adaptor': '',
                                                              'dataType': 'json',
                                                              'data': {
                                                                'type': 2,
                                                                'stageId': '${id}',
                                                                'devId': '${devId}'
                                                              }
                                                            },
                                                            'onEvent': {
                                                              'validateSucc': {
                                                                'weight': 0,
                                                                'actions': [
                                                                  {
                                                                    'ignoreError': false,
                                                                    'actionType': 'dialog',
                                                                    'dialog': {
                                                                      'type': 'dialog',
                                                                      'title': '分享链接',
                                                                      'confirmBtnLevel': 'primary',
                                                                      'body': [
                                                                        {
                                                                          'type': 'tpl',
                                                                          'id': 'u:44a34bb76286',
                                                                          'tpl': "<span style='word-break:break-all;'>${window:location[origin]}/static/byzt/client_view.html?stageId=${id}&stageName=${stageName}&sampleId=${sampleId}&devId=${devId}&deviceNo=${deviceNo}&type=2&token=${token}</span>",
                                                                          'inline': true,
                                                                          'wrapperComponent': '',
                                                                          'style': {
                                                                            'boxShadow': ' 0px 0px 0px 0px transparent'
                                                                          }
                                                                        },
                                                                        {
                                                                          'type': 'flex',
                                                                          'className': 'p-1',
                                                                          'items': [
                                                                            {
                                                                              'type': 'container',
                                                                              'body': [
                                                                                {
                                                                                  'type': 'button',
                                                                                  'label': '取消',
                                                                                  'id': 'u:a58a4a57f915',
                                                                                  'level': 'default',
                                                                                  'onEvent': {
                                                                                    'click': {
                                                                                      'weight': 0,
                                                                                      'actions': []
                                                                                    }
                                                                                  },
                                                                                  'close': true,
                                                                                  'className': 'mr-2 className-a58a4a57f915',
                                                                                  'themeCss': {
                                                                                    'className': {
                                                                                      'padding-and-margin:default': {
                                                                                        'marginTop': '0',
                                                                                        'marginRight': '10px',
                                                                                        'marginBottom': '0',
                                                                                        'marginLeft': '0'
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                },
                                                                                {
                                                                                  'type': 'button',
                                                                                  'label': '复制',
                                                                                  'onEvent': {
                                                                                    'click': {
                                                                                      'actions': [
                                                                                        {
                                                                                          'actionType': 'copy',
                                                                                          'args': {
                                                                                            'content': '${locationUrl}/static/byzt/client_view.html?stageId=${id}&stageName=${stageName}&sampleId=${sampleId}&devId=${devId}&deviceNo=${deviceNo}&type=2&token=${token}',
                                                                                            'copyFormat': 'text/plain'
                                                                                          },
                                                                                          'ignoreError': false
                                                                                        }
                                                                                      ]
                                                                                    }
                                                                                  },
                                                                                  'id': 'u:a06c47b1f3bf',
                                                                                  'level': 'primary',
                                                                                  'close': true
                                                                                }
                                                                              ],
                                                                              'size': 'xs',
                                                                              'style': {
                                                                                'position': 'static',
                                                                                'display': 'flex',
                                                                                'flex': '1 1 auto',
                                                                                'flexGrow': 1,
                                                                                'flexBasis': 'auto',
                                                                                'boxShadow': ' 0px 0px 0px 0px transparent',
                                                                                'flexWrap': 'nowrap',
                                                                                'justifyContent': 'flex-end',
                                                                                'alignItems': 'flex-end',
                                                                                'marginTop': '20px',
                                                                                'marginRight': '0',
                                                                                'marginBottom': '0',
                                                                                'marginLeft': '0'
                                                                              },
                                                                              'wrapperBody': false,
                                                                              'isFixedHeight': false,
                                                                              'isFixedWidth': false,
                                                                              'id': 'u:6bf826c6feba'
                                                                            }
                                                                          ],
                                                                          'style': {
                                                                            'position': 'relative',
                                                                            'inset': 'auto',
                                                                            'flexWrap': 'nowrap',
                                                                            'boxShadow': ' 0px 0px 0px 0px transparent'
                                                                          },
                                                                          'id': 'u:49affa0663ff',
                                                                          'isFixedHeight': false,
                                                                          'isFixedWidth': false
                                                                        }
                                                                      ],
                                                                      'id': 'u:6f99a844c371',
                                                                      'actions': []
                                                                    },
                                                                    'close': false
                                                                  }
                                                                ],
                                                                'close': false
                                                              }
                                                            },
                                                            'closeDialogOnSubmit': false
                                                          }
                                                        ],
                                                        'id': 'u:bf7469976e89',
                                                        'showCloseButton': true,
                                                        'showErrorMsg': true,
                                                        'showLoading': true,
                                                        'className': 'app-popover',
                                                        'closeOnEsc': false,
                                                        'close': false
                                                      }
                                                    }
                                                  ]
                                                }
                                              },
                                              'id': 'u:f2bd3b139631',
                                              'level': 'link'
                                            },
                                            {
                                              'type': 'button',
                                              'label': '修改',
                                              'onEvent': {
                                                'click': {
                                                  'actions': [
                                                    {
                                                      'ignoreError': false,
                                                      'actionType': 'drawer',
                                                      'drawer': {
                                                        'type': 'drawer',
                                                        'title': '编辑',
                                                        'body': [
                                                          {
                                                            'type': 'form',
                                                            'id': 'u:35c3af7117a9',
                                                            'title': '表单',
                                                            'body': [
                                                              {
                                                                'type': 'input-text',
                                                                'label': 'id',
                                                                'name': 'id',
                                                                'id': 'u:f7566dd0f2c2',
                                                                'hidden': true
                                                              },
                                                              {
                                                                'type': 'input-text',
                                                                'label': '组态类型',
                                                                'name': 'stageType',
                                                                'id': 'u:a4f9f9082323',
                                                                'value': '2',
                                                                'hidden': true,
                                                                'readOnly': true
                                                              },
                                                              {
                                                                'type': 'tree-select',
                                                                'label': '所属项目',
                                                                'name': 'projectId',
                                                                'id': 'CREATE:所属项目',
                                                                'multiple': false,
                                                                'onEvent': {},
                                                                'enableNodePath': false,
                                                                'showIcon': true,
                                                                'initiallyOpen': true,
                                                                'source': {
                                                                  'url': '/system/project/info/projectAllTree',
                                                                  'method': 'get'
                                                                },
                                                                'labelField': 'projectName',
                                                                'valueField': 'id',
                                                                'required': true,
                                                                'searchable': true
                                                              },
                                                              {
                                                                'type': 'select',
                                                                'label': '设备类型',
                                                                'name': 'deviceType',
                                                                'id': 'u:9356d704ffb9',
                                                                'multiple': false,
                                                                'required': true,
                                                                'options': [
                                                                  {
                                                                    'label': '直连设备',
                                                                    'value': 1
                                                                  },
                                                                  {
                                                                    'label': '网关设备',
                                                                    'value': 2
                                                                  },
                                                                  {
                                                                    'label': '网关子设备',
                                                                    'value': 0
                                                                  }
                                                                ],
                                                                'value': ''
                                                              },
                                                              {
                                                                'type': 'tree-select',
                                                                'label': '所属模板',
                                                                'name': 'sampleId',
                                                                'id': 'CREATE:所属项目',
                                                                'multiple': false,
                                                                'onEvent': {},
                                                                'enableNodePath': false,
                                                                'showIcon': true,
                                                                'initiallyOpen': true,
                                                                'source': {
                                                                  'url': '/gather/sample/info/getSampleDataSourceAmis?sampleType=${deviceType}',
                                                                  'method': 'get',
                                                                  'requestAdaptor': '',
                                                                  'adaptor': 'payload = payload.options;\r\nreturn payload;',
                                                                  'messages': {}
                                                                },
                                                                'labelField': 'label',
                                                                'valueField': 'value',
                                                                'required': true,
                                                                'searchable': true
                                                              },
                                                              {
                                                                'type': 'input-text',
                                                                'name': 'stageName',
                                                                'label': '组态名称',
                                                                'id': 'u:eadf3148a116'
                                                              },
                                                              {
                                                                'type': 'input-text',
                                                                'label': '分辨率宽度',
                                                                'name': 'resolutionWidth',
                                                                'id': 'u:ffd2e8dcb42e',
                                                                'mode': 'inline',
                                                                'className': 'ml-7'
                                                              },
                                                              {
                                                                'type': 'input-text',
                                                                'label': 'x 高度',
                                                                'name': 'resolutionHeight',
                                                                'id': 'u:11d8348c9f29',
                                                                'mode': 'inline'
                                                              },
                                                              {
                                                                'type': 'switch',
                                                                'label': '状态',
                                                                'name': 'status',
                                                                'id': 'u:67239c2b989d',
                                                                'option': '',
                                                                'falseValue': 0,
                                                                'trueValue': 1,
                                                                'value': 1
                                                              },
                                                              {
                                                                'type': 'textarea',
                                                                'label': '备注',
                                                                'name': 'stageMemo',
                                                                'id': 'u:0d58c9d42906',
                                                                'minRows': 3,
                                                                'maxRows': 20
                                                              }
                                                            ],
                                                            'name': '独立组态',
                                                            'api': {
                                                              'url': '/gather/stage/info/edit',
                                                              'method': 'post',
                                                              'requestAdaptor': '',
                                                              'adaptor': 'response.data = {};\r\nreturn response;',
                                                              'messages': {},
                                                              'dataType': 'json'
                                                            },
                                                            'onEvent': {
                                                              'submitSucc': {
                                                                'weight': 0,
                                                                'actions': [
                                                                  {
                                                                    'componentId': 'u:48f462f67630',
                                                                    'ignoreError': false,
                                                                    'actionType': 'reload',
                                                                    'args': {
                                                                      'resetPage': true
                                                                    }
                                                                  }
                                                                ]
                                                              }
                                                            }
                                                          }
                                                        ],
                                                        'className': 'app-popover',
                                                        'id': 'u:74058ed82f89',
                                                        'resizable': false,
                                                        'size': 'lg',
                                                        'closeOnOutside': true
                                                      }
                                                    }
                                                  ]
                                                }
                                              },
                                              'id': 'u:192a4a1d220c',
                                              'level': 'link'
                                            },
                                            {
                                              'type': 'button',
                                              'label': '复制',
                                              'onEvent': {
                                                'click': {
                                                  'actions': [
                                                    {
                                                      'ignoreError': false,
                                                      'actionType': 'ajax',
                                                      'outputVar': 'responseResult',
                                                      'options': {},
                                                      'api': {
                                                        'url': '/gather/stage/info/copy',
                                                        'method': 'post',
                                                        'requestAdaptor': '',
                                                        'adaptor': '',
                                                        'messages': {},
                                                        'data': {
                                                          '&': '$$',
                                                          'id': '${id}'
                                                        }
                                                      }
                                                    },
                                                    {
                                                      'componentId': 'u:48f462f67630',
                                                      'ignoreError': false,
                                                      'actionType': 'reload',
                                                      'args': {
                                                        'resetPage': false
                                                      }
                                                    }
                                                  ]
                                                }
                                              },
                                              'id': 'u:2fe1b08abd9f',
                                              'level': 'link'
                                            },
                                            {
                                              'type': 'button',
                                              'label': '删除',
                                              'onEvent': {
                                                'click': {
                                                  'actions': [
                                                    {
                                                      'ignoreError': false,
                                                      'outputVar': 'responseResult',
                                                      'actionType': 'ajax',
                                                      'options': {},
                                                      'api': {
                                                        'url': '/gather/stage/info/delete?id=${id}&objectId=${objectId}',
                                                        'method': 'post',
                                                        'requestAdaptor': 'var id = api.data.id;\r\napi.data=[];\r\napi.data[0]=id;\r\nreturn api;',
                                                        'adaptor': '',
                                                        'messages': {},
                                                        'dataType': 'json',
                                                        'data': {
                                                          '&': '$$'
                                                        }
                                                      }
                                                    },
                                                    {
                                                      'ignoreError': false,
                                                      'actionType': 'reload',
                                                      'componentId': 'u:48f462f67630',
                                                      'args': {
                                                        'resetPage': true
                                                      }
                                                    }
                                                  ]
                                                }
                                              },
                                              'id': 'u:20d3b44b8d2e',
                                              'level': 'link'
                                            }
                                          ]
                                        }
                                      ],
                                      'bulkActions': [],
                                      'itemActions': [],
                                      'features': [],
                                      'filterColumnCount': 3,
                                      'perPageAvailable': [
                                        10,
                                        20
                                      ],
                                      'name': '独立组态',
                                      'messages': {},
                                      'headerToolbar': [
                                        {
                                          'type': 'bulk-actions',
                                          'align': 'left'
                                        },
                                        {
                                          'type': 'export-excel',
                                          'tpl': '内容',
                                          'wrapperComponent': '',
                                          'id': 'u:5377ddbfd09a'
                                        },
                                        {
                                          'type': 'button',
                                          'tpl': '内容',
                                          'wrapperComponent': '',
                                          'label': '新增',
                                          'align': 'left',
                                          'id': 'u:76f6472136d6',
                                          'level': 'primary',
                                          'onEvent': {
                                            'click': {
                                              'weight': 0,
                                              'actions': [
                                                {
                                                  'ignoreError': false,
                                                  'actionType': 'drawer',
                                                  'drawer': {
                                                    'type': 'drawer',
                                                    'title': '新增',
                                                    'body': [
                                                      {
                                                        'type': 'form',
                                                        'title': '表单',
                                                        'body': [
                                                          {
                                                            'type': 'input-text',
                                                            'label': '组态类型',
                                                            'name': 'stageType',
                                                            'id': 'u:578d5471cc7a',
                                                            'hidden': true,
                                                            'readOnly': true,
                                                            'static': false,
                                                            'value': '2'
                                                          },
                                                          {
                                                            'type': 'tree-select',
                                                            'label': '所属项目',
                                                            'name': 'projectId',
                                                            'id': 'CREATE:所属项目',
                                                            'multiple': false,
                                                            'onEvent': {},
                                                            'enableNodePath': false,
                                                            'showIcon': true,
                                                            'initiallyOpen': true,
                                                            'source': {
                                                              'url': '/system/project/info/projectAllTree',
                                                              'method': 'get'
                                                            },
                                                            'labelField': 'projectName',
                                                            'valueField': 'id',
                                                            'required': true,
                                                            'searchable': true
                                                          },
                                                          {
                                                            'type': 'select',
                                                            'label': '设备类型',
                                                            'name': 'deviceType',
                                                            'id': 'u:9356d704ffb9',
                                                            'multiple': false,
                                                            'required': true,
                                                            'options': [
                                                              {
                                                                'label': '直连设备',
                                                                'value': 1
                                                              },
                                                              {
                                                                'label': '网关设备',
                                                                'value': 2
                                                              },
                                                              {
                                                                'label': '网关子设备',
                                                                'value': 0
                                                              }
                                                            ],
                                                            'value': ''
                                                          },
                                                          {
                                                            'type': 'tree-select',
                                                            'label': '所属模板',
                                                            'name': 'sampleId',
                                                            'id': 'CREATE:所属项目',
                                                            'multiple': false,
                                                            'onEvent': {},
                                                            'enableNodePath': false,
                                                            'showIcon': true,
                                                            'initiallyOpen': true,
                                                            'source': {
                                                              'url': '/gather/sample/info/getSampleDataSourceAmis?sampleType=${deviceType}',
                                                              'method': 'get',
                                                              'requestAdaptor': '',
                                                              'adaptor': 'response.data.value=null;\r\nreturn response;',
                                                              'messages': {}
                                                            },
                                                            'labelField': 'label',
                                                            'valueField': 'value',
                                                            'required': true,
                                                            'searchable': true
                                                          },
                                                          {
                                                            'type': 'input-text',
                                                            'name': 'stageName',
                                                            'label': '组态名称',
                                                            'id': 'u:eadf3148a116'
                                                          },
                                                          {
                                                            'type': 'input-text',
                                                            'label': '分辨率宽度',
                                                            'name': 'resolutionWidth',
                                                            'id': 'u:ffd2e8dcb42e',
                                                            'mode': 'inline',
                                                            'className': 'ml-7'
                                                          },
                                                          {
                                                            'type': 'input-text',
                                                            'label': 'x 高度',
                                                            'name': 'resolutionHeight',
                                                            'id': 'u:11d8348c9f29',
                                                            'mode': 'inline'
                                                          },
                                                          {
                                                            'type': 'switch',
                                                            'label': '状态',
                                                            'name': 'status',
                                                            'id': 'u:67239c2b989d',
                                                            'option': '',
                                                            'falseValue': 0,
                                                            'trueValue': 1,
                                                            'value': 1
                                                          },
                                                          {
                                                            'type': 'textarea',
                                                            'label': '备注',
                                                            'name': 'stageMemo',
                                                            'id': 'u:0d58c9d42906',
                                                            'minRows': 3,
                                                            'maxRows': 20
                                                          }
                                                        ],
                                                        'id': 'u:35c3af7117a9',
                                                        'name': '独立组态',
                                                        'api': {
                                                          'url': '/gather/stage/info/add',
                                                          'method': 'post',
                                                          'requestAdaptor': '',
                                                          'adaptor': 'response.data = {};\r\nreturn response;',
                                                          'messages': {},
                                                          'dataType': 'json'
                                                        },
                                                        'onEvent': {
                                                          'submitSucc': {
                                                            'weight': 0,
                                                            'actions': [
                                                              {
                                                                'componentId': 'u:48f462f67630',
                                                                'ignoreError': false,
                                                                'actionType': 'reload',
                                                                'args': {
                                                                  'resetPage': true
                                                                }
                                                              }
                                                            ]
                                                          }
                                                        }
                                                      }
                                                    ],
                                                    'className': 'app-popover',
                                                    'id': 'u:74058ed82f89',
                                                    'resizable': false,
                                                    'size': 'lg',
                                                    'closeOnOutside': true
                                                  }
                                                }
                                              ]
                                            }
                                          }
                                        },
                                        {
                                          'type': 'columns-toggler',
                                          'tpl': '内容',
                                          'wrapperComponent': '',
                                          'align': 'right'
                                        },
                                        {
                                          'type': 'drag-toggler',
                                          'tpl': '内容',
                                          'wrapperComponent': '',
                                          'align': 'right'
                                        },
                                        {
                                          'type': 'pagination',
                                          'tpl': '内容',
                                          'wrapperComponent': '',
                                          'align': 'right'
                                        }
                                      ],
                                      'alwaysShowPagination': true,
                                      'perPage': 10,
                                      'maxKeepItemSelectionLength': 11,
                                      'keepItemSelectionOnPageChange': true,
                                      'pageField': 'pageNum',
                                      'perPageField': 'pageSize',
                                      'labelTpl': '${id}',
                                      'filter': null,
                                      'filterTogglable': true,
                                      'autoGenerateFilter': {
                                        'columnsNum': 4,
                                        'showBtnToolbar': false
                                      },
                                      'footerToolbar': [
                                        {
                                          'type': 'statistics',
                                          'tpl': '内容',
                                          'wrapperComponent': ''
                                        },
                                        {
                                          'type': 'pagination',
                                          'tpl': '内容',
                                          'wrapperComponent': '',
                                          'layout': 'perpage,pager,go'
                                        }
                                      ]
                                    }
                                  ],
                                  'id': 'u:4d647baace14'
                                }
                              ],
                              'id': 'u:5c2c8a3d6d37',
                              'tabsMode': 'radio'
                            }
                          ],
                          'id': 'u:a62508156b47'
                        },
                        {
                          'title': '规则',
                          'body': [
                            {
                              'type': 'crud',
                              'id': 'AlarmRule:table',
                              'api': {
                                'method': 'get',
                                'url': '/gather/rule/info/page?ruleObjectId=${deviceId}',
                                'messages': {},
                                'requestAdaptor': '',
                                'adaptor': '',
                                'dataType': 'json',
                                'data': {
                                  '&': '$$',
                                  'objectId': '${objectId}'
                                }
                              },
                              'columns': [
                                {
                                  'name': 'ruleName',
                                  'label': '规则名称',
                                  'type': 'text',
                                  'id': 'LIST:规则名称',
                                  'searchable': {
                                    'clearable': 'true',
                                    'id': 'u:cc43ed3835ef',
                                    'name': '规则列表',
                                    'type': 'input-text'
                                  },
                                  'placeholder': '-'
                                },
                                {
                                  'name': 'projectId',
                                  'label': '所属项目',
                                  'type': 'mapping',
                                  'id': 'LIST:所属项目',
                                  'searchable': {
                                    'id': 'u:8c1eeb1ea671',
                                    'name': 'projectId',
                                    'type': 'tree-select',
                                    'label': '所属项目',
                                    'multiple': false,
                                    'onEvent': {},
                                    'enableNodePath': false,
                                    'showIcon': true,
                                    'initiallyOpen': true,
                                    'source': {
                                      'url': '/system/project/info/projectAllTree',
                                      'method': 'get'
                                    },
                                    'labelField': 'projectName',
                                    'valueField': 'id'
                                  },
                                  'source': {
                                    'url': '/system/project/info/dataSource',
                                    'method': 'get'
                                  }
                                },
                                {
                                  'type': 'switch',
                                  'label': '状态',
                                  'name': 'status',
                                  'id': 'u:7e7ff8996c61',
                                  'option': '',
                                  'falseValue': 0,
                                  'trueValue': 1,
                                  'onEvent': {
                                    'change': {
                                      'weight': 0,
                                      'actions': [
                                        {
                                          'ignoreError': false,
                                          'outputVar': 'responseResult',
                                          'actionType': 'ajax',
                                          'options': {},
                                          'api': {
                                            'url': '/gather/rule/info/updateStatus',
                                            'method': 'post',
                                            'requestAdaptor': '',
                                            'adaptor': '',
                                            'messages': {},
                                            'data': {
                                              '&': '$$',
                                              'id': '${id}'
                                            },
                                            'dataType': 'json'
                                          }
                                        }
                                      ]
                                    }
                                  },
                                  'searchable': {
                                    'type': 'select',
                                    'label': '状态',
                                    'name': 'status',
                                    'options': [
                                      {
                                        'label': '启用',
                                        'value': 1
                                      },
                                      {
                                        'label': '禁用',
                                        'value': 0
                                      }
                                    ],
                                    'id': 'u:ace4c43c42fe',
                                    'multiple': false,
                                    'clearable': true
                                  },
                                  'value': false
                                },
                                {
                                  'type': 'mapping',
                                  'map': {
                                    '1': '协议解析前',
                                    '2': '协议解析后',
                                    '3': '数据存储前',
                                    '4': '数据存储后'
                                  },
                                  'itemSchema': {
                                    'type': 'tag',
                                    'label': '${item}'
                                  },
                                  'name': 'ruleTriggerType',
                                  'label': '触发节点',
                                  'id': 'u:7193932bcd4a',
                                  'placeholder': '未配置'
                                },
                                {
                                  'type': 'text',
                                  'label': '创建时间',
                                  'name': 'createTime',
                                  'id': 'LIST:创建时间'
                                },
                                {
                                  'type': 'text',
                                  'label': '排序',
                                  'name': 'sort',
                                  'id': 'LIST:排序'
                                },
                                {
                                  'type': 'text',
                                  'label': '备注',
                                  'name': 'remark',
                                  'id': 'LIST:备注'
                                },
                                {
                                  'type': 'operation',
                                  'label': '操作',
                                  'id': 'u:3ba1b5bedd76',
                                  'buttons': [
                                    {
                                      'type': 'button',
                                      'label': '规则配置',
                                      'onEvent': {
                                        'click': {
                                          'actions': [
                                            {
                                              'ignoreError': false,
                                              'actionType': 'dialog',
                                              'dialog': {
                                                'size': 'lg',
                                                'type': 'dialog',
                                                'title': '报警规则配置',
                                                'body': [
                                                  {
                                                    'type': 'form',
                                                    'title': '表单',
                                                    'body': [
                                                      {
                                                        'id': 'u:ac04c8b2a7d7',
                                                        'type': 'fieldset',
                                                        'title': '基础配置',
                                                        'body': [
                                                          {
                                                            'name': 'ruleName',
                                                            'label': '规则名称',
                                                            'id': 'UPDATE:规则名称',
                                                            'type': 'input-text'
                                                          },
                                                          {
                                                            'name': 'id',
                                                            'label': '规则Id',
                                                            'id': 'UPDATE:所属项目',
                                                            'type': 'input-text',
                                                            'hidden': true,
                                                            'required': true,
                                                            'value': ''
                                                          },
                                                          {
                                                            'name': 'projectId',
                                                            'label': '所属项目',
                                                            'id': 'UPDATE:所属项目',
                                                            'type': 'input-text',
                                                            'hidden': true
                                                          },
                                                          {
                                                            'name': 'status',
                                                            'label': '状态',
                                                            'id': 'u:f81a71a40fc2',
                                                            'type': 'switch',
                                                            'option': '',
                                                            'falseValue': 0,
                                                            'trueValue': 1,
                                                            'value': 0,
                                                            'hidden': true
                                                          },
                                                          {
                                                            'name': 'sort',
                                                            'label': '排序',
                                                            'id': 'u:92c81c844665',
                                                            'type': 'input-number',
                                                            'keyboard': true,
                                                            'required': true
                                                          },
                                                          {
                                                            'name': 'remark',
                                                            'label': '备注',
                                                            'id': 'UPDATE:备注',
                                                            'type': 'input-text'
                                                          }
                                                        ],
                                                        'collapsable': true
                                                      },
                                                      {
                                                        'id': 'u:70c088b997f7',
                                                        'type': 'fieldset',
                                                        'title': '对象配置',
                                                        'collapsable': true,
                                                        'body': [
                                                          {
                                                            'type': 'select',
                                                            'label': '触发节点',
                                                            'name': 'ruleTriggerType',
                                                            'options': [
                                                              {
                                                                'label': '协议解析前',
                                                                'value': 1
                                                              },
                                                              {
                                                                'label': '协议解析后',
                                                                'value': 2
                                                              },
                                                              {
                                                                'label': '数据存储前',
                                                                'value': 3
                                                              },
                                                              {
                                                                'label': '数据存储后',
                                                                'value': 4
                                                              }
                                                            ],
                                                            'id': 'u:4fa9c5beb67f',
                                                            'value': 1,
                                                            'required': true,
                                                            'multiple': false,
                                                            'checkAll': false
                                                          },
                                                          {
                                                            'type': 'radios',
                                                            'label': '触发对象类型',
                                                            'name': 'ruleType',
                                                            'id': 'u:d57d8b8d6cc5',
                                                            'required': true,
                                                            'options': [
                                                              {
                                                                'label': '设备',
                                                                'value': 1
                                                              },
                                                              {
                                                                'label': '模板',
                                                                'value': 2
                                                              }
                                                            ],
                                                            'value': 1,
                                                            'selectFirst': false
                                                          },
                                                          {
                                                            'type': 'tree-select',
                                                            'label': '设备源',
                                                            'name': 'ruleObjectId',
                                                            'id': 'u:385f6470cc83',
                                                            'required': true,
                                                            'multiple': false,
                                                            'source': {
                                                              'url': '/gather/trigger/info/getDeviceSource?triggerType=${ruleType}',
                                                              'method': 'get'
                                                            },
                                                            'onEvent': {},
                                                            'enableNodePath': false,
                                                            'showIcon': true,
                                                            'initiallyOpen': true
                                                          },
                                                          {
                                                            'type': 'switch',
                                                            'label': '筛选条件',
                                                            'name': 'isFilter',
                                                            'id': 'u:e10cd1f0ea3b',
                                                            'value': false,
                                                            'option': '开启后执行规则需满足条件',
                                                            'falseValue': false,
                                                            'trueValue': true,
                                                            'hidden': true
                                                          },
                                                          {
                                                            'name': 'ruleAttrId',
                                                            'label': '设备属性',
                                                            'id': 'u:51708f0109b8',
                                                            'type': 'select',
                                                            'hiddenOn': '${!isFilter}',
                                                            'mode': 'inline',
                                                            'multiple': false,
                                                            'size': 'sm',
                                                            'staticClassName': '',
                                                            'className': 'm-l-xl',
                                                            'source': {
                                                              'url': '/gather/trigger/info/getDeviceAttrSource?triggerObjectId=${ruleObjectId}&triggerType=${ruleType}',
                                                              'method': 'get'
                                                            }
                                                          },
                                                          {
                                                            'name': 'ruleCondition',
                                                            'label': '',
                                                            'id': 'u:190849f7ecf6',
                                                            'type': 'select',
                                                            'hiddenOn': '${!isFilter}',
                                                            'mode': 'inline',
                                                            'options': [
                                                              {
                                                                'label': '开关OFF',
                                                                'value': 0
                                                              },
                                                              {
                                                                'label': '开关ON',
                                                                'value': 1
                                                              },
                                                              {
                                                                'label': '数值小于A',
                                                                'value': 2
                                                              },
                                                              {
                                                                'label': '数值大于B',
                                                                'value': 3
                                                              },
                                                              {
                                                                'label': '数值大于A且小于B',
                                                                'value': 4
                                                              },
                                                              {
                                                                'label': '数值小于A或大于B',
                                                                'value': 5
                                                              },
                                                              {
                                                                'label': '数值等于A',
                                                                'value': 6
                                                              }
                                                            ],
                                                            'value': 2,
                                                            'multiple': false,
                                                            'required': true,
                                                            'size': 'sm'
                                                          },
                                                          {
                                                            'name': 'ruleValueA',
                                                            'label': '',
                                                            'id': 'UPDATE:A值',
                                                            'type': 'input-text',
                                                            'hiddenOn': '${(ruleCondition!=2&&ruleCondition!=4&&ruleCondition!=5&&ruleCondition!=6)||!isFilter}',
                                                            'mode': 'inline',
                                                            'placeholder': 'A值'
                                                          },
                                                          {
                                                            'name': 'ruleValueB',
                                                            'label': '',
                                                            'id': 'UPDATE:B值',
                                                            'type': 'input-text',
                                                            'hiddenOn': '${(ruleCondition!=3&&ruleCondition!=4&&ruleCondition!=5)||!isFilter}',
                                                            'mode': 'inline',
                                                            'placeholder': 'B值'
                                                          }
                                                        ]
                                                      },
                                                      {
                                                        'type': 'fieldset',
                                                        'title': '规则动作',
                                                        'collapsable': true,
                                                        'body': [
                                                          {
                                                            'type': 'select',
                                                            'label': '动作类型',
                                                            'name': 'ruleComponentType',
                                                            'id': 'u:4fa9c5beb67f',
                                                            'value': 2,
                                                            'required': true,
                                                            'multiple': false,
                                                            'checkAll': false,
                                                            'options': [
                                                              {
                                                                'label': '标准动作',
                                                                'value': 1
                                                              },
                                                              {
                                                                'label': '自定义脚本',
                                                                'value': 2
                                                              }
                                                            ]
                                                          },
                                                          {
                                                            'type': 'select',
                                                            'label': '动作',
                                                            'name': 'ruleComponentId',
                                                            'id': 'u:09d234b0617f',
                                                            'multiple': false,
                                                            'checkAll': false,
                                                            'options': [
                                                              {
                                                                'label': '微信公众号报警',
                                                                'value': 1
                                                              },
                                                              {
                                                                'label': '邮件报警',
                                                                'value': 2
                                                              },
                                                              {
                                                                'label': '短信报警',
                                                                'value': 3
                                                              },
                                                              {
                                                                'label': '电话报警',
                                                                'value': 4
                                                              }
                                                            ],
                                                            'hidden': true
                                                          },
                                                          {
                                                            'type': 'editor',
                                                            'label': '自定义脚本',
                                                            'name': 'script',
                                                            'id': 'u:704c6feef2dc',
                                                            'language': 'java',
                                                            'value': 'import com.yomahub.liteflow.slot.DefaultContext;\nimport com.yomahub.liteflow.spi.holder.ContextAwareHolder;\nimport com.yomahub.liteflow.script.body.JaninoCommonScriptBody;\nimport com.yomahub.liteflow.script.ScriptExecuteWrap;\nimport com.zbtech.iot.core.engine.context.MessageContext;\nimport com.zbtech.gather.modular.device.engine.context.GatherMessageContext;\n\n\npublic class Demo implements JaninoCommonScriptBody{\n    public Void body(ScriptExecuteWrap wrap){\n        MessageContext context = (MessageContext)wrap.cmp.getContextBean(MessageContext.class);\n        GatherMessageContext dataContext = (GatherMessageContext)wrap.cmp.getContextBean(GatherMessageContext.class);\n\n        return null;\n    }\n}'
                                                          }
                                                        ],
                                                        'id': 'u:e69e4d607f8a'
                                                      }
                                                    ],
                                                    'id': 'u:203cbc4fd268',
                                                    'api': {
                                                      'url': '/gather/rule/info/config',
                                                      'method': 'post',
                                                      'requestAdaptor': '',
                                                      'adaptor': 'response.data={};\r\nreturn response;',
                                                      'messages': {
                                                        'success': '更新成功',
                                                        'failed': '更新失败'
                                                      },
                                                      'dataType': 'json',
                                                      'data': {
                                                        '&': '$$',
                                                        'objectId': '${objectId}'
                                                      }
                                                    },
                                                    'onEvent': {
                                                      'submitSucc': {
                                                        'weight': 0,
                                                        'actions': [
                                                          {
                                                            'componentId': 'AlarmRule:table',
                                                            'ignoreError': false,
                                                            'actionType': 'reload',
                                                            'args': {
                                                              'resetPage': true
                                                            }
                                                          }
                                                        ]
                                                      }
                                                    },
                                                    'name': '报警规则',
                                                    'initApi': {
                                                      'url': '/gather/rule/info/detail?id=${id}',
                                                      'method': 'get',
                                                      'requestAdaptor': '',
                                                      'adaptor': '',
                                                      'messages': {},
                                                      'dataType': 'json'
                                                    }
                                                  }
                                                ],
                                                'showCloseButton': true,
                                                'showErrorMsg': true,
                                                'showLoading': true,
                                                'className': 'app-popover',
                                                'id': 'u:9ba0f77d4e62',
                                                'resizable': false
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      'id': 'AlarmRule:update',
                                      'level': 'link',
                                      'hiddenOn': "${permissions!='*:*:*'&&!ARRAYINCLUDES(permissions,'gather:rule:edit')}"
                                    },
                                    {
                                      'type': 'button',
                                      'label': '删除',
                                      'onEvent': {
                                        'click': {
                                          'actions': [
                                            {
                                              'ignoreError': false,
                                              'outputVar': 'responseResult',
                                              'actionType': 'ajax',
                                              'options': {
                                                'silent': false
                                              },
                                              'api': {
                                                'url': '/gather/rule/info/delete?id=${id}',
                                                'method': 'post',
                                                'requestAdaptor': 'var id = api.data.id;\r\napi.data=[];\r\napi.data[0]=id;\r\nreturn api;',
                                                'adaptor': '',
                                                'messages': {},
                                                'dataType': 'json',
                                                'replaceData': false,
                                                'data': {
                                                  '&': '$$'
                                                }
                                              }
                                            },
                                            {
                                              'componentId': 'AlarmRule:table',
                                              'ignoreError': false,
                                              'actionType': 'reload',
                                              'args': {
                                                'resetPage': true
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      'id': 'AlarmRule:delete',
                                      'level': 'link',
                                      'confirmText': '数据删除后无法恢复，请确认!',
                                      'hiddenOn': "${permissions!='*:*:*'&&!ARRAYINCLUDES(permissions,'gather:rule:delete')}"
                                    }
                                  ]
                                }
                              ],
                              'bulkActions': [],
                              'itemActions': [],
                              'perPageAvailable': [
                                10,
                                20
                              ],
                              'messages': {},
                              'pageField': 'pageNum',
                              'perPageField': 'pageSize',
                              'features': [
                                'filter'
                              ],
                              'headerToolbar': [
                                'bulkActions',
                                {
                                  'type': 'export-excel',
                                  'id': 'u:0e71cdedac66',
                                  'hiddenOn': "${permissions!='*:*:*'&&!ARRAYINCLUDES(permissions,'gather:trigger:export')}"
                                },
                                {
                                  'type': 'columns-toggler',
                                  'align': 'right'
                                },
                                {
                                  'type': 'drag-toggler',
                                  'align': 'right'
                                },
                                {
                                  'type': 'pagination',
                                  'align': 'right'
                                }
                              ],
                              'footerToolbar': [
                                {
                                  'type': 'statistics'
                                },
                                {
                                  'type': 'pagination',
                                  'layout': 'perPage,pager,go'
                                }
                              ],
                              'alwaysShowPagination': true,
                              'draggable': false,
                              'perPage': 10,
                              'keepItemSelectionOnPageChange': true,
                              'maxKeepItemSelectionLength': 11,
                              'labelTpl': '${id}',
                              'autoGenerateFilter': {
                                'columnsNum': 4,
                                'showBtnToolbar': false
                              },
                              'quickSaveApi': '',
                              'quickSaveItemApi': '',
                              'filterTogglable': true,
                              'filterSettingSource': [
                                'objectCode',
                                'objectName',
                                'tableName',
                                'id',
                                'createTime'
                              ],
                              'onEvent': {},
                              'footable': false,
                              'name': '设备详情demo'
                            }
                          ],
                          'id': 'u:653e672b2965',
                          'hiddenOn': "${permissions!='*:*:*'&&!ARRAYINCLUDES(permissions,'gather:rule:list')}"
                        },
                        {
                          'title': '调试',
                          'body': [
                            {
                              'id': 'u:9875d91e8486',
                              'type': 'log',
                              'autoScroll': true,
                              'height': 500,
                              'encoding': 'utf-8',
                              'source': {
                                'url': 'http://192.168.1.52:8080/admin-api/gather/device/debug/logs',
                                'method': 'get',
                                'requestAdaptor': '',
                                'adaptor': '',
                                'messages': {}
                              },
                              'credentials': 'same-origin',
                              'operation': 'restart,clear,showLineNumber,stop'
                            }
                          ],
                          'id': 'u:ce7676cdf332',
                          'hidden': 'true'
                        }
                      ],
                      'mountOnEnter': true,
                      'unmountOnExit': true,
                      'onEvent': {
                        'change': {
                          'weight': 0,
                          'actions': [
                            {
                              'componentId': 'u:048c2e359e49',
                              'ignoreError': false,
                              'actionType': 'reload',
                              'expression': '${event.data.value==1}'
                            }
                          ]
                        }
                      }
                    }
                  ],
                  'name': '设备列表',
                  'messages': {},
                  'api': {
                    'url': '/gather/device/info/detail?id=${deviceId}',
                    'method': 'get',
                    'requestAdaptor': '',
                    'adaptor': 'response.data.deviceId= response.data.id;\r\nreturn response;',
                    'dataType': 'json'
                  },
                  'onEvent': {
                    'fetchInited': {
                      'weight': 0,
                      'actions': [
                        {
                          'componentId': 'u:104133c688dd',
                          'ignoreError': false,
                          'actionType': 'reload'
                        }
                      ]
                    }
                  }
                }
              ],
              'showCloseButton': true,
              'showErrorMsg': true,
              'showLoading': true,
              'className': 'app-popover',
              'id': 'u:3c55f7f2f583',
              'width': '80%',
              'resizable': false,
              'headClassName': 'm-none p-none no-border w-xxs',
              'actions': [],
              'footClassName': 'm-none p-none'
            }
          }
        ]
      }
    },
    'id': 'u:1a484815ca6f',
    'themeCss': {
      'className': {
        'background:default': 'rgba(255, 158, 48, 0.11)',
        'font:default': {
          'color': '#ff9e30'
        },
        'background:hover': 'rgba(255, 158, 48, 0.11)',
        'font:hover': {
          'color': '#ff9e30'
        },
        'border:hover': {
          'border': '0'
        },
        'border:default': {
          'border': '0'
        },
        'background:active': 'rgba(255, 158, 48, 0.11)',
        'font:active': {
          'color': '#ff9e30'
        },
        'border:active': {
          'border': '0'
        }
      }
    },
    'className': 'className-1a484815ca6f',
    'hiddenOn': "${permissions!='*:*:*'&&!ARRAYINCLUDES(permissions,'gather:device:detail')}",
    'icon': 'fas fa-file-alt'
  };

  // 容器类组件必需字段
  // regions = [
  //   {
  //     key: 'body',
  //     label: '内容区'
  //   }
  // ];

  panelTitle = '配置'; // 右侧属性面板Title

  panelControls = [ // 右侧属性面板配置项

  ];
}

registerEditorPlugin(InfoGridPlugin)
