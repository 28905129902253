/* eslint-disable no-template-curly-in-string */
/**
 * @file 编辑器扩展, 增加自定义组件
 */
import { BasePlugin, registerEditorPlugin } from 'amis-editor'

export class InfoComponentPlugin extends BasePlugin {
  // 关联渲染器名字
  rendererName = 'info-component2';
  $schema = '/schemas/UnkownSchema.json';
  notRenderFormZone = true;
  // 组件名称（组件面板显示的Title）
  name = '子组件2.0';
  order=0; // 排序
  description = '详情页面展示信息';
  tags = ['组件', 'A自定义'];
  icon = 'fa fa-file-code-o';
  scaffold = { // 插入到页面时需要
    'type': 'service',
    'body': [],
    'id': 'u:c96ad399bb50',
    'dsType': 'api',
    'schemaApi': {
      'url': '/amis/menu/content',
      'method': 'get',
      'requestAdaptor': '',
      'adaptor': "let schemaStr = response.data\r\nif(schemaStr){\r\n  let schemaObj = response.data?JSON.parse(response.data) : {}\r\n  if(schemaObj.definitions) {\r\n    for(let key in schemaObj.definitions) {\r\n      if(schemaStr.indexOf('{\"$ref\":\"'+key+'\"}')!=-1){\r\n        schemaStr = schemaStr.replace('{\"$ref\":\"'+key+'\"}',JSON.stringify(schemaObj.definitions[key]))\r\n      }\r\n    }\r\n  }\r\n  response['data'] = response.data?JSON.parse(schemaStr) : {}\r\n}\r\n\r\nreturn response",
      'messages': {},
      'data': {
        'menuId': 2934
      }
    },
    'initFetchSchema': true,
    'onEvent': {
      'fetchSchemaInited': {
        'weight': 0,
        'actions': [
          {
            'componentId': 'u:7bf6552bacfd',
            'ignoreError': false,
            'actionType': 'setValue',
            'args': {
              'value': {
                'isChild': true
              }
            }
          }
        ]
      }
    }
  };
  previewSchema = { // 组件面板预览时需要
    'type': 'service',
    'body': [],
    'id': 'u:c96ad399bb50',
    'dsType': 'api',
    'schemaApi': {
      'url': '/amis/menu/content',
      'method': 'get',
      'requestAdaptor': '',
      'adaptor': "let schemaStr = response.data\r\nif(schemaStr){\r\n  let schemaObj = response.data?JSON.parse(response.data) : {}\r\n  if(schemaObj.definitions) {\r\n    for(let key in schemaObj.definitions) {\r\n      if(schemaStr.indexOf('{\"$ref\":\"'+key+'\"}')!=-1){\r\n        schemaStr = schemaStr.replace('{\"$ref\":\"'+key+'\"}',JSON.stringify(schemaObj.definitions[key]))\r\n      }\r\n    }\r\n  }\r\n  response['data'] = response.data?JSON.parse(schemaStr) : {}\r\n}\r\n\r\nreturn response",
      'messages': {},
      'data': {
        'menuId': 2934
      }
    },
    'initFetchSchema': true,
    'onEvent': {
      'fetchSchemaInited': {
        'weight': 0,
        'actions': [
          {
            'componentId': 'u:7bf6552bacfd',
            'ignoreError': false,
            'actionType': 'setValue',
            'args': {
              'value': {
                'isChild': true
              }
            }
          }
        ]
      }
    }
  };

  // 容器类组件必需字段
  // regions = [
  //   {
  //     key: 'body',
  //     label: '内容区'
  //   }
  // ];

  panelTitle = '配置'; // 右侧属性面板Title

  panelControls = [ // 右侧属性面板配置项

  ];
}

registerEditorPlugin(InfoComponentPlugin)
