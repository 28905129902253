<template>
  <a-dropdown :trigger="['click']" v-if="nickname" placement="bottomRight" :overlayStyle="{zIndex:9999999}" @visibleChange="visibleChangeHanle">
    <span class="ant-pro-account-avatar" style="padding-left: 0;min-width: 152px;justify-content: flex-end;padding-right: 5px;padding-left: 5px;" >
      <!-- <span class="avatar_border"></span> -->
      <a-avatar size="small" style="width: 35px;height: 35px;flex-shrink: 0;" :src="avatar" class="antd-pro-global-header-index-avatar" />
      <div class="nickname" style="width: 100%;">
        <span class="font14M" style="color: #333333;line-height: 14px;margin-bottom: 6px;">{{ nickname }}</span>
        <span class="font10M" style="color: #999999;line-height: 12px;">{{ accountType }}</span>
      </div>
      
      <!-- <img style="width: 7px;margin-left:8px" src="@/assets/homeImg/avatar_xl.png" alt="" srcset=""> -->
    </span>
    <template v-slot:overlay>
      <div class="user-info">
        <div class="user-info-top">
          <span class="font16M" style="color: #333333;">{{ nickname }}</span>
          <span class="font14R" style="color: #999999;margin: 12px 0 16px;">账号ID {{ userInfo.userId }}</span>
          <div class="account-btn">
            <span class="font16R">{{ accountType }}</span>
          </div>
        </div>
        <div class="user-info-money">
          <div class="user-info-money-box">
            <div class="user-info-money-box-item">
              <div class="user-info-money-box-item-price">
                <span class="font11B">￥</span>
                <span class="font18B">{{ userInfo.withdrawal||0 }}</span>
              </div>
              <span class="user-info-money-box-item-name font14R">可提现</span>
            </div>
            <div class="user-info-money-box-item">
              <div class="user-info-money-box-item-price">
                <span class="font11B">￥</span>
                <span class="font18B">{{ userInfo.alreadyWithdrawal||0 }}</span>
              </div>
              <span class="user-info-money-box-item-name font14R">已提现</span>
            </div>
            <div class="user-info-money-box-item">
              <div class="user-info-money-box-item-price">
                <span class="font11B">￥</span>
                <span class="font18B">{{ userInfo.totalRevenue||0 }}</span>
              </div>
              <span class="user-info-money-box-item-name font14R">总收益</span>
            </div>
          </div>
          <div class="user-info-money-btn">
            <!-- <div class="user-info-money-btn-item" @click="handleToPage('/caiwuguanli/chongzhizhongxin')">
              <span class="font14R">充值</span>
            </div> -->

            <div class="user-info-money-btn-item" @click="handleToPage('/yonghuguanli/dailishang/detail')">
              <span class="font14R">提现</span>
            </div>
          </div>
        </div>

        <div v-if="userInfo.roleCodes.includes('agent_admin')||userInfo.roleCodes.includes('site_admin')||userInfo.roleCodes.includes('site_user')" class="user-info-li" @click="handleToPage('/account/center')">
          <img src="@/assets/topBtn/10.png">
          <span class="font16R">个人中心</span>
        </div>
        <div v-hasPermi="['monitor:logininfor:list']" class="user-info-li" @click="handleToPage('/system/logininfor')">
          <img src="@/assets/topBtn/11.png">
          <span class="font16R">登录日志</span>
        </div>
        <div class="user-info-li" @click="handleToPage('/account/settings')">
          <img src="@/assets/topBtn/12.png">
          <span class="font16R">我的资源</span>
        </div>
        <div class="user-info-li" v-if="false">
          <img src="@/assets/topBtn/13.png">
          <span class="font16R">平台电话</span>
        </div>
        <div class="user-info-li" @click="clearCachePage">
          <img src="@/assets/topBtn/14.png">
          <span class="font16R">清除缓存</span>
        </div>

        <div class="user-info-out" @click="handleLogout">
          <span class="font16R">退出登录</span>
        </div>

      </div>
      
      <a-menu v-if="false" class="ant-pro-drop-down menu" :selected-keys="[]">
        <a-menu-item v-if="menu" key="center" @click="handleToPage('/account/center')">
          <a-icon type="user" />
          个人中心
        </a-menu-item>
        <a-menu-item v-if="menu" key="settings" @click="handleToPage('/account/settings')">
          <a-icon type="setting" />
          个人设置
        </a-menu-item>
        <!-- <a-menu-item v-if="menu" key="amis" @click="handleEdit">
          <a-icon type="setting" />
          编辑器
        </a-menu-item> -->
        <a-menu-divider v-if="menu" />
        <a-menu-item key="logout" @click="handleLogout">
          <a-icon type="logout" />
          退出登录
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
  <span v-else>
    <a-spin size="small" :style="{ marginLeft: 8, marginRight: 8 }" />
  </span>
</template>

<script>
import { Modal } from 'ant-design-vue'
import { mapGetters } from 'vuex'
import auth from '@/plugins/auth'

export default {
  name: 'AvatarDropdown',
  props: {
    menu: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters([
      'avatar',
      'nickname',
      'userInfo',
      'permissions'
    ]),
    accountType() {
      let text = '主账号'
      if (this.userInfo?.roleList?.length > 0) {
        const roleNames = []
        this.userInfo.roleList.forEach(item => {
          roleNames.push(item.roleName)
        })
        text = roleNames.join(',')
      }
      return text
    }
  },
  methods: {
    // 清理缓存
    clearCachePage() {
      location.reload()
    },
    /**
     * @desc 跳转页面
     * @param {  }
     * @version: 1.0.0
     */
    handleToPage(path) {
      if (path === '/caiwuguanli/chongzhizhongxin') {
        if (!auth.hasPermi('caiwuguanli:chongzhizhongxin:page')) {
          return
        }
      }
      if (path === '/yonghuguanli/dailishang/detail') {
        // this.$router.push({ path: path,
        //   query: {
        //     id: this.userInfo.userId,
        //     pageTab: 5
        //   } })
      } else if (path === '/account/center') {
        if (this.userInfo.roleCodes.includes('agent_admin')) {
          // 代理商
          this.$router.push({ path: '/yonghuguanli/dailishang/detail',
          query: {
            id: this.userInfo.userId
          } })
        } else if (this.userInfo.roleCodes.includes('site_admin')) {
          // 场地管理员
          this.$router.push({ path: '/yonghuguanli/changdifang/detail',
          query: {
            id: this.userInfo.userId
          } })
        } else if (this.userInfo.roleCodes.includes('site_user')) {
          // 场地维护人员
          this.$router.push({ path: '/yonghuguanli/weihurenyuan/detail',
          query: {
            id: this.userInfo.userId
          } })
        } else {
          this.$router.push({ path: path })
        }
      } else {
        this.$router.push({ path: path })
      }
    },
    // 菜单显示状态改变时调用，参数为 visible
    visibleChangeHanle(visible) {
      // console.log('菜单显示状态改变时调用，参数为 visible>>>>>', visible)
      if (visible) {
        this.$store.dispatch('GetInfo')
      }
    },
    handleToCenter () {
      this.$router.push({ path: '/account/center' })
    },
    handleToSettings () {
      this.$router.push({ path: '/account/settings' })
    },
    // 退出登录
    handleLogout (e) {
      Modal.confirm({
        title: '提示',
        content: '确定注销并退出系统吗？',
        onOk: () => {
          return this.$store.dispatch('Logout').then(() => {
            location.href = '/index'
          })
        },
        onCancel () {}
      })
    }
  }
}
</script>

<style lang="less" scoped>
.ant-pro-drop-down {
  /deep/ .action {
    margin-right: 8px;
  }
  /deep/ .ant-dropdown-menu-item {
    min-width: 160px;
  }
}

.nickname {
  display: flex;
  flex-direction: column;
  // align-items: flex-end;
  justify-content: center;
  height: 64px;
  margin-right: 16px;
  text-align: left;

  span {
    flex-shrink: 0;
    line-height: 14px;
  }
}

.user-info {
  width: 380px;
  // height: 588px;
  margin-right: 16px;
  padding-bottom: 18px;
  background: #FFFFFF;
  box-shadow: 0px 6px 26px 1px rgba(0,0,0,0.08);
  border-radius: 12px 12px 12px 12px;
  opacity: 1;

  .user-info-top {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 128px;
    background: #F6F9FD;
    border-radius: 12px 12px 0px 0px;
    padding: 20px 30px 18px;
    opacity: 1;

    .account-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      // width: 93px;
      height: 32px;
      background: rgba(40, 120, 255, 0.05);
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      border: 1.5px solid #2878FF;
      cursor: pointer;
      span {
        color: #2878FF;
      }
    }
  }

  .user-info-money {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 30px;
    padding-top: 22px;
    padding-right: 16px;
    padding-bottom: 19px;
    margin-bottom: 6px;
    border-bottom: 1px solid #E2E2E2;

    .user-info-money-box {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      width: 100%;

      .user-info-money-box-item {
        display: flex;
        flex-direction: column;
        align-items: center;

        .user-info-money-box-item-price {
          margin-bottom: 10px;
          span {
            color: #2D2D2D;
          }
        }

        .user-info-money-box-item-name {
          color: #666666;
        }

      }

    }

    .user-info-money-btn {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 0 21px 0 27px;
      margin-top: 19px;

      .user-info-money-btn-item {
        width: 120px;
        height: 28px;
        background: #2878FF;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 2px solid #2878FF;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        span {
          color: #FFFFFF;
        }
      }

    }

  }

  .user-info-li {
    display: flex;
    width: 100%;
    padding: 16px 31px;
    cursor: pointer;
    img {
      width: 15px;
      height: 15px;
      margin-right: 18px;
    }
    span {
      color: #333;
    }
  }

  .user-info-out {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 316px;
    height: 46px;
    margin: 18px auto 0;
    background: #EFEFEF;
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
    cursor: pointer;
    span {
      color: #999999;
    }
  }
}
</style>
