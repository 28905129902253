/* eslint-disable no-template-curly-in-string */
/**
 * @file 编辑器扩展, 增加自定义组件
 */
import { BasePlugin, registerEditorPlugin } from 'amis-editor'

export class InfoGridPlugin extends BasePlugin {
  // 关联渲染器名字
  rendererName = 'cham-chart';
  $schema = '/schemas/UnkownSchema.json';
  notRenderFormZone = true;
  // 组件名称（组件面板显示的Title）
  name = 'chamchart';
  order = 0; // 排序
  description = '自定义chart';
  tags = ['展示', 'A自定义'];
  icon = 'fa fa-file-code-o';
  scaffold = { // 插入到页面时需要
    'type': 'chart',
    'config': {
      'xAxis': {
        'name': '时间(分钟)',
        'nameTextStyle': {
          'color': '#707070',
          'verticalAlign': 'top',
          'padding': [6, 0, 0, 0],
          'fontFamily': '思源-Bold'
        },
        'type': 'time',
        'boundaryGap': false,
        'axisTick': {
          'lineStyle': {
            'color': '#D7DBDE'
          }
        },
        'axisLine': {
          'lineStyle': {
            'color': '#D7DBDE'
          }
        },
        'axisLabel': {
          'color': '#707070'
        }
      },
      'yAxis': {
        'type': 'value',
        'name': '数量(次)',
        'nameTextStyle': {
          'fontSize': 12,
          'align': 'right',
          'padding': [0, 10, 10, 0],
          'color': '#707070'
        },
        'axisLabel': {
          'color': '#707070'
        },
        'splitLine': {
          'lineStyle': {
            'color': '#D7DBDE',
            'type': 'dashed'
          }
        }
      },
      'series': [
      ],
      'backgroundColor': 'transparent',
      'dataZoom': [
        {
          'start': 0,
          'end': 10
        }
      ],
      'tooltip': {
        'trigger': 'axis'
      }
    },
    'replaceChartOption': false,
    'id': 'u:104133c688dc',
    'name': 'historyChart',
    'style': {
      'boxShadow': ' 0px 0px 0px 0px transparent',
      'width': '100%',
      'height': '300px'
    },
    'api': {
      'url': '/gather/home/index/activeUser',
      'method': 'get',
      'requestAdaptor': '',
      'adaptor': '',
      'messages': {
      }
    },
    'initFetch': false,
    'dataFilter': "config.legend={\r\n  data:[]\r\n};\r\nconfig.series = data.items.map(function(item) { \r\n  config.legend.data.push(item.name);\r\n  let obj = { name: item.name, data:[],type:'line'}; \r\n  item.dps.forEach(function(itemDps) { \r\n    obj.data.push([formatDate(Number(itemDps.ts)), itemDps.val]) \r\n  }); \r\n  return obj;\r\n}); \r\n\r\nfunction formatDate(value) {var date = new Date(value);var y = date.getFullYear(),m = date.getMonth() + 1,d = date.getDate(),h = date.getHours(),i = date.getMinutes(),s = date.getSeconds();if (m < 10) { m = '0' + m; }if (d < 10) { d = '0' + d; }if (h < 10) { h = '0' + h; }if (i < 10) { i = '0' + i; }if (s < 10) { s = '0' + s; }var t = y + '-' + m + '-' + d + ' ' + h + ':' + i + ':' + s;return t;\r\n}\r\nconsole.log('config====',config);\r\n"
  };
  previewSchema = { // 组件面板预览时需要
    'type': 'chart',
    'config': {
      'xAxis': {
        'type': 'time',
        'id': 'u:f4c5d3195bad',
        'axisLabel': {
          'formatter': '{yyyy}-{MM}-{dd} {HH}:{mm}:{ss}'
        }
      },
      'yAxis': {
        'type': 'value',
        'id': 'u:8887928a6efc'
      },
      'series': [
      ],
      'backgroundColor': 'transparent',
      'dataZoom': [
        {
          'start': 0,
          'end': 10
        }
      ],
      'tooltip': {
        'trigger': 'axis'
      }
    },
    'replaceChartOption': false,
    'id': 'u:104133c688dc',
    'name': 'historyChart',
    'style': {
      'boxShadow': ' 0px 0px 0px 0px transparent',
      'width': '100%',
      'height': '300px'
    },
    'api': {
      'url': '/gather/home/index/activeUser',
      'method': 'get',
      'requestAdaptor': '',
      'adaptor': '',
      'messages': {
      }
    },
    'initFetch': false,
    'dataFilter': "config.legend={\r\n  data:[]\r\n};\r\nconfig.series = data.items.map(function(item) { \r\n  config.legend.data.push(item.name);\r\n  let obj = { name: item.name, data:[],type:'line'}; \r\n  item.dps.forEach(function(itemDps) { \r\n    obj.data.push([formatDate(Number(itemDps.ts)), itemDps.val]) \r\n  }); \r\n  return obj;\r\n}); \r\n\r\nfunction formatDate(value) {var date = new Date(value);var y = date.getFullYear(),m = date.getMonth() + 1,d = date.getDate(),h = date.getHours(),i = date.getMinutes(),s = date.getSeconds();if (m < 10) { m = '0' + m; }if (d < 10) { d = '0' + d; }if (h < 10) { h = '0' + h; }if (i < 10) { i = '0' + i; }if (s < 10) { s = '0' + s; }var t = y + '-' + m + '-' + d + ' ' + h + ':' + i + ':' + s;return t;\r\n}\r\nconsole.log('config====',config);\r\n"
  };

  // 容器类组件必需字段
  // regions = [
  //   {
  //     key: 'body',
  //     label: '内容区'
  //   }
  // ];

  panelTitle = '配置'; // 右侧属性面板Title

  panelControls = [ // 右侧属性面板配置项

  ];
}

registerEditorPlugin(InfoGridPlugin)
